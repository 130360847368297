@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  src: url(../font/Roboto-Regular.eot);
  src: url(../font/Roboto-Regular.eot?#iefix) format('embedded-opentype'), url(../font/Roboto-Regular.woff) format('woff'), url(../font/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(../font/Roboto-Medium-webfont.eot);
  src: url(../font/Roboto-Medium-webfont.eot?#iefix) format('embedded-opentype'), url(../font/Roboto-Medium-webfont.woff) format('woff'), url(../font/Roboto-Medium-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(../font/Roboto-Bold.eot);
  src: url(../font/Roboto-Bold.eot?#iefix) format('embedded-opentype'), url(../font/Roboto-Bold.woff) format('woff'), url(../font/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url(../font/Roboto-Light-webfont.eot);
  src: url(../font/Roboto-Light-webfont.eot?#iefix) format('embedded-opentype'), url(../font/Roboto-Light-webfont.woff) format('woff'), url(../font/Roboto-Light-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'notosans';
  src: url(../font/NotoSansCJKkr-Regular.eot);
  src: url(../font/NotoSansCJKkr-Regular.eot?#iefix) format('embedded-opentype'), url(../font/NotoSansCJKkr-Regular.woff) format('woff'), url(../font/NotoSansCJKkr-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'notosans';
  font-weight: 300;
  src: url(../font/notoSansCJKkr_Light.eot);
  src: url(../font/notoSansCJKkr_Light.eot?#iefix) format('embedded-opentype'), url(../font/notoSansCJKkr_Light.woff) format('woff'), url(../font/notoSansCJKkr_Light.ttf) format('truetype');
}

@font-face {
  font-family: 'notosans';
  font-weight: 500;
  src: url(../font/notoSansCJKkr_Medium.eot);
  src: url(../font/notoSansCJKkr_Medium.eot?#iefix) format('embedded-opentype'), url(../font/notoSansCJKkr_Medium.woff) format('woff'), url(../font/notoSansCJKkr_Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'NanumMyeongjo';
  font-weight: normal;
  src: url(../font/NanumMyeongjo.eot);
  src: url(../font/NanumMyeongjo.eot?#iefix) format('embedded-opentype'), url(../font/NanumMyeongjo.woff) format('woff'), url(../font/NanumMyeongjo.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow';
  src: url(../font/Barlow-Medium.eot?#iefix) format(embedded-opentype),  url(../font/Barlow-Medium.woff) format('woff'), url(../font/Barlow-Medium.ttf)  format('truetype');
  font-weight: normal;
  font-style: normal;
}