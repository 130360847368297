.community_page {
    .view_area {
        .ct {
            padding-bottom: 38px;

            img {
                display: block;
                margin: 80px auto 0;

                &+.attach_box {
                    margin-top: 34px;
                }
            }
        }
    }
}

.screenshot_slide {
    font-size: 0;
    text-align: center;

    li {
        display: inline-block;
        width: 538px;
        margin-left: 36px;
        border: 1px solid #ccc;
        text-align: left;

        &:first-child {
            margin-left: 0;
        }
    }

    a {
        display: block;
        position: relative;
        padding: 326px 28px 20px;
    }

    .img {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;

        .lbl {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 67px;
            height: 35px;
            font-size: 16px;
            line-height: 35px;
        }

        img {
            position: absolute;
            width: 100%;
        }
    }

    .tit {
        display: block;
        height: 42px;
        font-size: 0;
        font-weight: 400;
        line-height: 22px;
        color: $black2;

        .txt {
            overflow: hidden;
            font-size: 16px;
            &+.ico_attach{
                margin-left: 12px;
            }
        }
        
        .number {
            display: inline-block;
            margin-left: 12px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: $gray2;
            line-height: 18px;
            vertical-align: top;

            &+.txt_new {
                margin-left: 4px;
            }
        }

        .txt_new {
            margin-top: -2px;
        }

        .ico_attach {
            display: inline-block;
            margin-left: 4px;
        }
    }

    &.slick-slider {
        &:hover .slick-arrow {
            opacity: 0.8;
            transition: opacity 200ms ease;
        }
    
        .slick-slide:not(.slick-center) {
            margin-left: -35px;
            transform: scale(0.74074, 0.73933);
            transition: all 300ms ease;
    
            &:before {
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                z-index: 3;
                background-color: rgba(0,0,0,0.5);
                content: '';
            }

            a {
                padding: 326px 34px 20px 36px;
            }

            .lbl {
                transform: scale(1.35, 1.3526);
                transform-origin: 0 0;
            }

            .tit {
                font-size: 0px;
            }

            .txt{
                font-size: 18px;
                &+.ico_attach{
                    margin-left: 12px;
                }
            }

            .number{
                margin-top: 2px;
            }

            .txt_new {
                margin-top: -1px;
            }

            .ico_attach{
                margin-left: 4px;
            }

            .time {
                font-size: 17px;
            }

            .info {
                right: 65px;
                transform: scale(1.2, 1.2);
                transform-origin: 0 0;
            }
        }
    
        .slick-center {
            margin-left: -35px;
            transition: all 300ms ease;
        }
    
        .slick-arrow {
            position: absolute;
            top: 50%;
            z-index: 5;
            transform: translateY(-50%);
            opacity: 0;
            background-color: black;
            font-size: 0;
            outline: none;
            transition: opacity 200ms ease;
        }
    
        .slick-prev {
            left: 0;
            @include sp-normal('ico_slide_prev');
        }
    
        .slick-next {
            right: 0;
            @include sp-normal('ico_slide_next');
        }
    }
}

.tbl_img_box {
    border-top: 2px solid #384859;
    border-bottom: 1px solid #a4a4a4;

    .screenshot_slide {
        margin: 40px 0;
    }

    .tbl_img_lst {
        &:before {
            border-top: 1px solid #e5e5e5;
        }
 
        .nodata {
            &:after {
                content: none;
            }
        }

        a {
            padding-bottom: 22px;
        }

        .number {
            display: inline-block;
            margin-left: 12px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 300;
            color: $gray2;
            line-height: 24px;
            vertical-align: top;
            &+.txt_new {
                margin-left: 4px;
            }
        }

        .txt {
            max-width: 310px;
            &+.ico_attach{
                margin-left: 12px;
            }
        }

        .txt_new {
            margin-left: 12px;
        }

        .ico_attach {
            display: inline-block;
            margin: 6px 0 0 4px;
            vertical-align: top;
        }

        .time {
            margin-top: 5px;
        }
    }

    .name {
        &:before {
            margin-top: -2px;
        }
    }

    .time {
        display: inline-block;
        margin-top: 12px;
        line-height: 20px;

        &:before {
            content: none;
        }
    }

    .info {
        position: absolute;
        bottom: 24px;
        right: 28px;
    }

    .heart {
        margin-left: 12px;

        &:before {
            margin-top: -1px;
        }
    }
}

.attach_box {
    margin: 75px 0 0 31px;
    @extend .clearfix;
    &.open {
        .btn_toggle_attach {
            &:before {
                transform: rotate(180deg);
            }
        }
    }
    .attach_lst_wrap {
        float: left;
    }

    .btn_like {
        float: right;
        margin-top: 49px;
        .ico_heart_n {
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    .btn_toggle_attach {
        position: relative;
        padding-left: 22px;
        font-size: 14px;
        font-weight: normal;
        color: $gray2;
        outline: none;

        &:before {
            position: absolute;
            top: 8px;
            left: 0;
            @include sp-normal('ico_arr_down3');
            content: '';
        }
    }

    .lst {
        width: 433px;
        margin-top: 7px;

        li {
            margin-top: 10px;
            border: 1px solid #e5e5e5;
            line-height: 54px;

            &:first-child {
                margin-top: 0;
            }
        }

        a {
            position: relative;
            display: block;
            font-size: 0;
            padding-left: 20px;
        }

        .ico {
            display: inline-block;
            vertical-align: middle;
        }

        .filename {
            overflow: hidden;
            display: inline-block;
            max-width: 320px;
            margin-left: 7px;
            font-size: 14px;
            font-weight: 300;
            color: #444;
            letter-spacing: 0.02em;
            vertical-align: top;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .ico_download {
            position: absolute;
            top: 15px;
            right: 20px;
        }
    }
}