$screen-xsm: 320px; // Mobile Minimum Size
$screen-sm: 828px; // Mobile Maximum Size (iPhone6,7,8 Plus)
$screen-md: 1024px;
$screen-lg: 1366px; // Tablet Maximum Size (ipad Pro)
$screen-xlg: 1920px; // Desktop Maximum Size

$black: #000;
$black2: #222;
$white: #fff;

$red: #e02424;
$blue: #404dab;
$green: #509216;
$yellow: #b78331;
$purple: #a540ab;
$gray: #76777f;
$gray2: #868686;
$gray3: #a4a4a4;
$gray4: #ebebeb;