.service_page {
    .wrap {
        padding-top: 90px;
    }
    .breadcrumb {
        .select_lst {
            width: 155px;
        }
    }
    .inner{
        .emp_txt {
            margin-top: 22px;
            font-size: 18px;
            letter-spacing: -0.3px;
            color: #222;
            .btn {
                margin-left: 30px;
            }
        }
        &>.clearfix {
            .menu_depth_3 {
                float: left;
                margin-top: 0;
                .tab_lst {
                    width: auto;
                }
            }
            .search_box {
                float: right;
            }
            &:first-child {
                margin-top: 13px;
            }
        }
    }
    h2.sub_tit {
        font-weight: 400;
    }
    .select {
        .btn_open_select {
            color: #868686;
        }
        &.open .btn_open_select {
            color: #222222;
        }
    }
    .flow_guide_area {
        position: relative;
        text-align: center;
        .flow_lst {
            margin-top: 39px;
            li.active {
                margin-top: -16px;
            }
            &.flow_v2 {
                margin-top: 25px;
                li.active {
                    margin-top: 0;
                }
            }
        }
        .btn_flow {
            display: block;
            position: absolute;
            left: 50%;
            top: 71px;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            span {
                display: block;
            }
            &.prev {
                margin-left: -106px;
            }
            &.next {
                margin-left: 100px;
            }
        }
    }
    .sp_common_area {
        margin-top: 60px;
        h3 {
            font-size: 24px;
            font-weight: 400;
            color: #222222;
            letter-spacing: -1.4px;
            line-height: 22px;
            .btn {
                margin-left: 30px;
                vertical-align: text-top;
            }
            em {
                display: inline-block;
                margin-left: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #404dab;
                vertical-align: text-bottom;
                letter-spacing: 0;
            }
            small {
                display: inline-block;
                margin-left: 30px;
                font-size: 16px;
                font-weight: 300;
            }
            &+.dot_lst {
                margin-top: 21px;
            }
        }
        p {
            margin-top: 11px;
            line-height: 33px;
            letter-spacing: -0.1px;
            word-wrap:break-word;
            font-weight: 300;
            strong {
                font-weight: 400;
                color: #222222;
            }
            .emp_txt {
                margin-top: 0;
            }
            &+.tbl_lst {
                margin-top: 20px;
            }
            span.btn {
                margin: 0 4px;
                line-height: 23px;
            }
            &+img {
                margin-top: 22px;
            }
        }
        img + .tbl_lst {
            margin-top: 30px;
        }
        .dot_lst {
            margin-top: 14px;
            li~li {
                margin-top: 9px;
            }
        }
        .tbl_lst {
            margin-top: 20px;
            border-top: 1px solid #a4a4a4;
            &+.gray_bx {
                margin-top: 30px;
            }

            &.txt_left {
                th,
                td {
                    padding-left: 30px;
                }
            }
        }
        .emp_txt {
            display: block;
            margin-top: 13px;
            font-weight: 500;
            font-size: 15px;
        }
        &~.sp_common_area {
            margin-top: 78px;
        }
        &+.tbl_lst {
            margin-top: 56px;
            &~.tbl_lst {
                margin-top: 60px;
            }
        }
        &+.tabs_lnk_lst {
            margin-top: 28px;
        }
        .clearfix {
            .btn_grp {
                margin-top: 25px;
                .btn {
                    width: 100px;
                }
            }
            &+.tbl_lst {
                margin-top: 14px;
            }
        }
        .txt_note {
            margin-top: 20px;
            letter-spacing: -1.2px;
            .btn_sm {
                height: 26px;
                margin: 3px 7px 0;
                font-size: 12px;
                line-height: 26px;
                vertical-align: top;
            }
        }
        .term_txt_bx {
            margin-top: 21px;
            padding: 40px 0;
            border-top: 1px solid #a4a4a4;
            border-bottom: 1px solid #a4a4a4;
            font-weight: 300;
            .js_scrollbar {
                max-height: 350px;
                .sb-scrollbar-container {
                    right: 0;
                }
                .sb-content {
                    width: calc(100% + 17px);
                    padding: 0 30px;
                }
            }
            .term_tit {
                font-size: 20px;
                font-weight: bold;
            }
            strong {
                font-weight: 500;
            }
            ul {
                line-height: 25px;
            }
            .disc_lst {
                list-style: inside disc;
            }
        }
        .agr_chk_bx {
            margin-top: 18px;
            padding: 35px 40px;
            label {
                padding-left: 40px;
                font-size: 22px;
                font-weight: 500;
                color: #222222;
                &:before {
                    @extend .ico_chkbox2;
                }
            }
            input:checked + label:before {
                @extend .ico_chkbox2_checked;
            }
            .chk_detail {
                display: block;
                margin-top: 6px;
                margin-left: 40px;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
    .btn_grp {
        margin-top: 51px;
        text-align: center;
        .btn {
            vertical-align: top;
        }
        &.cs_btn_grp {
            margin-top: 120px;
        }
        &.ct_btn_grp {
            margin-top: 130px;
        }
        &.ge_btn_grp {
            margin-top: 60px;
        }
    }
    .sub_sticky+.inner {
        .sp_common_area {
            &:first-child {
                margin-top: 12px;
            }
            h3 {
                font-weight: 500;
            }
        }
    }
    .service_product_area {
        margin-top: 29px;
        padding-bottom: 50px;
        border-bottom: 1px solid #a4a4a4;
    }
    .ser_pro_lst {
        font-size: 0;
        li {
            display: inline-block;
            position: relative;
            width: 442px;
            height: 207px;
            padding: 0 38px;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            .ser_pro_cont {
                display: table-cell;
                height: inherit;
                vertical-align: middle;
            }
            &:after {
                display: none;
                position: absolute;
                top: -1px;
                left: -1px;
                bottom: -1px;
                right: -1px;
                background: rgba(0,0,0,.8);
                content: '';
            }
            &~li:not(:nth-child(3n+1)) {
                margin-left: 42px;
            }
            &:nth-child(3)~li {
                margin-top: 42px;
            }
            &:hover {
                &:after,.btn_grp {
                    display: block;
                }
            }
        }
        .tag {
            display: block;
            position: absolute;
            top: -1px;
            left: -1px;
            width: 66px;
            height: 30px;
            background: #404dab;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 30px;
            text-align: center;
        }
        .guideline {
            width: 250px;
            min-height: 100px;
            word-break: break-word;
            strong {
                display: block;
                font-size: 18px;
                font-weight: 500;
                line-height: 17px;
                color: #000;
            }
            p {
                margin-top: 7px;
                font-size: 14px;
                font-weight: 300;
                line-height: 18px;
                color: #222222;
            }
            small {
                display: block;
                margin-top: 4px;
                font-size: 14px;
                font-weight: 300;
                color: #868686;
            }
        }
        .price {
            display: block;
            margin-top: 1px;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            color: #000;
            &:before {
                display: inline-block;
                margin-right: 8px;
                @extend .ico_currency;
                vertical-align: top;
                content: '';
            }
        }
        .ico {
            position: absolute;
            left: 305px;
            top: 50%;
            transform: translateY(-50%);
        }
        .btn_grp {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            width: 100%;
            margin-top: 0;
            transform: translate(-50%,-50%);
            .btn {
                width: 130px;
                height: 50px;
                line-height: 50px;
                box-sizing: border-box;
                &~.btn {
                    margin-left: 10px;
                }
            }
        }
    }
    .tbl_lst {
        th.txt_left {
            padding-left: 30px;
        }
        .inp_txt {
            display: inline-block;
            width: auto;
            &.id_inp {
                width: 225px;
                &+.btn {
                    width: 100px;
                    margin-left: 10px;
                }
            }
        }
        .btn {
            font-size: 14px;
            vertical-align: middle;
            line-height: 36px;
        }
        .select ~ .select {
            margin-left: 12px;
        }
        dl {
            font-size: 0;
            dt,dd {
                display: inline-block;
                font-size: 16px;
            }
            dd {
                margin-left: 20px;
                &~dt {
                    margin-left: 30px;
                    &:before {
                        display: inline-block;
                        width: 1px;
                        height: 17px;
                        margin-right: 30px;
                        margin-top: 5px;
                        background: #c8c8cf;
                        vertical-align: top;
                        content: '';
                    }
                }
            }
        }
        .txt_msg {
            display: inline-block;
            font-size: 16px;
            font-weight: 300;
            color: #b83d3d;
        }
        .nodata {
            height: 219px;
            .txt_msg {
                margin-top: 17px;
                font-size: 16px;
                font-weight: 400;
                color: #868686;
            }
            .ico {
                margin-top: 2px;
            }
        }
    }
    .registry_step_lst {
        margin-top: 40px;
        &+.sp_common_area {
            margin-top: 80px;
            .dot_lst {
                margin-top: 13px !important;
                .txt_reg {
                    color: #222222;
                }
            }
        }
        .txt {
            white-space: nowrap;
        }
    }
    .step_lst {
        margin-top: 22px;
        padding: 42px 40px;
        .lst_itm {
            position: relative;
            height: 246px;
            padding-top: 86px;
            .step_ico {
                position: absolute;
                top: 46px;
                left: 50%;
                margin: 0;
                transform: translateX(-50%);
            }
            .step_tit {
                font-size: 20px;
            }
            .step_desc {
                margin-top: 11px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.7px;
            }
        }
    }
    .sl_server, .sl_char {
        padding: 30px 40px 30px 50px;
        margin-top: 17px;
        font-size: 0;
        @extend .clearfix;
        label {
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
        }
        .select {
            width: 210px;
            margin-left: 35px;
        }
        .btn.btn_aside {
            height: 50px;
            line-height: 50px;
        }
        .txt_gray {
            display: block;
            float: right;
            margin-top: 14px;
            letter-spacing: -0.35px;
            font-size: 14px;
            font-weight: 300;
            .btn_sm {
                min-width: 45px;
                height: 24px;
                margin: 0 3px;
                font-size: 13px;
                line-height: 24px;
                vertical-align: middle;
            }
        }
    }
    .sl_server {
        .btn.btn_aside {
            width: 107px;
        }
    }
    .sl_char {
        .btn.btn_aside {
            width: 126px;
            margin-left: 55px;
            border-radius: 2px;
        }
    }
    .tabs_lnk_lst {
        &+.tbl_lst {
            margin-top: 29px;
        }
        &:first-child {
            margin-top: 7px;
            letter-spacing: -0.8px;
        }
        &+.sp_common_area {
            margin-top: 70px;
        }
    }
    .impossible_itms {
        width: 360px;
    }
    .id_classification {
        width: 203px;
    }
    .lock_char_tbl {
        td {
            .btn {
                width: 100px;
                margin-left: 60px;
                font-size: 14px;
                line-height: 36px;
                vertical-align: middle;
            }
            &.txt_bold {
                font-weight: 400;
            }
            .txt_msg {
                margin-left: 55px;
            }
        }
    }
    .last_notice {
        margin-top: 127px;
        strong {
            font-size: 18px;
        }
        .dot_lst {
            margin-top: 6px;
            li {
                margin-top: 0;
                line-height: 35px;
                &:before {
                    top: 16px;
                }
            }
        }
    }
    .complete_msg_area {
        margin-top: 127px;
        text-align: center;
        font-size: 0;
        .ico_done {
            display: inline-block;
        }
        strong {
            display: block;
            margin-top: 32px;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: -1.3px;
        }
        small {
            display: block;
            margin-top: 3px;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: -0.3px;
            em {
                font-weight: 400;
                color: #222222;
            }
        }
        .sv_info_bx {
            margin-top: 54px;
        }
        &+.btn_grp {
            margin-top: 60px;
        }
    }
    .result_area {
        margin-top: 55px;
        .gray_bx {
            position: relative;
            padding: 42px 64px;
            &>span {
                font-size: 18px;
                font-weight: 500;
            }
            .res {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                .old_res {
                    font-size: 20px;
                    font-weight: 400;
                    color: #868686;
                }
                .new_res {
                    font-size: 20px;
                    font-weight: 500;
                    color: #404dab;
                }
                .ico_change_to {
                    display: inline-block;
                    margin: 0 60px;
                }
            }
        }
        .tbl_lst {
            margin-top: 30px;
        }
        &+.btn_grp {
            margin-top: 61px;
            .btn {
                font-weight: 400;
            }
        }
    }
    .sv_info_bx {
        display: inline-block;
        width: 346px;
        height: 180px;
        padding: 40px 29px;
        margin-top: 20px;
        border: 1px solid #cccccc;
        text-align: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .info_txt {
            float: left;
            max-width: 186px;
            p {
                margin-top: 6px;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
            .price {
                display: block;
                margin-top: 24px;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                color: #000;
                &:before {
                    display: inline-block;
                    margin-right: 5px;
                    @extend .ico_currency_sm;
                    vertical-align: top;
                    content: '';
                }
            }
        }
        .img_container {
            float: right;
            font-size: 0;
        }
    }
    &.guild_create {
        .registry_step_lst + .sp_common_area {
            margin-top: -7px;
        }
    }
    &.guild_entrust_lst {
        .sp_common_area {
            .btn_grp.right {
                margin-top: 0;
            }
        }
    }
    .gc_btn_grp {
        margin-top: 130px;
    }
    .area_tit {
        &+p {
            margin-top: 25px;
        }
    }
    .safe_login_area {
        margin-top: 11px;
        p {
            font-size: 18px;
        }
        .dot_lst {
            margin-top: 12px;
            li ~ li {
                margin-top: 9px;
            }
            &+p {
                margin-top: 14px;
            }
        }
        .step_lst {
            margin-top: 46px;
            padding: 46px;
            text-align: center;
            .lst_itm {
                width: 637px;
                height: 269px;
                padding-bottom: 30px;
                &~.lst_itm {
                    margin-left: 40px;
                }
            }
            .step_ico {
                top: 42px;
            }
            .step_tit {
                margin-top: 54px;
                font-size: 22px;
                &.txt_blue {
                    color: #404dab;
                }
            }
            .btn.btn_lg {
                width: 130px;
                height: 48px;
                margin-top: 28px;
            }
        }
        .info_bx {
            margin-top: 46px;
        }
        .phone_verify {
            margin-top: 30px;
            tr,th, td {
                min-height: 74px;
                border: none;
            }
            tr:only-child {
                height: 76px;
            }
            tr:not(:only-child){
                &:first-child {
                    th,td {
                        padding-top: 5px;
                    }
                }
                &:last-child {
                    th,td {
                        padding-bottom: 15px;
                    }
                }
            }
            th {
                font-size: 14px;
            }
            .inp_txt {
                width: 47px;
            }
            .btn {
                margin-left: 39px;
            }
            .verify_phone_bx {
                input {
                    font-size: 14px;
                }
            }
            .dot_lst {
                margin-top: -5px;
                font-size: 14px;
                li {
                    margin-top: 2px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .verify_guide_bx {
        display: block;
        margin-left: 30px;
        text-align: left;
        .btn {
            width: 51px;
            height: 23px;
            padding: 0;
            margin-left: 0 !important;
            font-size: 13px;
            line-height: 23px;
            vertical-align: baseline;
            font-weight: 400;
        }
    }
    &.vip_services {
        .dot_lst.note_txt {
            margin-top: 19px;
            li {
                font-size: 14px;
            }
            li:before {
                top: 5px;
            }
            li~li {
                margin-top: 17px;
            }
        }
        .btn_grp.right {
            margin-top: 15px;
            .btn {
                width: auto;
                padding: 0 30px;
            }
        }
        .service_info {
            padding: 42px 66px 42px 70px;
            margin-top: 26px;
            white-space: nowrap;
            .class_rate, .class_progress {
                position: relative;
                display: inline-block;
                width: 635px;
                min-height: 201px;
                vertical-align: top;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            .class_rate {
                .rate {
                    display: block;
                    margin-top: 22px;
                    font-size: 24px;
                    span {
                        display: inline-block;
                        margin-left: 10px;
                        &:before {
                            display: inline-block;
                            width: 1px;
                            height: 15px;
                            margin-right: 10px;
                            background: #c8c8cf;
                            vertical-align: middle;
                            content: '';
                        }
                    }
                }
                dl {
                    margin-top: 10px;
                    font-size: 14px;
                    dd, dt {
                        display: inline;
                    }
                    div ~ div {
                        margin-top: 2px;
                    }
                }
                .btn.btn_lg {
                    width: 162px;
                    margin-top: 18px;
                }
                .rate_ico {
                    position: absolute;
                    right: 92px;
                    top: 26px;
                }
            }
            .class_progress {
                padding-left: 87px;
                margin-left: -4px;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1px;
                    height: 200px;
                    background: #ffffff;
                    content: '';
                }
                .class_lst {
                    margin-top: 40px;
                    font-size: 0;
                    li {
                        display: inline-block;
                        width: 138px;
                        text-align: center;
                        &.on {
                            .rate_tit {
                                font-weight: 400;
                                color: #404dab;
                            }
                        }
                    }
                }
                .progress_bar {
                    position: absolute;
                    top: 105px;
                    overflow: hidden;
                    width: 552px;
                    height: 24px;
                    font-size: 0;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    border-radius: 2px;
                    background: url('../img/service/progress_bar_bg.png') no-repeat;
                    li {
                        position: relative;
                        display: inline-block;
                        max-width: 138px;
                        height: 24px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: inherit;
                            content: '';
                            background: #404dab;
                        }
                        &~li {
                            &:before {
                                -webkit-box-shadow: inset 1px 0px 0px 0px rgba(89,102,190,1);
                                -moz-box-shadow: inset 1px 0px 0px 0px rgba(89,102,190,1);
                                box-shadow: inset 1px 0px 0px 0px rgba(89,102,190,1);
                            }
                        }
                    }
                }
                .rate_badge {
                    display: block;
                    margin: 0 auto;
                }
                .rate_tit {
                    display: block;
                    margin-top: 59px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #222222;
                }
            }
        }
        .service_detail {
            margin-top: 80px;
            [class^="ico_"] {
                display: inline-block;
                margin-right: 15px;
                vertical-align: middle;
            }
            .class_name {
                font-size: 16px;
                font-weight: 400;
            }
            .dot_lst {
                margin-top: 0;
                li~li {
                    margin-top: 6px;
                }
            }
            td {
                &:nth-child(3) {
                    padding: 28px 0;
                }
            }
            .rate_area {
                padding-left: 102px;
                text-align: left;
            }
        }
    }
    .gray_bx.result {
        background: #f8f8f8;
    }
}

.flow_lst {
    display: inline-block;
    font-size: 0;
    li {
        display: inline-block;
        margin: 0 40px;
        .icon_container {
            position: relative;
            display: block;
            width: 92px;
            height: 92px;
            margin: 0 auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            background: #cccccc;
            font-size: 0;
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
        .flow_name {
            display: block;
            margin-top: 15px;
            font-size: 18px;
            font-weight: 300;
            color: #868686;
            text-align: center;
            line-height: 18px;
        }
        &.active {
            .icon_container {
                width: 113px;
                height: 113px;
                background: #384859;
            }
            .flow_name {
                font-weight: bold;
                color: #022222;
            }
        }
    }
    &.flow_v2 {
        li {
            position: relative;
            margin: 0 42px;
            &:first-child:nth-last-child(6),&:first-child:nth-last-child(6)~li {
                margin:0 36px;
            }
            &:first-child {
                margin-left: 0!important;
            }
            &:last-child {
                margin-right: 0!important;
            }
            &.active {
                .flow_name {
                    font-weight: 300;
                }
                .icon_container {
                    width: 92px;
                    height: 92px;
                }
            }
            &~li {
                &:before {
                    position: absolute;
                    top: 36px;
                    left: -46px;
                    @extend .ico_flow_arr;
                    content: '';
                }
            }
        }
    }
}
.tbl_fm {
    margin-top: 27px;
    font-size: 16px;
    .tbl_lst {
        border-top: none;
    }
    th,td {
        border-top:1px solid #e5e5e5;
        border-bottom:1px solid #e5e5e5;
        padding-left: 30px;
        text-align: left;
        vertical-align: middle;
    }
    input[type="text"] {
        width: 100%;
        height: 36px;
        border:none;
        font-size: 14px;
        color: #222222;
        outline: none;
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-weight: 300;
            color: #a4a4a4;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-weight: 300;
            color: #a4a4a4;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            font-weight: 300;
            color: #a4a4a4;
        }
    }
}