.invite_page {
    .menu_depth_3 {
        .tab_itm {
            a {
                padding: 0 15px;
            }
        }
    }
    .cp_history_area {
        .left.note_txt {
            margin-top: 5px;
        }
    }
    .tbl_lst {
        margin-top: 30px;
    }
    .dot_lst {
        margin-top: 30px;
        li {
            &:before {
                top: 5px;
                background: #868686;
            }
            &~li {
                margin-top: 16px;
                .note_txt {
                    margin-top: 0;
                }
            }
        }
    }
}

.invite_step_area {
    margin-top: 57px;

    .area_tit {
        display: inline-block;
        margin-top: 50px;
    }
}

.proceed_area {
    margin-top: 47px;
    .intro_txt + img {
        margin-top: -144px;
    }
    .adv_guide_bx {
        margin-top: 48px;
        text-align: center;
        strong {
            display: inline-block;
            font-size: 20px;
            color: #222222;
            line-height: 19px;
            vertical-align: middle;
            &:after {
                display: inline-block;
                width: 1px;
                height: 13px;
                background: #c8c8cf;
                margin-left: 20px;
                margin-top: 3px;
                vertical-align: top;
                content: '';
            }
        }
        .dot_lst {
            display: inline-block;
            vertical-align: middle;
            li {
                margin-left: 17px;
                font-size: 18px;
                line-height: 17px;
                color: #222222;
                letter-spacing: -0.4px;
                &:before {
                    top: 6px;
                    background: #868686;
                }
            }
        }
    }
    .area_tit {
        float: left;
        margin-top: 80px;
    }
    .btn_grp {
        float: right;
        margin-top: 77px;
        .btn:first-child~.btn {
            margin-left:6px;
        }
    }
    .step_lst {
        li:last-child {
            .step_tit {
                margin-top: 13px;
            }
            .step_desc {
                margin-top: 6px;
            }
        }
        .step_desc {
            letter-spacing: -1px;
        }
    }
}

.coupon_reward_area {
    margin-top: 58px;
    .tbl_lst {
        margin-top: 47px;
        &.coupon_reward {
            thead {
                th:first-child {
                    padding-left: 224px;
                }
            }

            tbody {
                td:first-child {
                    padding-left: 137px;

                    img {
                        margin-top: 0;
                        margin-right: 9px;
                    }

                    a {
                        font-size: 16px;
                        color: $black2;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

.reward_token_area,.reward_helper_area {
    margin-top: 58px;
    .tbl_lst {
        margin-top: 47px;
        tbody {
            td:last-child {
                min-height: 60px;
                height: auto;
                padding: 17px 0 17px 119px;
            }
        }

        .item_lst {
            li {
                display: inline-block;
                width: 345px;
                &:nth-child(even) {
                    margin-left: 13px;
                }
                &:nth-child(2)~li {
                    margin-top: 20px;
                }
                img {
                    margin-top: 0 !important;
                    margin-right: 14px;
                    vertical-align: top !important;
                }
            }
        }
    }
}

.send_cp_area {
    &>.clearfix{
        .area_tit{
            float: left;
        }
        .btn_grp{
            float: right;
        }
    }
    .send_cp_bx {
        width: 940px;
        height: 163px;
        padding: 43px 50px;
        margin-top: 26px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .txt {
            font-size: 16px;
            font-weight: 300;
            line-height: 16px;
            color: #222222;
        }
    }
    .send_cp_fm {
        margin-top: 10px;
        .def_inp {
            width: 678px;
            vertical-align: top;
            &::-webkit-input-placeholder {
                color: #a4a4a4;
            }

            &:-ms-input-placeholder {
                color: #a4a4a4;
            }

            &::placeholder {
                color: #a4a4a4;
            }
        }
        .btn {
            width: 162px;
            height: 50px;
            margin-left: -4px;
            border: 1px solid #ebebeb;
            border-left: none;
            vertical-align: top;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .cp_stt_bx {
        width: 470px;
        height: 163px;
        margin-top: 26px;
        padding: 0 47px 0 53px;
        border:1px solid #e3e3e3;
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .cp_type_box {
        display: table;
        height: 80px;
        width: 100%;
        &~.cp_type_box {
            border-top:1px solid #e5e5e5;
        }
        .cp_type {
            display: table-cell;
            text-align: left;
            vertical-align: middle;
            strong {
                font-size: 20px;
                font-weight: 400;
                line-height: 19px;
                color: #222222;
            }
            .note_txt {
                display: block;
                margin-top: 8px;
            }
        }
        .num_count {
            display: table-cell;
            font-size: 40px;
            text-align: right;
            vertical-align: middle;
        }
    }
}

.friend_cp_area {
    margin-top: 60px;

    .tbl_lst {
        .nodata {
            height: 305px;

            .ico {
                margin-top: 0;
            }

            .txt_msg {
                margin-top: 17px;
                font-size: 16px;
            }
            .btn {
                margin-top: 27px;
            }
        }
    }
}

.inv_reward_area {
    margin-top: 63px;
    &>.clearfix {
        .area_tit{
            float: left;
        }
        .note_txt {
            float: right;
            margin-top: 15px;
        }
    }
    .reward_coin_bx {
        display: table;
        width:100%;
        height: 133px;
        margin-top: 27px;
        padding: 43px 50px;
        box-sizing: border-box;
        .tit {
            display: table-cell;
            width: 577px;
            font-size: 28px;
            font-weight: 500;
            vertical-align: middle;
            letter-spacing: -1.6px;
        }
        .coin_info {
            &> div{
                display: table-cell;
                vertical-align: middle;
                &:first-child {
                    width: 502px;
                }
            }
            dt {
                display: inline-block;
                font-size: 28px;
                font-weight: 300;
                vertical-align: middle;
                letter-spacing: -1.5px;
            }
            dd {
                display: inline-block;
                vertical-align: middle;
            }
            .num {
                display: inline-block;
                margin-left: 27px;
                font-family: 'Barlow', sans-serif;
                font-size: 32px;
                font-weight: 500;
                color: #404dab;
                vertical-align: middle;
            }
            .btn {
                width: 130px;
                height: 48px;
                vertical-align: middle;
                margin-left: 43px;
            }
        }
    }
}

.redeem_itm_area {
    margin-top: 60px;
    &>.clearfix {
        .area_tit{
            float: left;
        }
        .note_txt {
            float: right;
            margin-top: 13px;
        }
    }
}

.cp_history_area {
    margin-top: 30px;
}