$normal-vars: (
	'btn_cash_charge': (
		offset-x: -883px,
		offset-y: -804px,
		width: 80px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'btn_down': (
		offset-x: -242px,
		offset-y: -397px,
		width: 472px,
		height: 76px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'btn_down_hover': (
		offset-x: -242px,
		offset-y: -317px,
		width: 472px,
		height: 76px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'btn_minus': (
		offset-x: -769px,
		offset-y: -995px,
		width: 36px,
		height: 36px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'btn_minus_gamemall': (
		offset-x: -359px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'btn_plus': (
		offset-x: -809px,
		offset-y: -995px,
		width: 36px,
		height: 36px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'btn_plus_gamemall': (
		offset-x: -335px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ch_lst_indi': (
		offset-x: -999px,
		offset-y: -982px,
		width: 56px,
		height: 5px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ch_lst_indi_hover': (
		offset-x: -925px,
		offset-y: -380px,
		width: 56px,
		height: 5px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ch_lst_next': (
		offset-x: -1079px,
		offset-y: -752px,
		width: 26px,
		height: 53px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ch_lst_prev': (
		offset-x: -1043px,
		offset-y: -1078px,
		width: 26px,
		height: 52px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'dark_elf': (
		offset-x: 0px,
		offset-y: -741px,
		width: 360px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'dekan': (
		offset-x: -718px,
		offset-y: -317px,
		width: 261px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'dhan': (
		offset-x: -667px,
		offset-y: -741px,
		width: 234px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'down': (
		offset-x: -1193px,
		offset-y: -220px,
		width: 10px,
		height: 6px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'dungeon': (
		offset-x: -242px,
		offset-y: -477px,
		width: 399px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'elf': (
		offset-x: -204px,
		offset-y: -995px,
		width: 118px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'flow_next': (
		offset-x: -1265px,
		offset-y: -1036px,
		width: 13px,
		height: 25px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'flow_prev': (
		offset-x: -1093px,
		offset-y: -407px,
		width: 12px,
		height: 24px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'giant': (
		offset-x: -718px,
		offset-y: -397px,
		width: 246px,
		height: 61px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'gold_lbl': (
		offset-x: -378px,
		offset-y: -540px,
		width: 102px,
		height: 33px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'green_lbl': (
		offset-x: -999px,
		offset-y: -945px,
		width: 102px,
		height: 33px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'guide_btn_buy': (
		offset-x: -611px,
		offset-y: -928px,
		width: 35px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'guide_btn_give': (
		offset-x: -572px,
		offset-y: -928px,
		width: 35px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'half_elf': (
		offset-x: -645px,
		offset-y: -477px,
		width: 344px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'human': (
		offset-x: -364px,
		offset-y: -741px,
		width: 299px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_account_thief': (
		offset-x: -1207px,
		offset-y: -943px,
		width: 67px,
		height: 60px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_archive': (
		offset-x: -95px,
		offset-y: -995px,
		width: 105px,
		height: 67px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_accordion': (
		offset-x: -718px,
		offset-y: -462px,
		width: 21px,
		height: 11px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_bubble': (
		offset-x: -1094px,
		offset-y: -643px,
		width: 12px,
		height: 9px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_calendar': (
		offset-x: -1094px,
		offset-y: -678px,
		width: 12px,
		height: 7px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_down': (
		offset-x: -1094px,
		offset-y: -666px,
		width: 11px,
		height: 8px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_down2': (
		offset-x: -1094px,
		offset-y: -689px,
		width: 11px,
		height: 6px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_down3': (
		offset-x: -1094px,
		offset-y: -656px,
		width: 11px,
		height: 6px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_down4': (
		offset-x: -1085px,
		offset-y: -395px,
		width: 15px,
		height: 8px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_faq': (
		offset-x: -1327px,
		offset-y: -967px,
		width: 8px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_gamemall': (
		offset-x: -1326px,
		offset-y: -1144px,
		width: 9px,
		height: 6px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_lnb': (
		offset-x: -1079px,
		offset-y: -841px,
		width: 15px,
		height: 8px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_refund': (
		offset-x: -1327px,
		offset-y: -1035px,
		width: 8px,
		height: 5px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_right': (
		offset-x: -1330px,
		offset-y: -569px,
		width: 5px,
		height: 9px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_txt': (
		offset-x: -1329px,
		offset-y: -914px,
		width: 6px,
		height: 9px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_txt_blue': (
		offset-x: -1329px,
		offset-y: -901px,
		width: 6px,
		height: 9px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_arr_txt_gamemall': (
		offset-x: -1329px,
		offset-y: -557px,
		width: 4px,
		height: 7px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_attach': (
		offset-x: -1094px,
		offset-y: -533px,
		width: 12px,
		height: 12px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_bars': (
		offset-x: -847px,
		offset-y: -540px,
		width: 16px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_bars_hover': (
		offset-x: -827px,
		offset-y: -540px,
		width: 16px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_bronze': (
		offset-x: -350px,
		offset-y: -587px,
		width: 171px,
		height: 150px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_bronze_badge': (
		offset-x: -1283px,
		offset-y: -997px,
		width: 40px,
		height: 45px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_bronze_sm': (
		offset-x: -651px,
		offset-y: -995px,
		width: 56px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_calendar': (
		offset-x: -455px,
		offset-y: -1153px,
		width: 17px,
		height: 19px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_calendar_gamemall': (
		offset-x: -1093px,
		offset-y: -435px,
		width: 14px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_cash': (
		offset-x: -1088px,
		offset-y: -903px,
		width: 19px,
		height: 19px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_cash_black_small': (
		offset-x: -1103px,
		offset-y: -1112px,
		width: 17px,
		height: 17px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_cash_blue_small': (
		offset-x: -476px,
		offset-y: -1153px,
		width: 17px,
		height: 17px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_cash_gray': (
		offset-x: -1088px,
		offset-y: -880px,
		width: 19px,
		height: 19px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_cash_gray_small': (
		offset-x: -497px,
		offset-y: -1153px,
		width: 17px,
		height: 17px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_change_pass': (
		offset-x: -1111px,
		offset-y: -603px,
		width: 91px,
		height: 72px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_change_request': (
		offset-x: -1207px,
		offset-y: -1084px,
		width: 62px,
		height: 61px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_change_to': (
		offset-x: -1327px,
		offset-y: -1016px,
		width: 8px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_char_change_o': (
		offset-x: -1111px,
		offset-y: -347px,
		width: 82px,
		height: 82px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_char_lock': (
		offset-x: -1283px,
		offset-y: -413px,
		width: 51px,
		height: 46px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_char_transferred': (
		offset-x: -1283px,
		offset-y: -569px,
		width: 43px,
		height: 47px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_name': (
		offset-x: -1283px,
		offset-y: -857px,
		width: 46px,
		height: 40px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_name_b': (
		offset-x: 0px,
		offset-y: -995px,
		width: 91px,
		height: 79px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_name_o': (
		offset-x: -1111px,
		offset-y: -261px,
		width: 82px,
		height: 82px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_protect': (
		offset-x: -1283px,
		offset-y: -765px,
		width: 45px,
		height: 42px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_protect2_b': (
		offset-x: -233px,
		offset-y: -868px,
		width: 112px,
		height: 101px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_protect_1': (
		offset-x: -1283px,
		offset-y: -811px,
		width: 45px,
		height: 42px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_protect_1_b': (
		offset-x: -999px,
		offset-y: -666px,
		width: 91px,
		height: 82px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_protect_b': (
		offset-x: -999px,
		offset-y: -494px,
		width: 91px,
		height: 82px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_unprotect': (
		offset-x: -1283px,
		offset-y: -719px,
		width: 45px,
		height: 42px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_unprotect_1': (
		offset-x: -1283px,
		offset-y: -673px,
		width: 45px,
		height: 42px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_character_unprotect_b': (
		offset-x: -999px,
		offset-y: -580px,
		width: 91px,
		height: 82px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chars_locked': (
		offset-x: -404px,
		offset-y: -995px,
		width: 63px,
		height: 54px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_check_acc': (
		offset-x: -416px,
		offset-y: -1078px,
		width: 65px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chkbox': (
		offset-x: -407px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chkbox2': (
		offset-x: -383px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chkbox2_checked': (
		offset-x: -287px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chkbox3': (
		offset-x: -165px,
		offset-y: -1187px,
		width: 16px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chkbox3_checked': (
		offset-x: -145px,
		offset-y: -1187px,
		width: 16px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_chkbox_checked': (
		offset-x: -311px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_circle_blue': (
		offset-x: -1124px,
		offset-y: -1112px,
		width: 17px,
		height: 17px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_circle_white': (
		offset-x: -1166px,
		offset-y: -1112px,
		width: 17px,
		height: 17px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_clock': (
		offset-x: -1265px,
		offset-y: -1065px,
		width: 14px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_close_pop': (
		offset-x: -1103px,
		offset-y: -1078px,
		width: 30px,
		height: 30px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_close_pop_gamemall': (
		offset-x: -518px,
		offset-y: -1153px,
		width: 16px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_comment': (
		offset-x: -867px,
		offset-y: -540px,
		width: 14px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_confirm_accept': (
		offset-x: -711px,
		offset-y: -995px,
		width: 54px,
		height: 46px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_confirm_accept_b': (
		offset-x: -999px,
		offset-y: 0px,
		width: 108px,
		height: 91px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon': (
		offset-x: -650px,
		offset-y: -928px,
		width: 29px,
		height: 18px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon_confirm': (
		offset-x: -88px,
		offset-y: -1078px,
		width: 84px,
		height: 71px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon_confirm2': (
		offset-x: 0px,
		offset-y: -1078px,
		width: 84px,
		height: 71px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon_in': (
		offset-x: -1207px,
		offset-y: -1007px,
		width: 54px,
		height: 73px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon_out': (
		offset-x: -63px,
		offset-y: -1153px,
		width: 54px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon_reg': (
		offset-x: -1207px,
		offset-y: -880px,
		width: 71px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_coupon_write': (
		offset-x: -905px,
		offset-y: -741px,
		width: 79px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_currency': (
		offset-x: -263px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_currency_sm': (
		offset-x: -1145px,
		offset-y: -1112px,
		width: 17px,
		height: 17px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_daum': (
		offset-x: -437px,
		offset-y: -928px,
		width: 59px,
		height: 24px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_del': (
		offset-x: -705px,
		offset-y: -928px,
		width: 18px,
		height: 18px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_delegation': (
		offset-x: -711px,
		offset-y: -868px,
		width: 97px,
		height: 48px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_diamond': (
		offset-x: -967px,
		offset-y: -804px,
		width: 24px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_done': (
		offset-x: -999px,
		offset-y: -197px,
		width: 98px,
		height: 98px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_done2': (
		offset-x: -1111px,
		offset-y: -842px,
		width: 78px,
		height: 78px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_down_btn': (
		offset-x: -1283px,
		offset-y: -360px,
		width: 49px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_download': (
		offset-x: -237px,
		offset-y: -1153px,
		width: 22px,
		height: 22px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_download_l': (
		offset-x: -121px,
		offset-y: -1187px,
		width: 20px,
		height: 24px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_download_ost': (
		offset-x: -1088px,
		offset-y: -926px,
		width: 16px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_download_s': (
		offset-x: -751px,
		offset-y: -928px,
		width: 16px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_exc_l': (
		offset-x: -999px,
		offset-y: -95px,
		width: 98px,
		height: 98px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_exc_s': (
		offset-x: -1111px,
		offset-y: -679px,
		width: 80px,
		height: 80px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_exc_xs': (
		offset-x: -1111px,
		offset-y: -924px,
		width: 78px,
		height: 78px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_eye': (
		offset-x: -743px,
		offset-y: -462px,
		width: 14px,
		height: 10px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_facebook': (
		offset-x: -964px,
		offset-y: -995px,
		width: 34px,
		height: 34px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_feature_1': (
		offset-x: -700px,
		offset-y: -587px,
		width: 108px,
		height: 134px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_feature_2': (
		offset-x: -117px,
		offset-y: -868px,
		width: 112px,
		height: 112px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_feature_3': (
		offset-x: 0px,
		offset-y: -868px,
		width: 113px,
		height: 123px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_first': (
		offset-x: -1283px,
		offset-y: -1190px,
		width: 40px,
		height: 40px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_first_gamemall': (
		offset-x: -1193px,
		offset-y: -168px,
		width: 10px,
		height: 10px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_flow_arr': (
		offset-x: -1265px,
		offset-y: -1007px,
		width: 13px,
		height: 25px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_flow_done': (
		offset-x: -1041px,
		offset-y: -995px,
		width: 36px,
		height: 26px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_fraud': (
		offset-x: -1207px,
		offset-y: -680px,
		width: 70px,
		height: 62px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_game_access': (
		offset-x: -935px,
		offset-y: -868px,
		width: 58px,
		height: 45px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gender_change': (
		offset-x: -934px,
		offset-y: -587px,
		width: 59px,
		height: 60px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gender_change_2': (
		offset-x: -1111px,
		offset-y: -80px,
		width: 83px,
		height: 84px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gender_change_b': (
		offset-x: -812px,
		offset-y: -587px,
		width: 118px,
		height: 118px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gift': (
		offset-x: -1093px,
		offset-y: -455px,
		width: 14px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gift_change': (
		offset-x: 0px,
		offset-y: -1153px,
		width: 59px,
		height: 59px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gold': (
		offset-x: 0px,
		offset-y: -587px,
		width: 171px,
		height: 150px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gold_badge': (
		offset-x: -1283px,
		offset-y: -1095px,
		width: 40px,
		height: 45px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_gold_sm': (
		offset-x: -471px,
		offset-y: -995px,
		width: 56px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guide': (
		offset-x: -1094px,
		offset-y: -613px,
		width: 11px,
		height: 11px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guide_gray': (
		offset-x: -1094px,
		offset-y: -565px,
		width: 11px,
		height: 11px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guild_accept_2': (
		offset-x: -1283px,
		offset-y: -901px,
		width: 42px,
		height: 43px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guild_accept_2_b': (
		offset-x: -999px,
		offset-y: -854px,
		width: 85px,
		height: 87px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guild_change': (
		offset-x: -1283px,
		offset-y: -513px,
		width: 42px,
		height: 52px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guild_change_b': (
		offset-x: -999px,
		offset-y: -299px,
		width: 82px,
		height: 104px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guild_namechange': (
		offset-x: -1283px,
		offset-y: -620px,
		width: 39px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_guild_namechange_b': (
		offset-x: -999px,
		offset-y: -752px,
		width: 76px,
		height: 98px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_hand': (
		offset-x: -1207px,
		offset-y: -811px,
		width: 65px,
		height: 65px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_hand_gift': (
		offset-x: -1207px,
		offset-y: -84px,
		width: 70px,
		height: 76px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_heart': (
		offset-x: -771px,
		offset-y: -928px,
		width: 16px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_heart_n': (
		offset-x: -791px,
		offset-y: -928px,
		width: 16px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_heart_w': (
		offset-x: -811px,
		offset-y: -928px,
		width: 16px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_home': (
		offset-x: -1094px,
		offset-y: -580px,
		width: 12px,
		height: 12px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_house': (
		offset-x: -1207px,
		offset-y: -164px,
		width: 72px,
		height: 71px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_house_change': (
		offset-x: -1111px,
		offset-y: -433px,
		width: 82px,
		height: 81px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_house_switch': (
		offset-x: -999px,
		offset-y: -407px,
		width: 90px,
		height: 83px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_ie': (
		offset-x: -849px,
		offset-y: -995px,
		width: 35px,
		height: 35px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_inquiry': (
		offset-x: -1207px,
		offset-y: -746px,
		width: 71px,
		height: 61px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_itm_buy': (
		offset-x: -874px,
		offset-y: -868px,
		width: 57px,
		height: 47px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_krwon': (
		offset-x: -211px,
		offset-y: -1153px,
		width: 22px,
		height: 22px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_last': (
		offset-x: -393px,
		offset-y: -928px,
		width: 40px,
		height: 40px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_last_gamemall': (
		offset-x: -1193px,
		offset-y: -182px,
		width: 10px,
		height: 10px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_link': (
		offset-x: -888px,
		offset-y: -995px,
		width: 34px,
		height: 34px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_lock': (
		offset-x: -1094px,
		offset-y: -494px,
		width: 13px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_lock_shield': (
		offset-x: -1207px,
		offset-y: 0px,
		width: 72px,
		height: 80px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_login': (
		offset-x: -743px,
		offset-y: -540px,
		width: 56px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_login2': (
		offset-x: -871px,
		offset-y: -380px,
		width: 50px,
		height: 12px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_login_ongate': (
		offset-x: -718px,
		offset-y: -380px,
		width: 73px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_maintenance': (
		offset-x: -1111px,
		offset-y: 0px,
		width: 92px,
		height: 76px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_mini_download': (
		offset-x: -1094px,
		offset-y: -596px,
		width: 11px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_monitor': (
		offset-x: -326px,
		offset-y: -995px,
		width: 74px,
		height: 56px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_monitor2': (
		offset-x: -1207px,
		offset-y: -613px,
		width: 72px,
		height: 63px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_monitor_l': (
		offset-x: -1111px,
		offset-y: -1006px,
		width: 88px,
		height: 68px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_new_window': (
		offset-x: -1094px,
		offset-y: -628px,
		width: 11px,
		height: 11px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_next': (
		offset-x: -349px,
		offset-y: -928px,
		width: 40px,
		height: 40px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_next_gamemall': (
		offset-x: -1329px,
		offset-y: -543px,
		width: 6px,
		height: 10px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_nodata': (
		offset-x: -1207px,
		offset-y: -239px,
		width: 70px,
		height: 70px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_npc_guild': (
		offset-x: -1283px,
		offset-y: -463px,
		width: 50px,
		height: 46px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_ongate': (
		offset-x: -795px,
		offset-y: -380px,
		width: 72px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_paper': (
		offset-x: -1283px,
		offset-y: -68px,
		width: 51px,
		height: 64px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_pdf': (
		offset-x: -431px,
		offset-y: -1153px,
		width: 20px,
		height: 20px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_person': (
		offset-x: -557px,
		offset-y: -1153px,
		width: 15px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_platinum': (
		offset-x: -175px,
		offset-y: -587px,
		width: 171px,
		height: 150px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_platinum_badge': (
		offset-x: -1283px,
		offset-y: -1046px,
		width: 40px,
		height: 45px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_platinum_sm': (
		offset-x: -531px,
		offset-y: -995px,
		width: 56px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_prev': (
		offset-x: -1283px,
		offset-y: -1234px,
		width: 40px,
		height: 40px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_prev_gamemall': (
		offset-x: -1326px,
		offset-y: -658px,
		width: 6px,
		height: 10px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_quest': (
		offset-x: -683px,
		offset-y: -928px,
		width: 18px,
		height: 18px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_race_change': (
		offset-x: -934px,
		offset-y: -651px,
		width: 58px,
		height: 53px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_race_change_2': (
		offset-x: -1111px,
		offset-y: -763px,
		width: 83px,
		height: 75px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_right_angle': (
		offset-x: -1193px,
		offset-y: -196px,
		width: 10px,
		height: 10px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_search': (
		offset-x: -1081px,
		offset-y: -995px,
		width: 24px,
		height: 24px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_search_gamemall': (
		offset-x: -1187px,
		offset-y: -1112px,
		width: 16px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_server_log': (
		offset-x: -1283px,
		offset-y: -136px,
		width: 51px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_shield_lock': (
		offset-x: -1088px,
		offset-y: -854px,
		width: 18px,
		height: 22px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_silver': (
		offset-x: -525px,
		offset-y: -587px,
		width: 171px,
		height: 150px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_silver_badge': (
		offset-x: -1283px,
		offset-y: -948px,
		width: 40px,
		height: 45px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_silver_sm': (
		offset-x: -591px,
		offset-y: -995px,
		width: 56px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_slide_next': (
		offset-x: -1207px,
		offset-y: -313px,
		width: 70px,
		height: 70px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_slide_prev': (
		offset-x: -1207px,
		offset-y: -387px,
		width: 70px,
		height: 70px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_sm_cog': (
		offset-x: -538px,
		offset-y: -1153px,
		width: 15px,
		height: 16px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_smartphone': (
		offset-x: -1283px,
		offset-y: 0px,
		width: 52px,
		height: 64px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_smartphone_l': (
		offset-x: -1207px,
		offset-y: -534px,
		width: 61px,
		height: 75px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step1': (
		offset-x: -919px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step1_on': (
		offset-x: -857px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step2': (
		offset-x: -795px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step2_on': (
		offset-x: -733px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step3': (
		offset-x: -609px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step3_on': (
		offset-x: -547px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step4': (
		offset-x: -671px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step4_on': (
		offset-x: -981px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_step_krwon': (
		offset-x: -485px,
		offset-y: -1078px,
		width: 58px,
		height: 58px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_tools': (
		offset-x: -812px,
		offset-y: -868px,
		width: 58px,
		height: 48px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_top_btn': (
		offset-x: -1283px,
		offset-y: -307px,
		width: 49px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_transcendence_stat': (
		offset-x: 0px,
		offset-y: -1216px,
		width: 54px,
		height: 63px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_transcendence_stat_2': (
		offset-x: -1111px,
		offset-y: -168px,
		width: 78px,
		height: 89px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_transfer_complete': (
		offset-x: -1283px,
		offset-y: -1144px,
		width: 39px,
		height: 42px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_twitter': (
		offset-x: -926px,
		offset-y: -995px,
		width: 34px,
		height: 34px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_unlock_char': (
		offset-x: -1283px,
		offset-y: -198px,
		width: 52px,
		height: 52px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_user': (
		offset-x: -885px,
		offset-y: -540px,
		width: 14px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_xp_recovery': (
		offset-x: -1111px,
		offset-y: -518px,
		width: 82px,
		height: 81px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'intro_close_btn': (
		offset-x: -1207px,
		offset-y: -461px,
		width: 69px,
		height: 69px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'intro_def_btn': (
		offset-x: -319px,
		offset-y: -804px,
		width: 229px,
		height: 44px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'intro_def_btn_hover': (
		offset-x: -552px,
		offset-y: -804px,
		width: 229px,
		height: 44px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo': (
		offset-x: -530px,
		offset-y: -868px,
		width: 177px,
		height: 56px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_gamemania': (
		offset-x: -785px,
		offset-y: -804px,
		width: 94px,
		height: 28px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_gray': (
		offset-x: -349px,
		offset-y: -868px,
		width: 177px,
		height: 56px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_kakaogames': (
		offset-x: -484px,
		offset-y: -540px,
		width: 133px,
		height: 23px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_ongate': (
		offset-x: -621px,
		offset-y: -540px,
		width: 118px,
		height: 19px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_playwith': (
		offset-x: -242px,
		offset-y: -540px,
		width: 132px,
		height: 34px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_banner_arr_next': (
		offset-x: -1327px,
		offset-y: -948px,
		width: 8px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_banner_arr_prev': (
		offset-x: -1326px,
		offset-y: -620px,
		width: 8px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_evt_arr_next': (
		offset-x: -1329px,
		offset-y: -513px,
		width: 6px,
		height: 11px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_evt_arr_prev': (
		offset-x: -1329px,
		offset-y: -528px,
		width: 6px,
		height: 11px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_server_layer_close': (
		offset-x: -1094px,
		offset-y: -549px,
		width: 12px,
		height: 12px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_shop_arr_next': (
		offset-x: -1327px,
		offset-y: -997px,
		width: 8px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_shop_arr_prev': (
		offset-x: -1326px,
		offset-y: -639px,
		width: 8px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_vip_arr_next': (
		offset-x: -1085px,
		offset-y: -299px,
		width: 22px,
		height: 44px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'main_vip_arr_prev': (
		offset-x: -1085px,
		offset-y: -347px,
		width: 22px,
		height: 44px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_arr_top': (
		offset-x: -1094px,
		offset-y: -699px,
		width: 11px,
		height: 6px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_ico_cash': (
		offset-x: -1171px,
		offset-y: -1078px,
		width: 30px,
		height: 25px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_ico_coupon': (
		offset-x: -500px,
		offset-y: -928px,
		width: 30px,
		height: 24px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_ico_cs': (
		offset-x: -1137px,
		offset-y: -1078px,
		width: 30px,
		height: 27px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_ico_invite': (
		offset-x: -1079px,
		offset-y: -809px,
		width: 28px,
		height: 28px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_ico_pcbang': (
		offset-x: -1002px,
		offset-y: -995px,
		width: 35px,
		height: 28px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'quick_ico_report': (
		offset-x: -534px,
		offset-y: -928px,
		width: 34px,
		height: 21px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'rdo_def': (
		offset-x: -849px,
		offset-y: -928px,
		width: 14px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'rdo_def2': (
		offset-x: -831px,
		offset-y: -928px,
		width: 14px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'rdo_selected': (
		offset-x: -1093px,
		offset-y: -475px,
		width: 14px,
		height: 14px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'red_dot': (
		offset-x: -1327px,
		offset-y: -986px,
		width: 7px,
		height: 7px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'restart_txt': (
		offset-x: 0px,
		offset-y: 0px,
		width: 995px,
		height: 313px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	's_rank_1': (
		offset-x: -151px,
		offset-y: -1153px,
		width: 26px,
		height: 30px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	's_rank_2': (
		offset-x: -181px,
		offset-y: -1153px,
		width: 26px,
		height: 30px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	's_rank_3': (
		offset-x: -121px,
		offset-y: -1153px,
		width: 26px,
		height: 30px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'sub_visual_arr_next': (
		offset-x: -1073px,
		offset-y: -1078px,
		width: 26px,
		height: 52px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'sub_visual_arr_prev': (
		offset-x: -968px,
		offset-y: -397px,
		width: 26px,
		height: 52px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'timeline_dot': (
		offset-x: -1094px,
		offset-y: -514px,
		width: 13px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'tooltip_arrow': (
		offset-x: -727px,
		offset-y: -928px,
		width: 20px,
		height: 15px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'tooltip_arrow2': (
		offset-x: -803px,
		offset-y: -540px,
		width: 20px,
		height: 13px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'top_btn': (
		offset-x: -1283px,
		offset-y: -254px,
		width: 49px,
		height: 49px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'top_rank_1': (
		offset-x: -256px,
		offset-y: -1078px,
		width: 76px,
		height: 67px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'top_rank_2': (
		offset-x: -176px,
		offset-y: -1078px,
		width: 76px,
		height: 67px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'top_rank_3': (
		offset-x: -336px,
		offset-y: -1078px,
		width: 76px,
		height: 67px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'trinity': (
		offset-x: 0px,
		offset-y: -804px,
		width: 315px,
		height: 60px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'type_box': (
		offset-x: 0px,
		offset-y: -317px,
		width: 238px,
		height: 266px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
	'up': (
		offset-x: -1193px,
		offset-y: -210px,
		width: 10px,
		height: 6px,
		total-width: 1335px,
		total-height: 1279px,
		imageSrc: '../img/sprites/normal.png'
	),
);
$normal-origin: (
  total-width: 1335px,
  total-height: 1279px,
  imageSrc: '../img/sprites/normal.png'
);

@mixin sprite-size($image) {
	background-size: map-get($image, 'total-width') map-get($image, 'total-height');
}

@mixin sprite-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-normal {
  @include sprite-size($normal-origin);
  @include sprite-image($normal-origin);
}

@mixin sp-normal($image, $size: true) {
  @include set-normal;
	background-position: map-get(map-get($normal-vars, $image), 'offset-x') map-get(map-get($normal-vars, $image), 'offset-y');
	@if $size {
		width: map-get(map-get($normal-vars, $image), 'width');
		height: map-get(map-get($normal-vars, $image), 'height');
	}
}
