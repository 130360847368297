.download_page {
    .download_faq {
        li > span {
            height: 72px!important;
        }
        .qa_lbl {
            display: inline-block;
            margin-right: 26px;
            font-family: 'notosans',sans-serif;
            font-size: 24px;
            font-weight: bold;
            line-height: 23px;
            vertical-align: top;
            color: #404dab;
        }
        .btn {
            color: #868686!important;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .download_lst {
        margin-top: 80px;
        li {
            display: inline-block;
            &~li {
                margin-left: 36px;
            }
        }
        .download_btn {
            display: block;
            width: 685px;
            height: 167px;
            padding-top: 52px;
            background: url("../img/btn_down_bg.jpg") no-repeat;
            color: #ffffff;
            text-align: center;
            box-sizing: border-box;
            .ico_download_l {
                display: inline-block;
                margin-top: 8px;
                margin-right: 10px;
                vertical-align: top;
            }
            .txt {
                display: block;
                font-size: 26px;
                font-weight: 500;
                letter-spacing: -0.8px;
            }
            .sub_txt {
                display: block;
                font-size: 15px;
                font-weight: 300;
                opacity: .3;
            }
            .notice {
                display: none;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
            }
            &:hover {
                padding-top: 70px;
                background: url("../img/btn_down_hover.jpg") no-repeat;
                .txt,.sub_txt {
                    display: none;
                }
                .notice {
                    display: block;
                }
            }
        }
        .dot_lst {
            margin-top: 7px;
            font-size: 14px;
            letter-spacing: -0.2px;
            li:before {
                top: 9px;
            }
        }
        &+.dot_lst {
            margin-top: 21px;
            .btn_sm {
                height: 26px;
                font-weight: 300;
                font-size: 13px;
                line-height: 26px;
                color: #868686;
            }
            .ico_sm_cog {
                display: inline-block;
                margin-top: 5px;
                vertical-align: top;
            }
            li~li {
                margin-top: 8px;
            }
        }
    }
    .requirement_area {
        margin-top: 78px;

        .tbl_lst {
            margin-top: 30px;
        }
    }
    .driver_down_area {
        margin-top: 80px;
        .area_tit {
            float: left;
        }
        .note_txt {
            float: right;
            margin-top: 15px;
        }
        .driver_lst {
            margin-top: 29px;
            li {
                display: inline-block;
                width: 450px;
                height: 272px;
                padding: 36px;
                border:1px solid #cccccc;
                box-sizing: border-box;
                text-align: center;
                .logo_box {
                    display: block;
                    height: 77px;
                    width: 100%;
                    .amd_logo {
                        margin-top: 15px;
                    }
                }
                .d_name {
                    display: block;
                    margin-top: 18px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #222222;
                    line-height: 17px;
                    letter-spacing: -0.7px;
                }
                .btn {
                    width: 160px;
                    height: 50px;
                    margin-top: 35px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 50px;
                    .ico_download_s {
                        display: inline-block;
                        margin-left: 8px;
                    }
                }
                &~li{
                    margin-left: 26px;
                }
            }
        }
    }
    .ost_lst {
        padding-top: 40px;
        border-top: 2px solid #384859;
        li {
            display: inline-block;
            position: relative;
            &:not(:nth-child(3n+1)){
                margin-left: 26px;
            }
            &:nth-last-child(n+4) {
                margin-bottom: 30px;
            }
            .ost_bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                img {
                    display: block;
                }
            }
            .ost_detail {
                display: table-cell;
                position: relative;
                width: 450px;
                height: 400px;
                text-align: center;
                vertical-align: middle;
                box-sizing: border-box;
            }
            .ost_name {
                display: block;
                font-size: 24px;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: -1px;
            }
            .ost_desc {
                display: none;
                margin-top: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                opacity: 0.5;
            }
            .btn_ost_download {
                display: none;
                width: 160px;
                height: 50px;
                margin: 42px auto 0;
                background: #ffffff;
                font-size: 18px;
                font-weight: 500;
                line-height: 50px;
                color: #222222;
                .ico_download_ost {
                    display: inline-block;
                    margin-left: 11px
                }
            }
            &:hover {
                .ost_desc,.btn_ost_download {
                    display: block;
                }
                .ost_bg:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #000;
                    opacity: 0.7;
                    content: '';
                }
                .ost_detail {
                    padding-top: 90px;
                    vertical-align: top;
                }
            }
        }
    }
}