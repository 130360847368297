.sb-container {
    position: relative;
    overflow: hidden!important;
}
.sb-content {
    height: 100%;
    width: 120%;
    padding-top: 40px;
    padding-right: 20%;
    padding-bottom: 40px;
    overflow-y: scroll;
    box-sizing: border-box;
}
.sb-scrollbar-container {
    position: absolute;
    top: 0;
    right: 9px;
    bottom: 0;
    width: 8px;
    background: #ededed;
}
.sb-scrollbar-container-hide {
    right: -20px;
}
.sb-scrollbar {
    position: absolute;
    right: 0;
    width: 8px;
    background: #868686;
}
.sb-container-noscroll {
    .sb-scrollbar-container {
        visibility: hidden;
    }
}