.cash_page {
    .cash_status_area {
        padding-top: 50px;
        border-top: 2px solid #384859;
    }

    .menu_depth_3 {
        margin-top: 40px;
    }

    .tbl_desc {
        margin-top: 30px;
    }

    .tbl_lst {
        margin-top: 30px;

        &+.btn_wrap {
            margin-top: 16px;
            text-align: right;

            &+.pagination {
                margin-top: 35px;
            }
        }
}
}

.cash_page2 {
    .wrap {
        padding-top: 110px;
        padding-bottom: 90px;
    }

    .registry_step_lst {
        .ico_step1 {
            @include sp-normal('ico_step_krwon');
        }

        .ico_step2 {
            @include sp-normal('ico_step2_on');
        }

        .ico_step3 {
            @include sp-normal('ico_step3_on');
        }

        .ico_step4 {
            @include sp-normal('ico_step4_on');
        }

        .txt {
            font-weight: 500;
            color: $black2;
        }
    }

    .cash_status_area {
        &+.tbl_lst {
            margin-top: 53px;
        }
    }

    .tbl_lst {
        th {
            padding-left: 30px;
            font-size: 14px;
        }

        td {
            height: 74px;

            em {
                font-weight: 400;
            }
        }

        .txt_note {
            margin-left: 13px;
            font-size: 14px;
            color: #404dab;
            letter-spacing: -0.007em;
        }

        .bank {
            width: 210px;
        }

        .inp_acc_num {
            width: 216px;
            margin: 0 10px 0 25px;
        }

        .inp_acc {
            width: 145px;
            margin-left: 14px;
        }

        .bar {
            display: inline-block;
            width: 1px;
            height: 16px;
            background-color: #c8c8cf;
            vertical-align: middle;
        }

        .btn_verify {
            margin: 0 7px;
        }

        .btn_authenticate {
            margin-left: 47px;
        }

        .validate {
            .message {
                display: inline-block;
                margin-left: 8px;
                vertical-align: middle;
            }
        }
    }

    .btn_wrap {
        margin-top: 60px;
        font-size: 0;
        text-align: center;

        .btn {
            margin-left: 20px;
            vertical-align: top;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .result_bx {
        width: 970px;
        margin: 0 auto;
        text-align: left;

        .tit {
            text-align: center;
        }

        .tbl_lst {
            margin-top: 50px;

            th {
                padding-left: 0;
                font-size: 16px;
            }

            td {
                height: 70px;
                padding-left: 30px;
            }
        }

        .lst_tit {
            display: block;
            margin-top: 25px;
            font-size: 18px;
        }

        .dot_lst {
            margin-top: 10px;
        }

        .btn_wrap {
            margin-top: 36px;
        }

        .info_lst li {
            font-weight: 400;
        }
    }
}

.cash_status_area {
    .cash_status {
        position: relative;
        padding: 43px 60px 42px;
        background-color: #f2f2f2;
        font-size: 0;

        .tit {
            display: inline-block;
            min-width: 200px;
            font-size: 28px;
            color: #101010;
            letter-spacing: -0.05em;
            line-height: 46px;
            vertical-align: top;
        }

        .current_cash {
            display: inline-block;
            margin-left: 7px;
            font-size: 30px;
            font-weight: 300;
            color: #404dab;
            line-height: 46px;
            letter-spacing: -0.01em;
            vertical-align: top;

            .num {
                font-family: 'barlow', sans-serif;
                font-size: 31px;
                font-weight: 400;
            }
        }

        .btn {
            vertical-align: top;
            width: 130px;
            height: 48px;
            margin-left: 16px;
            font-size: 16px;
        }

        .btn_refund_info {
            position: absolute;
            top: 43px;
            right: 60px;

            &:after {
                display: inline-block;
                margin: -2px 0 0 15px;
                @include sp-normal('ico_arr_refund');
                vertical-align: middle;
                content: '';
            }

            &.open {
                &:after {
                    transform: rotate(180deg);
                }

                &+.refund_info {
                    display: block;
                }
            }
        }

        .refund_info {
            display: none;
            position: absolute;
            top: 109px;
            right: 0;
            width: 650px;
            padding: 25px 30px 25px 33px;
            border: 1px solid #e5e5e5;
            background-color: #fff;
            font-size: 14px;

            &:before {
                position: absolute;
                top: -9px;
                right: 118px;
                @include sp-normal('ico_arr_bubble');
                content: '';
            }

            li {
                padding-left: 13px;
                letter-spacing: -0.013em;

                &:before {
                    top: 9px;
                }

                &~li {
                    margin-top: 2px;
                }
            }
        }
    }

    .progress_bx {
        position: absolute;
        top: 51px;
        right: 60px;
        text-align: right;

        .progress_bar {
            display: block;
            width: 584px;
            height: 30px;
            border: 1px solid #dadada;
            border-radius: 15px;
            background-color: #fff;
        }

        .bar {
            display: block;
            height: 28px;
            border: 1px solid #404dab;
            border-radius: 14px;
            background-color: #404dab;
        }

        .progress_info {
            margin-top: 7px;
            font-size: 14px;
            line-height: 21px;

            dt {
                display: inline-block;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }
    
            dd {
                display: inline-block;
                color: #404dab;
                letter-spacing: 0.01em;
            }

            .ico {
                display: inline-block;
                margin: 1px 1px 0 0;
                vertical-align: top;
            }
        }
    }

    .cash_limit {
        display: block;
        margin-top: 20px;
        text-align: right;

        .num {
            color: #b83d3d;
        }

        .btn_outline {
            width: 104px;
            height: 30px;
            margin-left: 4px;
            line-height: 28px;
            color: #868686;
            border: 1px solid #a4a4a4;
        }
    }
}

.charge_limit_area {
    display: table;
    width: 100%;
    margin-top: 70px;
    border: solid #a4a4a4;
    border-width: 1px 0;

    .tit {
        display: table-cell;
        width: 200px;
        padding-left: 31px;
        font-size: 16px;
        vertical-align: middle;
        box-sizing: border-box;
    }

    .ct {
        display: table-cell;
        padding: 20px 0 25px;
    }

    .cash {
        margin-right: 13px;
        font-size: 16px;
        font-weight: 300;
        color: #b83d3d;

        .num {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .dot_lst {
        margin-top: 14px;

        li {
            padding-left: 13px;
            font-size: 14px;
            color: $gray2;
            line-height: 23px;
            letter-spacing: -0.01em;

            &:before {
                background-color: $gray2;
            }

            &~li {
                margin-top: 0;
            }
        }
    }
}

.chart_area {
    margin-top: 58px;

    .area_tit {
        font-size: 24px;
        letter-spacing: -0.02em;
    }

    .chart_bx {
        height: 353px;
        margin: 100px 0 60px;
        border-bottom: 1px solid #ccc;
        font-size: 0;
    }

    .chart_bar {
        display: inline-block;
        position: relative;
        bottom: 0;
        width: 80px;
        height: 100%;
        margin-left: 186px;
        color: $black2;

        &:first-child {
            margin-left: 0;
        }

        .value {
            position: absolute;
            top: -28px;
            width: 100%;
            font-size: 16px;
            text-align: center;
            white-space: nowrap;
        }

        .bar {
            display: block;
            position: absolute;
            bottom: 0;
            width: 80px;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            background-color: #404dab;
        }

        .time {
            position: absolute;
            bottom: -60px;
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        .point_txt {
            font-weight: bold;
            color: #404dab;
        }
    }
}