.login_area,
.find_area {
    border-top: 2px solid #384859;

    .tbl_lst {
        border-top: 1px solid #a4a4a4;
    }

    .btn_wrap {
        text-align: center;

        .btn {
            margin-left: 16px;
            vertical-align: top;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.login_area {
    .txt_note {
        margin-top: 45px;
        font-weight: 300;
        color: $black2;
        letter-spacing: -0.005em;

        &+.tbl_lst {
            margin-top: 16px;
        }
    }

    .lst_tit {
        display: block;
        margin-top: 24px;
        font-size: 18px;
        font-weight: 500;
        color: #101010;
    }

    .dot_lst {
        margin-top: 11px;

        li {
            margin-top: 11px;
            color: $gray2;
            letter-spacing: -0.025em;

            &:before {
                background-color: $gray2;
            }
        }
    }

    .btn_wrap {
        margin-top: 56px;
    }
}

.find_area {
    .txt_note {
        margin-top: 37px;
        font-weight: 300;
        color: $black2;
        letter-spacing: -0.024em;

        &+.tbl_lst {
            margin-top: 26px;
        }
    }
    
    .dot_lst {
        margin-top: 37px;

        li {
            margin-top: 10px;
            letter-spacing: -0.02em;

            &:first-child {
                margin-top: 0;
            }
        }

        &+.tbl_lst {
            margin-top: 25px;

            &.txt_left {
                margin-top: 45px;
            }
        }
    }

    .tbl_lst {
        .select {
            width: 100px;
            height: 46px;

            &.year {
                width: 172px;
            }

            &+.select {
                margin-left: 6px;
            }
        }

        &.txt_left {
            tbody {
                th {
                    font-size: 14px;
                    padding: 26px 0 0 30px;
                    vertical-align: top;
                }
    
                td {
                    height: auto;
                    padding: 14px 0;
                    padding-left: 0;
                }
            }
        }

        .nodata {
            height: 499px;

            .ico {
                margin-top: 4px;
            }

            .txt_msg {
                margin-top: 16px;
                font-size: 16px;
            }
        }
    }

    .btn_wrap {
        margin-top: 60px;
    }

    .result_bx {
        .txt {
            margin-top: 2px;
        }

        .btn_wrap {
            margin-top: 56px;
        }
    }
}

.account_area {
    border-top: 2px solid #384859;

    .card_lst {
        margin-top: 28px;
        padding: 46px 23px;

        li {
            padding: 0 23px;
        }

        &+.dot_lst {
            margin-top: 24px;

            li {
                padding-left: 12px;
                color: $gray2;
                letter-spacing: -0.02em;

                &:before {
                    background-color: $gray2;
                }

                &~li {
                    margin-top: 9px;
                }
            }
        }
    }

    .card_itm {
        height: 233px;
        padding-top: 42px;

        .ico_monitor_l {
            margin-top: 7px;
        }

        .desc {
            margin-top: 8px;
            font-size: 16px;
        }
    }

    .policy_bx {
        margin-top: 49px;
        padding: 36px 40px 33px;
        background-color: #f2f2f2;

        .txt {
            display: block;
            margin: 6px 0 0 40px;
            font-size: 14px;
            font-weight: 300;
            color: $black2;
        }
    }

    .btn_wrap {
        margin-top: 60px;
        text-align: center;

        .btn {
            margin-left: 16px;
            vertical-align: top;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .txt_note {
        float: right;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 300;
        color: $black2;
    }

    .result_bx + .tbl_lst {
        margin-top: 34px;
        border-top: 1px solid #a4a4a4;
    }

    .tbl_lst {
        margin-top: 29px;
        border-top: 2px solid #a4a4a4;

        &.txt_left {
            tbody {
                th {
                    font-size: 14px;
                    padding: 26px 0 0 30px;
                    vertical-align: top;
                }
    
                td {
                    height: auto;
                    padding: 14px 0;
                    padding-left: 0;
                }
            }
        }

        .btn_certification {
            margin-left: 46px;

            &+.sms {
                margin-left: 27px;
            }
        }

        .note {
            display: block;
            margin-top: 11px;
            font-size: 14px;
            letter-spacing: -0.01em;

            &+.sms {
                margin: 7px 0 16px;
            }
        }

        .confirm {
            .message {
                display: inline-block;
                margin-left: 10px;
                color: #a4a4a4;
                vertical-align: middle
            }
        }

        .captcha_bx {
            margin: 15px 0 16px;

            .img_captcha {
                display: block;
            }

            .inp_captcha {
                width: 510px;
                height: 58px;
                margin-top: 20px;
                padding: 0 20px;
                border: 1px solid #ccc;
                border-radius: 2px;
                font-size: 16px;

                &::-webkit-input-placeholder {
                    font-size: 14px;
                    color: #a4a4a4;
                }
    
                &:-ms-input-placeholder {
                    font-size: 14px;
                    color: #a4a4a4;
                }
    
                &::placeholder {
                    font-size: 14px;
                    color: #a4a4a4;
                }
            }
        }
    }
}

.login_fm {
    width: 550px;
    margin: 96px auto 0;
    text-align: center;

    label {
        display: block;
        font-size: 14px;
        color: $black2;
        text-align: left;
    }

    .inp_bx {
        display: block;
        position: relative;
        margin-top: 9px;
        border: 1px solid #ccc;
        border-radius: 2px;
        box-sizing: border-box;

        input {
            display: block;
            width: 100%;
            height: 58px;
            padding: 0 54px 0 19px;
            border: none;
            outline: none;
            font-size: 16px;
            color: $black2;
            box-sizing: border-box;

            &::-webkit-input-placeholder {
                font-size: 14px;
                color: #a4a4a4;
            }

            &:-ms-input-placeholder {
                font-size: 14px;
                color: #a4a4a4;
            }

            &::placeholder {
                font-size: 14px;
                color: #a4a4a4;
            }
        }

        .ico {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }

        &+label {
            margin-top: 16px;
        }
    }

    .btn_login {
        height: 60px;
        margin-top: 60px;
        font-size: 18px;
    }

    .chkbox {
        margin-top: 20px;

        label {
            font-size: 16px;
            color: $gray2;
        }
    }

    .lnk_lst {
        margin-top: 70px;
        padding-top: 15px;
        border-top: 1px solid #e5e5e5;

        li {
            display: inline-block;
            position: relative;
            margin-left: 15px;
            padding-left: 20px;

            &:before {
                position: absolute;
                top: 6px;
                left: 0;
                width: 1px;
                height: 14px;
                background-color: #c8c8cf;
                content: '';
            }

            &:first-child {
                margin-left: 0;
                padding-left: 0;

                &:before {
                    content: none;
                }
            }

            a {
                font-weight: 300;
                color: $black2;
                letter-spacing: -0.02em;
            }
        }
    }

    .sign_up {
        margin-top: 69px;
        font-size: 16px;
        font-weight: 300;
        color: $gray2;
        letter-spacing: -0.02em;

        .btn {
            width: 90px;
            height: 34px;
            margin-left: 15px;
            font-size: 16px;
            font-weight: 400;
            line-height: 34px;
        }
    }

    .error {
        display: block;
        margin-top: 7px;
        font-size: 14px;
        font-weight: 300;
        color: #b83d3d;
        line-height: 20px;
        letter-spacing: -0.005em;
        text-align: left;
    }

    .captcha {
        margin-top: 16px;

        &+.inp_bx {
            margin-top: 20px;
        }

        &~.btn {
            margin-top: 40px;
        }
    }
}

.registry_step_lst {
    margin: 80px 0 56px;
    text-align: center;
    font-size: 0;

    li {
        display: inline-block;
        position: relative;
        width: 220px;

        &:before {
            position: absolute;
            top: 29px;
            left: -71px;
            width: 142px;
            height: 1px;
            background-color: #ccc;
            content: '';
        }

        &:first-child {
            margin-left: 0;

            &:before {
                content: none;
            }
        }

        &.on {
            .ico_step1 {
                @include sp-normal('ico_step1_on');
            }

            .ico_step2 {
                @include sp-normal('ico_step2_on');
            }

            .ico_step3 {
                @include sp-normal('ico_step3_on');
            }

            .ico_step4 {
                @include sp-normal('ico_step4_on');
            }

            .txt {
                font-weight: 400;
                color: $black2;
            }
        }
    }

    .ico {
        display: block;
        margin: 0 auto;
    }

    .txt {
        display: block;
        margin-top: 14px;
        font-size: 18px;
        font-weight: 300;
        color: #a4a4a4;
        letter-spacing: -0.02em;
    }

    &+.result_bx {
        padding-top: 70px;

        .txt {
            margin-top: 2px;
        }
    }
}

.chkbox {
    &.policy {
        input:checked+label {
            &:before {
                @include sp-normal('ico_chkbox2_checked');
            }
        }
    
        label {
            padding-left: 39px;
            font-size: 22px;
            font-weight: 500;
            color: $black2;
            letter-spacing: -0.04em;
            
            &:before {
                @include sp-normal('ico_chkbox2');
            }
    
            &.txt_blue {
                color: #404dab;
            }
        }
    }

    &.email {
        display: block;
        margin-top: 15px;

        &+.dot_lst {
            margin: 4px 0 11px;
            font-size: 14px;
            color: $gray2;

            li {
                margin-top: 0;

                &:before {
                    background-color: $gray2;
                }
            }
        }
    }
}

.accordion{
    &_lst {
        margin-top: 52px;

        .accordion_itm {
            margin-top: 52px;
            border-bottom: 1px solid #a4a4a4;

            &:first-child {
                margin-top: 0;
            }

            &.open {
                .accordion_header {
                    border-bottom: 1px solid #a4a4a4;

                    &:after {
                        transform: rotate(0);
                    }
                }
    
                .accordion_content {
                    display: block;
                }
            }
        }
    }

    &_header {
        position: relative;
        border-bottom: 0;
        cursor: pointer;

        &:after {
            position: absolute;
            top: 39px;
            right: 0;
            @include sp-normal('ico_arr_accordion');
            transform: rotate(180deg);
            content: '';
        }

        .policy {
            padding: 30px 0;

            label {
                padding-left: 31px;
                letter-spacing: -0.02em;
            }
        }
    }

    &_content {
        display: none;
        margin: 40px 0;
        padding-left: 30px;
        color: $black2;

        .sb-scrollbar-container {
            right: 0;
        }

        .sb-content {
            max-height: 349px;
            padding-top: 0;
            padding-bottom: 0;
        }

        .tit {
            font-size: 26px;
            font-weight: 400;
            line-height: 1;
        }

        .chapter_tit {
            display: block;
            margin-top: 20px;
            font-size: 22px;
        }

        .article_tit {
            display: block;
            margin-top: 24px;
        }

        .article_itm {
            p {
                margin-top: 2px;
                line-height: 25px;
            }

            ol {
                margin-top: 2px;
            }

            li {
                line-height: 25px;
            }
        }

        .article_menu_lst {
            li {
                line-height: 1;
                margin-top: 8px;

                &:first-child {
                    margin-top: 0;
                }
            }

            a {
                font-weight: 500;
                color: $black2;
            }
        }

        table {
            font-size: 16px;

            th,
            td {
                border: 1px solid #e5e5e5;
                font-weight: 400;
                text-align: center;
            }

            th {
                height: 58px;
                background-color: #f8f8f8;
            }

            td {
                height: 60px;
            }
        }

        .lst_tit {
            display: block;
            margin-top: 24px;
            font-size: 18px;
            font-weight: 400;
            color: #101010;

            &+.dot_lst {
                margin-top: 16px;
            }
        }

        .dot_lst {
            margin-top: 30px;

            li {
                line-height: 1;
                color: $gray2;
                letter-spacing: -0.024em;

                &:before {
                    top: 7px;
                    background-color: $gray2;
                }

                &~li {
                    margin-top: 18px;
                }
            }
        }
    }
}