.game_guide_layout {
    .sb-scrollbar-container{
        top: 15px;
        bottom: 15px;
        right: -20px;
        border-radius: 4px;
    }
    .sb-scrollbar{
        border-radius: 4px;
        z-index: 99;
    }
    .wrap {
        min-height: 100%;
        padding-top: 100px;
        padding-bottom: 90px;
        box-sizing: border-box;
    }
    .tbl_lst {
        border-top-color: #a4a4a4;

        td {
            letter-spacing: -.2px;
        }
    }
    footer {
        position:absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 90px;
        margin-left: 300px;
        padding-left: 80px;
        border-top: 1px solid #e5e5e5;
        .inner {
            width: 100%;
            padding: 0;
        }
        .copyright {
            font-size: 13px;
            line-height: 90px;
            color: #222222;
        }
    }
    .header{
        .inner {
            width: 300px;
            padding: 0;
        }
        .title {
            width: 300px;
            margin-bottom: 38px;
            & + .btn {
                margin-left: 20px;
            }
        }
        .title a{
            display: block;
            position: relative;
            overflow: hidden;
            @extend .logo;
            margin: 0 auto;
            line-height: 999px;
        }
        .title ~ .btn {
            width: 125px;
            height: 44px;
            font-size: 14px;
            font-weight: 400;
            line-height: 45px;
            [class^="ico_"] {
                display: inline-block;
                margin-top: -2px;
                margin-right: 6px;
                vertical-align: middle;
            }
            &.silverfill {
                margin-left: 6px;
            }
        }
        .gnb {
            min-width: 300px;
            margin-top: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        }
        .gnb_lst {
            display: block;
            margin-left: 0;
            min-width: 100%;
            .lst_itm {
                display: block;
                min-height: 71px;
                height: auto;
                border-top:1px solid rgba(255,255,255,.05);
                text-align: left;
                & > a {
                    padding: 0 30px;
                    font-size: 18px;
                    font-weight: 300;
                    color: #c5c5d0;
                    line-height: 72px;
                    &:after {
                        position: absolute;
                        top: 50%;
                        right: 30px;
                        @extend .ico_arr_down4;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        content: '';
                    }
                    &.no_sub:after {
                        -webkit-transform: translateY(-50%) rotateZ(270deg);
                        -moz-transform: translateY(-50%) rotateZ(270deg);
                        -ms-transform: translateY(-50%) rotateZ(270deg);
                        -o-transform: translateY(-50%) rotateZ(270deg);
                        transform: translateY(-50%) rotateZ(270deg);
                    }
                    &.on:not(.no_sub):after {
                        -webkit-transform: translateY(-50%) rotateZ(180deg);
                        -moz-transform: translateY(-50%) rotateZ(180deg);
                        -ms-transform: translateY(-50%) rotateZ(180deg);
                        -o-transform: translateY(-50%) rotateZ(180deg);
                        transform: translateY(-50%) rotateZ(180deg);
                    }
                    &:hover,&.on {
                        color: #ffffff;
                        font-weight: 500;
                    }
                }
                .sub_lst {
                    position: relative;
                    top: 0;
                    margin-top: 0;
                    padding: 20px 0;
                    border-top:1px solid rgba(255,255,255,.05);
                    li~li {
                        margin-top: 18px;
                    }
                    a {
                        display: block;
                        padding: 0 49px;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 16px;
                        color: #9da3aa;
                        &.new:after {
                            display: inline-block;
                            @extend .red_dot;
                            margin-left: 4px;
                            margin-top: 4px;
                            vertical-align: top;
                            content: '';
                        }
                        &:hover,&.on {
                            color: #ffffff;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    #content {
        padding-bottom: 130px;
        .inner {
            width: 1000px;
            margin-left: 380px;
            padding-left: 0;
            padding-right: 0;
        }
        .page_header_area {
            margin-bottom: 40px;
            &+.inner {
                &>*:first-child {
                    margin-top: 0;
                }
            }
            .inner {
                padding-bottom: 40px;
                border-bottom: 2px solid #384859;
            }
            h2 {
                font-weight: 400;
                font-size: 40px;
                line-height: 36px;
            }
            .breadcrumb {
                position: absolute;
                bottom:17px;
                right: 0;
            }
            &>.clearfix {
                &>*:first-child {
                    float: left;
                    margin-top: 8px;
                }
                &>*:last-of-type:last-child {
                    float: right;
                }
            }
        }
        .comment_box {
            margin-top: 60px;
            padding-top: 30px;
            border-top: 1px solid #e5e5e5;
        }
    }
    .sc_lst {
        padding-left: 0;
        font-size: 0;
        li {
            display: inline-block;
            position: relative;
            a {
                display: block;
                .sc_img_bx {
                    width: 235px;
                    height: 202px;
                    background-color: #f4f4f4;
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .sc_tit {
                    display: block;
                    position: absolute;
                    bottom: 25px;
                    left: 50%;
                    font-size: 16px;
                    line-height: 15px;
                    color: #fafafa;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    white-space: nowrap;
                }
            }
            &:first-child~li {
                margin-left: 20px;
            }
        }
    }
    .search_area {
        margin-top: 60px;
        margin-bottom: 0;
        padding: 30px 50px;
        background: #f8f8f8;
        .inp_grp {
            float: right;
        }
        .select {
            width: 140px;
            margin-right: -5px;
            background: #ffffff;
            vertical-align: middle;
            color: #101010;
        }
        .search_inp {
            overflow: hidden;
            margin-left: 0;
            width: 656px;
            vertical-align: middle;
            input {
                width: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            button {
                background: #ebebeb;
            }
        }
        .search_lbl {
            display: inline-block;
            float: left;
            font-size: 18px;
            color: #222222;
            line-height: 50px;
            vertical-align: middle;
        }
        &+.tbl_lst {
            margin-top: 30px;
        }
    }
    .category_bx {
        display: block;
        float: left;
        width: 320px;
        margin-top: 30px;
        margin-right: 20px;
        border-bottom: 1px solid #a4a4a4;
        border-top: 2px solid #a4a4a4;
        &:nth-child(3n) {
            margin-right: 0;
        }
        .cate_tit {
            display: block;
            height: 60px;
            border-bottom: 1px solid #dfdfdf;
            background: #f8f8f8;
            font-size: 16px;
            font-weight: 400;
            line-height: 60px;
            color: #222222;
            text-align: center;
        }
        .cate_lst {
            display: block;
            height: 400px;
            width: 100%;
            padding: 32px 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-writing-mode: vertical-lr;
            -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
            &.type2 {
                height: 247px;

                li {
                    width: 100%;
                }
            }
            li {
                display: inline-block;
                width: 50%;
                margin: 4px 0 3px;
                -webkit-writing-mode: horizontal-tb;
                -ms-writing-mode: lr-tb;
                writing-mode: horizontal-tb;
                vertical-align: bottom;
                a {
                    display: block;
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 15px;
                    color: #222222;

                    &:hover {
                        font-weight: bold;
                        color: #404dab;
                    }
                }
                &.new {
                    a:after {
                        display: inline-block;
                        @extend .red_dot;
                        margin-left: 4px;
                        margin-top: 4px;
                        vertical-align: top;
                        content: '';
                    }
                }
            }
        }
    }
    .filter_lst {
        background: #f2f2f2;
        &>li {
            height: 55px;
            border-top: 1px solid #ffffff;
        }
        .filter_name {
            display: inline-block;
            width: 164px;
            padding-left: 24px;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            line-height: 55px;
            vertical-align: middle;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .filter_val_lst {
            display: inline-block;
            margin-left: -4px;
            vertical-align: middle;
            li {
                display: inline-block;
                &~li{
                    margin-left: 15px;
                    &:before {
                        display: inline-block;
                        width: 1px;
                        height: 14px;
                        background: #c8c8ce;
                        margin-right: 15px;
                        content: '';
                    }
                }
                a {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 300;
                    color: #222222;
                    vertical-align: text-bottom;
                }
            }
        }
        &+.itm_search {
            margin-top: 50px;
        }
    }
    .itm_search {
        .inp_grp {
            float: left;
            .select {
                width: 130px;
            }
            .search_inp {
                margin-left: -5px;
                input {
                    width: 469px;
                    &::placeholder {
                        color: #a4a4a4;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        color: #a4a4a4;
                    }

                    &::-ms-input-placeholder {
                        color: #a4a4a4;
                    }
                }
            }
        }

        &+.tbl_lst {
            margin-top: 19px;
        }
    }
    .rating_filter {
        float: right;
        width: 130px;
    }
    .menu_depth_2 {
        li {
            display: inline-block;
            width: 113px;
            height: 50px;
            border:1px solid #ebebeb;
            background: #ffffff;
            box-sizing: border-box;
            a {
                display: block;
                font-size: 18px;
                font-weight: 400;
                line-height: 48px;
                color: #a4a4a4;
                text-align: center;
            }
            &.on,&:hover {
                border:none;
                background: #384859;
                a {
                    line-height: 50px;
                    color: #ffffff;
                }
            }
            &~li {
                margin-left: 9px;
            }
        }
    }
    .tabs_lnk_lst {
        margin-top: 47px;
        &+.editor_area {
            margin-top: 75px;
        }
    }

    .change_log {
        margin-top: 23px;
        border-top: 2px solid #a4a4a4;
        li {
            padding: 60px 0 53px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        .type {
            display: inline-block;
        }
        .type_box {
            display: table-cell;
            padding: 0 12px;
            text-align: center;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            vertical-align: middle;
            em {
                font-size: 22px;
                font-weight: 400;
                color: #ffffff;
                line-height: 26px;
            }
            p {
                margin-top: 7px;
                font-size: 15px;
                font-weight: 300;
                color: #ffffff;
                line-height: 19px;
                opacity: .6;
            }
        }
        .change_detail {
            display: inline-block;
            width: 680px;
            margin-left: 78px;
            vertical-align: top;
            .gold_lbl {
                margin-top: 0;
            }
            .green_lbl {
                margin-top: 23px;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .history_lst {
        margin-top: 23px;
        border-top: 2px solid #a4a4a4;
        padding-top: 84px;
        &>li {
            position: relative;
            color: #868686;
            &:before {
                position: absolute;
                top: 0;
                left: 444px;
                @extend .timeline_dot;
                content: '';
            }
            &:after {
                position: absolute;
                top: 0;
                left: 450px;
                width: 1px;
                height: 100%;
                background: #384859;
                content: '';
            }
            &:last-child {
                &:after {
                    display: none
                }
                .period_detail,.period_name {
                    padding-bottom: 0;
                }
            }
        }
        .period_name {
            display: inline-block;
            width: 451px;
            padding-bottom: 75px;
            vertical-align: top;
            h5 {
                position: absolute;
                left: 0;
                bottom: 100%;
                margin-bottom: 13px;
            }
            img {
                margin-top: 0;
            }
            ul {
                margin-top: 7px;
                font-size: 15px;
                &>li {
                    position: relative;
                    padding-left: 11px;
                    font-weight: 300;

                    &:before {
                        position: absolute;
                        top: 11px;
                        left: 0;
                        width: 3px;
                        height: 3px;
                        background: #868686;
                        content: '';
                    }
                }
            }
        }
        .period_detail {
            display: inline-block;
            width: 545px;
            padding-bottom: 69px;
            padding-left: 49px;
            margin-left: -4px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            vertical-align: top;
            .num,.tit {
                display: inline-block;
                font-size: 20px;
                font-weight: 400;
                color: #404dab;
                line-height: 19px;
                vertical-align: top;
            }
            .tit {
                margin-left: 8px;
                &:before {
                    display: inline-block;
                    width: 1px;
                    height: 14px;
                    background: #c8c8cf;
                    margin-right: 8px;
                    content: '';
                }
            }
            p {
                margin-top: 7px;
                color: #222;
                line-height: 27px;
                letter-spacing: -0.45px;
            }
        }
    }
}