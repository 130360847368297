@charset "UTF-8";
/* SCSS */
/* Sprites */
/* Common */
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  src: url(../font/Roboto-Regular.eot);
  src: url(../font/Roboto-Regular.eot?#iefix) format("embedded-opentype"), url(../font/Roboto-Regular.woff) format("woff"), url(../font/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(../font/Roboto-Medium-webfont.eot);
  src: url(../font/Roboto-Medium-webfont.eot?#iefix) format("embedded-opentype"), url(../font/Roboto-Medium-webfont.woff) format("woff"), url(../font/Roboto-Medium-webfont.ttf) format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(../font/Roboto-Bold.eot);
  src: url(../font/Roboto-Bold.eot?#iefix) format("embedded-opentype"), url(../font/Roboto-Bold.woff) format("woff"), url(../font/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url(../font/Roboto-Light-webfont.eot);
  src: url(../font/Roboto-Light-webfont.eot?#iefix) format("embedded-opentype"), url(../font/Roboto-Light-webfont.woff) format("woff"), url(../font/Roboto-Light-webfont.ttf) format("truetype");
}

@font-face {
  font-family: 'notosans';
  src: url(../font/NotoSansCJKkr-Regular.eot);
  src: url(../font/NotoSansCJKkr-Regular.eot?#iefix) format("embedded-opentype"), url(../font/NotoSansCJKkr-Regular.woff) format("woff"), url(../font/NotoSansCJKkr-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'notosans';
  font-weight: 300;
  src: url(../font/notoSansCJKkr_Light.eot);
  src: url(../font/notoSansCJKkr_Light.eot?#iefix) format("embedded-opentype"), url(../font/notoSansCJKkr_Light.woff) format("woff"), url(../font/notoSansCJKkr_Light.ttf) format("truetype");
}

@font-face {
  font-family: 'notosans';
  font-weight: 500;
  src: url(../font/notoSansCJKkr_Medium.eot);
  src: url(../font/notoSansCJKkr_Medium.eot?#iefix) format("embedded-opentype"), url(../font/notoSansCJKkr_Medium.woff) format("woff"), url(../font/notoSansCJKkr_Medium.ttf) format("truetype");
}

@font-face {
  font-family: 'NanumMyeongjo';
  font-weight: normal;
  src: url(../font/NanumMyeongjo.eot);
  src: url(../font/NanumMyeongjo.eot?#iefix) format("embedded-opentype"), url(../font/NanumMyeongjo.woff) format("woff"), url(../font/NanumMyeongjo.ttf) format("truetype");
}

@font-face {
  font-family: 'Barlow';
  src: url(../font/Barlow-Medium.eot?#iefix) format(embedded-opentype), url(../font/Barlow-Medium.woff) format("woff"), url(../font/Barlow-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

.btn_cash_charge {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -883px -804px;
  width: 80px;
  height: 20px;
}

.btn_down {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -242px -397px;
  width: 472px;
  height: 76px;
}

.btn_down_hover, .intro_page .btn_down:hover {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -242px -317px;
  width: 472px;
  height: 76px;
}

.btn_minus {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -769px -995px;
  width: 36px;
  height: 36px;
}

.btn_minus_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -359px -1153px;
  width: 20px;
  height: 20px;
}

.btn_plus {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -809px -995px;
  width: 36px;
  height: 36px;
}

.btn_plus_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -335px -1153px;
  width: 20px;
  height: 20px;
}

.ch_lst_indi, .intro_page .character_area .ch_lst .slick-dots li button {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -982px;
  width: 56px;
  height: 5px;
}

.ch_lst_indi_hover, .intro_page .character_area .ch_lst .slick-dots li.slick-active button, .intro_page .character_area .ch_lst .slick-dots li button:hover {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -925px -380px;
  width: 56px;
  height: 5px;
}

.ch_lst_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1079px -752px;
  width: 26px;
  height: 53px;
}

.ch_lst_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1043px -1078px;
  width: 26px;
  height: 52px;
}

.dark_elf {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -741px;
  width: 360px;
  height: 59px;
}

.dekan {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -718px -317px;
  width: 261px;
  height: 59px;
}

.dhan {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -667px -741px;
  width: 234px;
  height: 59px;
}

.down, .tbl_lst .r_num .r_change.r_down:before, .tbl_lst .sort_btn:after {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -220px;
  width: 10px;
  height: 6px;
}

.dungeon {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -242px -477px;
  width: 399px;
  height: 59px;
}

.elf {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -204px -995px;
  width: 118px;
  height: 59px;
}

.flow_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1265px -1036px;
  width: 13px;
  height: 25px;
}

.flow_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1093px -407px;
  width: 12px;
  height: 24px;
}

.giant {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -718px -397px;
  width: 246px;
  height: 61px;
}

.gold_lbl {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -378px -540px;
  width: 102px;
  height: 33px;
}

.green_lbl {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -945px;
  width: 102px;
  height: 33px;
}

.guide_btn_buy {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -611px -928px;
  width: 35px;
  height: 20px;
}

.guide_btn_give {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -572px -928px;
  width: 35px;
  height: 20px;
}

.half_elf {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -645px -477px;
  width: 344px;
  height: 59px;
}

.human {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -364px -741px;
  width: 299px;
  height: 59px;
}

.ico_account_thief {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -943px;
  width: 67px;
  height: 60px;
}

.ico_archive {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -95px -995px;
  width: 105px;
  height: 67px;
}

.ico_arr_accordion {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -718px -462px;
  width: 21px;
  height: 11px;
}

.ico_arr_bubble {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -643px;
  width: 12px;
  height: 9px;
}

.ico_arr_calendar {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -678px;
  width: 12px;
  height: 7px;
}

.ico_arr_down {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -666px;
  width: 11px;
  height: 8px;
}

.ico_arr_down2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -689px;
  width: 11px;
  height: 6px;
}

.ico_arr_down3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -656px;
  width: 11px;
  height: 6px;
}

.ico_arr_down4, .game_guide_layout .header .gnb_lst .lst_itm > a:after {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1085px -395px;
  width: 15px;
  height: 8px;
}

.ico_arr_faq {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -967px;
  width: 8px;
  height: 15px;
}

.ico_arr_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1326px -1144px;
  width: 9px;
  height: 6px;
}

.ico_arr_lnb {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1079px -841px;
  width: 15px;
  height: 8px;
}

.ico_arr_refund {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -1035px;
  width: 8px;
  height: 5px;
}

.ico_arr_right {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1330px -569px;
  width: 5px;
  height: 9px;
}

.ico_arr_txt {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -914px;
  width: 6px;
  height: 9px;
}

.ico_arr_txt_blue {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -901px;
  width: 6px;
  height: 9px;
}

.ico_arr_txt_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -557px;
  width: 4px;
  height: 7px;
}

.ico_attach {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -533px;
  width: 12px;
  height: 12px;
}

.ico_bars, .right_shortcut li a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -847px -540px;
  width: 16px;
  height: 13px;
}

.ico_bars_hover, .right_shortcut li a:hover:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -827px -540px;
  width: 16px;
  height: 13px;
}

.ico_bronze {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -350px -587px;
  width: 171px;
  height: 150px;
}

.ico_bronze_badge {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -997px;
  width: 40px;
  height: 45px;
}

.ico_bronze_sm {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -651px -995px;
  width: 56px;
  height: 49px;
}

.ico_calendar {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -455px -1153px;
  width: 17px;
  height: 19px;
}

.ico_calendar_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1093px -435px;
  width: 14px;
  height: 16px;
}

.ico_cash {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1088px -903px;
  width: 19px;
  height: 19px;
}

.ico_cash_black_small {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1103px -1112px;
  width: 17px;
  height: 17px;
}

.ico_cash_blue_small {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -476px -1153px;
  width: 17px;
  height: 17px;
}

.ico_cash_gray {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1088px -880px;
  width: 19px;
  height: 19px;
}

.ico_cash_gray_small {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -497px -1153px;
  width: 17px;
  height: 17px;
}

.ico_change_pass {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -603px;
  width: 91px;
  height: 72px;
}

.ico_change_request {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -1084px;
  width: 62px;
  height: 61px;
}

.ico_change_to {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -1016px;
  width: 8px;
  height: 15px;
}

.ico_char_change_o {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -347px;
  width: 82px;
  height: 82px;
}

.ico_char_lock {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -413px;
  width: 51px;
  height: 46px;
}

.ico_char_transferred {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -569px;
  width: 43px;
  height: 47px;
}

.ico_character_name {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -857px;
  width: 46px;
  height: 40px;
}

.ico_character_name_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -995px;
  width: 91px;
  height: 79px;
}

.ico_character_name_o {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -261px;
  width: 82px;
  height: 82px;
}

.ico_character_protect {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -765px;
  width: 45px;
  height: 42px;
}

.ico_character_protect2_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -233px -868px;
  width: 112px;
  height: 101px;
}

.ico_character_protect_1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -811px;
  width: 45px;
  height: 42px;
}

.ico_character_protect_1_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -666px;
  width: 91px;
  height: 82px;
}

.ico_character_protect_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -494px;
  width: 91px;
  height: 82px;
}

.ico_character_unprotect {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -719px;
  width: 45px;
  height: 42px;
}

.ico_character_unprotect_1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -673px;
  width: 45px;
  height: 42px;
}

.ico_character_unprotect_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -580px;
  width: 91px;
  height: 82px;
}

.ico_chars_locked {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -404px -995px;
  width: 63px;
  height: 54px;
}

.ico_check_acc {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -416px -1078px;
  width: 65px;
  height: 58px;
}

.ico_chkbox, .chkbox label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -407px -1153px;
  width: 20px;
  height: 20px;
}

.ico_chkbox2, .service_page .sp_common_area .agr_chk_bx label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -383px -1153px;
  width: 20px;
  height: 20px;
}

.ico_chkbox2_checked, .service_page .sp_common_area .agr_chk_bx input:checked + label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -287px -1153px;
  width: 20px;
  height: 20px;
}

.ico_chkbox3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -165px -1187px;
  width: 16px;
  height: 20px;
}

.ico_chkbox3_checked {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -145px -1187px;
  width: 16px;
  height: 20px;
}

.ico_chkbox_checked, .chkbox input:checked + label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -311px -1153px;
  width: 20px;
  height: 20px;
}

.ico_circle_blue {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1124px -1112px;
  width: 17px;
  height: 17px;
}

.ico_circle_white {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1166px -1112px;
  width: 17px;
  height: 17px;
}

.ico_clock {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1265px -1065px;
  width: 14px;
  height: 14px;
}

.ico_close_pop {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1103px -1078px;
  width: 30px;
  height: 30px;
}

.ico_close_pop_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -518px -1153px;
  width: 16px;
  height: 16px;
}

.ico_comment {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -867px -540px;
  width: 14px;
  height: 13px;
}

.ico_confirm_accept {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -711px -995px;
  width: 54px;
  height: 46px;
}

.ico_confirm_accept_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px 0px;
  width: 108px;
  height: 91px;
}

.ico_coupon {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -650px -928px;
  width: 29px;
  height: 18px;
}

.ico_coupon_confirm {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -88px -1078px;
  width: 84px;
  height: 71px;
}

.ico_coupon_confirm2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -1078px;
  width: 84px;
  height: 71px;
}

.ico_coupon_in {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -1007px;
  width: 54px;
  height: 73px;
}

.ico_coupon_out {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -63px -1153px;
  width: 54px;
  height: 59px;
}

.ico_coupon_reg {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -880px;
  width: 71px;
  height: 59px;
}

.ico_coupon_write {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -905px -741px;
  width: 79px;
  height: 58px;
}

.ico_currency, .service_page .ser_pro_lst .price:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -263px -1153px;
  width: 20px;
  height: 20px;
}

.ico_currency_sm, .service_page .sv_info_bx .info_txt .price:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1145px -1112px;
  width: 17px;
  height: 17px;
}

.ico_daum {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -437px -928px;
  width: 59px;
  height: 24px;
}

.ico_del {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -705px -928px;
  width: 18px;
  height: 18px;
}

.ico_delegation {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -711px -868px;
  width: 97px;
  height: 48px;
}

.ico_diamond {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -967px -804px;
  width: 24px;
  height: 20px;
}

.ico_done {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -197px;
  width: 98px;
  height: 98px;
}

.ico_done2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -842px;
  width: 78px;
  height: 78px;
}

.ico_down_btn {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -360px;
  width: 49px;
  height: 49px;
}

.ico_download {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -237px -1153px;
  width: 22px;
  height: 22px;
}

.ico_download_l {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -121px -1187px;
  width: 20px;
  height: 24px;
}

.ico_download_ost {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1088px -926px;
  width: 16px;
  height: 14px;
}

.ico_download_s {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -751px -928px;
  width: 16px;
  height: 14px;
}

.ico_exc_l {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -95px;
  width: 98px;
  height: 98px;
}

.ico_exc_s {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -679px;
  width: 80px;
  height: 80px;
}

.ico_exc_xs {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -924px;
  width: 78px;
  height: 78px;
}

.ico_eye {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -743px -462px;
  width: 14px;
  height: 10px;
}

.ico_facebook {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -964px -995px;
  width: 34px;
  height: 34px;
}

.ico_feature_1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -700px -587px;
  width: 108px;
  height: 134px;
}

.ico_feature_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -117px -868px;
  width: 112px;
  height: 112px;
}

.ico_feature_3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -868px;
  width: 113px;
  height: 123px;
}

.ico_first {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -1190px;
  width: 40px;
  height: 40px;
}

.ico_first_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -168px;
  width: 10px;
  height: 10px;
}

.ico_flow_arr, .flow_lst.flow_v2 li ~ li:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1265px -1007px;
  width: 13px;
  height: 25px;
}

.ico_flow_done {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1041px -995px;
  width: 36px;
  height: 26px;
}

.ico_fraud {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -680px;
  width: 70px;
  height: 62px;
}

.ico_game_access {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -935px -868px;
  width: 58px;
  height: 45px;
}

.ico_gender_change {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -934px -587px;
  width: 59px;
  height: 60px;
}

.ico_gender_change_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -80px;
  width: 83px;
  height: 84px;
}

.ico_gender_change_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -812px -587px;
  width: 118px;
  height: 118px;
}

.ico_gift {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1093px -455px;
  width: 14px;
  height: 16px;
}

.ico_gift_change {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -1153px;
  width: 59px;
  height: 59px;
}

.ico_gold {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -587px;
  width: 171px;
  height: 150px;
}

.ico_gold_badge {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -1095px;
  width: 40px;
  height: 45px;
}

.ico_gold_sm {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -471px -995px;
  width: 56px;
  height: 49px;
}

.ico_guide {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -613px;
  width: 11px;
  height: 11px;
}

.ico_guide_gray {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -565px;
  width: 11px;
  height: 11px;
}

.ico_guild_accept_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -901px;
  width: 42px;
  height: 43px;
}

.ico_guild_accept_2_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -854px;
  width: 85px;
  height: 87px;
}

.ico_guild_change {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -513px;
  width: 42px;
  height: 52px;
}

.ico_guild_change_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -299px;
  width: 82px;
  height: 104px;
}

.ico_guild_namechange {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -620px;
  width: 39px;
  height: 49px;
}

.ico_guild_namechange_b {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -752px;
  width: 76px;
  height: 98px;
}

.ico_hand {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -811px;
  width: 65px;
  height: 65px;
}

.ico_hand_gift {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -84px;
  width: 70px;
  height: 76px;
}

.ico_heart {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -771px -928px;
  width: 16px;
  height: 14px;
}

.ico_heart_n {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -791px -928px;
  width: 16px;
  height: 14px;
}

.ico_heart_w {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -811px -928px;
  width: 16px;
  height: 14px;
}

.ico_home {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -580px;
  width: 12px;
  height: 12px;
}

.ico_house {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -164px;
  width: 72px;
  height: 71px;
}

.ico_house_change {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -433px;
  width: 82px;
  height: 81px;
}

.ico_house_switch {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -999px -407px;
  width: 90px;
  height: 83px;
}

.ico_ie {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -849px -995px;
  width: 35px;
  height: 35px;
}

.ico_inquiry {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -746px;
  width: 71px;
  height: 61px;
}

.ico_itm_buy {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -874px -868px;
  width: 57px;
  height: 47px;
}

.ico_krwon {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -211px -1153px;
  width: 22px;
  height: 22px;
}

.ico_last {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -393px -928px;
  width: 40px;
  height: 40px;
}

.ico_last_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -182px;
  width: 10px;
  height: 10px;
}

.ico_link {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -888px -995px;
  width: 34px;
  height: 34px;
}

.ico_lock {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -494px;
  width: 13px;
  height: 16px;
}

.ico_lock_shield {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px 0px;
  width: 72px;
  height: 80px;
}

.ico_login {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -743px -540px;
  width: 56px;
  height: 14px;
}

.ico_login2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -871px -380px;
  width: 50px;
  height: 12px;
}

.ico_login_ongate {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -718px -380px;
  width: 73px;
  height: 13px;
}

.ico_maintenance {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px 0px;
  width: 92px;
  height: 76px;
}

.ico_mini_download {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -596px;
  width: 11px;
  height: 13px;
}

.ico_monitor {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -326px -995px;
  width: 74px;
  height: 56px;
}

.ico_monitor2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -613px;
  width: 72px;
  height: 63px;
}

.ico_monitor_l {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -1006px;
  width: 88px;
  height: 68px;
}

.ico_new_window {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -628px;
  width: 11px;
  height: 11px;
}

.ico_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -349px -928px;
  width: 40px;
  height: 40px;
}

.ico_next_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -543px;
  width: 6px;
  height: 10px;
}

.ico_nodata {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -239px;
  width: 70px;
  height: 70px;
}

.ico_npc_guild {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -463px;
  width: 50px;
  height: 46px;
}

.ico_ongate {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -795px -380px;
  width: 72px;
  height: 13px;
}

.ico_paper {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -68px;
  width: 51px;
  height: 64px;
}

.ico_pdf {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -431px -1153px;
  width: 20px;
  height: 20px;
}

.ico_person {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -557px -1153px;
  width: 15px;
  height: 16px;
}

.ico_platinum {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -175px -587px;
  width: 171px;
  height: 150px;
}

.ico_platinum_badge {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -1046px;
  width: 40px;
  height: 45px;
}

.ico_platinum_sm {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -531px -995px;
  width: 56px;
  height: 49px;
}

.ico_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -1234px;
  width: 40px;
  height: 40px;
}

.ico_prev_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1326px -658px;
  width: 6px;
  height: 10px;
}

.ico_quest {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -683px -928px;
  width: 18px;
  height: 18px;
}

.ico_race_change {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -934px -651px;
  width: 58px;
  height: 53px;
}

.ico_race_change_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -763px;
  width: 83px;
  height: 75px;
}

.ico_right_angle {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -196px;
  width: 10px;
  height: 10px;
}

.ico_search {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1081px -995px;
  width: 24px;
  height: 24px;
}

.ico_search_gamemall {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1187px -1112px;
  width: 16px;
  height: 16px;
}

.ico_server_log {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -136px;
  width: 51px;
  height: 58px;
}

.ico_shield_lock {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1088px -854px;
  width: 18px;
  height: 22px;
}

.ico_silver {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -525px -587px;
  width: 171px;
  height: 150px;
}

.ico_silver_badge {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -948px;
  width: 40px;
  height: 45px;
}

.ico_silver_sm {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -591px -995px;
  width: 56px;
  height: 49px;
}

.ico_slide_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -313px;
  width: 70px;
  height: 70px;
}

.ico_slide_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -387px;
  width: 70px;
  height: 70px;
}

.ico_sm_cog {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -538px -1153px;
  width: 15px;
  height: 16px;
}

.ico_smartphone {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px 0px;
  width: 52px;
  height: 64px;
}

.ico_smartphone_l {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -534px;
  width: 61px;
  height: 75px;
}

.ico_step1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -919px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step1_on {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -857px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -795px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step2_on {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -733px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -609px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step3_on {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -547px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step4 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -671px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step4_on {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -981px -1078px;
  width: 58px;
  height: 58px;
}

.ico_step_krwon {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -485px -1078px;
  width: 58px;
  height: 58px;
}

.ico_tools {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -812px -868px;
  width: 58px;
  height: 48px;
}

.ico_top_btn {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -307px;
  width: 49px;
  height: 49px;
}

.ico_transcendence_stat {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -1216px;
  width: 54px;
  height: 63px;
}

.ico_transcendence_stat_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -168px;
  width: 78px;
  height: 89px;
}

.ico_transfer_complete {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -1144px;
  width: 39px;
  height: 42px;
}

.ico_twitter {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -926px -995px;
  width: 34px;
  height: 34px;
}

.ico_unlock_char {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -198px;
  width: 52px;
  height: 52px;
}

.ico_user {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -885px -540px;
  width: 14px;
  height: 13px;
}

.ico_xp_recovery {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1111px -518px;
  width: 82px;
  height: 81px;
}

.intro_close_btn {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -461px;
  width: 69px;
  height: 69px;
}

.intro_def_btn, .intro_page .content > div.monster_area .monster_info .intro_btn {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -319px -804px;
  width: 229px;
  height: 44px;
}

.intro_def_btn_hover, .intro_page .content > div.monster_area .monster_info .intro_btn:hover {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -552px -804px;
  width: 229px;
  height: 44px;
}

.logo, .game_guide_layout .header .title a {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -530px -868px;
  width: 177px;
  height: 56px;
}

.logo_gamemania {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -785px -804px;
  width: 94px;
  height: 28px;
}

.logo_gray, .logo.gray a, .game_guide_layout .header .title a.gray a {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -349px -868px;
  width: 177px;
  height: 56px;
}

.logo_kakaogames {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -484px -540px;
  width: 133px;
  height: 23px;
}

.logo_ongate {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -621px -540px;
  width: 118px;
  height: 19px;
}

.logo_playwith {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -242px -540px;
  width: 132px;
  height: 34px;
}

.main_banner_arr_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -948px;
  width: 8px;
  height: 15px;
}

.main_banner_arr_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1326px -620px;
  width: 8px;
  height: 15px;
}

.main_evt_arr_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -513px;
  width: 6px;
  height: 11px;
}

.main_evt_arr_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -528px;
  width: 6px;
  height: 11px;
}

.main_server_layer_close {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -549px;
  width: 12px;
  height: 12px;
}

.main_shop_arr_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -997px;
  width: 8px;
  height: 15px;
}

.main_shop_arr_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1326px -639px;
  width: 8px;
  height: 15px;
}

.main_vip_arr_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1085px -299px;
  width: 22px;
  height: 44px;
}

.main_vip_arr_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1085px -347px;
  width: 22px;
  height: 44px;
}

.quick_arr_top {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -699px;
  width: 11px;
  height: 6px;
}

.quick_ico_cash {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1171px -1078px;
  width: 30px;
  height: 25px;
}

.quick_ico_coupon {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -500px -928px;
  width: 30px;
  height: 24px;
}

.quick_ico_cs {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1137px -1078px;
  width: 30px;
  height: 27px;
}

.quick_ico_invite {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1079px -809px;
  width: 28px;
  height: 28px;
}

.quick_ico_pcbang {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1002px -995px;
  width: 35px;
  height: 28px;
}

.quick_ico_report {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -534px -928px;
  width: 34px;
  height: 21px;
}

.rdo_def, .rdo.rdo_v2 label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -849px -928px;
  width: 14px;
  height: 14px;
}

.rdo_def2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -831px -928px;
  width: 14px;
  height: 14px;
}

.rdo_selected, .rdo.rdo_v2 input:checked + label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1093px -475px;
  width: 14px;
  height: 14px;
}

.red_dot, .game_guide_layout .header .gnb_lst .lst_itm .sub_lst a.new:after, .game_guide_layout .category_bx .cate_lst li.new a:after {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -986px;
  width: 7px;
  height: 7px;
}

.restart_txt {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px 0px;
  width: 995px;
  height: 313px;
}

.s_rank_1, .tbl_lst .lbl_top_1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -151px -1153px;
  width: 26px;
  height: 30px;
}

.s_rank_2, .tbl_lst .lbl_top_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -181px -1153px;
  width: 26px;
  height: 30px;
}

.s_rank_3, .tbl_lst .lbl_top_3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -121px -1153px;
  width: 26px;
  height: 30px;
}

.sub_visual_arr_next {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1073px -1078px;
  width: 26px;
  height: 52px;
}

.sub_visual_arr_prev {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -968px -397px;
  width: 26px;
  height: 52px;
}

.timeline_dot, .game_guide_layout .history_lst > li:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -514px;
  width: 13px;
  height: 15px;
}

.tooltip_arrow {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -727px -928px;
  width: 20px;
  height: 15px;
}

.tooltip_arrow2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -803px -540px;
  width: 20px;
  height: 13px;
}

.top_btn, .btn_go_top {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1283px -254px;
  width: 49px;
  height: 49px;
}

.top_rank_1, .top_rank_lst .top_rank_ico.top_rank_1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -256px -1078px;
  width: 76px;
  height: 67px;
}

.top_rank_2, .top_rank_lst .top_rank_ico.top_rank_2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -176px -1078px;
  width: 76px;
  height: 67px;
}

.top_rank_3, .top_rank_lst .top_rank_ico.top_rank_3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -336px -1078px;
  width: 76px;
  height: 67px;
}

.trinity {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -804px;
  width: 315px;
  height: 60px;
}

.type_box {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: 0px -317px;
  width: 238px;
  height: 266px;
}

.up, .tbl_lst .r_num .r_change.r_up:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -210px;
  width: 10px;
  height: 6px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
th,
td,
form,
fieldset,
legend,
textarea,
input,
select,
textarea,
button,
article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
  margin: 0;
  padding: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
  display: block;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

img,
form,
fieldset,
svg,
li {
  border: 0;
  vertical-align: top;
}

ul,
ol {
  list-style: none;
}

button {
  overflow: visible;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

button span {
  position: relative;
}

button:disabled {
  cursor: default;
}

button::-moz-focus-inner {
  padding: 0;
  margin: -1px;
}

input::-ms-reveal {
  display: none;
}

input::-ms-clear {
  display: none;
}

input,
button,
select {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  overflow: auto;
}

address,
caption,
em {
  font-style: normal;
}

a,
a:focus,
a:active,
a:hover {
  text-decoration: none;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

.sb-container {
  position: relative;
  overflow: hidden !important;
}

.sb-content {
  height: 100%;
  width: 120%;
  padding-top: 40px;
  padding-right: 20%;
  padding-bottom: 40px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.sb-scrollbar-container {
  position: absolute;
  top: 0;
  right: 9px;
  bottom: 0;
  width: 8px;
  background: #ededed;
}

.sb-scrollbar-container-hide {
  right: -20px;
}

.sb-scrollbar {
  position: absolute;
  right: 0;
  width: 8px;
  background: #868686;
}

.sb-container-noscroll .sb-scrollbar-container {
  visibility: hidden;
}

.tbl_lst_indi {
  margin-top: 30px;
  height: 13px;
}

.tbl_lst_indi li {
  display: inline-block;
  height: 100%;
}

.tbl_lst_indi li a {
  display: inline-block;
  font-size: 15px;
  line-height: 13px;
  color: #868686;
  vertical-align: top;
}

.tbl_lst_indi li ~ li {
  margin-left: 16px;
}

.tbl_lst_indi li ~ li:before {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin-right: 20px;
  background: #c8c8cf;
  vertical-align: top;
  content: '';
}

.tbl_lst_indi li.active a {
  color: #222222;
}

.tbl_lst {
  width: 100%;
  border-top: 2px solid #384859;
  border-bottom: 1px solid #a4a4a4;
  font-size: 16px;
  text-align: center;
}

.tbl_lst.bot_line_gray {
  border-bottom: 1px solid #e5e5e5;
}

.tbl_lst.top_line_gray {
  border-top: 1px solid #e5e5e5;
}

.tbl_lst.top_line_gray2 {
  border-top: 1px solid #a4a4a4;
}

.tbl_lst.high_lh th, .tbl_lst.high_lh td {
  height: 70px;
}

.tbl_lst.type1 th, .tbl_lst.type1 td {
  padding-top: 8px;
  padding-bottom: 7px;
}

.tbl_lst th {
  font-weight: 400;
}

.tbl_lst thead th {
  height: 60px;
  border-bottom: 1px solid #dfdfdf;
  font-weight: 400;
  background-color: #f8f8f8;
}

.tbl_lst thead th.txt_left {
  padding-left: 40px;
}

.tbl_lst tbody tr {
  border-top: 1px solid #dfdfdf;
}

.tbl_lst tbody tr:first-child {
  border-top: 0;
}

.tbl_lst tbody tr.top_rank_row td {
  font-weight: 400;
}

.tbl_lst tbody tr.top_rank_row td .r_change {
  top: 10px;
}

.tbl_lst .nodata {
  height: 480px;
}

.tbl_lst .nodata .ico {
  display: block;
  margin: 8px auto 0;
}

.tbl_lst .nodata .txt_msg {
  display: block;
  margin: 12px auto 0;
  font-size: 24px;
  font-weight: normal;
  color: #868686;
  letter-spacing: -0.02em;
  text-align: center;
}

.tbl_lst td {
  height: 60px;
  font-weight: 300;
  color: #222;
}

.tbl_lst td.txt_left {
  padding-left: 40px;
}

.tbl_lst td.guild_name_cell {
  padding-left: 145px;
}

.tbl_lst td.guild_name_cell a {
  font-size: 16px;
  color: #222222;
}

.tbl_lst td.guild_name_cell2 {
  padding-left: 88px;
}

.tbl_lst td.guild_name_cell2 img {
  margin-top: 4px;
}

.tbl_lst td a {
  display: inline-block;
  font-size: 0;
  line-height: 20px;
  vertical-align: top;
}

.tbl_lst td a:hover {
  text-decoration: underline;
}

.tbl_lst td a:hover .tit {
  text-decoration: underline;
}

.tbl_lst td a.btn {
  text-decoration: none;
}

.tbl_lst td .lbl_outline + .tit {
  max-width: 740px;
  margin-left: 10px;
}

.tbl_lst td .tit {
  overflow: hidden;
  display: inline-block;
  max-width: 870px;
  font-size: 16px;
  color: #222;
  line-height: 28px;
  letter-spacing: -0.005em;
  vertical-align: top;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tbl_lst td .tit + .ico_attach {
  margin-left: 12px;
}

.tbl_lst td .number {
  display: inline-block;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  line-height: 25px;
  vertical-align: top;
}

.tbl_lst td .number + .txt_new {
  margin-left: 4px;
}

.tbl_lst td .ico_attach {
  display: inline-block;
  margin-left: 4px;
}

.tbl_lst td .txt_new {
  line-height: 26px;
}

.tbl_lst td em {
  font-weight: bold;
}

.tbl_lst td .ico_change {
  display: inline-block;
  font-size: 11px;
  margin: 0 4px;
  line-height: 19px;
}

.tbl_lst td .txt_result {
  font-size: 14px;
}

.tbl_lst .cell_v1 {
  background-color: #f8f8f8;
}

.tbl_lst .cell_v1 td {
  font-weight: 400;
}

.tbl_lst .cell_v2 {
  background-color: #f3f3f4;
}

.tbl_lst .cell_v3 {
  background-color: #f2f2f2;
}

.tbl_lst .r_num {
  display: inline-block;
  position: relative;
  width: 26px;
  vertical-align: middle;
}

.tbl_lst .r_num .r_change {
  position: absolute;
  top: 7px;
  left: 100%;
  height: 9px;
  margin-left: 10px;
  font-size: 13px;
  line-height: 9px;
  color: #868686;
  white-space: nowrap;
}

.tbl_lst .r_num .r_change:before {
  display: inline-block;
  width: 7px;
  height: 1px;
  margin-right: 7px;
  background-color: #8b8c92;
  vertical-align: middle;
  content: '';
}

.tbl_lst .r_num .r_change.r_up:before, .tbl_lst .r_num .r_change.r_down:before {
  display: inline-block;
  background-color: transparent;
}

.tbl_lst .r_num .r_change.r_up:before {
  margin-top: -3px;
}

.tbl_lst .r_num .r_change.r_down:before {
  margin-top: -2px;
}

.tbl_lst .r_num [class^="lbl_top_"] {
  display: block;
}

.tbl_lst .lbl_top_1 {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.tbl_lst .lbl_top_2 {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.tbl_lst .lbl_top_3 {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

.tbl_lst .sort_btn {
  font-size: 16px;
  outline: none;
}

.tbl_lst .sort_btn:after {
  display: inline-block;
  margin-left: 8px;
  margin-top: 9px;
  vertical-align: top;
  content: '';
}

.tbl_lst .sort_btn.no_btn:after {
  margin-top: 7px;
}

.tbl_lst .sort_btn.sort_up:after {
  transform: rotateZ(180deg);
}

.tbl_lst.question a {
  display: block;
  position: relative;
  line-height: 72px;
}

.tbl_lst.question a:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 50px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -967px;
  width: 8px;
  height: 15px;
  transform: translateY(-50%);
  content: '';
}

.tbl_lst.question a:hover .tit {
  text-decoration: none;
}

.tbl_lst.question .txt_q {
  display: inline-block;
  width: 117px;
  font-family: 'notosans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #404dab;
  line-height: 69px;
  text-align: center;
  vertical-align: top;
}

.tbl_lst.question .lbl_outline {
  vertical-align: middle;
}

.tbl_lst.question .tit {
  padding-left: 0;
  line-height: 72px;
}

.tbl_lst.download_faq tr > *:first-child {
  padding-left: 50px;
  text-align: left;
  box-sizing: border-box;
}

.tbl_lst.download_faq tr > *:nth-child(2) {
  padding-right: 50px;
  text-align: left;
  box-sizing: border-box;
}

.tbl_lst.novel .txt_left {
  padding-left: 20px;
}

.tbl_lst.novel .tit {
  max-width: 240px;
  font-weight: 400;
  color: #222;
}

.tbl_lst.novel .number {
  margin-left: 8px;
}

.tbl_lst.novel .txt_novel {
  display: inline-block;
  max-width: 776px;
  padding: 17px 0 18px;
  font-size: 14px;
  font-weight: 300;
  color: #222;
  line-height: 20px;
  vertical-align: top;
}

.tbl_lst.novel .txt_novel:hover {
  text-decoration: underline;
}

.tbl_lst.novel + .pagination {
  margin-top: 100px;
}

.tbl_lst .dash {
  font-size: 18px;
  font-weight: 300;
  padding: 0 12px;
}

.tbl_lst .at_sign {
  font-size: 16px;
  font-weight: 300;
}

.tbl_lst .at_sign + .domain {
  margin: -6px 0 0 21px;
}

.tbl_lst .sms {
  margin-left: 44px;
}

.tbl_lst .certification_box {
  display: inline-block;
  margin-left: 27px;
}

.tbl_lst .certification_box:before {
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: #c8c8cf;
  vertical-align: middle;
  content: '';
}

.tbl_lst .certification_box input {
  width: 104px;
  margin-left: 25px;
}

.tbl_lst .certification_box .countdown {
  font-weight: 400;
  color: #404dab;
}

.tbl_lst .certification_box .btn {
  margin-left: 12px;
}

.tbl_lst .message {
  overflow: hidden;
  display: none;
  font-size: 14px;
  font-weight: 300;
  color: #b83d3d;
  line-height: 24px;
  vertical-align: top;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tbl_lst .validate .message {
  display: block;
}

.write_area .area_tit {
  margin-bottom: 17px;
}

.write_area .tbl_lst {
  border-bottom: 0;
}

.write_area .tbl_lst .editor {
  border-top: 0;
}

.write_area .tbl_lst th,
.write_area .tbl_lst td {
  height: 74px;
  text-align: left;
}

.write_area .tbl_lst th {
  padding-left: 29px;
  font-size: 14px;
  font-weight: 400;
}

.write_area .tbl_lst .rdo_lst {
  font-size: 14px;
  font-weight: 400;
}

.write_area .tbl_lst .rdo_lst li {
  margin-left: 24px;
}

.write_area .tbl_lst .rdo_lst li:first-child {
  margin-left: 0;
}

.write_area .tbl_lst .rdo_v2 input:checked + label {
  padding-top: 1px;
  font-weight: 400;
  color: #384859;
}

.write_area .tbl_lst .rdo_v2 label {
  display: inline-block;
  font-weight: 300;
  color: #868686;
}

.write_area .tbl_lst .rdo_v2 label:before {
  margin: -2px 5px 0 0;
}

.write_area .tbl_lst .sms {
  margin-left: 44px;
}

.write_area .tbl_lst .file_upload_box {
  margin: 10px 0;
}

.server_status {
  display: inline-block;
  width: 44px;
  background-color: #404dab;
  font-size: 13px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  vertical-align: top;
}

.server_status.inspect {
  background-color: #76777f;
}

.server_status.crowded {
  background-color: #e02424;
}

.server_status.uncrowded {
  background-color: #509216;
}

.server_status.recommand {
  background-color: #b78331;
}

.shop_label {
  display: inline-block;
  width: 66px;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.shop_label.best {
  background-color: #b78331;
}

.shop_label.new {
  background-color: #404dab;
}

.shop_label.md {
  background-color: #509216;
}

.lbl {
  display: inline-block;
  min-width: 70px;
  padding: 0 5px;
  font-size: 13px;
  color: #fff;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  box-sizing: border-box;
}

.lbl.red {
  background-color: #e02424;
}

.lbl.yellow {
  background-color: #b78331;
}

.lbl.green {
  background-color: #509216;
}

.lbl.blue {
  background-color: #404dab;
}

.lbl.purple {
  background-color: #a540ab;
}

.lbl_outline {
  display: inline-block;
  min-width: 72px;
  height: 26px;
  padding: 0 5px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  box-sizing: border-box;
}

.lbl_outline.red {
  border: 1px solid #e02424;
  color: #e02424;
}

.lbl_outline.yellow {
  border: 1px solid #b78331;
  color: #b78331;
}

.lbl_outline.green {
  border: 1px solid #509216;
  color: #509216;
}

.lbl_outline.blue {
  border: 1px solid #404dab;
  color: #404dab;
}

.lbl_outline.purple {
  border: 1px solid #a540ab;
  color: #a540ab;
}

.lbl_outline.black {
  border: 1px solid #222;
  color: #222;
}

.lbl_outline.s_lbl {
  width: 55px;
  height: 24px;
  line-height: 24px;
}

.lbl_outline.txt_s {
  font-size: 12px;
}

.layer {
  display: none;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  padding: 50px 0;
  font-size: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
}

.layer:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.popup {
  display: none;
  position: relative;
  z-index: 10001;
  width: 1410px;
  margin: 50px auto;
  padding: 26px 60px 60px;
  background-color: #fff;
  text-align: left;
  box-sizing: border-box;
  vertical-align: middle;
}

.popup .tbl_lst em {
  font-weight: 400;
}

.popup.guild_popup {
  width: 1092px;
  font-size: 16px;
}

.popup.guild_popup .popup_ct {
  margin-top: 27px;
}

.popup.guild_popup .popup_ct p {
  font-size: 16px;
  font-weight: 300;
  color: #222222;
}

.popup.calc_guide_pop {
  width: 932px;
  height: 420px;
  font-size: 16px;
}

.popup.calc_guide_pop .popup_ct {
  margin-top: 25px;
}

.popup.calc_guide_pop .popup_ct p {
  font-size: 16px;
  font-weight: 300;
  color: #222222;
}

.popup.calc_guide_pop .sb-container {
  width: calc(100% + 30px);
}

.popup.calc_guide_pop .sb-content {
  max-height: 199px !important;
}

.popup.invite_adv_pop, .popup.info_coupon_return {
  width: 932px;
  height: 420px;
}

.popup.invite_adv_pop .sb-container, .popup.info_coupon_return .sb-container {
  width: calc(100% + 30px);
}

.popup.invite_adv_pop .sb-content, .popup.info_coupon_return .sb-content {
  max-height: 243px !important;
}

.popup.invite_adv_pop .sb-content .dot_lst, .popup.info_coupon_return .sb-content .dot_lst {
  margin-top: 30px;
}

.popup.support_popup {
  width: 1092px;
  font-size: 16px;
}

.popup.support_popup .popup_ct {
  margin-top: 25px;
}

.popup.support_popup .popup_ct > strong {
  display: block;
  margin: 21px 0 10px;
  color: #222;
}

.popup.support_popup .popup_ct > strong:first-child {
  margin-top: 0;
}

.popup.support_popup .popup_ct .select {
  width: 130px;
}

.popup.support_popup .popup_ct .select + .select {
  margin-left: 6px;
}

.popup.support_popup .popup_ct .btn_authenticate {
  width: 104px;
  margin-left: 22px;
}

.popup.support_popup .popup_ct .btn_authenticate.grayfill2 {
  width: 92px;
}

.popup.support_popup .popup_ct .certification_box input {
  width: 104px;
}

.popup.support_popup .dash_lst {
  margin-top: 12px;
}

.popup.support_popup .dash_lst li {
  margin-top: 4px;
  padding-left: 10px;
  font-size: 14px;
  color: #868686;
  line-height: 22px;
}

.popup.support_popup .dash_lst li:first-child {
  margin-top: 0;
}

.popup.sanctions_popup {
  width: 1092px;
}

.popup.sanctions_popup .popup_ct p {
  margin-top: 28px;
  font-size: 16px;
  color: #222222;
}

.popup.sanctions_popup .popup_ct p a {
  position: relative;
  color: #404dab;
}

.popup.sanctions_popup .popup_ct p a:before {
  position: absolute;
  left: 1px;
  right: 1px;
  bottom: 1px;
  height: 1px;
  background-color: #404dab;
  content: '';
}

.popup.sanctions_popup .tbl_lst {
  margin-top: 26px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
}

.popup.sanctions_popup .tbl_lst th, .popup.sanctions_popup .tbl_lst td {
  height: 70px;
  padding-left: 30px;
  border-top: 1px solid #e5e5e5;
}

.popup.sanctions_popup .tbl_lst .txt_bold {
  font-weight: 400;
}

.popup.sanctions_popup .pop_btn_grp .btn.btn_lg {
  width: 262px;
  margin: 0 10px;
}

.popup.product_popup {
  width: 1092px;
}

.popup.product_popup .tbl_lst {
  border: solid #a4a4a4;
  border-width: 1px 0;
}

.popup.product_popup .tbl_lst th {
  padding-left: 30px;
}

.popup.product_popup .tbl_lst td {
  height: 70px;
  padding-left: 30px;
}

.popup.product_popup .tbl_lst td > div {
  padding: 17px 0 15px;
}

.popup.product_popup .tbl_lst .search_inp {
  padding: 0;
}

.popup.product_popup .tbl_lst .search_inp input {
  font-size: 16px;
}

.popup.product_popup .tbl_lst .search_inp input::-webkit-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.popup.product_popup .tbl_lst .search_inp input:-moz-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.popup.product_popup .tbl_lst .search_inp input:-ms-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.popup.product_popup .tbl_lst .search_inp input::placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.popup.product_popup .tbl_lst .cash {
  margin-left: 14px;
  font-size: 14px;
  color: #b83d3d;
}

.popup.product_popup .tbl_lst .txt_note {
  font-size: 14px;
  font-weight: 300;
  color: #404dab;
}

.popup.product_popup .tbl_lst .btn_minus,
.popup.product_popup .tbl_lst .btn_plus {
  vertical-align: middle;
}

.popup.product_popup .tbl_lst .num {
  display: inline-block;
  margin: 0 11px;
  font-weight: 400;
  line-height: 36px;
  vertical-align: middle;
}

.popup.product_popup .tbl_lst .btn_charge {
  width: 60px;
  margin-left: 46px;
}

.popup.product_popup .tbl_lst .btn_limit {
  margin-left: 54px;
}

.popup.product_popup .tbl_lst .inp_pass {
  width: 160px;
}

.popup.product_popup .product_bx {
  height: 130px;
  margin: 50px 0 40px;
  font-size: 0;
}

.popup.product_popup .product_info {
  display: inline-block;
  position: relative;
  width: 450px;
  height: 100%;
  padding: 13px 0 0 134px;
  box-sizing: border-box;
  vertical-align: top;
}

.popup.product_popup .product_info .btn_group {
  margin-top: 27px;
}

.popup.product_popup .product_info .btn_group button {
  width: 34px;
  height: 34px;
  margin-left: 8px;
  border-radius: 2px;
  vertical-align: top;
}

.popup.product_popup .product_info .btn_group button:first-child {
  margin-left: 0;
}

.popup.product_popup .product_info .ico {
  display: inline-block;
}

.popup.product_popup .product_info .ico_heart_w {
  margin-top: 4px;
}

.popup.product_popup .product_info .btn_heart {
  background-color: #e02424;
}

.popup.product_popup .product_info .btn_heart.is_disabled {
  background-color: #a4a4a4;
}

.popup.product_popup .product_info .btn_gift {
  background-color: #404dab;
}

.popup.product_popup .product_thumb {
  position: absolute;
  top: 13px;
  left: 0;
  width: 104px;
  height: 104px;
  border: 2px solid #f4cf88;
  box-sizing: border-box;
}

.popup.product_popup .product_thumb img {
  width: 100%;
  height: 100%;
}

.popup.product_popup .product_name {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.popup.product_popup .product_desc {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  color: #022222;
}

.popup.product_popup .product_detail {
  display: inline-block;
  width: 499px;
  height: 100%;
  margin-left: 22px;
  padding: 6px 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  vertical-align: top;
}

.popup.product_popup .product_detail p {
  margin-top: 0;
  color: #222;
  line-height: 21px;
}

.popup.product_popup .product_detail ul {
  font-size: 14px;
  font-weight: 300;
  color: #222;
}

.popup.product_popup .product_detail .sb-content {
  padding-top: 0;
}

.popup.product_popup .product_detail .sb-scrollbar-container {
  top: 10px;
  right: 6px;
  bottom: 10px;
  border-radius: 2px;
  background: #f8f8f8;
}

.popup.product_popup .product_detail .sb-scrollbar {
  border-radius: 2px;
}

.popup.product_popup .search_result {
  margin-top: 10px;
  font-size: 0;
}

.popup.product_popup .search_result dt {
  display: inline-block;
  position: relative;
  margin-left: 30px;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #222;
}

.popup.product_popup .search_result dt:before {
  position: absolute;
  top: 6px;
  left: 0;
  width: 1px;
  height: 14px;
  background-color: #c8c8cf;
  content: '';
}

.popup.product_popup .search_result dt:first-child {
  margin-left: 0;
  padding-left: 0;
}

.popup.product_popup .search_result dt:first-child:before {
  content: none;
}

.popup.product_popup .search_result dd {
  display: inline-block;
  margin-left: 17px;
  font-size: 16px;
  color: #868686;
}

.popup.product_popup .lst_tit {
  display: block;
  margin-top: 13px;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  line-height: 30px;
}

.popup.product_popup .dot_lst {
  margin-top: 2px;
}

.popup.product_popup .dot_lst li {
  margin-top: 0;
  font-size: 14px;
  line-height: 22px;
  color: #222;
}

.popup.char_lst_popup {
  width: 1092px;
}

.popup.char_lst_popup .dot_lst {
  margin-top: 30px;
  font-size: 16px;
}

.popup.char_lst_popup .btn_grp {
  margin-top: 60px;
}

.popup.char_transfer_popup {
  width: 1092px;
}

.popup.char_transfer_popup .popup_ct p {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 36px;
  color: #222222;
}

.popup.char_transfer_popup .popup_ct p strong {
  font-weight: 500;
}

.popup.char_transfer_popup .popup_ct .tbl_lst {
  margin-top: 19px;
}

.popup.char_transfer_popup .popup_ct .tbl_lst .txt_left {
  padding-left: 30px;
}

.popup.char_transfer_popup .popup_ct .dot_lst {
  margin-top: 24px;
  font-size: 16px;
}

.popup.char_transfer_popup .popup_ct .pop_btn_grp {
  margin-top: 55px;
}

.popup.purchase_history {
  width: 1092px;
}

.popup.purchase_history .note {
  margin: 25px 0 26px;
}

.popup.purchase_history .note strong {
  display: block;
  font-size: 20px;
  color: #222;
}

.popup.purchase_history .note p {
  margin-top: 3px;
  font-size: 16px;
  color: #222;
}

.popup.purchase_history .tbl_lst {
  border: solid #e5e5e5;
  border-width: 1px 0;
}

.popup.purchase_history .tbl_lst th,
.popup.purchase_history .tbl_lst td {
  padding-left: 30px;
}

.popup.purchase_history .guide_lst {
  margin-top: 26px;
}

.popup.purchase_history .guide_lst dt {
  margin-top: 15px;
  font-size: 16px;
  color: #222;
}

.popup.purchase_history .guide_lst dt:first-child {
  margin-top: 0;
}

.popup.purchase_history .guide_lst dd {
  font-size: 14px;
  font-weight: 300;
  color: #868686;
}

.popup.purchase_history .guide_lst .ico_arr_txt {
  display: inline-block;
  margin: 6px 0 0;
  font-size: 0;
  vertical-align: top;
}

.popup.delegation_sv_popup {
  width: 1092px;
}

.popup.delegation_sv_popup .dot_lst {
  margin-top: 10px;
  font-size: 16px;
}

.popup.delegation_sv_popup .tbl_lst .txt_left {
  padding-left: 26px;
}

.popup.delegation_sv_popup .tbl_lst .btn {
  width: 100px;
  margin-left: 60px;
  font-size: 14px;
}

.popup.cash_popup {
  width: 1092px;
}

.popup.cash_popup .popup_ct {
  position: relative;
}

.popup.cash_popup .txt_note {
  margin-top: 37px;
  font-size: 16px;
  color: #222;
}

.popup.cash_popup .btn_setting_limit {
  position: absolute;
  top: -7px;
  right: 0;
  width: 120px;
  font-size: 16px;
  font-weight: 400;
}

.popup.cash_popup .tbl_lst {
  margin-top: 27px;
}

.popup.cash_popup .tbl_lst th {
  padding-left: 30px;
}

.popup.cash_popup .tbl_lst td {
  height: 70px;
  padding-left: 30px;
}

.popup.cash_popup .tbl_lst td > div {
  padding: 17px 0 15px;
}

.popup.cash_popup .tbl_lst .rdo.rdo_v1 label {
  border-radius: 2px;
}

.popup.cash_popup .tbl_lst .rdo_lst {
  margin: -5px -6px -4px;
}

.popup.cash_popup .tbl_lst .rdo_lst li {
  margin: 5px 6px 4px;
}

.popup.cash_popup .tbl_lst .cash {
  font-size: 18px;
  font-weight: 400;
  color: #222;
  letter-spacing: -0.02em;
}

.popup.cash_popup .tbl_lst .cash .num {
  font-size: 20px;
  font-weight: 500;
}

.popup.cash_popup .tbl_lst .cash.txt_blue {
  color: #404dab;
}

.popup.cash_popup .tbl_lst .txt_after_charge {
  font-size: 18px;
  font-weight: 400;
  color: #404dab;
}

.popup.cash_popup .tbl_lst em {
  font-weight: 400;
}

.popup.cash_popup .txt_notice {
  margin-top: 16px;
  font-size: 16px;
  color: #404dab;
}

.popup.cash_popup .cash_term {
  margin-top: 17px;
}

.popup.cash_popup .cash_term label {
  font-size: 16px;
  color: #222;
}

.popup.cash_popup .cash_term + .btn_outline {
  width: 68px;
  height: 28px;
  margin-left: 10px;
  font-weight: 400;
  line-height: 28px;
  border: 1px solid #404dab;
  color: #404dab;
}

.popup.cash_popup .registry_step_lst {
  margin: 50px 0 45px;
}

.popup.cash_popup .lst_tit {
  display: block;
  margin-top: 17px;
  font-size: 16px;
  color: #222;
}

.popup.cash_popup .dot_lst {
  margin-top: 4px;
}

.popup.cash_popup .dot_lst li {
  font-size: 14px;
}

.popup.cash_popup .dot_lst li:before {
  top: 9px;
}

.popup.cash_popup .dot_lst li ~ li {
  margin-top: 2px;
}

.popup.cash_popup .inp_pass {
  width: 190px;
}

.popup.cash_popup .validate .message {
  display: inline-block;
  margin-left: 16px;
  vertical-align: top;
  line-height: 46px;
}

.popup.cash_popup .btn_edit {
  display: inline-block;
  margin-top: 14px;
  font-size: 14px;
  color: #404dab;
}

.popup.cash_popup .btn_edit .ico_arr_txt_blue {
  display: inline-block;
  margin-top: 5px;
  font-size: 0;
  vertical-align: top;
}

.popup.cash_popup .result_bx {
  margin-top: 55px;
  padding-top: 0;
}

.popup.cash_popup .result_bx .tit {
  margin-top: 17px;
  font-size: 15px;
  font-weight: 400;
  color: #868686;
}

.popup.cash_popup .result_bx + .tbl_lst {
  margin-top: 62px;
}

.popup.cash_popup .result_bx + .tbl_lst + .dot_lst {
  margin-top: 15px;
}

.popup.buy_vip_popup {
  width: 1092px;
}

.popup.buy_vip_popup .tbl_lst {
  margin-top: 30px;
}

.popup.buy_vip_popup .tbl_lst .btn {
  width: 100px;
  margin-left: 60px;
  font-size: 14px;
}

.popup.buy_vip_popup .tbl_lst .inp_txt {
  width: 150px;
}

.popup.buy_vip_popup .tbl_lst .inp_txt + .txt_blue {
  margin-left: 20px;
}

.popup.buy_vip_popup .tbl_lst td.txt_left {
  padding-left: 30px;
}

.popup.buy_vip_popup .tbl_lst .txt_msg {
  margin-left: 15px;
}

.popup.buy_vip_popup .dot_lst {
  margin-top: 14px;
  font-size: 16px;
}

.popup.buy_vip_popup .dot_lst li ~ li {
  margin-top: 12px;
}

.popup .pop_block {
  margin-top: 25px;
}

.popup .pop_block .block_tit {
  display: block;
  font-size: 18px;
  color: #101010;
  font-weight: 500;
}

.popup .pop_block .block_tit + .tbl_lst {
  margin-top: 17px;
}

.popup .pop_block ~ .pop_block {
  margin-top: 40px;
}

.popup .popup_tit_box {
  margin-top: 26px;
  padding-bottom: 24px;
  border-bottom: 2px solid #384859;
  text-align: center;
}

.popup .popup_tit_box .pop_tit {
  font-size: 26px;
  font-weight: 500;
  color: #384859;
}

.popup .popup_tit_box.has_tab_lnk {
  padding-bottom: 15px;
}

.popup .popup_tit_box .tabs_lnk_lst {
  margin-top: 6px;
  text-align: left;
}

.popup .tab {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.popup .tab li {
  display: table-cell;
}

.popup .tab li.on a {
  color: #384859;
  border-bottom: 2px solid #384859;
}

.popup .tab a {
  display: block;
  font-size: 26px;
  font-weight: 500;
  color: #a4a4a4;
  letter-spacing: -0.02em;
  line-height: 90px;
  text-align: center;
  border-bottom: 2px solid #e5e5e5;
  cursor: pointer;
}

.popup .popup_ct {
  margin-top: 2px;
}

.popup .popup_ct.tab_ct {
  display: none;
}

.popup .popup_ct .tit {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.popup .popup_ct .subtit {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 300;
  color: #222;
}

.popup .popup_ct .tbl_wrap {
  margin-top: 14px;
}

.popup .popup_ct .tbl_wrap th,
.popup .popup_ct .tbl_wrap td {
  padding: 7px 0;
  border: 1px solid #cecece;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;
}

.popup .popup_ct .tbl_wrap thead th {
  color: #222;
  background-color: #f8f8f8;
}

.popup .popup_ct .tbl_wrap td {
  color: #5e5e5e;
}

.popup .popup_ct .tbl_wrap + .tbl_wrap {
  margin-top: 33px;
}

.popup .popup_ct p {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #868686;
}

.popup .popup_ct .agreement_lst > li {
  margin-top: 34px;
  padding-bottom: 35px;
  border-bottom: 1px solid #e2e7ee;
}

.popup .popup_ct .agreement_lst > li:first-child {
  margin-top: 0;
}

.popup .popup_ct .agreement_lst > li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.popup .popup_ct .agreement_lst > li em {
  font-weight: normal;
  color: #101010;
}

.popup .popup_ct .agreement_lst .tit {
  font-size: 18px;
}

.popup .popup_ct .agreement_lst .tit .num {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin-right: 6px;
}

.popup .popup_ct .agreement_lst .dot_lst {
  margin-top: 30px;
}

.popup .popup_ct .agreement_lst .dash_lst {
  margin-top: 19px;
}

.popup .sb-container {
  width: 1325px;
}

.popup .sb-scrollbar-container {
  right: 0;
}

.popup .sb-content {
  max-height: 534px !important;
  padding-top: 22px;
  padding-bottom: 0;
}

.popup .sb-content-inner {
  width: calc(100% - 30px);
  font-size: 16px;
}

.popup .btn_close {
  display: inline-block;
  position: absolute;
  top: -60px;
  right: -10px;
  padding: 10px;
}

.popup .pop_btn_grp {
  margin-top: 40px;
  text-align: center;
}

.popup .pop_btn_grp .btn {
  margin: 0 8px;
}

.popup .dot_lst li strong {
  font-weight: 400;
}

body, input, select, textarea, button {
  font-family: 'Roboto', 'notosans', 'Apple SD Gothic Neo', Dotum, '돋움', sans-serif;
}

.blind,
caption span,
legend {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  text-indent: -9999px;
  white-space: nowrap;
}

i {
  display: inline-block;
}

.skip {
  height: 0;
}

.skip a {
  display: block;
  position: absolute;
  left: 0;
  top: -100px;
  width: 100%;
  height: 25px;
  text-align: center;
}

.skip a:focus,
#skip a:active {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 10px 0;
  background: #000;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.wrap {
  position: relative;
  min-width: 1730px;
  min-height: 100%;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 343px;
  box-sizing: border-box;
}

.inner {
  position: relative;
  width: 1410px;
  margin: 0 auto;
  padding: 0 20px;
}

.inner:after {
  display: block;
  clear: both;
  content: '';
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1730px;
  height: 90px;
  background: #000;
  z-index: 9999;
}

.header.transparent {
  background: transparent;
}

.header.transparent .gnb {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header.fixed_left_header {
  position: fixed !important;
  width: 300px;
  min-width: 300px;
  height: 100%;
  padding: 42px 20px 42px 0;
  background: #384859;
  border-bottom: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.header.fixed_left_header .sb-content {
  width: 122%;
  padding: 0 42px 0 0;
}

.header.fixed_left_header .sub_tit {
  display: block;
  margin-left: 29px;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
}

.header.white {
  height: 110px;
  border-bottom: 1px solid #ebebeb;
  background: #f8f8f8;
}

.header.white .inner {
  height: 100%;
}

.header.white .logo, .game_guide_layout .header.white .title a {
  margin: 27px 27px 0 20px;
}

.header.white .header_tit {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  letter-spacing: -0.02em;
  transform: translate(-50%, -50%);
}

.header.open {
  width: auto;
  height: auto;
  background: none;
}

.logo, .game_guide_layout .header .title a {
  position: absolute;
  top: 0;
  left: 0;
  margin: 18px 27px 0 62px;
  vertical-align: top;
}

.logo a, .game_guide_layout .header .title a a {
  display: block;
  overflow: hidden;
  width: 177px;
  height: 56px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -530px -868px;
  width: 177px;
  height: 56px;
  line-height: 999px;
}

.gnb {
  position: relative;
  min-width: 1440px;
  height: 100%;
  border-bottom: 1px solid #171a1d;
  box-sizing: border-box;
  z-index: 1;
}

.gnb span {
  position: relative;
}

.gnb .ico_new {
  overflow: hidden;
  position: absolute;
  width: 5px;
  height: 5px;
  background: #e80000;
  line-height: 999px;
  transform: rotate(45deg);
}

.gnb_lst {
  display: table;
  position: relative;
  min-width: 860px;
  margin-left: 265px;
  font-size: 0;
  vertical-align: top;
}

.gnb_lst .lst_itm {
  display: table-cell;
  position: relative;
  height: 90px;
  text-align: center;
  vertical-align: top;
}

.gnb_lst .lst_itm a {
  position: relative;
  display: block;
  min-width: 117px;
  height: 100%;
  font-size: 20px;
  color: #fff;
  line-height: 20px;
}

.gnb_lst .lst_itm > a span {
  display: inline-block;
  vertical-align: middle;
}

.gnb_lst .lst_itm > a:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.gnb_lst .lst_itm > a .ico_new {
  top: -6px;
  right: -10px;
}

.gnb_lst .lst_itm .ico_guide {
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: -19px;
  width: 11px;
  height: 11px;
  margin-top: -6px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -613px;
  width: 11px;
  height: 11px;
  line-height: 999px;
  content: "";
}

.lst_itm .sub_lst {
  display: none;
  position: absolute;
  top: 94px;
  width: 100%;
  margin-top: 11px;
}

.lst_itm .sub_lst a {
  position: relative;
  display: block;
  height: 20px;
  padding: 5px 0;
  font-weight: 300;
  font-size: 14px;
  color: #8e8e8e;
  letter-spacing: -.3px;
}

.lst_itm .sub_lst a > span {
  display: inline-block;
  height: 20px;
  line-height: 20px;
}

.lst_itm .sub_lst a:hover {
  color: #fff;
  font-weight: 400;
}

.lst_itm .sub_lst a:hover > span {
  line-height: 22px;
  text-decoration: underline;
}

.lst_itm .sub_lst .ico_new {
  top: -2px;
  right: -8px;
}

header .gnb_util {
  position: absolute;
  top: 0;
  right: 0;
  margin: 26px 51px 0 0;
  font-size: 0;
}

header .gnb_util .btn_login {
  display: inline-block;
  padding: 10px 29px 10px 32px;
}

header .gnb_util .btn_login .ico_login {
  overflow: hidden;
  display: block;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -743px -540px;
  width: 56px;
  height: 14px;
  line-height: 999px;
}

header .gnb_util .btn_login.btn_name {
  padding: 5px 28px;
  font-size: 18px;
  color: #fff;
}

header .gnb_util .btn_login_ongate {
  display: inline-block;
  padding: 5px 0;
  margin-right: 30px;
  font-size: 18px;
  color: #fff;
}

header .gnb_util .btn_login_ongate .ico_login_ongate {
  overflow: hidden;
  display: inline-block;
  margin: -3px 10px 0 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -718px -380px;
  width: 73px;
  height: 13px;
  line-height: 999px;
  vertical-align: middle;
}

header .gnb_util .btn_login_gamemania {
  display: inline-block;
  padding: 5px 0;
  margin-right: 30px;
  font-size: 18px;
  color: #fff;
}

header .gnb_util .btn_outline {
  display: inline-block;
  height: 32px;
  margin: 2px 25px 0 0;
  padding: 5px 14px 0;
  border: 1px solid #a4a4a4;
  border-radius: 2px;
  font-size: 15px;
  color: #fff;
  vertical-align: top;
  box-sizing: border-box;
  letter-spacing: -.5px;
}

header .gnb_util .btn_outline .ico_login {
  margin-top: 4px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -871px -380px;
  width: 50px;
  height: 12px;
}

header .gnb_util .member {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

header .gnb_util .member_info {
  display: none;
  position: absolute;
  top: 52px;
  left: -68px;
  width: 300px;
  height: auto;
  padding-bottom: 20px;
  background: #fff;
}

header .gnb_util .member_info:before {
  position: absolute;
  top: -13px;
  left: 130px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -727px -928px;
  width: 20px;
  height: 15px;
  content: "";
}

header .gnb_util .member_info .info_header {
  display: block;
  padding: 11px 20px 13px;
  background: #efefef;
}

header .gnb_util .member_info .info_header .name {
  font-size: 16px;
  letter-spacing: -.4px;
}

header .gnb_util .member_info .info_cnt {
  padding: 12px 20px 0;
}

header .gnb_util .member_info .cash_area {
  position: relative;
}

header .gnb_util .member_info .cash_area + .cash_area {
  margin-top: 12px;
}

header .gnb_util .member_info .cash {
  font-size: 16px;
  color: #000;
  line-height: 30px;
}

header .gnb_util .member_info .cash:before {
  display: inline-block;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1088px -903px;
  width: 19px;
  height: 19px;
  margin: -4px 4px 0 0;
  vertical-align: middle;
  content: "";
}

header .gnb_util .member_info .cash .point_txt {
  font-weight: 500;
}

header .gnb_util .member_info .btn_cash {
  overflow: hidden;
  float: right;
  height: 30px;
  padding: 0 10px;
  border-radius: 3px;
  background: #384859;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
}

header .gnb_util .member_info .btn_cash:after {
  display: block;
  clear: both;
  content: "";
}

header .gnb_util .member_info .info_lst {
  margin-top: 12px;
  padding-top: 20px;
  border-top: 1px solid #dfdfdf;
  text-align: center;
}

header .gnb_util .member_info .info_lst li {
  display: inline-block;
  position: relative;
  margin: 0 0 0 21px;
  padding: 0 0 0 21px;
  background-image: none;
  float: initial;
}

header .gnb_util .member_info .info_lst li:before {
  position: absolute;
  left: 0;
  top: 5px;
  width: 1px;
  height: 11px;
  background: #dfdfdf;
  content: "";
}

header .gnb_util .member_info .info_lst li:first-child {
  margin: 0;
  padding: 0;
}

header .gnb_util .member_info .info_lst li:first-child:before {
  display: none;
}

header .gnb_util .member_info .info_lst li a {
  font-size: 15px;
  color: #000;
  letter-spacing: -.4px;
}

header .gnb_util .member_info .info_lst_type2 {
  text-align: center;
}

header .gnb_util .member_info .info_lst_type2 li {
  margin-left: 9px;
  padding-left: 10px;
}

header .gnb_util .member_info .info_lst_type2 li a {
  font-size: 13px;
  font-weight: 500;
}

header .gnb_util .pcroom {
  display: inline-block;
  margin-top: 8px;
  font-size: 14px;
  color: #cab18c;
  vertical-align: top;
}

header .gnb_util .pcroom .case {
  padding-left: 5px;
  color: #fff;
  opacity: 0.4;
}

header .gnb_util .pcroom .case.on {
  opacity: 1;
}

header .gnb_util .util_lst {
  display: inline-block;
  margin: 8px 0 0 23px;
  vertical-align: top;
}

header .gnb_util .util_lst li {
  display: inline-block;
  position: relative;
  height: auto;
  vertical-align: top;
  padding: 0 0 0 11px;
  margin: 0 0 0 12px;
  background: none;
  line-height: unset;
}

header .gnb_util .util_lst li:first-child {
  padding: 0;
  margin: 0;
}

header .gnb_util .util_lst li a {
  display: block;
  font-size: 14px;
  color: #cab18c;
}

header .gnb_util .util_lst li:before {
  position: absolute;
  top: 5px;
  left: 0;
  width: 1px;
  height: 10px;
  background: #2c2c2c;
  content: "";
}

header .gnb_util .util_lst li:first-child:before {
  display: none;
}

.depth_bg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 336px;
  background: #000;
}

.lnb {
  width: 270px;
  border: solid #222;
  border-width: 1px 0;
}

.lnb_itm {
  border-top: 1px solid #e5e5e5;
}

.lnb_itm:first-child {
  border-top: 0;
}

.lnb_itm.has_sub a:after {
  position: absolute;
  top: 32px;
  right: 25px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1079px -841px;
  width: 15px;
  height: 8px;
  content: '';
}

.lnb_itm.has_sub.on a:after {
  transform: rotate(180deg);
}

.lnb_itm.has_sub.on .sub_lst {
  display: block;
}

.lnb_itm.on > a {
  color: #222;
}

.lnb_itm > a {
  display: block;
  position: relative;
  padding-left: 25px;
  font-size: 18px;
  color: #a4a4a4;
  line-height: 72px;
  letter-spacing: -0.02em;
}

.lnb_itm .sub_lst {
  display: none;
  padding: 13px 0 9px 44px;
  border-top: 1px solid #e5e5e5;
}

.lnb_itm .sub_lst .sub_itm {
  padding: 6px 0 8px;
}

.lnb_itm .sub_lst .sub_itm.on a {
  color: #000;
}

.lnb_itm .sub_lst .sub_itm a {
  font-size: 16px;
  color: #a4a4a4;
  line-height: 20px;
}

iframe {
  display: block;
}

.content > .inner:last-child {
  padding-bottom: 130px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #13151a;
}

.center_footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 89px;
  border-top: 1px solid #e5e5e5;
  background: #ffffff;
  text-align: center;
  line-height: 89px;
}

.center_footer .copyright {
  font-size: 13px;
}

.policy_area {
  padding: 37px 0 35px;
  text-align: center;
}

.policy_lst {
  position: relative;
  font-size: 0;
}

.policy_lst .lst_itm {
  display: inline-block;
  position: relative;
}

.policy_lst .lst_itm a {
  padding: 4px 14px;
  font-size: 15px;
  font-weight: 300;
  color: #dadadb;
  letter-spacing: -.2px;
}

.policy_lst .lst_itm:before {
  position: absolute;
  top: 6px;
  left: 0;
  width: 1px;
  height: 11px;
  background: #424448;
  content: "";
}

.policy_lst .lst_itm:first-child:before {
  display: none;
}

.policy_lst .dsc_tooltip {
  display: none;
  position: absolute;
  bottom: 40px;
  left: -318px;
  z-index: 100;
  width: 746px;
  height: 130px;
  padding: 25px 0;
  background: #26272c;
  border-radius: 3px;
  box-sizing: border-box;
}

.policy_lst .dsc_tooltip:after {
  position: absolute;
  bottom: -13px;
  left: 50%;
  margin-left: -10px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -803px -540px;
  width: 20px;
  height: 13px;
  content: "";
}

.company_info {
  padding: 31px 0 92px;
  border-top: 1px solid #24272d;
  text-align: center;
  line-height: 22px;
  font-size: 13px;
}

.company_info .info_lst {
  width: 640px;
  margin: 0 auto;
  font-size: 13px;
}

.company_info .info_lst .lst_itm {
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  color: #707172;
  letter-spacing: -.2px;
}

.company_info .info_lst .lst_itm a {
  display: inline-block;
  font-size: 13px;
  color: #5a5c5f;
  letter-spacing: -.2px;
}

.company_info .copyright, .company_info .copy_lst {
  margin-top: 2px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #707172;
}

.company_info .copy_lst {
  font-size: 0;
}

.company_info .copy_lst li {
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 13px;
  color: #707172;
  vertical-align: middle;
}

.company_info .copy_lst li:before {
  display: inline-block;
  width: 1px;
  height: 12px;
  margin: 0 12px;
  background: #4b4c55;
  vertical-align: middle;
  content: "";
}

.company_info .copy_lst li:first-child:before {
  display: none;
}

.company_info .line_end {
  margin-right: 0;
}

.company_info .dsc_txt {
  font-size: 13px;
  color: #5a5c5f;
  letter-spacing: -.2px;
}

.channel .info_lst {
  width: 1048px;
}

.logo_playwith {
  display: inline-block;
  margin-top: 16px;
  opacity: 0.8;
}

.logo_playwith a {
  display: block;
  overflow: hidden;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -242px -540px;
  width: 132px;
  height: 34px;
  line-height: 999px;
}

.logo_kakaogames {
  display: inline-block;
  margin-top: 16px;
  margin-left: 20px;
  vertical-align: top;
}

.logo_kakaogames a {
  display: block;
  overflow: hidden;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -484px -540px;
  width: 133px;
  height: 23px;
  line-height: 999px;
}

.logo_ongate {
  display: inline-block;
  margin-top: 20px;
  margin-left: 20px;
  vertical-align: top;
}

.logo_ongate a {
  display: block;
  overflow: hidden;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -621px -540px;
  width: 118px;
  height: 19px;
  line-height: 999px;
}

.logo_gamemania {
  display: inline-block;
  margin-top: 16px;
  margin-left: 20px;
  vertical-align: top;
}

.logo_gamemania a {
  display: block;
  overflow: hidden;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -785px -804px;
  width: 94px;
  height: 28px;
  line-height: 999px;
}

.quick_menu {
  display: none;
  position: absolute;
  right: 50px;
  z-index: 100;
  width: 90px;
}

.quick_menu.floating {
  position: fixed;
  top: 200px;
}

.quick_menu ul {
  overflow: hidden;
  padding-bottom: 25px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border: 1px solid #e5e5e5;
  border-bottom: 0;
  background-color: #f2f2f2;
}

.quick_menu li {
  margin-top: 29px;
}

.quick_menu li a {
  display: block;
  font-weight: 300;
  font-size: 14px;
  color: #3f546b;
  text-align: center;
}

.quick_menu li a:before {
  display: block;
  margin: 0 auto 5px;
  content: '';
}

.quick_menu li.cash a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1171px -1078px;
  width: 30px;
  height: 25px;
}

.quick_menu li.coupon a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -500px -928px;
  width: 30px;
  height: 24px;
}

.quick_menu li.pcbang a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1002px -995px;
  width: 35px;
  height: 28px;
}

.quick_menu li.report a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -534px -928px;
  width: 34px;
  height: 21px;
}

.quick_menu li.cs a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1137px -1078px;
  width: 30px;
  height: 27px;
}

.quick_menu li.invite a {
  color: #404dab;
}

.quick_menu li.invite a:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1079px -809px;
  width: 28px;
  height: 28px;
}

.quick_menu .btn_top {
  display: block;
  width: 100%;
  background-color: #8b8c92;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  line-height: 49px;
  outline: none;
}

.quick_menu .btn_top span {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  vertical-align: middle;
}

.quick_menu .btn_top:after {
  display: inline-block;
  position: relative;
  margin-left: 3px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -699px;
  width: 11px;
  height: 6px;
  vertical-align: middle;
  content: '';
}

.icon {
  display: inline-block;
  width: 22px;
  height: 21px;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.icon:before {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #fff;
  line-height: 21px;
}

.icon.notice {
  background-color: #e02424;
}

.icon.notice:before {
  content: 'N';
}

.icon.update {
  background-color: #404dab;
}

.icon.update:before {
  content: 'U';
}

.sub_visual {
  position: relative;
  overflow: hidden;
  height: 412px;
}

.sub_visual_lst .slide {
  overflow: hidden;
  position: relative;
  height: 412px;
}

.sub_visual_lst .slide a {
  display: block;
  height: 590px;
}

.sub_visual_lst .img_container img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: auto;
  z-index: 0;
  transform: translateX(-50%);
}

.sub_visual_lst .desc_container {
  position: absolute;
  top: 122px;
  left: 50%;
  margin-left: -633px;
  color: #fff;
}

.sub_visual_lst .desc_container .date {
  font-family: 'Roboto';
  font-size: 16px;
}

.sub_visual_lst .desc_container .evt_tit {
  margin-top: 16px;
  margin-left: -5px;
  font-family: 'NanumMyeongjo';
  font-size: 60px;
  letter-spacing: -4.5px;
}

.sub_visual_lst .desc_container .evt_desc {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -.37px;
}

.sub_visual_lst .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 50px;
  height: 80px;
  margin-top: -40px;
}

.sub_visual_lst .slick-arrow span {
  display: inline-block;
  color: #fff;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.sub_visual_lst .slick-prev {
  left: 50%;
  margin-left: -720px;
}

.sub_visual_lst .slick-prev span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -968px -397px;
  width: 26px;
  height: 52px;
}

.sub_visual_lst .slick-next {
  right: 50%;
  margin-right: -720px;
}

.sub_visual_lst .slick-next span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1073px -1078px;
  width: 26px;
  height: 52px;
}

.sub_visual_lst .slick-dots {
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -633px;
  bottom: 82px;
}

.sub_visual_lst .slick-dots li {
  display: inline-block;
  margin-right: 4px;
}

.sub_visual_lst .slick-dots li button {
  width: 30px;
  height: 2px;
  background-color: #74777e;
  font-size: 0;
  line-height: 0;
}

.sub_visual_lst .slick-dots li.slick-active button {
  background-color: #fff;
}

.select {
  display: inline-block;
  position: relative;
  width: 190px;
  height: 50px;
  border: solid 1px #ebebeb;
  color: #222;
  font-size: 14px;
  vertical-align: top;
  box-sizing: border-box;
  z-index: 1;
}

.select .btn_open_select {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 50px 0 20px;
  background: #ffffff;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
}

.select .btn_open_select:after {
  position: absolute;
  top: 50%;
  right: 20px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -689px;
  width: 11px;
  height: 6px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.select.open {
  border: 1px solid #222;
  border-bottom: transparent;
}

.select.open .select_lst {
  display: block;
  top: 100%;
  z-index: 9999;
  right: -1px;
}

.select.open .btn_open_select:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select.lst_up {
  border-bottom: 1px solid #222;
}

.select.lst_up .select_lst {
  top: auto;
  bottom: 100%;
  border-top: 1px solid #222;
  border-bottom: transparent;
}

.select.domain {
  width: 170px;
  height: 46px;
}

.select_lst {
  overflow-y: auto;
  display: none;
  position: absolute;
  width: 100%;
  max-height: 200px;
  border: 1px solid #222;
  border-top: transparent;
  background-color: #fff;
}

.select_lst .select_itm {
  width: 100%;
  border-top: 1px solid #ebebeb;
}

.select_lst .select_itm:first-child {
  border-top: 0;
}

.select_lst .btn_option {
  width: 100%;
  padding-left: 22px;
  font-weight: 300;
  font-size: 14px;
  color: #222;
  line-height: 49px;
  text-align: left;
}

.select_lst .btn_option:hover {
  background-color: #f2f2f2;
}

.breadcrumb {
  font-size: 0;
}

.breadcrumb .itm {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.breadcrumb .itm:after {
  position: absolute;
  top: 6px;
  right: -15px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1330px -569px;
  width: 5px;
  height: 9px;
  content: '';
}

.breadcrumb .itm.dropdown {
  position: relative;
}

.breadcrumb .itm.dropdown a {
  position: relative;
  padding-right: 20px;
}

.breadcrumb .itm.dropdown a:before {
  position: absolute;
  top: 4px;
  right: 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -666px;
  width: 11px;
  height: 8px;
  content: '';
}

.breadcrumb .itm.dropdown.open a:before {
  transform: rotate(180deg);
}

.breadcrumb .itm.dropdown.open .select_lst {
  display: block;
}

.breadcrumb .itm + .itm {
  padding-left: 24px;
}

.breadcrumb .itm:last-child:after {
  content: none;
}

.breadcrumb a {
  font-size: 14px;
  color: #868686;
}

.breadcrumb .select_lst {
  top: 28px;
  right: 0;
  z-index: 1;
  width: 140px;
  max-height: initial;
  border: 1px solid #ebebeb;
}

.tab_lst {
  overflow: hidden;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tab_lst .tab_itm {
  display: table-cell;
  border: 1px solid #ebebeb;
  background-color: #fff;
}

.tab_lst .tab_itm:last-child {
  border-right: 1px solid #ebebeb;
}

.tab_lst .tab_itm.on, .tab_lst .tab_itm:hover {
  background-color: #384859;
  border: 1px solid #384859;
}

.tab_lst .tab_itm.on a, .tab_lst .tab_itm:hover a {
  color: #fff;
}

.tab_lst a {
  display: block;
  font-size: 20px;
  color: #a4a4a4;
  text-align: center;
  line-height: 61px;
}

.tab_lst .ico_guide {
  display: inline-block;
  margin-top: -4px;
  vertical-align: middle;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -565px;
  width: 11px;
  height: 11px;
}

.menu_depth_3 {
  margin-top: 30px;
}

.menu_depth_3 .tab_lst .tab_itm {
  display: inline-block;
}

.menu_depth_3 .tab_lst .tab_itm a {
  display: block;
  min-width: 130px;
  padding: 0 33px;
  font-size: 18px;
  line-height: 48px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.menu_depth_3 .tab_lst .tab_itm ~ .tab_itm {
  margin-left: 12px;
}

.sub_sticky {
  position: absolute;
  width: 100%;
  padding: 77px 0 32px;
  min-width: 1730px;
  background-color: #fff;
  z-index: 10;
}

.sub_sticky .tit {
  font-size: 40px;
  font-weight: normal;
  color: #222;
  letter-spacing: -0.02em;
}

.sub_sticky .breadcrumb {
  position: absolute;
  top: 32px;
  right: 20px;
}

.sub_sticky .tab_lst {
  margin-top: 41px;
}

.sub_sticky .tab_lst .tab_itm:not(:last-child) {
  border-right: none;
}

.sub_sticky .tab_lst.has_border {
  padding-top: 50px;
  border-top: 2px solid #384859;
}

.sub_sticky .filter_time {
  position: absolute;
  top: 33px;
  right: 20px;
}

.sub_sticky .filter_time .btn_time {
  font-size: 14px;
  color: #868686;
  line-height: 18px;
}

.sub_sticky .filter_time .btn_time:after {
  display: inline-block;
  margin: 4px 0 0 10px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -666px;
  width: 11px;
  height: 8px;
  vertical-align: top;
  content: '';
}

.sub_sticky .filter_time .select_lst {
  top: 26px;
  right: 0;
  width: 140px;
  border: 1px solid #ebebeb;
}

.sub_sticky .filter_time.open .btn_time:after {
  transform: rotate(180deg);
}

.sticky {
  position: fixed;
  top: 50px;
  right: 0;
  left: 0;
}

.pagination {
  margin-top: 50px;
  font-size: 0;
  text-align: center;
}

.pagination .btn_pagination,
.pagination .lst_itm {
  display: inline-block;
  vertical-align: middle;
}

.pagination .btn_pagination a,
.pagination .lst_itm a {
  display: block;
  width: 40px;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #a4a4a4;
  line-height: 40px;
  text-align: center;
}

.pagination .lst_itm a:hover {
  color: #202020;
}

.pagination .lst_itm a.on {
  color: #202020;
  font-weight: 500;
}

.pagination .btn_pagination.prev, .pagination .btn_pagination.last {
  margin-left: -1px;
}

.pagination_lst {
  display: inline-block;
  margin: 0 19px;
}

.pagination_lst .lst_itm {
  margin: 0 1px;
}

.tbl_img_lst {
  position: relative;
  overflow: hidden;
  margin: 0 -15px;
  padding-bottom: 33px;
  font-size: 0;
}

.tbl_img_lst:before {
  display: block;
  position: absolute;
  top: 0;
  right: 15px;
  left: 15px;
  border-top: solid 2px #384859;
  content: '';
}

.tbl_img_lst li {
  display: inline-block;
  width: 33.333%;
  padding: 42px 15px 18px;
  box-sizing: border-box;
}

.tbl_img_lst li.end a {
  cursor: default;
}

.tbl_img_lst li.end .overlay {
  display: block;
}

.tbl_img_lst li.nodata {
  position: relative;
  width: 100%;
  height: 444px;
  text-align: center;
}

.tbl_img_lst li.nodata:after {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  border-bottom: 1px solid #a4a4a4;
  content: '';
}

.tbl_img_lst li.nodata .ico {
  display: block;
  margin: 110px auto 0;
}

.tbl_img_lst li.nodata .txt_msg {
  display: block;
  margin: 13px auto 0;
  font-size: 24px;
  font-weight: normal;
  color: #868686;
  letter-spacing: -0.02em;
}

.tbl_img_lst a {
  display: block;
  position: relative;
  width: 390px;
  padding: 278px 29px 24px;
  border: solid 1px #ccc;
}

.tbl_img_lst a .time:before {
  content: none;
}

.tbl_img_lst .lbl {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 5;
  min-width: 67px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
}

.tbl_img_lst .img {
  overflow: hidden;
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  height: 255px;
  background: url(../img/news/img_event_default.jpg) no-repeat;
}

.tbl_img_lst .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.tbl_img_lst .img .overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
}

.tbl_img_lst .img .overlay strong {
  display: inline-block;
  width: 94px;
  height: 32px;
  margin-top: 98px;
  border: 1px solid #a4a4a4;
  border-radius: 3px;
  font-size: 16px;
  font-weight: normal;
  line-height: 32px;
  letter-spacing: -0.025em;
}

.tbl_img_lst .img .overlay p {
  margin-top: 7px;
  font-size: 14px;
  letter-spacing: -0.02em;
}

.tbl_img_lst .tit {
  display: block;
  box-sizing: border-box;
}

.tbl_img_lst .tit .txt {
  overflow: hidden;
  display: inline-block;
  max-width: 360px;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tbl_img_lst .tit .txt_new {
  margin-left: 10px;
  line-height: 20px;
}

.tbl_img_lst .time {
  display: inline-block;
  margin-top: 7px;
}

.tbl_img_lst .view {
  position: absolute;
  bottom: 24px;
  right: 28px;
}

.search_inp {
  display: inline-block;
  position: relative;
  border: 1px solid #ebebeb;
  color: #222;
  font-size: 14px;
  box-sizing: border-box;
}

.search_inp input {
  width: 465px;
  height: 48px;
  padding: 0 54px 0 19px;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: 0.04em;
  outline: none;
}

.search_inp input::-webkit-input-placeholder {
  color: #ccc;
}

.search_inp input::-moz-placeholder {
  color: #ccc;
}

.search_inp input:-ms-input-placeholder {
  color: #ccc;
}

.search_inp input:-moz-placeholder {
  color: #ccc;
}

.search_inp button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  background-color: #f2f2f2;
  font-size: 0;
  outline: none;
}

.clearfix:after, .attach_box:after, .service_page .sl_server:after, .service_page .sl_char:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: '';
}

.notice_txt {
  font-size: 14px;
  line-height: 15px;
  font-weight: 300;
  color: #222222;
}

.txt_blue {
  color: #404dab !important;
}

.txt_gray {
  color: #868686 !important;
}

.txt_gray2 {
  color: #5e5e5e !important;
}

.txt_gray3 {
  color: #a4a4a4 !important;
}

.txt_left {
  text-align: left;
}

.txt_center {
  text-align: center;
}

.txt_right {
  text-align: right;
}

.txt_reg {
  font-weight: 400;
}

.txt_med {
  font-weight: 500;
}

.search_box .select {
  height: 56px;
}

.search_box .select + .search_inp {
  margin-left: -1px;
}

.search_box.size_l .search_inp input {
  width: 537px;
  height: 54px;
}

.search_box.size_l .search_inp button {
  width: 55px;
  height: 54px;
}

.view_area {
  position: relative;
  width: 990px;
}

.view_area.faq .ct {
  border-bottom: 0;
}

.view_area.faq .ct ~ .ct {
  border-bottom: 1px solid #a4a4a4;
}

.view_area.faq .info .time {
  right: 20px;
  letter-spacing: 0.01em;
}

.view_area.faq .btn_wrap {
  margin-top: 39px;
  text-align: right;
}

.view_area .title {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 150px 0 29px;
  border-top: solid 2px #384859;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  font-size: 0;
  box-sizing: border-box;
}

.view_area .title strong {
  display: inline-block;
  position: relative;
  font-size: 0;
  color: #222;
  font-weight: normal;
  line-height: 80px;
}

.view_area .title strong .lbl_outline {
  margin: 0 10px 0 2px;
  vertical-align: middle;
}

.view_area .title strong .txt {
  display: inline-block;
  overflow: hidden;
  max-width: 681px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 20px;
}

.view_area .title strong .txt_q,
.view_area .title strong .txt_a {
  display: inline-block;
  font-family: 'notosans', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #404dab;
  vertical-align: top;
}

.view_area .title strong .txt_q {
  margin: 0 14px 0 1px;
  line-height: 74px;
}

.view_area .title strong .txt_a {
  margin: 0 14px 0 3px;
  line-height: 77px;
}

.view_area .title strong .ico_attach {
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
}

.view_area .info {
  position: relative;
  height: 59px;
  border-bottom: 1px solid #e5e5e5;
}

.view_area .info .view,
.view_area .info .time,
.view_area .info .comment,
.view_area .info .name {
  display: inline-block;
  position: absolute;
  top: 0;
  line-height: 59px;
}

.view_area .info .name {
  left: 30px;
}

.view_area .info .comment {
  right: 240px;
  width: 150px;
}

.view_area .info .view {
  right: 150px;
  width: 90px;
}

.view_area .info .time {
  right: 0;
  width: 150px;
}

.view_area .ct {
  padding: 33px 0 72px;
  border-bottom: 1px solid #a4a4a4;
  font-size: 16px;
  font-weight: 300;
  color: #222;
  word-break: break-word;
}

.view_area .ct p {
  margin: 25px 0 0 31px;
  line-height: 25px;
}

.view_area .ct p:first-child {
  margin-top: 0;
}

.view_area .ct > span {
  display: block;
  margin: 5px 0 0 29px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
}

.view_area .ct > span + table {
  margin-top: 32px;
}

.view_area .ct table {
  margin: 8px 0 0 31px;
  font-size: 14px;
  font-weight: 300;
}

.view_area .ct thead th {
  height: 37px;
  color: #222;
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
}

.view_area .ct tbody td {
  height: 50px;
  border: 1px solid #e5e5e5;
  line-height: 18px;
  text-align: center;
}

.view_area .share_box {
  position: relative;
}

.view_area .share_box .share {
  padding: 39px 0 30px;
  font-size: 0;
}

.view_area .share_box .share li {
  display: inline-block;
  margin-left: 8px;
}

.view_area .share_box .share li:first-child {
  margin-left: 0;
}

.view_area .share_box .share li a {
  display: block;
}

.view_area .share_box .btn_wrap {
  position: absolute;
  top: 39px;
  right: 0;
  font-size: 0;
}

.view_area .share_box .btn_wrap .btn {
  margin-left: 8px;
}

.view_area .share_box .btn_wrap .btn:first-child {
  margin-left: 0;
}

.view_area .tbl_lst {
  margin-top: 32px;
  border: solid #e5e5e5;
  border-width: 1px 0;
}

.view_area .tbl_lst td {
  height: 62px;
}

.view_area .tbl_lst td.txt_left {
  padding-left: 30px;
}

.view_area .tbl_lst td .lbl_outline + .tit {
  max-width: 400px;
}

.view_area .tbl_lst td .tit {
  max-width: 490px;
  font-size: 14px;
  letter-spacing: -0.02em;
}

.write_area {
  position: relative;
  width: 990px;
}

.write_area.full_width {
  width: 100%;
}

.write_area.full_width input::-webkit-input-placeholder {
  font-size: 14px;
}

.write_area.full_width input::-moz-placeholder {
  font-size: 14px;
}

.write_area.full_width input:-ms-input-placeholder {
  font-size: 14px;
}

.write_area.full_width input:-moz-placeholder {
  font-size: 14px;
}

.write_area .area_tit {
  font-family: 'notosans', sans-serif;
  line-height: 48px;
}

.write_area .txt_note {
  position: absolute;
  top: 19px;
  right: 0;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  letter-spacing: -0.02em;
}

.write_area .file_upload_box .guide_txt {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #a4a4a4;
}

.write_area .file_upload_box label {
  padding: 0 13px;
  cursor: pointer;
}

.write_area .file_lst {
  display: inline-block;
  width: calc(100% - 105px);
  vertical-align: top;
  line-height: 30px;
  font-size: 0;
}

.write_area .file_lst li {
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.write_area .file_lst li .ico {
  display: inline-block;
  margin-top: 5px;
  vertical-align: top;
}

.write_area .file_lst li .filename {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  vertical-align: top;
}

.write_area .file_lst li .btn_remove_file {
  display: inline-block;
  margin: 6px 0 0 10px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -705px -928px;
  width: 18px;
  height: 18px;
  vertical-align: top;
}

.write_area .btn_wrap {
  margin-top: 59px;
  font-size: 0;
  text-align: center;
}

.write_area .btn_wrap .btn {
  margin-left: 20px;
  vertical-align: top;
}

.write_area .btn_wrap .btn:first-child {
  margin-left: 0;
}

.write_area .btn_wrap .btn_outline {
  border: 1px solid #a4a4a4;
  color: #a4a4a4;
}

.inp_txt {
  width: 100%;
  padding: 11px 0;
  border: 0;
  font-size: 16px;
  color: #222;
  line-height: 24px;
  outline: none;
  box-sizing: border-box;
}

.inp_txt::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #a4a4a4;
}

.inp_txt:-moz-placeholder {
  font-size: 14px;
  color: #a4a4a4;
  font-weight: 300;
}

.inp_txt:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #a4a4a4;
}

.inp_txt::placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #a4a4a4;
}

.inp_txt.password {
  width: 500px;
}

.inp_txt.phone {
  position: relative;
  width: 35px;
  margin: 0;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

.inp_txt.phone.num2 {
  width: 45px;
}

.inp_txt.inp_mail {
  width: 201px;
}

.inp_txt.inp_domain {
  width: 150px;
  margin: 0 5px;
}

.inp_txt.inp_domain:read-only {
  pointer-events: none;
}

.aside {
  position: absolute;
  top: 0;
  right: -420px;
  width: 300px;
  border: 1px solid #e5e5e5;
}

.aside .tit {
  display: block;
  padding-left: 20px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f8f8f8;
  line-height: 80px;
  font-size: 20px;
  font-weight: normal;
  color: #222;
}

.aside .recent_posts_lst {
  padding: 0 20px;
}

.aside .recent_posts_lst li {
  border-top: solid 1px #e5e5e5;
}

.aside .recent_posts_lst li:first-child {
  border-top: none;
}

.aside .recent_posts_lst a {
  display: block;
  padding: 20px 0 17px;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.02em;
}

.aside .recent_posts_lst strong {
  display: block;
  margin-top: 6px;
  font-weight: 300;
  line-height: 21px;
  word-break: break-word;
}

.aside .recent_posts_lst .time {
  display: block;
  margin-top: 2px;
  color: #a4a4a4;
  text-align: left;
  letter-spacing: 0;
}

.aside .recent_posts_lst .time:before {
  content: none;
}

.aside .term_policy_lst {
  padding: 0 20px;
}

.aside .term_policy_lst li {
  border-top: solid 1px #e5e5e5;
}

.aside .term_policy_lst li:first-child {
  border-top: none;
}

.aside .term_policy_lst li.on a {
  color: #404dab;
}

.aside .term_policy_lst a {
  display: block;
  padding: 17px 0;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.02em;
}

.btn {
  display: inline-block;
  width: 93px;
  height: 36px;
  border: transparent;
  background-color: #384859;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
}

.btn.btn_round {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.btn.btn_aside {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn.disabled {
  cursor: not-allowed;
}

.btn.grayfill {
  background: #a4a4a4;
}

.btn.goldfill {
  background: #a68a5f;
}

.btn.silverfill {
  background: #a5a5b6;
}

.btn.grayfill2 {
  background: #868686;
}

.btn.grayfill3 {
  background-color: #737f8b;
}

.btn.whitefill {
  background-color: #ffffff;
  color: #222222;
}

.btn.btn_outline {
  background-color: transparent;
  border: 1px solid #3f546b;
  color: #3f546b;
}

.btn.btn_outline.grayout {
  border: 1px solid #a4a4a4;
  color: #a4a4a4;
}

.btn.btn_outline.blueout {
  border: 1px solid #404dab;
  color: #404dab;
}

.btn.btn_outline.whiteout {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.btn.full_width {
  width: 100%;
}

.btn.btn_sm {
  width: auto;
  height: 30px;
  padding: 0 10px;
  line-height: 32px;
  font-weight: 400;
  box-sizing: border-box;
}

.btn.btn_sm.btn_round {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.btn.btn_xs {
  width: auto;
  height: 22px;
  padding: 0 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

.btn.btn_lg {
  width: 242px;
  height: 52px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 50px;
  font-weight: 500;
  box-sizing: border-box;
}

.right_shortcut {
  float: right;
  height: 16px;
  margin-top: 10px;
}

.right_shortcut li {
  display: inline-block;
  height: 16px;
  vertical-align: top;
}

.right_shortcut li ~ li {
  margin-left: 14px;
}

.right_shortcut li ~ li:before {
  display: inline-block;
  width: 1px;
  height: 13px;
  margin-right: 18px;
  margin-top: 2px;
  background: #c8c8cf;
  vertical-align: top;
  content: '';
}

.right_shortcut li a:hover, .right_shortcut li.selected {
  color: #404dab;
}

.right_shortcut li a {
  display: inline-block;
  height: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  color: #222222;
  vertical-align: top;
}

.right_shortcut li a:before {
  display: inline-block;
  margin-right: 8px;
  vertical-align: baseline;
  content: '';
}

.right {
  float: right;
}

.left {
  float: left;
}

.chkbox {
  display: inline-block;
  padding: 0;
  font-size: 0;
}

.chkbox input {
  position: absolute;
  opacity: 0;
}

.chkbox label {
  display: inline-block;
  position: relative;
  padding-left: 27px;
  font-size: 14px;
  font-weight: 300;
  color: #5e5e5e;
  line-height: 20px;
  cursor: pointer;
}

.chkbox label:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.chkbox.no_txt label {
  padding-left: 0;
}

.chkbox.no_txt label:before {
  position: relative;
  display: block;
}

.rdo {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 34px;
  vertical-align: top;
}

.rdo input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.rdo.rdo_v1 label {
  display: inline-block;
  height: 36px;
  min-width: 92px;
  padding: 0 15px;
  border: 1px solid #ebebeb;
  font-size: 14px;
  font-weight: 300;
  color: #a4a4a4;
  text-align: center;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
}

.rdo.rdo_v1 input:checked + label {
  border: none;
  background: #737f8b;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
}

.rdo.rdo_v2 label:before {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  content: '';
}

.rdo_lst {
  display: inline-block;
}

.rdo_lst li {
  display: inline-block;
}

.rdo_lst li ~ li {
  margin-left: 8px;
}

.rdo_lst.rdo_lst_v2 li:not(:last-child) {
  margin-right: 46px;
}

.rdo_lst.rdo_lst_v2 li ~ li {
  margin-left: 0;
}

.comment_box {
  margin-top: 32px;
}

.comment_box .txtarea {
  width: 100%;
  height: 110px;
  font-size: 0;
}

.comment_box .txtarea textarea {
  width: 100%;
  height: 100%;
  padding: 16px 28px;
  border: 1px solid rgba(197, 208, 221, 0.5);
  font-size: 14px;
  font-weight: 300;
  box-sizing: border-box;
  resize: none;
}

.comment_box .txtarea textarea::-webkit-input-placeholder {
  color: #a5a5a5;
}

.comment_box .txtarea textarea::-moz-placeholder {
  color: #a5a5a5;
}

.comment_box .txtarea textarea:-ms-input-placeholder {
  color: #a5a5a5;
}

.comment_box .txtarea textarea:-moz-placeholder {
  color: #a5a5a5;
}

.comment_box .txtarea.not_login textarea {
  display: none;
}

.comment_box .txtarea.not_login .notice_login {
  display: block;
}

.comment_box .notice_login {
  display: none;
  width: 100%;
  height: 100%;
  padding: 18px 0;
  border: 1px solid rgba(204, 204, 204, 0.5);
  background-color: #f8f8f8;
  font-size: 16px;
  font-weight: 300;
  color: #a4a4a4;
  text-align: center;
  letter-spacing: -0.01em;
  box-sizing: border-box;
}

.comment_box .notice_login .btn {
  margin-top: 7px;
  border-radius: 3px;
}

.comment_box .btn_wrap {
  text-align: right;
  margin-top: 10px;
}

.comment_box .btn_wrap .char_num {
  margin-right: 6px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #a4a4a4;
}

.comment_box .action_bx {
  position: absolute;
  top: 3px;
  right: 16px;
  font-size: 0;
}

.comment_box .action_bx button {
  position: relative;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 300;
  color: #222;
  line-height: 20px;
}

.comment_box .action_bx button:hover {
  color: #404dab;
}

.comment_box .action_bx button + button:before {
  position: absolute;
  top: 4px;
  left: 0;
  width: 1px;
  height: 12px;
  background-color: #c8c8cf;
  content: '';
}

.comment_box .lst {
  margin-top: 40px;
  border-bottom: 1px solid #e5e5e5;
}

.comment_box .lst > li {
  padding: 23px 0 26px;
  border-top: 1px solid rgba(197, 208, 221, 0.5);
}

.comment_box .lst .itm {
  position: relative;
  padding-left: 30px;
}

.comment_box .lst .username {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.02em;
}

.comment_box .lst .comment_time {
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #a5a5a5;
}

.comment_box .lst .txt {
  margin: 9px 30px 0 0;
  font-weight: 300;
  color: #868686;
  letter-spacing: 0.01em;
  word-wrap: break-word;
}

.comment_box .lst .reply {
  padding: 0 0 30px;
  border-top: 0;
}

.comment_box .lst .reply .itm {
  margin-top: 0;
  padding: 13px 0 14px 53px;
  background-color: #f8f8f8;
}

.comment_box .lst .reply .itm:before {
  display: block;
  position: absolute;
  top: 18px;
  left: 30px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -196px;
  width: 10px;
  height: 10px;
  content: '';
}

.comment_box .lst .reply .itm .action_bx {
  top: 17px;
}

.comment_box .lst .reply_write {
  position: relative;
  margin: 23px 0 0;
  padding-left: 53px;
}

.comment_box .lst .reply_write:before {
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1193px -196px;
  width: 10px;
  height: 10px;
  content: '';
}

.comment_box .lst .reply_write .txtarea {
  height: 86px;
}

.comment_box .lst .notice_login {
  padding: 8px 0;
}

.comment_box .btn_more {
  display: block;
  width: 450px;
  height: 65px;
  margin: 28px 0 0 278px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 16px;
  color: #76777f;
}

.comment_box .btn_more:after {
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -666px;
  width: 11px;
  height: 8px;
  content: '';
}

.comment_box + .tbl_lst {
  margin-top: 80px;
}

.dot_lst > li {
  position: relative;
  padding-left: 11px;
  font-weight: 300;
}

.dot_lst > li:before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 3px;
  height: 3px;
  background: #222222;
  content: '';
}

.dot_lst > li.txt_blue:before {
  background: #404dab;
}

.dot_lst > li.txt_gray:before {
  background: #868686;
}

.dot_lst > li.txt_gray2:before {
  background: #5e5e5e;
}

.dot_lst > li ~ li {
  margin-top: 13px;
}

.dot_lst > li em {
  font-weight: 400;
}

.dot_lst.inline_lst li {
  display: inline-block;
  margin-top: 0;
  vertical-align: middle;
}

.dash_lst > li {
  position: relative;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 300;
  color: #5e5e5e;
  line-height: 25px;
  margin-top: 9px;
}

.dash_lst > li:first-child {
  margin-top: 0;
}

.dash_lst > li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '-';
}

.content_head {
  margin-top: 27px;
  padding-bottom: 19px;
  border-bottom: 2px solid #384859;
}

.tabs_lnk_lst li {
  display: inline-block;
  margin-right: 16px;
}

.tabs_lnk_lst li:last-child {
  margin-right: 0;
}

.tabs_lnk_lst li ~ li:before {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #c8c8cf;
  margin-right: 16px;
  vertical-align: middle;
  content: '';
}

.tabs_lnk_lst li.active a {
  color: #404dab;
}

.tabs_lnk_lst li a {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: #222222;
  vertical-align: middle;
}

.tabs_lnk_lst li [class^="ico_"] {
  display: inline-block;
  margin-left: 2px;
}

.area_tit {
  font-size: 30px;
  font-weight: 400;
  line-height: 27px;
}

.area_desc {
  display: inline-block;
  margin: 5px 0 0 8px;
  font-size: 14px;
  color: #a4a4a4;
  line-height: 14px;
}

.intro_txt {
  display: block;
  font-size: 35px;
  color: #222222;
  font-family: 'notosans', 'roboto', sans-serif;
  font-weight: 500;
  line-height: 37px;
  text-align: center;
}

.sub_intro_txt {
  display: block;
  margin-top: 15px;
  font-size: 25px;
  font-weight: 400;
  line-height: 23px;
  color: #222222;
  text-align: center;
}

.gray_bx {
  background: #f2f2f2;
}

.step_lst {
  margin-top: 29px;
}

.step_lst li {
  display: inline-block;
  position: relative;
  padding: 30px;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.step_lst li .step_lbl {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 93px;
  height: 40px;
  padding: 0 14px;
  background: #404dab;
  font-size: 14px;
  font-weight: 500;
  color: #f5f5f5;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
  box-sizing: border-box;
}

.step_lst li .step_lbl.green {
  background: #509216;
}

.step_lst li .step_ico {
  margin-top: 2px;
}

.step_lst li .step_ico span {
  display: block;
  margin: auto;
}

.step_lst li .step_tit {
  display: block;
  margin-top: 30px;
  font-size: 22px;
  color: #101010;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.step_lst li .step_tit + .step_desc {
  margin-top: 7px;
}

.step_lst li .step_desc {
  margin-top: 21px;
  font-size: 16px;
  color: #222222;
  text-align: center;
}

.step_lst.step3 {
  padding: 40px;
}

.step_lst.step3 li {
  width: 420px;
  height: 186px;
}

.step_lst.step3 li ~ li {
  margin-left: 31px;
}

.step_lst.step4 {
  padding: 50px;
}

.step_lst.step4 li {
  width: 630px;
  height: 250px;
  padding: 40px 45px 35px;
}

.step_lst.step4 li:nth-child(even) {
  margin-left: 46px;
}

.step_lst.step4 li:nth-child(2) ~ li {
  margin-top: 50px;
}

.step_lst.step4 li .step_desc {
  font-weight: 300;
}

.step_lst.step4 li .step_desc strong {
  font-weight: 400;
}

.note_txt {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
}

.hr_blue {
  display: block;
  margin: 53px 0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px solid #384859;
}

.btn_grp .btn ~ .btn {
  margin-left: 6px;
}

.def_inp {
  display: inline-block;
  height: 50px;
  padding: 18px 20px;
  border: 1px solid #ebebeb;
  border-right: none;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.top_line_gray {
  border-top: 1px solid #a4a4a4;
}

.btn_go_top {
  position: fixed;
  bottom: 239px;
  left: 1440px;
}

.result_bx {
  padding-top: 150px;
  text-align: center;
}

.result_bx .ico {
  display: block;
  margin: 0 auto;
}

.result_bx .tit {
  display: block;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #101010;
  letter-spacing: -0.04em;
}

.result_bx .txt {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.result_bx .btn {
  width: 262px;
  border-radius: 3px;
}

.result_bx .info_lst li {
  display: inline-block;
  position: relative;
  margin-left: 28px;
}

.result_bx .info_lst li:before {
  position: absolute;
  top: 6px;
  left: -16px;
  width: 1px;
  height: 13px;
  background-color: #c8c8cf;
  content: '';
}

.result_bx .info_lst li:first-child {
  margin-left: 0;
}

.result_bx .info_lst li:first-child:before {
  content: none;
}

.verify_phone_bx {
  display: inline-block;
  position: relative;
  margin-left: 30px;
}

.verify_phone_bx:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 17px;
  background: #c8c8cf;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.verify_phone_bx .txt {
  display: inline-block;
  width: 82px;
  margin-left: 31px;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  outline: none;
}

.verify_phone_bx .txt::-webkit-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.verify_phone_bx .txt:-ms-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.verify_phone_bx .txt::placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.verify_phone_bx .time_count {
  display: inline-block;
  margin-left: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #404dab;
}

.verify_phone_bx .btn {
  margin-left: 10px !important;
}

/* SVG Icons */
/* SVG Icons */
/* Pages */
/* MAIN */
.main_page .wrap, .main_page .header {
  min-width: 1890px;
}

.main_visual {
  position: relative;
  overflow: hidden;
  height: 590px;
}

.main_visual_lst .slide {
  overflow: hidden;
  position: relative;
}

.main_visual_lst .slide a {
  display: block;
  height: 590px;
}

.main_visual_lst .img_container img {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
}

.main_visual_lst .desc_container {
  position: absolute;
  top: 164px;
  left: 16.6%;
  color: #fff;
}

.main_visual_lst .desc_container .date {
  font-family: 'Roboto';
  font-size: 18px;
  letter-spacing: .5px;
}

.main_visual_lst .desc_container .evt_tit {
  margin-top: 6px;
  margin-left: -7px;
  font-family: 'NanumMyeongjo';
  font-size: 72px;
  letter-spacing: -4.5px;
  line-height: 87px;
}

.main_visual_lst .desc_container .evt_desc {
  margin-top: 4px;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: -.37px;
}

.main_visual_indicater {
  overflow: hidden;
  position: relative;
  z-index: 10;
  max-width: 1410px;
  height: 70px;
  margin: -70px auto 0;
}

.main_visual_indicater_lst {
  padding-left: 141px;
  padding-right: 71px;
  font-size: 0;
}

.main_visual_indicater_lst .indicator {
  border-left: 1px solid #1c1e1e;
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  color: rgba(255, 255, 255, 0.4);
  line-height: 70px;
  text-align: center;
}

.main_visual_indicater_lst .indicator.slick-current {
  color: #fff;
}

.main_visual_indicater .slick-arrow {
  position: absolute;
  top: 0;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.main_visual_indicater .slick-arrow span {
  display: inline-block;
  margin-top: 2px;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.main_visual_indicater .slick-prev {
  width: 70px;
  left: 0;
}

.main_visual_indicater .slick-prev span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1326px -620px;
  width: 8px;
  height: 15px;
}

.main_visual_indicater .slick-next {
  left: 70px;
  width: 71px;
  padding-left: 1px;
}

.main_visual_indicater .slick-next span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -948px;
  width: 8px;
  height: 15px;
}

.main_visual_indicater .slick-next:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 15px;
  margin-top: -7px;
  background-color: #3b3b3c;
  content: '';
}

.main_visual_indicater .btn_evt_link {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.8);
  border-left: 1px solid #1c1e1e;
  font-size: 0;
  line-height: 0;
}

.main_visual_indicater .btn_evt_link:before, .main_visual_indicater .btn_evt_link:after {
  position: absolute;
  background-color: #b3b6bb;
  content: '';
}

.main_visual_indicater .btn_evt_link:before {
  top: 28px;
  left: 34px;
  width: 2px;
  height: 14px;
}

.main_visual_indicater .btn_evt_link:after {
  top: 34px;
  left: 28px;
  width: 14px;
  height: 2px;
}

.main_boards {
  margin-top: 110px;
}

.main_boards .col {
  float: left;
  position: relative;
  width: 450px;
  box-sizing: border-box;
}

.main_boards .col_news {
  padding: 12px 5px 0 0;
}

.main_boards .col_news .col_tit {
  font-weight: 500;
  font-size: 26px;
}

.main_boards .col_news .news_lst {
  margin-top: 34px;
}

.main_boards .col_news .news_lst li {
  margin-top: 14px;
}

.main_boards .col_news .news_lst li:first-child {
  margin-top: 0;
}

.main_boards .col_news .news_lst li a {
  display: block;
}

.main_boards .col_news .news_lst li span {
  display: inline-block;
  line-height: 24px;
  vertical-align: top;
}

.main_boards .col_news .news_lst li .icon {
  margin-top: 2px;
}

.main_boards .col_news .news_lst li .tit {
  overflow: hidden;
  max-width: 70%;
  margin-left: 5px;
  font-size: 16px;
  color: #222;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main_boards .col_news .news_lst li .date {
  float: right;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #868686;
}

.main_boards .col_news .news_lst li .new {
  font-weight: 500;
  font-size: 14px;
  color: #e02424;
}

.main_boards .col_news .btn_more_news {
  position: absolute;
  top: 15px;
  right: 0;
  width: 34px;
  height: 34px;
  font-size: 0;
  line-height: 0;
}

.main_boards .col_news .btn_more_news:before, .main_boards .col_news .btn_more_news:after {
  position: absolute;
  background-color: #000;
  content: '';
}

.main_boards .col_news .btn_more_news:before {
  top: 5px;
  left: 16px;
  width: 2px;
  height: 24px;
}

.main_boards .col_news .btn_more_news:after {
  top: 16px;
  left: 5px;
  width: 24px;
  height: 2px;
}

.main_boards .col_gmstory {
  overflow: hidden;
  height: 289px;
}

.main_boards .col_gmstory .gmstory_banner .slide img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.main_boards .col_gmstory .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 45px;
  background-color: #fff;
  color: #fff;
}

.main_boards .col_gmstory .slick-arrow span {
  display: inline-block;
  margin-top: 4px;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.main_boards .col_gmstory .slick-prev {
  width: 45px;
  right: 46px;
}

.main_boards .col_gmstory .slick-prev span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -528px;
  width: 6px;
  height: 11px;
}

.main_boards .col_gmstory .slick-next {
  width: 46px;
  right: 0;
  padding-left: 1px;
}

.main_boards .col_gmstory .slick-next span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1329px -513px;
  width: 6px;
  height: 11px;
}

.main_boards .col_gmstory .slick-next:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 11px;
  margin-top: -6px;
  background-color: #dfdfdf;
  content: '';
}

.main_boards .col_download .btn_game_download {
  display: block;
  height: 148px;
  background: url("../img/btn_download.jpg") no-repeat;
  font-size: 0;
  line-height: 0;
}

.main_boards .col_download .btn_game_download:hover {
  background-image: url("../img/btn_download_hover.jpg");
}

.main_boards .col_download .login_info {
  height: 65px;
  margin-top: 6px;
  padding: 18px 25px 17px;
  background-color: #384859;
  font-size: 15px;
  color: #fff;
  line-height: 28px;
  box-sizing: border-box;
}

.main_boards .col_download .login_info .name {
  display: block;
  overflow: hidden;
  float: left;
  max-width: 220px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main_boards .col_download .login_info .cash_info {
  overflow: hidden;
  text-align: right;
}

.main_boards .col_download .login_info .cash_info .cash {
  display: inline-block;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.main_boards .col_download .login_info .cash_info .cash:before {
  display: inline-block;
  margin: -3px 5px 0 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1088px -880px;
  width: 19px;
  height: 19px;
  vertical-align: middle;
  content: '';
}

.main_boards .col_download .login_info .cash_info .btn_cash {
  width: 70px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #6e7985;
  border-radius: 2px;
  line-height: 28px;
  letter-spacing: -0.5px;
}

.main_boards .col_download .btn_login {
  display: block;
  width: 100%;
  margin-top: 6px;
  background-color: #384859;
  font-size: 15px;
  color: #fff;
  line-height: 65px;
}

.main_boards .col_download .btn_login .ico_daum {
  display: inline-block;
  overflow: hidden;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -437px -928px;
  width: 59px;
  height: 24px;
  vertical-align: middle;
  line-height: 999px;
  content: "";
}

.main_boards .col_download .btn_login .ico_ongate {
  display: inline-block;
  overflow: hidden;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -795px -380px;
  width: 72px;
  height: 13px;
  vertical-align: middle;
  line-height: 999px;
  content: "";
}

.main_boards .col_download .btn_login span {
  display: inline-block;
  vertical-align: middle;
}

.main_boards .col_download .server_info {
  position: relative;
  margin-top: 5px;
}

.main_boards .col_download .btn_server_status {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 15px;
  color: #373636;
  line-height: 63px;
}

.main_boards .col + .col {
  margin-left: 30px;
}

.main_boards .channel_download .login_info {
  position: relative;
  height: 75px;
  margin-top: 5px;
  padding: 11px 33px 11px 25px;
}

.main_boards .channel_download .login_info .myinfo_lst {
  position: absolute;
  bottom: 16px;
  font-size: 0;
  line-height: initial;
}

.main_boards .channel_download .login_info .myinfo_lst li {
  display: inline-block;
}

.main_boards .channel_download .login_info .myinfo_lst li:first-child:before {
  display: none;
}

.main_boards .channel_download .login_info .myinfo_lst li:before {
  display: inline-block;
  width: 1px;
  height: 11px;
  margin: 0 7px;
  background: #606d7a;
  vertical-align: middle;
  content: "";
}

.main_boards .channel_download .login_info .myinfo_lst li a {
  display: inline-block;
  font-size: 13px;
  color: #d1d4d6;
  vertical-align: middle;
}

.main_boards .channel_download .login_info .cash_info {
  line-height: initial;
}

.main_boards .channel_download .login_info .cash_info .cash {
  line-height: initial;
  vertical-align: middle;
}

.main_boards .channel_download .login_info .cash_info .dsc_txt {
  font-family: 'notosans';
  font-weight: 300;
}

.main_boards .channel_download .login_info .cash_info .btn_cash {
  display: inline-block;
  width: auto;
  border: 0;
  font-size: 12px;
  font-weight: 300;
  text-decoration: underline;
  line-height: initial;
  vertical-align: middle;
  letter-spacing: 0;
}

.main_boards .channel_download .login_info .cash_info + .cash_info {
  margin-top: 7px;
}

.main_boards .channel_download .btn_game_download {
  height: 129px;
  background: url("../img/btn_download2.jpg") no-repeat;
}

.main_boards .channel_download .btn_game_download:hover {
  background-image: url("../img/btn_download2_hover.jpg");
}

.main_boards .channel_download .btn_server_status {
  height: 75px;
}

.layer_server_status {
  display: none;
  position: absolute;
  top: 50px;
  left: 70px;
  z-index: 100;
  width: 310px;
  border: 1px solid #384859;
  background-color: #fff;
  box-sizing: border-box;
}

.layer_server_status .layer_title {
  padding: 0 18px;
  background-color: #384859;
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  line-height: 46px;
}

.layer_server_status .server_lst {
  padding: 13px 19px 15px;
}

.layer_server_status .server_lst li {
  overflow: hidden;
  border-top: 1px solid #dfdfdf;
  padding: 12px 15px 10px 10px;
}

.layer_server_status .server_lst li:first-child {
  border-top: 0;
}

.layer_server_status .server_lst li .server_name {
  float: left;
  font-size: 15px;
  line-height: 24px;
}

.layer_server_status .server_lst li .server_status {
  float: right;
}

.layer_server_status .btn_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 46px;
  font-size: 0;
  line-height: 0;
}

.layer_server_status .btn_close:after {
  display: inline-block;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -549px;
  width: 12px;
  height: 12px;
  content: '';
}

.main_shop {
  overflow: hidden;
  margin-top: 100px;
}

.main_shop_tab li {
  display: inline-block;
  position: relative;
  margin-right: 50px;
  padding-top: 3px;
  vertical-align: top;
}

.main_shop_tab li a {
  font-weight: 500;
  font-size: 20px;
  color: #a5a4a4;
  line-height: 60px;
  letter-spacing: -0.3px;
}

.main_shop_tab li.active:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #000;
  content: '';
}

.main_shop_tab li.active a {
  color: #000;
}

.main_shop_lst {
  overflow: hidden;
  height: 350px;
  margin: 10px -10px 0;
  font-size: 0;
  letter-spacing: -5px;
}

.main_shop_lst li {
  display: inline-block;
  width: 266px;
  margin: 0 10px;
  box-sizing: border-box;
  vertical-align: top;
  outline: 0;
}

.main_shop_lst .item_box {
  position: relative;
  height: 350px;
  border: 1px solid #ccc;
  padding: 49px 35px 0;
  text-align: center;
  letter-spacing: 0;
  box-sizing: border-box;
}

.main_shop_lst .thumb {
  display: block;
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  padding: 2px;
  background: linear-gradient(148deg, #f4cf88 30%, #986a4b 50%, #f4cf88 70%);
  box-sizing: border-box;
}

.main_shop_lst .thumb:before {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #000;
  width: 116px;
  height: 116px;
  content: '';
}

.main_shop_lst .thumb img {
  position: relative;
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.main_shop_lst .item_tit {
  display: block;
  overflow: hidden;
  margin-top: 17px;
  font-size: 14px;
  height: 40px;
}

.main_shop_lst .item_price {
  display: block;
  margin-top: 11px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.main_shop_lst .item_price:before {
  display: inline-block;
  margin: -2px 4px 0 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1103px -1112px;
  width: 17px;
  height: 17px;
  vertical-align: middle;
  content: '';
}

.main_shop_lst .btn_buy {
  position: absolute;
  left: 50%;
  bottom: 39px;
  width: 99px;
  margin-left: -50px;
  border-radius: 2px;
  background-color: #a68a5f;
  font-weight: 300;
  font-size: 13px;
  color: #fff;
  line-height: 34px;
}

.main_shop_lst .shop_label {
  position: absolute;
  top: -1px;
  left: -1px;
}

.main_shop_lst .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 35px;
  height: 34px;
  margin-top: -17px;
  background-color: #a4a4a4;
  box-sizing: border-box;
  opacity: 0;
}

.main_shop_lst .slick-arrow span {
  display: block;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.main_shop_lst .slick-prev {
  left: 10px;
}

.main_shop_lst .slick-prev span {
  margin-left: 12px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1326px -639px;
  width: 8px;
  height: 15px;
}

.main_shop_lst .slick-next {
  right: 10px;
}

.main_shop_lst .slick-next span {
  margin-left: 14px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -997px;
  width: 8px;
  height: 15px;
}

.main_shop .btn_cash_charge {
  position: absolute;
  top: 23px;
  right: 20px;
}

.main_vip_banner {
  overflow: hidden;
  position: relative;
  margin-top: 110px;
  height: 251px;
}

.main_vip_banner .slide {
  overflow: hidden;
  position: relative;
  height: 251px;
}

.main_vip_banner .slide img {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
}

.main_vip_banner .slick-arrow {
  opacity: 0;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 50px;
  height: 80px;
  margin-top: -40px;
}

.main_vip_banner .slick-arrow span {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

.main_vip_banner .slick-prev {
  left: 50%;
  margin-left: -720px;
}

.main_vip_banner .slick-prev span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1085px -347px;
  width: 22px;
  height: 44px;
}

.main_vip_banner .slick-next {
  right: 50%;
  margin-right: -720px;
}

.main_vip_banner .slick-next span {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1085px -299px;
  width: 22px;
  height: 44px;
}

.main_guide {
  padding: 120px 0;
  background: url("../img/main_guide_bg.jpg") no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.main_guide .inner {
  overflow: hidden;
  width: 1890px;
  padding: 0;
}

.main_guide_lst {
  overflow: hidden;
  margin: 0 -15px;
  font-size: 0;
  letter-spacing: -4px;
  text-align: center;
  white-space: nowrap;
}

.main_guide .guide {
  overflow: hidden;
  display: inline-block;
  position: relative;
  width: 330px;
  height: 622px;
  padding: 0 15px;
  font-size: normal;
  letter-spacing: 0;
  vertical-align: top;
  white-space: normal;
  outline: 0;
}

.main_guide .guide_thumb {
  overflow: hidden;
  position: absolute;
  width: 330px;
  height: 622px;
}

.main_guide .guide_thumb img {
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  width: auto;
  height: 522px;
  vertical-align: top;
  transform: translateY(-50%);
}

.main_guide .guide_thumb img.default {
  opacity: 1;
}

.main_guide .guide_thumb img.active {
  opacity: 0;
}

.main_guide .guide_title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 330px;
  z-index: 1;
  color: #fff;
  transform: translateY(-50%);
}

.main_guide .guide_title span {
  display: block;
  padding-left: 11px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 11px;
  text-transform: uppercase;
}

.main_guide .guide_title h3 {
  margin-top: 14px;
  font-family: 'NanumMyeongjo','나눔명조', sans-serif;
  font-weight: normal;
  font-size: 30px;
}

.main_guide .guide_link {
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: #000;
}

.main_guide .guide_link:before, .main_guide .guide_link:after {
  position: absolute;
  background-color: #fff;
  content: '';
}

.main_guide .guide_link:before {
  top: 18px;
  left: 29px;
  width: 2px;
  height: 24px;
}

.main_guide .guide_link:after {
  top: 29px;
  left: 18px;
  width: 24px;
  height: 2px;
}

.main_guide .guide_details {
  position: absolute;
  top: 174px;
  left: 466px;
  width: 365px;
  opacity: 0;
  z-index: 1;
  text-align: left;
}

.main_guide .guide_details .cate {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #e02424;
  letter-spacing: 16px;
  text-transform: uppercase;
}

.main_guide .guide_details .tit {
  margin-top: 19px;
  font-family: 'NanumMyeongjo','나눔명조', sans-serif;
  font-weight: normal;
  font-size: 70px;
  text-indent: -4px;
  color: #fff;
}

.main_guide .guide_details .desc {
  margin-top: 17px;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  opacity: .7;
  word-wrap: break-word;
  word-break: keep-all;
}

.board_area .name,
.view_area .name {
  font-size: 14px;
  color: #868686;
  text-align: center;
}

.board_area .name:before,
.view_area .name:before {
  display: inline-block;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -557px -1153px;
  width: 15px;
  height: 16px;
  vertical-align: middle;
  content: '';
}

.board_area .comment,
.view_area .comment {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #868686;
  text-align: center;
}

.board_area .comment:before,
.view_area .comment:before {
  display: inline-block;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -867px -540px;
  width: 14px;
  height: 13px;
  vertical-align: middle;
  content: '';
}

.board_area .view,
.view_area .view {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #868686;
  text-align: center;
}

.board_area .view:before,
.view_area .view:before {
  display: inline-block;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -743px -462px;
  width: 14px;
  height: 10px;
  vertical-align: middle;
  content: '';
}

.board_area .time,
.view_area .time {
  font-family: 'Roboto', 'notosans', sans-serif;
  font-size: 14px;
  color: #868686;
  text-align: center;
}

.board_area .time:before,
.view_area .time:before {
  display: inline-block;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1265px -1065px;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  content: '';
}

.board_area .heart,
.view_area .heart {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #868686;
  text-align: center;
}

.board_area .heart:before,
.view_area .heart:before {
  display: inline-block;
  margin-right: 5px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -771px -928px;
  width: 16px;
  height: 14px;
  vertical-align: middle;
  content: '';
}

.board_area .btn_wrap {
  margin-top: 16px;
  text-align: right;
}

.board_area .btn_wrap + .pagination {
  margin-top: 35px;
}

.board_area .search_box {
  margin-top: 40px;
  font-size: 0;
  text-align: center;
}

.board_area .area_tit {
  margin-bottom: 23px;
  line-height: 42px;
}

.txt_new {
  display: inline-block;
  margin-left: 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #e80000;
  vertical-align: top;
}

.btn_grp.pop {
  font-size: 0;
}

.btn_grp.pop .btn {
  overflow: hidden;
  width: auto;
  height: 28px;
  padding: 0 17px;
  border: 1px solid #a4a4a4;
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #868686;
  line-height: 30px;
}

.btn_grp.pop .btn + .btn {
  margin-left: 10px;
}

.btn_grp.pop + div {
  margin-top: 32px;
}

.tabs_server_lst {
  float: left;
}

.tabs_server_lst li {
  display: inline-block;
  width: 120px;
  height: 50px;
  border: 1px solid #ebebeb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tabs_server_lst li a {
  display: block;
  font-size: 18px;
  color: #a4a4a4;
  line-height: 48px;
  text-align: center;
}

.tabs_server_lst li ~ li {
  margin-left: 8px;
}

.tabs_server_lst li.on {
  background: #384859;
  border-color: #384859;
}

.tabs_server_lst li.on a {
  color: #ffffff;
}

.top_rank_lst {
  padding: 36px;
  background: #f4f5f7;
  font-size: 0;
}

.top_rank_lst .lst_itm {
  display: inline-block;
  width: 426px;
  height: 186px;
  margin-left: 30px;
  background: #ffffff;
}

.top_rank_lst .lst_itm:first-child {
  margin-left: 0;
}

.top_rank_lst .lst_itm:after {
  display: block;
  clear: both;
  content: "";
}

.top_rank_lst .top_rank_ico {
  display: block;
  width: 98px;
  height: 38px;
  margin: 27px auto 0;
  line-height: 999px;
}

.top_rank_lst .info_container {
  margin: 16px auto 0;
  text-align: center;
}

.top_rank_lst .name {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: #101010;
  line-height: 23px;
}

.top_rank_lst .name img {
  margin-top: -3px;
}

.top_rank_lst .desc_lst {
  font-size: 0;
}

.top_rank_lst .desc_lst li {
  display: inline-block;
  margin-left: 10px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #868686;
  line-height: 15px;
  vertical-align: bottom;
  letter-spacing: -0.2px;
}

.top_rank_lst .desc_lst li:first-child {
  margin: 0;
}

.top_rank_lst .desc_lst li:first-child:before {
  display: none;
}

.top_rank_lst .desc_lst li:before {
  display: inline-block;
  width: 1px;
  height: 14px;
  margin-right: 10px;
  background: #c8c8cf;
  vertical-align: bottom;
  content: '';
}

.top_rank_lst .desc_lst li span {
  display: inline-block;
  font-weight: 500;
}

.top_rank_lst.guild_rank .info_container {
  margin-top: 13px;
}

.top_rank_lst.guild_rank .name img {
  vertical-align: middle;
}

.top_rank_lst.guild_rank .name a {
  color: #222222;
}

.top_rank_lst.guild_rank .name a:hover {
  text-decoration: underline;
}

.top_rank_lst.guild_rank .desc_lst li {
  vertical-align: baseline;
}

.top_rank_lst.guild_rank .lbl_outline.s_lbl {
  width: 53px;
  height: 22px;
  line-height: 22px;
}

.rank_page .sub_sticky {
  padding-bottom: 30px;
}

.rank_page .search_box {
  float: right;
}

.rank_page .search_box .search_inp input {
  width: 469px;
  height: 48px;
}

.rank_page .tbl_lst_indi {
  float: left;
}

.rank_page .notice_txt {
  float: right;
  margin-top: 30px;
}

.rank_page .search_box .select {
  width: 130px;
  height: 50px;
  margin-right: -4px;
}

.rank_page .notice_txt_grp {
  float: left;
  margin-top: 13px;
}

.rank_page .notice_txt_grp .notice_txt {
  float: none;
  margin-top: 0;
  line-height: 20px;
}

.rank_page .btn_grp {
  float: right;
  margin-top: 22px;
}

.rank_page .btn_grp .btn:not(:last-child) {
  margin-right: 5px;
}

.rank_page .notice_area {
  margin-top: 0;
}

.rank_page .notice_area .notice_txt_grp {
  margin-top: 0;
}

.rank_page .notice_area .btn_grp {
  margin-top: 7px;
}

.rank_page .guild_info_area .area_tit {
  float: left;
}

.rank_page .guild_info_tbl {
  margin-top: 30px;
  border-top: none;
}

.rank_page .guild_info_tbl th, .rank_page .guild_info_tbl td {
  height: 54px;
  border: 1px solid #ebebeb;
  font-size: 16px;
  color: #222222;
}

.rank_page .guild_info_tbl th {
  font-weight: 400;
  border-bottom: none;
}

.rank_page .guild_info_tbl td {
  font-weight: 300;
}

.rank_page .guild_info_tbl td a {
  font-size: 16px;
  color: #222222;
}

.rank_page .guild_info_tbl td a:hover {
  text-decoration: underline;
}

.rank_page .guild_info_tbl .guild_main_info {
  border: none;
  background: url("../img/guild_info_bg.jpg") no-repeat;
}

.rank_page .guild_info_tbl .guild_main_info .guild_name {
  font-size: 26px;
  font-weight: 400;
  color: #ffffff;
}

.rank_page .guild_info_tbl .guild_main_info .guild_name img {
  margin-top: -5px;
  vertical-align: middle;
}

.rank_page .guild_info_tbl .guild_main_info dl dt, .rank_page .guild_info_tbl .guild_main_info dl dd {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  line-height: 13px;
  color: #ffffff;
}

.rank_page .guild_info_tbl .guild_main_info dl dt {
  margin-right: 7px;
  color: rgba(255, 255, 255, 0.4);
}

.rank_page .guild_info_tbl .guild_main_info dl dt:after {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin-left: 11px;
  background: #afb3b8;
  opacity: .2;
  content: '';
}

.rank_page .guild_fm {
  margin-top: 27px;
  font-size: 16px;
}

.rank_page .guild_fm .tbl_lst {
  border-top: none;
}

.rank_page .guild_fm th, .rank_page .guild_fm td {
  height: auto;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  vertical-align: top;
}

.rank_page .guild_fm th > div, .rank_page .guild_fm td > div {
  display: table-cell;
  min-height: 71px;
}

.rank_page .guild_fm th > div {
  display: block;
  line-height: 71px;
}

.rank_page .guild_fm td {
  padding-left: 30px;
  text-align: left;
  vertical-align: middle;
}

.rank_page .guild_fm input[type="text"] {
  width: 100%;
  height: 36px;
  border: none;
  font-size: 14px;
  color: #222222;
  outline: none;
}

.rank_page .guild_fm input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  opacity: 1;
  /* Firefox */
}

.rank_page .guild_fm input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a4a4a4;
}

.rank_page .guild_fm input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a4a4a4;
}

.rank_page .guild_fm .rdo_lst_v2 li {
  margin-top: 15px;
}

.rank_page .guild_fm .rdo_lst_v2 label img {
  vertical-align: middle;
}

.rank_page .guild_popup .dot_lst {
  margin-top: 11px;
}

.rank_page .calc_guide_pop .dot_lst + p {
  margin-top: 31px;
}

.rank_page .file_upload_box {
  margin-top: 32px;
  margin-bottom: 26px;
}

.rank_page .file_upload_box .guide_txt {
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  color: #222222;
}

.rank_page .file_upload_box label {
  cursor: pointer;
}

.rank_page .file_upload_box .file_name {
  display: inline-block;
  margin-left: 6px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  line-height: 30px;
}

.rank_page .file_upload_box .btn_del_file {
  margin-left: 9px;
  vertical-align: middle;
}

.rank_page .file_upload_box .btn_del_file .ico_del {
  display: block;
}

.rank_page .rank_board_area {
  margin-top: 80px;
}

.rank_page .rank_board_area:first-child {
  margin-top: 40px;
}

.rank_page .rank_board_area .area_tit + .top_filter {
  margin-top: 40px;
}

.rank_page .rank_board_area .clearfix > .area_tit, .rank_page .rank_board_area .attach_box > .area_tit, .rank_page .rank_board_area .service_page .sl_server > .area_tit, .service_page .rank_page .rank_board_area .sl_server > .area_tit, .rank_page .rank_board_area .service_page .sl_char > .area_tit, .service_page .rank_page .rank_board_area .sl_char > .area_tit {
  float: left;
  margin-top: 10px;
}

.rank_page .rank_board_area .tbl_lst {
  margin-top: 30px;
}

.rank_page .top3_area, .rank_page .guild_info_area {
  margin-top: 40px;
}

.invite_page .menu_depth_3 .tab_itm a {
  padding: 0 15px;
}

.invite_page .cp_history_area .left.note_txt {
  margin-top: 5px;
}

.invite_page .tbl_lst {
  margin-top: 30px;
}

.invite_page .dot_lst {
  margin-top: 30px;
}

.invite_page .dot_lst li:before {
  top: 5px;
  background: #868686;
}

.invite_page .dot_lst li ~ li {
  margin-top: 16px;
}

.invite_page .dot_lst li ~ li .note_txt {
  margin-top: 0;
}

.invite_step_area {
  margin-top: 57px;
}

.invite_step_area .area_tit {
  display: inline-block;
  margin-top: 50px;
}

.proceed_area {
  margin-top: 47px;
}

.proceed_area .intro_txt + img {
  margin-top: -144px;
}

.proceed_area .adv_guide_bx {
  margin-top: 48px;
  text-align: center;
}

.proceed_area .adv_guide_bx strong {
  display: inline-block;
  font-size: 20px;
  color: #222222;
  line-height: 19px;
  vertical-align: middle;
}

.proceed_area .adv_guide_bx strong:after {
  display: inline-block;
  width: 1px;
  height: 13px;
  background: #c8c8cf;
  margin-left: 20px;
  margin-top: 3px;
  vertical-align: top;
  content: '';
}

.proceed_area .adv_guide_bx .dot_lst {
  display: inline-block;
  vertical-align: middle;
}

.proceed_area .adv_guide_bx .dot_lst li {
  margin-left: 17px;
  font-size: 18px;
  line-height: 17px;
  color: #222222;
  letter-spacing: -0.4px;
}

.proceed_area .adv_guide_bx .dot_lst li:before {
  top: 6px;
  background: #868686;
}

.proceed_area .area_tit {
  float: left;
  margin-top: 80px;
}

.proceed_area .btn_grp {
  float: right;
  margin-top: 77px;
}

.proceed_area .btn_grp .btn:first-child ~ .btn {
  margin-left: 6px;
}

.proceed_area .step_lst li:last-child .step_tit {
  margin-top: 13px;
}

.proceed_area .step_lst li:last-child .step_desc {
  margin-top: 6px;
}

.proceed_area .step_lst .step_desc {
  letter-spacing: -1px;
}

.coupon_reward_area {
  margin-top: 58px;
}

.coupon_reward_area .tbl_lst {
  margin-top: 47px;
}

.coupon_reward_area .tbl_lst.coupon_reward thead th:first-child {
  padding-left: 224px;
}

.coupon_reward_area .tbl_lst.coupon_reward tbody td:first-child {
  padding-left: 137px;
}

.coupon_reward_area .tbl_lst.coupon_reward tbody td:first-child img {
  margin-top: 0;
  margin-right: 9px;
}

.coupon_reward_area .tbl_lst.coupon_reward tbody td:first-child a {
  font-size: 16px;
  color: #222;
  line-height: 24px;
}

.reward_token_area, .reward_helper_area {
  margin-top: 58px;
}

.reward_token_area .tbl_lst, .reward_helper_area .tbl_lst {
  margin-top: 47px;
}

.reward_token_area .tbl_lst tbody td:last-child, .reward_helper_area .tbl_lst tbody td:last-child {
  min-height: 60px;
  height: auto;
  padding: 17px 0 17px 119px;
}

.reward_token_area .tbl_lst .item_lst li, .reward_helper_area .tbl_lst .item_lst li {
  display: inline-block;
  width: 345px;
}

.reward_token_area .tbl_lst .item_lst li:nth-child(even), .reward_helper_area .tbl_lst .item_lst li:nth-child(even) {
  margin-left: 13px;
}

.reward_token_area .tbl_lst .item_lst li:nth-child(2) ~ li, .reward_helper_area .tbl_lst .item_lst li:nth-child(2) ~ li {
  margin-top: 20px;
}

.reward_token_area .tbl_lst .item_lst li img, .reward_helper_area .tbl_lst .item_lst li img {
  margin-top: 0 !important;
  margin-right: 14px;
  vertical-align: top !important;
}

.send_cp_area > .clearfix .area_tit, .send_cp_area > .attach_box .area_tit, .service_page .send_cp_area > .sl_server .area_tit, .service_page .send_cp_area > .sl_char .area_tit {
  float: left;
}

.send_cp_area > .clearfix .btn_grp, .send_cp_area > .attach_box .btn_grp, .service_page .send_cp_area > .sl_server .btn_grp, .service_page .send_cp_area > .sl_char .btn_grp {
  float: right;
}

.send_cp_area .send_cp_bx {
  width: 940px;
  height: 163px;
  padding: 43px 50px;
  margin-top: 26px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.send_cp_area .send_cp_bx .txt {
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  color: #222222;
}

.send_cp_area .send_cp_fm {
  margin-top: 10px;
}

.send_cp_area .send_cp_fm .def_inp {
  width: 678px;
  vertical-align: top;
}

.send_cp_area .send_cp_fm .def_inp::-webkit-input-placeholder {
  color: #a4a4a4;
}

.send_cp_area .send_cp_fm .def_inp:-ms-input-placeholder {
  color: #a4a4a4;
}

.send_cp_area .send_cp_fm .def_inp::placeholder {
  color: #a4a4a4;
}

.send_cp_area .send_cp_fm .btn {
  width: 162px;
  height: 50px;
  margin-left: -4px;
  border: 1px solid #ebebeb;
  border-left: none;
  vertical-align: top;
  font-size: 14px;
  font-weight: 500;
}

.send_cp_area .cp_stt_bx {
  width: 470px;
  height: 163px;
  margin-top: 26px;
  padding: 0 47px 0 53px;
  border: 1px solid #e3e3e3;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.send_cp_area .cp_type_box {
  display: table;
  height: 80px;
  width: 100%;
}

.send_cp_area .cp_type_box ~ .cp_type_box {
  border-top: 1px solid #e5e5e5;
}

.send_cp_area .cp_type_box .cp_type {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
}

.send_cp_area .cp_type_box .cp_type strong {
  font-size: 20px;
  font-weight: 400;
  line-height: 19px;
  color: #222222;
}

.send_cp_area .cp_type_box .cp_type .note_txt {
  display: block;
  margin-top: 8px;
}

.send_cp_area .cp_type_box .num_count {
  display: table-cell;
  font-size: 40px;
  text-align: right;
  vertical-align: middle;
}

.friend_cp_area {
  margin-top: 60px;
}

.friend_cp_area .tbl_lst .nodata {
  height: 305px;
}

.friend_cp_area .tbl_lst .nodata .ico {
  margin-top: 0;
}

.friend_cp_area .tbl_lst .nodata .txt_msg {
  margin-top: 17px;
  font-size: 16px;
}

.friend_cp_area .tbl_lst .nodata .btn {
  margin-top: 27px;
}

.inv_reward_area {
  margin-top: 63px;
}

.inv_reward_area > .clearfix .area_tit, .inv_reward_area > .attach_box .area_tit, .service_page .inv_reward_area > .sl_server .area_tit, .service_page .inv_reward_area > .sl_char .area_tit {
  float: left;
}

.inv_reward_area > .clearfix .note_txt, .inv_reward_area > .attach_box .note_txt, .service_page .inv_reward_area > .sl_server .note_txt, .service_page .inv_reward_area > .sl_char .note_txt {
  float: right;
  margin-top: 15px;
}

.inv_reward_area .reward_coin_bx {
  display: table;
  width: 100%;
  height: 133px;
  margin-top: 27px;
  padding: 43px 50px;
  box-sizing: border-box;
}

.inv_reward_area .reward_coin_bx .tit {
  display: table-cell;
  width: 577px;
  font-size: 28px;
  font-weight: 500;
  vertical-align: middle;
  letter-spacing: -1.6px;
}

.inv_reward_area .reward_coin_bx .coin_info > div {
  display: table-cell;
  vertical-align: middle;
}

.inv_reward_area .reward_coin_bx .coin_info > div:first-child {
  width: 502px;
}

.inv_reward_area .reward_coin_bx .coin_info dt {
  display: inline-block;
  font-size: 28px;
  font-weight: 300;
  vertical-align: middle;
  letter-spacing: -1.5px;
}

.inv_reward_area .reward_coin_bx .coin_info dd {
  display: inline-block;
  vertical-align: middle;
}

.inv_reward_area .reward_coin_bx .coin_info .num {
  display: inline-block;
  margin-left: 27px;
  font-family: 'Barlow', sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #404dab;
  vertical-align: middle;
}

.inv_reward_area .reward_coin_bx .coin_info .btn {
  width: 130px;
  height: 48px;
  vertical-align: middle;
  margin-left: 43px;
}

.redeem_itm_area {
  margin-top: 60px;
}

.redeem_itm_area > .clearfix .area_tit, .redeem_itm_area > .attach_box .area_tit, .service_page .redeem_itm_area > .sl_server .area_tit, .service_page .redeem_itm_area > .sl_char .area_tit {
  float: left;
}

.redeem_itm_area > .clearfix .note_txt, .redeem_itm_area > .attach_box .note_txt, .service_page .redeem_itm_area > .sl_server .note_txt, .service_page .redeem_itm_area > .sl_char .note_txt {
  float: right;
  margin-top: 13px;
}

.cp_history_area {
  margin-top: 30px;
}

.community_page .view_area .ct {
  padding-bottom: 38px;
}

.community_page .view_area .ct img {
  display: block;
  margin: 80px auto 0;
}

.community_page .view_area .ct img + .attach_box {
  margin-top: 34px;
}

.screenshot_slide {
  font-size: 0;
  text-align: center;
}

.screenshot_slide li {
  display: inline-block;
  width: 538px;
  margin-left: 36px;
  border: 1px solid #ccc;
  text-align: left;
}

.screenshot_slide li:first-child {
  margin-left: 0;
}

.screenshot_slide a {
  display: block;
  position: relative;
  padding: 326px 28px 20px;
}

.screenshot_slide .img {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
}

.screenshot_slide .img .lbl {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 67px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
}

.screenshot_slide .img img {
  position: absolute;
  width: 100%;
}

.screenshot_slide .tit {
  display: block;
  height: 42px;
  font-size: 0;
  font-weight: 400;
  line-height: 22px;
  color: #222;
}

.screenshot_slide .tit .txt {
  overflow: hidden;
  font-size: 16px;
}

.screenshot_slide .tit .txt + .ico_attach {
  margin-left: 12px;
}

.screenshot_slide .tit .number {
  display: inline-block;
  margin-left: 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  line-height: 18px;
  vertical-align: top;
}

.screenshot_slide .tit .number + .txt_new {
  margin-left: 4px;
}

.screenshot_slide .tit .txt_new {
  margin-top: -2px;
}

.screenshot_slide .tit .ico_attach {
  display: inline-block;
  margin-left: 4px;
}

.screenshot_slide.slick-slider:hover .slick-arrow {
  opacity: 0.8;
  transition: opacity 200ms ease;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) {
  margin-left: -35px;
  transform: scale(0.74074, 0.73933);
  transition: all 300ms ease;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center):before {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  content: '';
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) a {
  padding: 326px 34px 20px 36px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .lbl {
  transform: scale(1.35, 1.3526);
  transform-origin: 0 0;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .tit {
  font-size: 0px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .txt {
  font-size: 18px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .txt + .ico_attach {
  margin-left: 12px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .number {
  margin-top: 2px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .txt_new {
  margin-top: -1px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .ico_attach {
  margin-left: 4px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .time {
  font-size: 17px;
}

.screenshot_slide.slick-slider .slick-slide:not(.slick-center) .info {
  right: 65px;
  transform: scale(1.2, 1.2);
  transform-origin: 0 0;
}

.screenshot_slide.slick-slider .slick-center {
  margin-left: -35px;
  transition: all 300ms ease;
}

.screenshot_slide.slick-slider .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%);
  opacity: 0;
  background-color: black;
  font-size: 0;
  outline: none;
  transition: opacity 200ms ease;
}

.screenshot_slide.slick-slider .slick-prev {
  left: 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -387px;
  width: 70px;
  height: 70px;
}

.screenshot_slide.slick-slider .slick-next {
  right: 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1207px -313px;
  width: 70px;
  height: 70px;
}

.tbl_img_box {
  border-top: 2px solid #384859;
  border-bottom: 1px solid #a4a4a4;
}

.tbl_img_box .screenshot_slide {
  margin: 40px 0;
}

.tbl_img_box .tbl_img_lst:before {
  border-top: 1px solid #e5e5e5;
}

.tbl_img_box .tbl_img_lst .nodata:after {
  content: none;
}

.tbl_img_box .tbl_img_lst a {
  padding-bottom: 22px;
}

.tbl_img_box .tbl_img_lst .number {
  display: inline-block;
  margin-left: 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  line-height: 24px;
  vertical-align: top;
}

.tbl_img_box .tbl_img_lst .number + .txt_new {
  margin-left: 4px;
}

.tbl_img_box .tbl_img_lst .txt {
  max-width: 310px;
}

.tbl_img_box .tbl_img_lst .txt + .ico_attach {
  margin-left: 12px;
}

.tbl_img_box .tbl_img_lst .txt_new {
  margin-left: 12px;
}

.tbl_img_box .tbl_img_lst .ico_attach {
  display: inline-block;
  margin: 6px 0 0 4px;
  vertical-align: top;
}

.tbl_img_box .tbl_img_lst .time {
  margin-top: 5px;
}

.tbl_img_box .name:before {
  margin-top: -2px;
}

.tbl_img_box .time {
  display: inline-block;
  margin-top: 12px;
  line-height: 20px;
}

.tbl_img_box .time:before {
  content: none;
}

.tbl_img_box .info {
  position: absolute;
  bottom: 24px;
  right: 28px;
}

.tbl_img_box .heart {
  margin-left: 12px;
}

.tbl_img_box .heart:before {
  margin-top: -1px;
}

.attach_box {
  margin: 75px 0 0 31px;
}

.attach_box.open .btn_toggle_attach:before {
  transform: rotate(180deg);
}

.attach_box .attach_lst_wrap {
  float: left;
}

.attach_box .btn_like {
  float: right;
  margin-top: 49px;
}

.attach_box .btn_like .ico_heart_n {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.attach_box .btn_toggle_attach {
  position: relative;
  padding-left: 22px;
  font-size: 14px;
  font-weight: normal;
  color: #868686;
  outline: none;
}

.attach_box .btn_toggle_attach:before {
  position: absolute;
  top: 8px;
  left: 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -656px;
  width: 11px;
  height: 6px;
  content: '';
}

.attach_box .lst {
  width: 433px;
  margin-top: 7px;
}

.attach_box .lst li {
  margin-top: 10px;
  border: 1px solid #e5e5e5;
  line-height: 54px;
}

.attach_box .lst li:first-child {
  margin-top: 0;
}

.attach_box .lst a {
  position: relative;
  display: block;
  font-size: 0;
  padding-left: 20px;
}

.attach_box .lst .ico {
  display: inline-block;
  vertical-align: middle;
}

.attach_box .lst .filename {
  overflow: hidden;
  display: inline-block;
  max-width: 320px;
  margin-left: 7px;
  font-size: 14px;
  font-weight: 300;
  color: #444;
  letter-spacing: 0.02em;
  vertical-align: top;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attach_box .lst .ico_download {
  position: absolute;
  top: 15px;
  right: 20px;
}

.game_guide_layout .sb-scrollbar-container {
  top: 15px;
  bottom: 15px;
  right: -20px;
  border-radius: 4px;
}

.game_guide_layout .sb-scrollbar {
  border-radius: 4px;
  z-index: 99;
}

.game_guide_layout .wrap {
  min-height: 100%;
  padding-top: 100px;
  padding-bottom: 90px;
  box-sizing: border-box;
}

.game_guide_layout .tbl_lst {
  border-top-color: #a4a4a4;
}

.game_guide_layout .tbl_lst td {
  letter-spacing: -.2px;
}

.game_guide_layout footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  margin-left: 300px;
  padding-left: 80px;
  border-top: 1px solid #e5e5e5;
}

.game_guide_layout footer .inner {
  width: 100%;
  padding: 0;
}

.game_guide_layout footer .copyright {
  font-size: 13px;
  line-height: 90px;
  color: #222222;
}

.game_guide_layout .header .inner {
  width: 300px;
  padding: 0;
}

.game_guide_layout .header .title {
  width: 300px;
  margin-bottom: 38px;
}

.game_guide_layout .header .title + .btn {
  margin-left: 20px;
}

.game_guide_layout .header .title a {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  line-height: 999px;
}

.game_guide_layout .header .title ~ .btn {
  width: 125px;
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
}

.game_guide_layout .header .title ~ .btn [class^="ico_"] {
  display: inline-block;
  margin-top: -2px;
  margin-right: 6px;
  vertical-align: middle;
}

.game_guide_layout .header .title ~ .btn.silverfill {
  margin-left: 6px;
}

.game_guide_layout .header .gnb {
  min-width: 300px;
  margin-top: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.game_guide_layout .header .gnb_lst {
  display: block;
  margin-left: 0;
  min-width: 100%;
}

.game_guide_layout .header .gnb_lst .lst_itm {
  display: block;
  min-height: 71px;
  height: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  text-align: left;
}

.game_guide_layout .header .gnb_lst .lst_itm > a {
  padding: 0 30px;
  font-size: 18px;
  font-weight: 300;
  color: #c5c5d0;
  line-height: 72px;
}

.game_guide_layout .header .gnb_lst .lst_itm > a:after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.game_guide_layout .header .gnb_lst .lst_itm > a.no_sub:after {
  -webkit-transform: translateY(-50%) rotateZ(270deg);
  -moz-transform: translateY(-50%) rotateZ(270deg);
  -ms-transform: translateY(-50%) rotateZ(270deg);
  -o-transform: translateY(-50%) rotateZ(270deg);
  transform: translateY(-50%) rotateZ(270deg);
}

.game_guide_layout .header .gnb_lst .lst_itm > a.on:not(.no_sub):after {
  -webkit-transform: translateY(-50%) rotateZ(180deg);
  -moz-transform: translateY(-50%) rotateZ(180deg);
  -ms-transform: translateY(-50%) rotateZ(180deg);
  -o-transform: translateY(-50%) rotateZ(180deg);
  transform: translateY(-50%) rotateZ(180deg);
}

.game_guide_layout .header .gnb_lst .lst_itm > a:hover, .game_guide_layout .header .gnb_lst .lst_itm > a.on {
  color: #ffffff;
  font-weight: 500;
}

.game_guide_layout .header .gnb_lst .lst_itm .sub_lst {
  position: relative;
  top: 0;
  margin-top: 0;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.game_guide_layout .header .gnb_lst .lst_itm .sub_lst li ~ li {
  margin-top: 18px;
}

.game_guide_layout .header .gnb_lst .lst_itm .sub_lst a {
  display: block;
  padding: 0 49px;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  color: #9da3aa;
}

.game_guide_layout .header .gnb_lst .lst_itm .sub_lst a.new:after {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
  vertical-align: top;
  content: '';
}

.game_guide_layout .header .gnb_lst .lst_itm .sub_lst a:hover, .game_guide_layout .header .gnb_lst .lst_itm .sub_lst a.on {
  color: #ffffff;
  text-decoration: none;
}

.game_guide_layout #content {
  padding-bottom: 130px;
}

.game_guide_layout #content .inner {
  width: 1000px;
  margin-left: 380px;
  padding-left: 0;
  padding-right: 0;
}

.game_guide_layout #content .page_header_area {
  margin-bottom: 40px;
}

.game_guide_layout #content .page_header_area + .inner > *:first-child {
  margin-top: 0;
}

.game_guide_layout #content .page_header_area .inner {
  padding-bottom: 40px;
  border-bottom: 2px solid #384859;
}

.game_guide_layout #content .page_header_area h2 {
  font-weight: 400;
  font-size: 40px;
  line-height: 36px;
}

.game_guide_layout #content .page_header_area .breadcrumb {
  position: absolute;
  bottom: 17px;
  right: 0;
}

.game_guide_layout #content .page_header_area > .clearfix > *:first-child, .game_guide_layout #content .page_header_area > .attach_box > *:first-child, .game_guide_layout #content .service_page .page_header_area > .sl_server > *:first-child, .service_page .game_guide_layout #content .page_header_area > .sl_server > *:first-child, .game_guide_layout #content .service_page .page_header_area > .sl_char > *:first-child, .service_page .game_guide_layout #content .page_header_area > .sl_char > *:first-child {
  float: left;
  margin-top: 8px;
}

.game_guide_layout #content .page_header_area > .clearfix > *:last-of-type:last-child, .game_guide_layout #content .page_header_area > .attach_box > *:last-of-type:last-child, .game_guide_layout #content .service_page .page_header_area > .sl_server > *:last-of-type:last-child, .service_page .game_guide_layout #content .page_header_area > .sl_server > *:last-of-type:last-child, .game_guide_layout #content .service_page .page_header_area > .sl_char > *:last-of-type:last-child, .service_page .game_guide_layout #content .page_header_area > .sl_char > *:last-of-type:last-child {
  float: right;
}

.game_guide_layout #content .comment_box {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid #e5e5e5;
}

.game_guide_layout .sc_lst {
  padding-left: 0;
  font-size: 0;
}

.game_guide_layout .sc_lst li {
  display: inline-block;
  position: relative;
}

.game_guide_layout .sc_lst li a {
  display: block;
}

.game_guide_layout .sc_lst li a .sc_img_bx {
  width: 235px;
  height: 202px;
  background-color: #f4f4f4;
}

.game_guide_layout .sc_lst li a img {
  display: block;
  width: 100%;
  height: 100%;
}

.game_guide_layout .sc_lst li a .sc_tit {
  display: block;
  position: absolute;
  bottom: 25px;
  left: 50%;
  font-size: 16px;
  line-height: 15px;
  color: #fafafa;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}

.game_guide_layout .sc_lst li:first-child ~ li {
  margin-left: 20px;
}

.game_guide_layout .search_area {
  margin-top: 60px;
  margin-bottom: 0;
  padding: 30px 50px;
  background: #f8f8f8;
}

.game_guide_layout .search_area .inp_grp {
  float: right;
}

.game_guide_layout .search_area .select {
  width: 140px;
  margin-right: -5px;
  background: #ffffff;
  vertical-align: middle;
  color: #101010;
}

.game_guide_layout .search_area .search_inp {
  overflow: hidden;
  margin-left: 0;
  width: 656px;
  vertical-align: middle;
}

.game_guide_layout .search_area .search_inp input {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.game_guide_layout .search_area .search_inp button {
  background: #ebebeb;
}

.game_guide_layout .search_area .search_lbl {
  display: inline-block;
  float: left;
  font-size: 18px;
  color: #222222;
  line-height: 50px;
  vertical-align: middle;
}

.game_guide_layout .search_area + .tbl_lst {
  margin-top: 30px;
}

.game_guide_layout .category_bx {
  display: block;
  float: left;
  width: 320px;
  margin-top: 30px;
  margin-right: 20px;
  border-bottom: 1px solid #a4a4a4;
  border-top: 2px solid #a4a4a4;
}

.game_guide_layout .category_bx:nth-child(3n) {
  margin-right: 0;
}

.game_guide_layout .category_bx .cate_tit {
  display: block;
  height: 60px;
  border-bottom: 1px solid #dfdfdf;
  background: #f8f8f8;
  font-size: 16px;
  font-weight: 400;
  line-height: 60px;
  color: #222222;
  text-align: center;
}

.game_guide_layout .category_bx .cate_lst {
  display: block;
  height: 400px;
  width: 100%;
  padding: 32px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
}

.game_guide_layout .category_bx .cate_lst.type2 {
  height: 247px;
}

.game_guide_layout .category_bx .cate_lst.type2 li {
  width: 100%;
}

.game_guide_layout .category_bx .cate_lst li {
  display: inline-block;
  width: 50%;
  margin: 4px 0 3px;
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  vertical-align: bottom;
}

.game_guide_layout .category_bx .cate_lst li a {
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: 15px;
  color: #222222;
}

.game_guide_layout .category_bx .cate_lst li a:hover {
  font-weight: bold;
  color: #404dab;
}

.game_guide_layout .category_bx .cate_lst li.new a:after {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
  vertical-align: top;
  content: '';
}

.game_guide_layout .filter_lst {
  background: #f2f2f2;
}

.game_guide_layout .filter_lst > li {
  height: 55px;
  border-top: 1px solid #ffffff;
}

.game_guide_layout .filter_lst .filter_name {
  display: inline-block;
  width: 164px;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 55px;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.game_guide_layout .filter_lst .filter_val_lst {
  display: inline-block;
  margin-left: -4px;
  vertical-align: middle;
}

.game_guide_layout .filter_lst .filter_val_lst li {
  display: inline-block;
}

.game_guide_layout .filter_lst .filter_val_lst li ~ li {
  margin-left: 15px;
}

.game_guide_layout .filter_lst .filter_val_lst li ~ li:before {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #c8c8ce;
  margin-right: 15px;
  content: '';
}

.game_guide_layout .filter_lst .filter_val_lst li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  color: #222222;
  vertical-align: text-bottom;
}

.game_guide_layout .filter_lst + .itm_search {
  margin-top: 50px;
}

.game_guide_layout .itm_search .inp_grp {
  float: left;
}

.game_guide_layout .itm_search .inp_grp .select {
  width: 130px;
}

.game_guide_layout .itm_search .inp_grp .search_inp {
  margin-left: -5px;
}

.game_guide_layout .itm_search .inp_grp .search_inp input {
  width: 469px;
}

.game_guide_layout .itm_search .inp_grp .search_inp input::placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.game_guide_layout .itm_search .inp_grp .search_inp input:-ms-input-placeholder {
  color: #a4a4a4;
}

.game_guide_layout .itm_search .inp_grp .search_inp input::-ms-input-placeholder {
  color: #a4a4a4;
}

.game_guide_layout .itm_search + .tbl_lst {
  margin-top: 19px;
}

.game_guide_layout .rating_filter {
  float: right;
  width: 130px;
}

.game_guide_layout .menu_depth_2 li {
  display: inline-block;
  width: 113px;
  height: 50px;
  border: 1px solid #ebebeb;
  background: #ffffff;
  box-sizing: border-box;
}

.game_guide_layout .menu_depth_2 li a {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  color: #a4a4a4;
  text-align: center;
}

.game_guide_layout .menu_depth_2 li.on, .game_guide_layout .menu_depth_2 li:hover {
  border: none;
  background: #384859;
}

.game_guide_layout .menu_depth_2 li.on a, .game_guide_layout .menu_depth_2 li:hover a {
  line-height: 50px;
  color: #ffffff;
}

.game_guide_layout .menu_depth_2 li ~ li {
  margin-left: 9px;
}

.game_guide_layout .tabs_lnk_lst {
  margin-top: 47px;
}

.game_guide_layout .tabs_lnk_lst + .editor_area {
  margin-top: 75px;
}

.game_guide_layout .change_log {
  margin-top: 23px;
  border-top: 2px solid #a4a4a4;
}

.game_guide_layout .change_log li {
  padding: 60px 0 53px;
}

.game_guide_layout .change_log li:last-child {
  padding-bottom: 0;
}

.game_guide_layout .change_log .type {
  display: inline-block;
}

.game_guide_layout .change_log .type_box {
  display: table-cell;
  padding: 0 12px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
}

.game_guide_layout .change_log .type_box em {
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
}

.game_guide_layout .change_log .type_box p {
  margin-top: 7px;
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
  line-height: 19px;
  opacity: .6;
}

.game_guide_layout .change_log .change_detail {
  display: inline-block;
  width: 680px;
  margin-left: 78px;
  vertical-align: top;
}

.game_guide_layout .change_log .change_detail .gold_lbl {
  margin-top: 0;
}

.game_guide_layout .change_log .change_detail .green_lbl {
  margin-top: 23px;
}

.game_guide_layout .change_log .change_detail p {
  font-size: 16px;
}

.game_guide_layout .history_lst {
  margin-top: 23px;
  border-top: 2px solid #a4a4a4;
  padding-top: 84px;
}

.game_guide_layout .history_lst > li {
  position: relative;
  color: #868686;
}

.game_guide_layout .history_lst > li:before {
  position: absolute;
  top: 0;
  left: 444px;
  content: '';
}

.game_guide_layout .history_lst > li:after {
  position: absolute;
  top: 0;
  left: 450px;
  width: 1px;
  height: 100%;
  background: #384859;
  content: '';
}

.game_guide_layout .history_lst > li:last-child:after {
  display: none;
}

.game_guide_layout .history_lst > li:last-child .period_detail, .game_guide_layout .history_lst > li:last-child .period_name {
  padding-bottom: 0;
}

.game_guide_layout .history_lst .period_name {
  display: inline-block;
  width: 451px;
  padding-bottom: 75px;
  vertical-align: top;
}

.game_guide_layout .history_lst .period_name h5 {
  position: absolute;
  left: 0;
  bottom: 100%;
  margin-bottom: 13px;
}

.game_guide_layout .history_lst .period_name img {
  margin-top: 0;
}

.game_guide_layout .history_lst .period_name ul {
  margin-top: 7px;
  font-size: 15px;
}

.game_guide_layout .history_lst .period_name ul > li {
  position: relative;
  padding-left: 11px;
  font-weight: 300;
}

.game_guide_layout .history_lst .period_name ul > li:before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 3px;
  height: 3px;
  background: #868686;
  content: '';
}

.game_guide_layout .history_lst .period_detail {
  display: inline-block;
  width: 545px;
  padding-bottom: 69px;
  padding-left: 49px;
  margin-left: -4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}

.game_guide_layout .history_lst .period_detail .num, .game_guide_layout .history_lst .period_detail .tit {
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  color: #404dab;
  line-height: 19px;
  vertical-align: top;
}

.game_guide_layout .history_lst .period_detail .tit {
  margin-left: 8px;
}

.game_guide_layout .history_lst .period_detail .tit:before {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #c8c8cf;
  margin-right: 8px;
  content: '';
}

.game_guide_layout .history_lst .period_detail p {
  margin-top: 7px;
  color: #222;
  line-height: 27px;
  letter-spacing: -0.45px;
}

.download_page .download_faq li > span {
  height: 72px !important;
}

.download_page .download_faq .qa_lbl {
  display: inline-block;
  margin-right: 26px;
  font-family: 'notosans',sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 23px;
  vertical-align: top;
  color: #404dab;
}

.download_page .download_faq .btn {
  color: #868686 !important;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.download_page .download_faq .btn:hover {
  text-decoration: none;
}

.download_page .download_lst {
  margin-top: 80px;
}

.download_page .download_lst li {
  display: inline-block;
}

.download_page .download_lst li ~ li {
  margin-left: 36px;
}

.download_page .download_lst .download_btn {
  display: block;
  width: 685px;
  height: 167px;
  padding-top: 52px;
  background: url("../img/btn_down_bg.jpg") no-repeat;
  color: #ffffff;
  text-align: center;
  box-sizing: border-box;
}

.download_page .download_lst .download_btn .ico_download_l {
  display: inline-block;
  margin-top: 8px;
  margin-right: 10px;
  vertical-align: top;
}

.download_page .download_lst .download_btn .txt {
  display: block;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.8px;
}

.download_page .download_lst .download_btn .sub_txt {
  display: block;
  font-size: 15px;
  font-weight: 300;
  opacity: .3;
}

.download_page .download_lst .download_btn .notice {
  display: none;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.download_page .download_lst .download_btn:hover {
  padding-top: 70px;
  background: url("../img/btn_down_hover.jpg") no-repeat;
}

.download_page .download_lst .download_btn:hover .txt, .download_page .download_lst .download_btn:hover .sub_txt {
  display: none;
}

.download_page .download_lst .download_btn:hover .notice {
  display: block;
}

.download_page .download_lst .dot_lst {
  margin-top: 7px;
  font-size: 14px;
  letter-spacing: -0.2px;
}

.download_page .download_lst .dot_lst li:before {
  top: 9px;
}

.download_page .download_lst + .dot_lst {
  margin-top: 21px;
}

.download_page .download_lst + .dot_lst .btn_sm {
  height: 26px;
  font-weight: 300;
  font-size: 13px;
  line-height: 26px;
  color: #868686;
}

.download_page .download_lst + .dot_lst .ico_sm_cog {
  display: inline-block;
  margin-top: 5px;
  vertical-align: top;
}

.download_page .download_lst + .dot_lst li ~ li {
  margin-top: 8px;
}

.download_page .requirement_area {
  margin-top: 78px;
}

.download_page .requirement_area .tbl_lst {
  margin-top: 30px;
}

.download_page .driver_down_area {
  margin-top: 80px;
}

.download_page .driver_down_area .area_tit {
  float: left;
}

.download_page .driver_down_area .note_txt {
  float: right;
  margin-top: 15px;
}

.download_page .driver_down_area .driver_lst {
  margin-top: 29px;
}

.download_page .driver_down_area .driver_lst li {
  display: inline-block;
  width: 450px;
  height: 272px;
  padding: 36px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  text-align: center;
}

.download_page .driver_down_area .driver_lst li .logo_box {
  display: block;
  height: 77px;
  width: 100%;
}

.download_page .driver_down_area .driver_lst li .logo_box .amd_logo {
  margin-top: 15px;
}

.download_page .driver_down_area .driver_lst li .d_name {
  display: block;
  margin-top: 18px;
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 17px;
  letter-spacing: -0.7px;
}

.download_page .driver_down_area .driver_lst li .btn {
  width: 160px;
  height: 50px;
  margin-top: 35px;
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
}

.download_page .driver_down_area .driver_lst li .btn .ico_download_s {
  display: inline-block;
  margin-left: 8px;
}

.download_page .driver_down_area .driver_lst li ~ li {
  margin-left: 26px;
}

.download_page .ost_lst {
  padding-top: 40px;
  border-top: 2px solid #384859;
}

.download_page .ost_lst li {
  display: inline-block;
  position: relative;
}

.download_page .ost_lst li:not(:nth-child(3n+1)) {
  margin-left: 26px;
}

.download_page .ost_lst li:nth-last-child(n+4) {
  margin-bottom: 30px;
}

.download_page .ost_lst li .ost_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.download_page .ost_lst li .ost_bg img {
  display: block;
}

.download_page .ost_lst li .ost_detail {
  display: table-cell;
  position: relative;
  width: 450px;
  height: 400px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}

.download_page .ost_lst li .ost_name {
  display: block;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: -1px;
}

.download_page .ost_lst li .ost_desc {
  display: none;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.5;
}

.download_page .ost_lst li .btn_ost_download {
  display: none;
  width: 160px;
  height: 50px;
  margin: 42px auto 0;
  background: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 50px;
  color: #222222;
}

.download_page .ost_lst li .btn_ost_download .ico_download_ost {
  display: inline-block;
  margin-left: 11px;
}

.download_page .ost_lst li:hover .ost_desc, .download_page .ost_lst li:hover .btn_ost_download {
  display: block;
}

.download_page .ost_lst li:hover .ost_bg:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.7;
  content: '';
}

.download_page .ost_lst li:hover .ost_detail {
  padding-top: 90px;
  vertical-align: top;
}

.support_page .view_area {
  margin-top: 45px;
}

.support_page .view_area .ct {
  padding-bottom: 38px;
}

.inquiry_area .menu_depth_3 {
  margin-top: 39px;
}

.inquiry_area .menu_depth_3 .tab_itm a {
  min-width: 238px;
}

.inquiry_area .menu_depth_3 .tab_itm + .tab_itm {
  margin-left: 16px;
}

.inquiry_area .txt_note {
  margin-top: 26px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
  letter-spacing: -0.02em;
}

.inquiry_area .txt_note + .tbl_lst {
  margin-top: 27px;
}

.search_area {
  margin: 80px 0;
  padding: 54px 68px 32px;
  background-color: #f8f8f8;
}

.search_area .tit {
  display: inline-block;
  font-size: 28px;
  line-height: 57px;
  letter-spacing: -0.01em;
  vertical-align: top;
}

.search_area .search_box {
  display: inline-block;
  margin-left: 62px;
}

.search_area .search_inp input {
  width: 910px;
  font-size: 14px;
}

.search_area .search_inp input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  font-weight: 300;
  letter-spacing: -0.02em;
  opacity: 1;
  /* Firefox */
}

.search_area .search_inp input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a4a4a4;
  font-weight: 300;
  letter-spacing: -0.02em;
}

.search_area .search_inp input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a4a4a4;
  font-weight: 300;
  letter-spacing: -0.02em;
}

.search_area .filter_lst {
  margin-top: 7px;
}

.search_area .filter_lst li {
  display: inline-block;
  position: relative;
  margin-left: 26px;
}

.search_area .filter_lst li:before {
  position: absolute;
  top: 5px;
  left: -14px;
  width: 1px;
  height: 12px;
  background-color: #c5c5cc;
  content: '';
}

.search_area .filter_lst li:first-child {
  margin-left: 0;
}

.search_area .filter_lst li:first-child:before {
  content: none;
}

.search_area .filter_lst li a {
  font-size: 14px;
  font-weight: 300;
  color: #868686;
}

.search_area .btn {
  width: 162px;
  height: 50px;
  margin-left: 27px;
  line-height: 50px;
  vertical-align: top;
}

.board_area .tabs_server_lst {
  float: none;
}

.board_area .tabs_server_lst + .tbl_lst {
  margin-top: 28px;
}

.board_area .tabs_server_lst ~ .pagination {
  margin-top: 87px;
}

.report_lst li {
  width: 100%;
  margin-top: 14px;
}

.report_lst li:first-child {
  margin-top: 0;
}

.report_lst .report_itm {
  display: table;
  width: 100%;
  height: 133px;
  background-color: #f2f2f2;
}

.report_lst .report_itm > div {
  display: table-cell;
  vertical-align: middle;
}

.report_lst .report_itm .ico_box {
  width: 160px;
  padding-left: 20px;
  text-align: center;
  box-sizing: border-box;
}

.report_lst .report_itm .ico_box .ico {
  display: inline-block;
  vertical-align: middle;
}

.report_lst .report_itm .tit_box {
  width: 260px;
}

.report_lst .report_itm .tit_box .tit {
  font-family: 'notosans', sans-serif;
  font-size: 28px;
  font-weight: 500;
  vertical-align: middle;
  letter-spacing: -0.05em;
}

.report_lst .report_itm .content_box {
  width: 718px;
  padding-left: 13px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.025em;
  box-sizing: border-box;
}

.report_lst .report_itm .content_box .desc {
  vertical-align: middle;
  color: #222;
}

.report_lst .report_itm .content_box .note {
  color: #a4a4a4;
}

.report_lst .report_itm .btn_box {
  text-align: center;
}

.report_lst .report_itm .btn_box .btn {
  width: 130px;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  letter-spacing: -0.02em;
}

.report_area .area_tit {
  line-height: 48px;
}

.report_area .report_lst {
  margin-top: 16px;
}

.report_area ~ .report_area {
  margin-top: 41px;
}

.card_itm {
  display: block;
  height: 213px;
  padding-top: 42px;
  background-color: #fff;
  font-size: 0;
  text-align: center;
  box-sizing: border-box;
}

.card_itm .ico {
  display: block;
  margin: 0 auto;
}

.card_itm .info {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}

.card_itm .info .tit {
  display: block;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #101010;
}

.card_itm .info .desc {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #222;
}

.card_lst {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-top: 25px;
  padding: 45px 19px 35px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.card_lst li {
  display: table-cell;
  padding: 0 19px;
  vertical-align: middle;
}

.support_fm {
  margin-top: 25px;
  font-size: 16px;
}

.support_fm .tbl_lst {
  border-top: none;
}

.support_fm th, .support_fm td {
  height: auto;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  vertical-align: top;
}

.support_fm th > div, .support_fm td > div {
  display: table-cell;
  min-height: 70px;
  vertical-align: middle;
}

.support_fm th > div {
  display: block;
  padding-left: 30px;
  line-height: 70px;
  text-align: left;
}

.support_fm td {
  padding-left: 30px;
  text-align: left;
  vertical-align: middle;
}

.support_fm td > div {
  width: 795px;
}

.support_fm input[type="text"] {
  width: 100%;
  height: 36px;
  border: none;
  font-size: 14px;
  color: #222222;
  outline: none;
}

.support_fm input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  font-weight: 300;
  opacity: 1;
  /* Firefox */
}

.support_fm input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a4a4a4;
  font-weight: 300;
}

.support_fm input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a4a4a4;
  font-weight: 300;
}

.support_fm input[type="text"].phone {
  position: relative;
  width: 35px;
  margin: 0;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

.support_fm input[type="text"].phone.num2 {
  width: 45px;
}

.support_fm .dash {
  font-size: 18px;
  font-weight: 300;
  padding: 0 12px;
}

.support_fm .dot_lst {
  margin-top: 24px;
}

.support_fm .dot_lst .dash_lst {
  margin-left: -4px;
}

.datepicker_area .tui-datepicker {
  top: 53px;
  left: -13px;
  z-index: 1;
  border: 1px solid #ebebeb;
}

.datepicker_area .tui-datepicker:before {
  position: absolute;
  top: -7px;
  left: 50%;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -678px;
  width: 12px;
  height: 7px;
  transform: translateX(-50%);
  content: '';
}

.datepicker_area .tui-datepicker.tui-rangepicker .tui-is-selectable.tui-is-selected {
  background-color: #404dab;
}

.datepicker_area .tui-datepicker-input {
  height: 46px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
}

.datepicker_area .tui-datepicker-input input {
  padding-left: 13px;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.02em;
}

.datepicker_area .tui-datepicker-input + .select {
  width: 100px;
  height: 46px;
  margin-left: 10px;
}

.datepicker_area .tui-datepicker-body {
  width: 320px;
}

.datepicker_area .tui-calendar {
  width: 100%;
}

.datepicker_area .tui-calendar tr {
  border-top: none;
}

.datepicker_area .tui-calendar th {
  background-color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 0;
}

.datepicker_area .tui-calendar td {
  text-align: center;
}

.datepicker_area .tui-calendar-header {
  border-bottom: 0;
}

.datepicker_area .tui-calendar-header-inner {
  height: 60px;
}

.datepicker_area .tui-calendar-header-info {
  display: none;
}

.datepicker_area .tui-calendar-title {
  line-height: 28px;
}

.datepicker_area .tui-calendar-btn {
  width: 66px;
  height: 60px;
}

.datepicker_area .tui-calendar-body {
  width: 260px;
  margin: 0 auto;
}

.datepicker_area .tui-calendar-body-inner td {
  height: 38px;
  border-top: 0;
  color: #222;
}

.datepicker_area .tui-calendar-body-inner td.tui-is-blocked {
  color: #a4a4a4;
}

.datepicker_area .tui-calendar-body-header th {
  height: 37px;
  border-bottom: 1px solid #e5e5e5;
  color: #222;
}

.datepicker_area .select button {
  padding-left: 15px;
}

.datepicker_area .ico_calendar {
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 11px;
}

.datepicker_area .txt {
  display: inline-block;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 300;
  line-height: 44px;
  color: #222;
  vertical-align: top;
}

.my_page.completed_layout .wrap {
  padding-top: 111px;
}

.my_page.completed_layout .content > .inner {
  padding-bottom: 189px;
}

.my_page .user_info_area {
  padding-top: 49px;
  border-top: 2px solid #384859;
}

.my_page .user_tbl_wrap {
  overflow: hidden;
  border: 1px solid #a4a4a4;
}

.my_page .user_info {
  width: 1410px;
  margin: -1px;
  border-top: none;
}

.my_page .user_info .greeting {
  height: 212px;
  padding: 0 60px;
  border: 1px solid #a4a4a4;
  border-right: 1px solid #ebebeb;
  font-size: 26px;
  font-weight: 300;
  line-height: 24px;
  color: #222222;
  text-align: left;
}

.my_page .user_info .greeting strong {
  display: block;
  margin-top: 13px;
  font-size: 32px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.7px;
}

.my_page .user_info th, .my_page .user_info td {
  border: 1px solid #ebebeb;
  text-align: center;
}

.my_page .user_info th:last-child, .my_page .user_info td:last-child {
  border-right: 1px solid #a4a4a4;
}

.my_page .user_info th {
  height: 62px;
  border-top: 1px solid #a4a4a4;
  border-left: none;
}

.my_page .user_info td {
  border-left: none;
  padding: 27px 0 33px;
  border-bottom: 1px solid #a4a4a4;
}

.my_page .user_info td strong {
  display: block;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
}

.my_page .user_info td strong [class^="ico_"] {
  display: inline-block;
  margin-right: 8px;
  margin-top: 7px;
  vertical-align: top;
}

.my_page .user_info td a {
  display: inline-block;
  margin-top: 25px;
  font-size: 14px;
  color: #868686;
}

.my_page .user_info td a .ico_quest {
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
}

.my_page .user_info td a .ico_quest + span {
  position: relative;
}

.my_page .user_info td a .ico_quest + span:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #868686;
  content: '';
}

.my_page .user_info td a.btn {
  width: 130px;
  height: 42px;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 42px;
  color: #ffffff;
}

.my_page .user_info td a:hover {
  text-decoration: none;
}

.my_page .user_info_area + .tab_lst {
  margin-top: 80px;
}

.my_page .write_area, .my_page .acc_sanctions_area {
  margin-top: 39px;
}

.my_page .write_area .clearfix .area_tit, .my_page .write_area .attach_box .area_tit, .my_page .write_area .service_page .sl_server .area_tit, .service_page .my_page .write_area .sl_server .area_tit, .my_page .write_area .service_page .sl_char .area_tit, .service_page .my_page .write_area .sl_char .area_tit, .my_page .acc_sanctions_area .clearfix .area_tit, .my_page .acc_sanctions_area .attach_box .area_tit, .my_page .acc_sanctions_area .service_page .sl_server .area_tit, .service_page .my_page .acc_sanctions_area .sl_server .area_tit, .my_page .acc_sanctions_area .service_page .sl_char .area_tit, .service_page .my_page .acc_sanctions_area .sl_char .area_tit {
  float: left;
  margin-bottom: 0;
  line-height: 48px;
}

.my_page .write_area .clearfix .note_txt, .my_page .write_area .attach_box .note_txt, .my_page .write_area .service_page .sl_server .note_txt, .service_page .my_page .write_area .sl_server .note_txt, .my_page .write_area .service_page .sl_char .note_txt, .service_page .my_page .write_area .sl_char .note_txt, .my_page .acc_sanctions_area .clearfix .note_txt, .my_page .acc_sanctions_area .attach_box .note_txt, .my_page .acc_sanctions_area .service_page .sl_server .note_txt, .service_page .my_page .acc_sanctions_area .sl_server .note_txt, .my_page .acc_sanctions_area .service_page .sl_char .note_txt, .service_page .my_page .acc_sanctions_area .sl_char .note_txt {
  float: right;
}

.my_page .write_area .clearfix .note_txt .btn, .my_page .write_area .attach_box .note_txt .btn, .my_page .write_area .service_page .sl_server .note_txt .btn, .service_page .my_page .write_area .sl_server .note_txt .btn, .my_page .write_area .service_page .sl_char .note_txt .btn, .service_page .my_page .write_area .sl_char .note_txt .btn, .my_page .acc_sanctions_area .clearfix .note_txt .btn, .my_page .acc_sanctions_area .attach_box .note_txt .btn, .my_page .acc_sanctions_area .service_page .sl_server .note_txt .btn, .service_page .my_page .acc_sanctions_area .sl_server .note_txt .btn, .my_page .acc_sanctions_area .service_page .sl_char .note_txt .btn, .service_page .my_page .acc_sanctions_area .sl_char .note_txt .btn {
  width: 67px;
  height: 28px;
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
}

.my_page .write_area .tbl_lst, .my_page .acc_sanctions_area .tbl_lst {
  margin-top: 19px;
}

.my_page .write_area .clearfix .note_txt, .my_page .write_area .attach_box .note_txt, .my_page .write_area .service_page .sl_server .note_txt, .service_page .my_page .write_area .sl_server .note_txt, .my_page .write_area .service_page .sl_char .note_txt, .service_page .my_page .write_area .sl_char .note_txt {
  margin-top: 18px;
}

.my_page .write_area .tbl_lst {
  border-top: 1px solid #a4a4a4;
  border-bottom: 1px solid #a4a4a4;
}

.my_page .write_area .tbl_lst tr:first-child th, .my_page .write_area .tbl_lst tr:first-child td {
  border-top: none;
}

.my_page .write_area .tbl_lst th {
  height: auto;
  padding: 26px 0 0 31px;
  border-top: 1px solid #e5e5e5;
  font-size: 15px;
  vertical-align: top;
}

.my_page .write_area .tbl_lst td {
  height: 46px;
  padding: 14px 0;
}

.my_page .write_area .tbl_lst td span + .btn {
  margin-left: 35px;
}

.my_page .write_area .tbl_lst td input + .btn {
  margin-left: 20px;
}

.my_page .write_area .tbl_lst .dot_lst li:before {
  top: 9px;
}

.my_page .write_area .tbl_lst .dot_lst li ~ li {
  margin-top: 3px;
}

.my_page .write_area .tbl_lst .valign_top th {
  padding-top: 25px;
  vertical-align: top;
}

.my_page .write_area .tbl_lst .valign_top td {
  padding-top: 14px;
  padding-bottom: 16px;
}

.my_page .write_area .tbl_lst .chkbox.email {
  margin-top: 15px;
}

.my_page .write_area .tbl_lst.tbl_private tr:last-child td {
  font-size: 0;
}

.my_page .write_area .tbl_lst.tbl_private tr:last-child .btn {
  margin-left: 50px;
}

.my_page .write_area .tbl_lst.tbl_private .chkbox.sms {
  margin-left: 30px;
}

.my_page .write_area .cf_ct {
  margin-top: 20px;
  padding-top: 24px;
  border-top: 1px solid #a4a4a4;
  letter-spacing: -0.5px;
}

.my_page .write_area .cf_ct .cf_txt {
  font-size: 20px;
  font-weight: 500;
  color: #101010;
}

.my_page .write_area .cf_ct .cf_txt .username {
  color: #404dab;
}

.my_page .write_area .cf_ct .caution_txt {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #101010;
}

.my_page .write_area .cf_ct .dot_lst {
  margin-top: 11px;
  margin-bottom: -3px;
}

.my_page .write_area .cf_ct .dot_lst li ~ li {
  margin-top: 11px;
}

.my_page .result_bx .txt {
  margin-top: 0;
}

.my_page .precaution_done {
  width: 971px;
  margin: 52px auto 0;
}

.my_page .precaution_done th {
  font-size: 18px;
  font-weight: 500;
}

.my_page .precaution_done td {
  padding: 23px 36px 25px;
  text-align: left;
}

.my_page .precaution_done .dot_lst li ~ li {
  margin-top: 11px;
}

.my_page .precaution_done + .btn_wrap {
  margin-top: 40px;
}

.my_page .acc_sanctions_area .clearfix .note_txt, .my_page .acc_sanctions_area .attach_box .note_txt, .my_page .acc_sanctions_area .service_page .sl_server .note_txt, .service_page .my_page .acc_sanctions_area .sl_server .note_txt, .my_page .acc_sanctions_area .service_page .sl_char .note_txt, .service_page .my_page .acc_sanctions_area .sl_char .note_txt {
  margin-top: 26px;
}

.my_page .tbl_lst .nodata {
  height: 480px;
}

.my_page .tbl_lst .nodata .ico {
  display: block;
  margin: auto;
}

.my_page .tbl_lst .nodata .txt_msg {
  display: block;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #868686;
}

.my_page .tabs_lnk_lst {
  margin-top: 40px;
  font-size: 0;
}

.my_page .tabs_lnk_lst li {
  margin-right: 14px;
}

.my_page .tabs_lnk_lst li ~ li:before {
  margin-right: 13px;
}

.my_page .tabs_lnk_lst li.on a, .my_page .tabs_lnk_lst a:hover {
  color: #404dab;
}

.my_page .tabs_lnk_lst + .editor_area h5:first-child {
  margin-top: 76px;
}

.my_page .tabs_lnk_lst + .editor_area h5:first-child + .tbl_lst {
  margin-top: 20px;
}

.login_area,
.find_area {
  border-top: 2px solid #384859;
}

.login_area .tbl_lst,
.find_area .tbl_lst {
  border-top: 1px solid #a4a4a4;
}

.login_area .btn_wrap,
.find_area .btn_wrap {
  text-align: center;
}

.login_area .btn_wrap .btn,
.find_area .btn_wrap .btn {
  margin-left: 16px;
  vertical-align: top;
}

.login_area .btn_wrap .btn:first-child,
.find_area .btn_wrap .btn:first-child {
  margin-left: 0;
}

.login_area .txt_note {
  margin-top: 45px;
  font-weight: 300;
  color: #222;
  letter-spacing: -0.005em;
}

.login_area .txt_note + .tbl_lst {
  margin-top: 16px;
}

.login_area .lst_tit {
  display: block;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #101010;
}

.login_area .dot_lst {
  margin-top: 11px;
}

.login_area .dot_lst li {
  margin-top: 11px;
  color: #868686;
  letter-spacing: -0.025em;
}

.login_area .dot_lst li:before {
  background-color: #868686;
}

.login_area .btn_wrap {
  margin-top: 56px;
}

.find_area .txt_note {
  margin-top: 37px;
  font-weight: 300;
  color: #222;
  letter-spacing: -0.024em;
}

.find_area .txt_note + .tbl_lst {
  margin-top: 26px;
}

.find_area .dot_lst {
  margin-top: 37px;
}

.find_area .dot_lst li {
  margin-top: 10px;
  letter-spacing: -0.02em;
}

.find_area .dot_lst li:first-child {
  margin-top: 0;
}

.find_area .dot_lst + .tbl_lst {
  margin-top: 25px;
}

.find_area .dot_lst + .tbl_lst.txt_left {
  margin-top: 45px;
}

.find_area .tbl_lst .select {
  width: 100px;
  height: 46px;
}

.find_area .tbl_lst .select.year {
  width: 172px;
}

.find_area .tbl_lst .select + .select {
  margin-left: 6px;
}

.find_area .tbl_lst.txt_left tbody th {
  font-size: 14px;
  padding: 26px 0 0 30px;
  vertical-align: top;
}

.find_area .tbl_lst.txt_left tbody td {
  height: auto;
  padding: 14px 0;
  padding-left: 0;
}

.find_area .tbl_lst .nodata {
  height: 499px;
}

.find_area .tbl_lst .nodata .ico {
  margin-top: 4px;
}

.find_area .tbl_lst .nodata .txt_msg {
  margin-top: 16px;
  font-size: 16px;
}

.find_area .btn_wrap {
  margin-top: 60px;
}

.find_area .result_bx .txt {
  margin-top: 2px;
}

.find_area .result_bx .btn_wrap {
  margin-top: 56px;
}

.account_area {
  border-top: 2px solid #384859;
}

.account_area .card_lst {
  margin-top: 28px;
  padding: 46px 23px;
}

.account_area .card_lst li {
  padding: 0 23px;
}

.account_area .card_lst + .dot_lst {
  margin-top: 24px;
}

.account_area .card_lst + .dot_lst li {
  padding-left: 12px;
  color: #868686;
  letter-spacing: -0.02em;
}

.account_area .card_lst + .dot_lst li:before {
  background-color: #868686;
}

.account_area .card_lst + .dot_lst li ~ li {
  margin-top: 9px;
}

.account_area .card_itm {
  height: 233px;
  padding-top: 42px;
}

.account_area .card_itm .ico_monitor_l {
  margin-top: 7px;
}

.account_area .card_itm .desc {
  margin-top: 8px;
  font-size: 16px;
}

.account_area .policy_bx {
  margin-top: 49px;
  padding: 36px 40px 33px;
  background-color: #f2f2f2;
}

.account_area .policy_bx .txt {
  display: block;
  margin: 6px 0 0 40px;
  font-size: 14px;
  font-weight: 300;
  color: #222;
}

.account_area .btn_wrap {
  margin-top: 60px;
  text-align: center;
}

.account_area .btn_wrap .btn {
  margin-left: 16px;
  vertical-align: top;
}

.account_area .btn_wrap .btn:first-child {
  margin-left: 0;
}

.account_area .txt_note {
  float: right;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  color: #222;
}

.account_area .result_bx + .tbl_lst {
  margin-top: 34px;
  border-top: 1px solid #a4a4a4;
}

.account_area .tbl_lst {
  margin-top: 29px;
  border-top: 2px solid #a4a4a4;
}

.account_area .tbl_lst.txt_left tbody th {
  font-size: 14px;
  padding: 26px 0 0 30px;
  vertical-align: top;
}

.account_area .tbl_lst.txt_left tbody td {
  height: auto;
  padding: 14px 0;
  padding-left: 0;
}

.account_area .tbl_lst .btn_certification {
  margin-left: 46px;
}

.account_area .tbl_lst .btn_certification + .sms {
  margin-left: 27px;
}

.account_area .tbl_lst .note {
  display: block;
  margin-top: 11px;
  font-size: 14px;
  letter-spacing: -0.01em;
}

.account_area .tbl_lst .note + .sms {
  margin: 7px 0 16px;
}

.account_area .tbl_lst .confirm .message {
  display: inline-block;
  margin-left: 10px;
  color: #a4a4a4;
  vertical-align: middle;
}

.account_area .tbl_lst .captcha_bx {
  margin: 15px 0 16px;
}

.account_area .tbl_lst .captcha_bx .img_captcha {
  display: block;
}

.account_area .tbl_lst .captcha_bx .inp_captcha {
  width: 510px;
  height: 58px;
  margin-top: 20px;
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 16px;
}

.account_area .tbl_lst .captcha_bx .inp_captcha::-webkit-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.account_area .tbl_lst .captcha_bx .inp_captcha:-ms-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.account_area .tbl_lst .captcha_bx .inp_captcha::placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.login_fm {
  width: 550px;
  margin: 96px auto 0;
  text-align: center;
}

.login_fm label {
  display: block;
  font-size: 14px;
  color: #222;
  text-align: left;
}

.login_fm .inp_bx {
  display: block;
  position: relative;
  margin-top: 9px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
}

.login_fm .inp_bx input {
  display: block;
  width: 100%;
  height: 58px;
  padding: 0 54px 0 19px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #222;
  box-sizing: border-box;
}

.login_fm .inp_bx input::-webkit-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.login_fm .inp_bx input:-ms-input-placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.login_fm .inp_bx input::placeholder {
  font-size: 14px;
  color: #a4a4a4;
}

.login_fm .inp_bx .ico {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.login_fm .inp_bx + label {
  margin-top: 16px;
}

.login_fm .btn_login {
  height: 60px;
  margin-top: 60px;
  font-size: 18px;
}

.login_fm .chkbox {
  margin-top: 20px;
}

.login_fm .chkbox label {
  font-size: 16px;
  color: #868686;
}

.login_fm .lnk_lst {
  margin-top: 70px;
  padding-top: 15px;
  border-top: 1px solid #e5e5e5;
}

.login_fm .lnk_lst li {
  display: inline-block;
  position: relative;
  margin-left: 15px;
  padding-left: 20px;
}

.login_fm .lnk_lst li:before {
  position: absolute;
  top: 6px;
  left: 0;
  width: 1px;
  height: 14px;
  background-color: #c8c8cf;
  content: '';
}

.login_fm .lnk_lst li:first-child {
  margin-left: 0;
  padding-left: 0;
}

.login_fm .lnk_lst li:first-child:before {
  content: none;
}

.login_fm .lnk_lst li a {
  font-weight: 300;
  color: #222;
  letter-spacing: -0.02em;
}

.login_fm .sign_up {
  margin-top: 69px;
  font-size: 16px;
  font-weight: 300;
  color: #868686;
  letter-spacing: -0.02em;
}

.login_fm .sign_up .btn {
  width: 90px;
  height: 34px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
}

.login_fm .error {
  display: block;
  margin-top: 7px;
  font-size: 14px;
  font-weight: 300;
  color: #b83d3d;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
}

.login_fm .captcha {
  margin-top: 16px;
}

.login_fm .captcha + .inp_bx {
  margin-top: 20px;
}

.login_fm .captcha ~ .btn {
  margin-top: 40px;
}

.registry_step_lst {
  margin: 80px 0 56px;
  text-align: center;
  font-size: 0;
}

.registry_step_lst li {
  display: inline-block;
  position: relative;
  width: 220px;
}

.registry_step_lst li:before {
  position: absolute;
  top: 29px;
  left: -71px;
  width: 142px;
  height: 1px;
  background-color: #ccc;
  content: '';
}

.registry_step_lst li:first-child {
  margin-left: 0;
}

.registry_step_lst li:first-child:before {
  content: none;
}

.registry_step_lst li.on .ico_step1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -857px -1078px;
  width: 58px;
  height: 58px;
}

.registry_step_lst li.on .ico_step2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -733px -1078px;
  width: 58px;
  height: 58px;
}

.registry_step_lst li.on .ico_step3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -547px -1078px;
  width: 58px;
  height: 58px;
}

.registry_step_lst li.on .ico_step4 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -981px -1078px;
  width: 58px;
  height: 58px;
}

.registry_step_lst li.on .txt {
  font-weight: 400;
  color: #222;
}

.registry_step_lst .ico {
  display: block;
  margin: 0 auto;
}

.registry_step_lst .txt {
  display: block;
  margin-top: 14px;
  font-size: 18px;
  font-weight: 300;
  color: #a4a4a4;
  letter-spacing: -0.02em;
}

.registry_step_lst + .result_bx {
  padding-top: 70px;
}

.registry_step_lst + .result_bx .txt {
  margin-top: 2px;
}

.chkbox.policy input:checked + label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -287px -1153px;
  width: 20px;
  height: 20px;
}

.chkbox.policy label {
  padding-left: 39px;
  font-size: 22px;
  font-weight: 500;
  color: #222;
  letter-spacing: -0.04em;
}

.chkbox.policy label:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -383px -1153px;
  width: 20px;
  height: 20px;
}

.chkbox.policy label.txt_blue {
  color: #404dab;
}

.chkbox.email {
  display: block;
  margin-top: 15px;
}

.chkbox.email + .dot_lst {
  margin: 4px 0 11px;
  font-size: 14px;
  color: #868686;
}

.chkbox.email + .dot_lst li {
  margin-top: 0;
}

.chkbox.email + .dot_lst li:before {
  background-color: #868686;
}

.accordion_lst {
  margin-top: 52px;
}

.accordion_lst .accordion_itm {
  margin-top: 52px;
  border-bottom: 1px solid #a4a4a4;
}

.accordion_lst .accordion_itm:first-child {
  margin-top: 0;
}

.accordion_lst .accordion_itm.open .accordion_header {
  border-bottom: 1px solid #a4a4a4;
}

.accordion_lst .accordion_itm.open .accordion_header:after {
  transform: rotate(0);
}

.accordion_lst .accordion_itm.open .accordion_content {
  display: block;
}

.accordion_header {
  position: relative;
  border-bottom: 0;
  cursor: pointer;
}

.accordion_header:after {
  position: absolute;
  top: 39px;
  right: 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -718px -462px;
  width: 21px;
  height: 11px;
  transform: rotate(180deg);
  content: '';
}

.accordion_header .policy {
  padding: 30px 0;
}

.accordion_header .policy label {
  padding-left: 31px;
  letter-spacing: -0.02em;
}

.accordion_content {
  display: none;
  margin: 40px 0;
  padding-left: 30px;
  color: #222;
}

.accordion_content .sb-scrollbar-container {
  right: 0;
}

.accordion_content .sb-content {
  max-height: 349px;
  padding-top: 0;
  padding-bottom: 0;
}

.accordion_content .tit {
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
}

.accordion_content .chapter_tit {
  display: block;
  margin-top: 20px;
  font-size: 22px;
}

.accordion_content .article_tit {
  display: block;
  margin-top: 24px;
}

.accordion_content .article_itm p {
  margin-top: 2px;
  line-height: 25px;
}

.accordion_content .article_itm ol {
  margin-top: 2px;
}

.accordion_content .article_itm li {
  line-height: 25px;
}

.accordion_content .article_menu_lst li {
  line-height: 1;
  margin-top: 8px;
}

.accordion_content .article_menu_lst li:first-child {
  margin-top: 0;
}

.accordion_content .article_menu_lst a {
  font-weight: 500;
  color: #222;
}

.accordion_content table {
  font-size: 16px;
}

.accordion_content table th,
.accordion_content table td {
  border: 1px solid #e5e5e5;
  font-weight: 400;
  text-align: center;
}

.accordion_content table th {
  height: 58px;
  background-color: #f8f8f8;
}

.accordion_content table td {
  height: 60px;
}

.accordion_content .lst_tit {
  display: block;
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: #101010;
}

.accordion_content .lst_tit + .dot_lst {
  margin-top: 16px;
}

.accordion_content .dot_lst {
  margin-top: 30px;
}

.accordion_content .dot_lst li {
  line-height: 1;
  color: #868686;
  letter-spacing: -0.024em;
}

.accordion_content .dot_lst li:before {
  top: 7px;
  background-color: #868686;
}

.accordion_content .dot_lst li ~ li {
  margin-top: 18px;
}

.etc_page .wrap {
  padding-top: 111px;
  padding-bottom: 90px;
}

.etc_page .content > .inner {
  padding-bottom: 100px;
}

.etc_page .txt_note {
  display: block;
  font-weight: 300;
  color: #222;
  letter-spacing: -0.025em;
  line-height: 27px;
}

.etc_page .txt_note + p.txt_note {
  margin-top: 7px;
}

.etc_page strong.txt_note {
  margin-top: 27px;
  font-weight: 500;
}

.etc_page .card_lst {
  margin-top: 33px;
  padding: 46px 23px;
}

.etc_page .card_lst li {
  padding: 0 23px;
}

.etc_page .card_itm {
  height: 233px;
  padding-top: 42px;
}

.etc_page .card_itm .ico_monitor_l {
  margin-top: 7px;
}

.etc_page .card_itm .desc {
  margin-top: 8px;
  font-size: 16px;
}

.etc_page .lst_tit {
  display: block;
  margin-top: 24px;
  font-size: 18px;
}

.etc_page .dot_lst {
  margin-top: 7px;
}

.etc_page .dot_lst li {
  padding-left: 13px;
  line-height: 35px;
  letter-spacing: -0.025em;
}

.etc_page .dot_lst li:before {
  top: 16px;
  left: 1px;
  background-color: #868686;
}

.etc_page .dot_lst li ~ li {
  margin-top: 0;
}

.etc_page .dot_lst.gray li {
  color: #868686;
}

.etc_page .dot_lst.gray li:before {
  background-color: #868686;
}

.etc_page .btn_wrap {
  margin-top: 30px;
  font-size: 0;
  text-align: center;
}

.etc_page .btn_wrap .btn {
  vertical-align: top;
}

.etc_page .result_bx .txt {
  margin-top: 4px;
}

.etc_page .result_bx .btn_wrap {
  margin-top: 36px;
}

.etc_page .result_bx .btn_wrap .btn ~ .btn {
  margin-left: 20px;
}

.etc_page .tab_lst {
  padding: 100px 0 75px;
}

.etc_page .support_bx {
  margin-top: 25px;
  padding: 32px 0 29px 47px;
  border: solid #a4a4a4;
  border-width: 1px 0;
}

.etc_page .support_bx p {
  color: #101010;
  letter-spacing: -0.025em;
}

.etc_page .support_bx p + .lst_tit {
  margin-top: 32px;
}

.etc_page .result_bx .tbl_lst {
  width: 970px;
  margin: 52px auto 0;
  border-bottom: 1px solid #e5e5e5;
}

.etc_page .result_bx .tbl_lst th,
.etc_page .result_bx .tbl_lst td {
  height: 70px;
}

.etc_page .result_bx .tbl_lst th {
  background-color: #f8f8f8;
  font-weight: 500;
}

.etc_page .result_bx .tbl_lst td.txt_left {
  padding-left: 31px;
}

.etc_page .result_bx .guide_download {
  width: 970px;
  margin: 51px auto 0;
  padding: 35px 197px 41px;
  background-color: #f8f8f8;
  box-sizing: border-box;
}

.etc_page .result_bx .guide_download .txt_guide {
  font-weight: 300;
  color: #222;
  line-height: 25px;
  letter-spacing: -0.02em;
}

.etc_page .result_bx .guide_download .download_info {
  margin-top: 27px;
  padding-top: 30px;
  border-top: 1px solid #cecece;
  font-size: 0;
}

.etc_page .result_bx .guide_download .browser_ico {
  display: inline-block;
  vertical-align: middle;
}

.etc_page .result_bx .guide_download .browser_name {
  margin-left: 10px;
  font-size: 24px;
  font-weight: 500;
  vertical-align: middle;
}

.etc_page .result_bx .guide_download .btn_download {
  width: 195px;
  margin-left: 20px;
  vertical-align: middle;
}

.etc_page .result_bx .guide_download .btn_download .ico_download_s {
  display: inline-block;
  margin-left: 8px;
}

.etc_page .result_bx .guide_download + .btn_wrap {
  margin-top: 40px;
}

.rest_area {
  padding-top: 94px;
}

.view_area .view_ct {
  border-top: 2px solid #384859;
  border-bottom: 1px solid #a4a4a4;
}

.view_area .view_ct .js_scrollbar {
  min-height: 599px;
  max-height: 599px;
  margin: 38px 0 50px;
  padding-left: 30px;
}

.view_area .view_ct .sb-scrollbar-container {
  right: 0;
}

.view_area .view_ct .sb-content {
  padding-top: 0;
  padding-bottom: 0;
}

.view_area .view_ct .tit {
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
}

.view_area .view_ct .chapter_tit {
  display: block;
  margin-top: 20px;
  font-size: 22px;
}

.view_area .view_ct .article_tit {
  display: block;
  margin-top: 24px;
}

.view_area .view_ct .article_itm p {
  margin-top: 2px;
  line-height: 25px;
}

.view_area .view_ct .article_itm ol {
  margin-top: 2px;
}

.view_area .view_ct .article_itm li {
  line-height: 25px;
}

.view_area .view_ct .article_menu_lst li {
  line-height: 1;
  margin-top: 8px;
}

.view_area .view_ct .article_menu_lst li:first-child {
  margin-top: 0;
}

.view_area .view_ct .article_menu_lst a {
  font-weight: 500;
  color: #222;
}

.view_area .view_ct + .btn_wrap {
  margin-top: 40px;
  text-align: right;
}

.view_area .view_ct + .btn_wrap .btn {
  background-color: #3f546b;
}

.view_area .view_ct table {
  margin: 10px 0;
  table-layout: fixed;
  border-collapse: collapse;
}

.view_area .view_ct table th, .view_area .view_ct table td {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #a4a4a4;
  text-align: center;
  vertical-align: middle;
}

.view_area .view_ct table td {
  font-weight: 300;
}

.authentication_area {
  margin-top: 48px;
}

.authentication_area .tit {
  font-size: 24px;
  letter-spacing: -0.04em;
}

.authentication_area .email_bx {
  margin-top: 24px;
  padding: 27px 0 27px 31px;
  border: solid #a4a4a4;
  border-width: 1px 0;
}

.authentication_area .email_bx .txt {
  font-size: 14px;
  color: #222;
  letter-spacing: -0.025em;
}

.authentication_area .email_bx .inp_mail {
  margin-left: 75px;
  width: 196px;
}

.authentication_area .email_bx .at_sign {
  font-family: 'barlow', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #101010;
}

.authentication_area .email_bx .domain {
  margin-left: 32px;
}

.authentication_area + .card_lst {
  margin-top: 30px;
}

.service_page .wrap {
  padding-top: 90px;
}

.service_page .breadcrumb .select_lst {
  width: 155px;
}

.service_page .inner .emp_txt {
  margin-top: 22px;
  font-size: 18px;
  letter-spacing: -0.3px;
  color: #222;
}

.service_page .inner .emp_txt .btn {
  margin-left: 30px;
}

.service_page .inner > .clearfix .menu_depth_3, .service_page .inner > .attach_box .menu_depth_3, .service_page .inner > .sl_server .menu_depth_3, .service_page .inner > .sl_char .menu_depth_3 {
  float: left;
  margin-top: 0;
}

.service_page .inner > .clearfix .menu_depth_3 .tab_lst, .service_page .inner > .attach_box .menu_depth_3 .tab_lst, .service_page .inner > .sl_server .menu_depth_3 .tab_lst, .service_page .inner > .sl_char .menu_depth_3 .tab_lst {
  width: auto;
}

.service_page .inner > .clearfix .search_box, .service_page .inner > .attach_box .search_box, .service_page .inner > .sl_server .search_box, .service_page .inner > .sl_char .search_box {
  float: right;
}

.service_page .inner > .clearfix:first-child, .service_page .inner > .attach_box:first-child, .service_page .inner > .sl_server:first-child, .service_page .inner > .sl_char:first-child {
  margin-top: 13px;
}

.service_page h2.sub_tit {
  font-weight: 400;
}

.service_page .select .btn_open_select {
  color: #868686;
}

.service_page .select.open .btn_open_select {
  color: #222222;
}

.service_page .flow_guide_area {
  position: relative;
  text-align: center;
}

.service_page .flow_guide_area .flow_lst {
  margin-top: 39px;
}

.service_page .flow_guide_area .flow_lst li.active {
  margin-top: -16px;
}

.service_page .flow_guide_area .flow_lst.flow_v2 {
  margin-top: 25px;
}

.service_page .flow_guide_area .flow_lst.flow_v2 li.active {
  margin-top: 0;
}

.service_page .flow_guide_area .btn_flow {
  display: block;
  position: absolute;
  left: 50%;
  top: 71px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.service_page .flow_guide_area .btn_flow span {
  display: block;
}

.service_page .flow_guide_area .btn_flow.prev {
  margin-left: -106px;
}

.service_page .flow_guide_area .btn_flow.next {
  margin-left: 100px;
}

.service_page .sp_common_area {
  margin-top: 60px;
}

.service_page .sp_common_area h3 {
  font-size: 24px;
  font-weight: 400;
  color: #222222;
  letter-spacing: -1.4px;
  line-height: 22px;
}

.service_page .sp_common_area h3 .btn {
  margin-left: 30px;
  vertical-align: text-top;
}

.service_page .sp_common_area h3 em {
  display: inline-block;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #404dab;
  vertical-align: text-bottom;
  letter-spacing: 0;
}

.service_page .sp_common_area h3 small {
  display: inline-block;
  margin-left: 30px;
  font-size: 16px;
  font-weight: 300;
}

.service_page .sp_common_area h3 + .dot_lst {
  margin-top: 21px;
}

.service_page .sp_common_area p {
  margin-top: 11px;
  line-height: 33px;
  letter-spacing: -0.1px;
  word-wrap: break-word;
  font-weight: 300;
}

.service_page .sp_common_area p strong {
  font-weight: 400;
  color: #222222;
}

.service_page .sp_common_area p .emp_txt {
  margin-top: 0;
}

.service_page .sp_common_area p + .tbl_lst {
  margin-top: 20px;
}

.service_page .sp_common_area p span.btn {
  margin: 0 4px;
  line-height: 23px;
}

.service_page .sp_common_area p + img {
  margin-top: 22px;
}

.service_page .sp_common_area img + .tbl_lst {
  margin-top: 30px;
}

.service_page .sp_common_area .dot_lst {
  margin-top: 14px;
}

.service_page .sp_common_area .dot_lst li ~ li {
  margin-top: 9px;
}

.service_page .sp_common_area .tbl_lst {
  margin-top: 20px;
  border-top: 1px solid #a4a4a4;
}

.service_page .sp_common_area .tbl_lst + .gray_bx {
  margin-top: 30px;
}

.service_page .sp_common_area .tbl_lst.txt_left th,
.service_page .sp_common_area .tbl_lst.txt_left td {
  padding-left: 30px;
}

.service_page .sp_common_area .emp_txt {
  display: block;
  margin-top: 13px;
  font-weight: 500;
  font-size: 15px;
}

.service_page .sp_common_area ~ .sp_common_area {
  margin-top: 78px;
}

.service_page .sp_common_area + .tbl_lst {
  margin-top: 56px;
}

.service_page .sp_common_area + .tbl_lst ~ .tbl_lst {
  margin-top: 60px;
}

.service_page .sp_common_area + .tabs_lnk_lst {
  margin-top: 28px;
}

.service_page .sp_common_area .clearfix .btn_grp, .service_page .sp_common_area .attach_box .btn_grp, .service_page .sp_common_area .sl_server .btn_grp, .service_page .sp_common_area .sl_char .btn_grp {
  margin-top: 25px;
}

.service_page .sp_common_area .clearfix .btn_grp .btn, .service_page .sp_common_area .attach_box .btn_grp .btn, .service_page .sp_common_area .sl_server .btn_grp .btn, .service_page .sp_common_area .sl_char .btn_grp .btn {
  width: 100px;
}

.service_page .sp_common_area .clearfix + .tbl_lst, .service_page .sp_common_area .attach_box + .tbl_lst, .service_page .sp_common_area .sl_server + .tbl_lst, .service_page .sp_common_area .sl_char + .tbl_lst {
  margin-top: 14px;
}

.service_page .sp_common_area .txt_note {
  margin-top: 20px;
  letter-spacing: -1.2px;
}

.service_page .sp_common_area .txt_note .btn_sm {
  height: 26px;
  margin: 3px 7px 0;
  font-size: 12px;
  line-height: 26px;
  vertical-align: top;
}

.service_page .sp_common_area .term_txt_bx {
  margin-top: 21px;
  padding: 40px 0;
  border-top: 1px solid #a4a4a4;
  border-bottom: 1px solid #a4a4a4;
  font-weight: 300;
}

.service_page .sp_common_area .term_txt_bx .js_scrollbar {
  max-height: 350px;
}

.service_page .sp_common_area .term_txt_bx .js_scrollbar .sb-scrollbar-container {
  right: 0;
}

.service_page .sp_common_area .term_txt_bx .js_scrollbar .sb-content {
  width: calc(100% + 17px);
  padding: 0 30px;
}

.service_page .sp_common_area .term_txt_bx .term_tit {
  font-size: 20px;
  font-weight: bold;
}

.service_page .sp_common_area .term_txt_bx strong {
  font-weight: 500;
}

.service_page .sp_common_area .term_txt_bx ul {
  line-height: 25px;
}

.service_page .sp_common_area .term_txt_bx .disc_lst {
  list-style: inside disc;
}

.service_page .sp_common_area .agr_chk_bx {
  margin-top: 18px;
  padding: 35px 40px;
}

.service_page .sp_common_area .agr_chk_bx label {
  padding-left: 40px;
  font-size: 22px;
  font-weight: 500;
  color: #222222;
}

.service_page .sp_common_area .agr_chk_bx .chk_detail {
  display: block;
  margin-top: 6px;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 300;
}

.service_page .btn_grp {
  margin-top: 51px;
  text-align: center;
}

.service_page .btn_grp .btn {
  vertical-align: top;
}

.service_page .btn_grp.cs_btn_grp {
  margin-top: 120px;
}

.service_page .btn_grp.ct_btn_grp {
  margin-top: 130px;
}

.service_page .btn_grp.ge_btn_grp {
  margin-top: 60px;
}

.service_page .sub_sticky + .inner .sp_common_area:first-child {
  margin-top: 12px;
}

.service_page .sub_sticky + .inner .sp_common_area h3 {
  font-weight: 500;
}

.service_page .service_product_area {
  margin-top: 29px;
  padding-bottom: 50px;
  border-bottom: 1px solid #a4a4a4;
}

.service_page .ser_pro_lst {
  font-size: 0;
}

.service_page .ser_pro_lst li {
  display: inline-block;
  position: relative;
  width: 442px;
  height: 207px;
  padding: 0 38px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
}

.service_page .ser_pro_lst li .ser_pro_cont {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
}

.service_page .ser_pro_lst li:after {
  display: none;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: rgba(0, 0, 0, 0.8);
  content: '';
}

.service_page .ser_pro_lst li ~ li:not(:nth-child(3n+1)) {
  margin-left: 42px;
}

.service_page .ser_pro_lst li:nth-child(3) ~ li {
  margin-top: 42px;
}

.service_page .ser_pro_lst li:hover:after, .service_page .ser_pro_lst li:hover .btn_grp {
  display: block;
}

.service_page .ser_pro_lst .tag {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 66px;
  height: 30px;
  background: #404dab;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}

.service_page .ser_pro_lst .guideline {
  width: 250px;
  min-height: 100px;
  word-break: break-word;
}

.service_page .ser_pro_lst .guideline strong {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  color: #000;
}

.service_page .ser_pro_lst .guideline p {
  margin-top: 7px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: #222222;
}

.service_page .ser_pro_lst .guideline small {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
}

.service_page .ser_pro_lst .price {
  display: block;
  margin-top: 1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
}

.service_page .ser_pro_lst .price:before {
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
  content: '';
}

.service_page .ser_pro_lst .ico {
  position: absolute;
  left: 305px;
  top: 50%;
  transform: translateY(-50%);
}

.service_page .ser_pro_lst .btn_grp {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 100%;
  margin-top: 0;
  transform: translate(-50%, -50%);
}

.service_page .ser_pro_lst .btn_grp .btn {
  width: 130px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
}

.service_page .ser_pro_lst .btn_grp .btn ~ .btn {
  margin-left: 10px;
}

.service_page .tbl_lst th.txt_left {
  padding-left: 30px;
}

.service_page .tbl_lst .inp_txt {
  display: inline-block;
  width: auto;
}

.service_page .tbl_lst .inp_txt.id_inp {
  width: 225px;
}

.service_page .tbl_lst .inp_txt.id_inp + .btn {
  width: 100px;
  margin-left: 10px;
}

.service_page .tbl_lst .btn {
  font-size: 14px;
  vertical-align: middle;
  line-height: 36px;
}

.service_page .tbl_lst .select ~ .select {
  margin-left: 12px;
}

.service_page .tbl_lst dl {
  font-size: 0;
}

.service_page .tbl_lst dl dt, .service_page .tbl_lst dl dd {
  display: inline-block;
  font-size: 16px;
}

.service_page .tbl_lst dl dd {
  margin-left: 20px;
}

.service_page .tbl_lst dl dd ~ dt {
  margin-left: 30px;
}

.service_page .tbl_lst dl dd ~ dt:before {
  display: inline-block;
  width: 1px;
  height: 17px;
  margin-right: 30px;
  margin-top: 5px;
  background: #c8c8cf;
  vertical-align: top;
  content: '';
}

.service_page .tbl_lst .txt_msg {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  color: #b83d3d;
}

.service_page .tbl_lst .nodata {
  height: 219px;
}

.service_page .tbl_lst .nodata .txt_msg {
  margin-top: 17px;
  font-size: 16px;
  font-weight: 400;
  color: #868686;
}

.service_page .tbl_lst .nodata .ico {
  margin-top: 2px;
}

.service_page .registry_step_lst {
  margin-top: 40px;
}

.service_page .registry_step_lst + .sp_common_area {
  margin-top: 80px;
}

.service_page .registry_step_lst + .sp_common_area .dot_lst {
  margin-top: 13px !important;
}

.service_page .registry_step_lst + .sp_common_area .dot_lst .txt_reg {
  color: #222222;
}

.service_page .registry_step_lst .txt {
  white-space: nowrap;
}

.service_page .step_lst {
  margin-top: 22px;
  padding: 42px 40px;
}

.service_page .step_lst .lst_itm {
  position: relative;
  height: 246px;
  padding-top: 86px;
}

.service_page .step_lst .lst_itm .step_ico {
  position: absolute;
  top: 46px;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
}

.service_page .step_lst .lst_itm .step_tit {
  font-size: 20px;
}

.service_page .step_lst .lst_itm .step_desc {
  margin-top: 11px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
}

.service_page .sl_server, .service_page .sl_char {
  padding: 30px 40px 30px 50px;
  margin-top: 17px;
  font-size: 0;
}

.service_page .sl_server label, .service_page .sl_char label {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
}

.service_page .sl_server .select, .service_page .sl_char .select {
  width: 210px;
  margin-left: 35px;
}

.service_page .sl_server .btn.btn_aside, .service_page .sl_char .btn.btn_aside {
  height: 50px;
  line-height: 50px;
}

.service_page .sl_server .txt_gray, .service_page .sl_char .txt_gray {
  display: block;
  float: right;
  margin-top: 14px;
  letter-spacing: -0.35px;
  font-size: 14px;
  font-weight: 300;
}

.service_page .sl_server .txt_gray .btn_sm, .service_page .sl_char .txt_gray .btn_sm {
  min-width: 45px;
  height: 24px;
  margin: 0 3px;
  font-size: 13px;
  line-height: 24px;
  vertical-align: middle;
}

.service_page .sl_server .btn.btn_aside {
  width: 107px;
}

.service_page .sl_char .btn.btn_aside {
  width: 126px;
  margin-left: 55px;
  border-radius: 2px;
}

.service_page .tabs_lnk_lst + .tbl_lst {
  margin-top: 29px;
}

.service_page .tabs_lnk_lst:first-child {
  margin-top: 7px;
  letter-spacing: -0.8px;
}

.service_page .tabs_lnk_lst + .sp_common_area {
  margin-top: 70px;
}

.service_page .impossible_itms {
  width: 360px;
}

.service_page .id_classification {
  width: 203px;
}

.service_page .lock_char_tbl td .btn {
  width: 100px;
  margin-left: 60px;
  font-size: 14px;
  line-height: 36px;
  vertical-align: middle;
}

.service_page .lock_char_tbl td.txt_bold {
  font-weight: 400;
}

.service_page .lock_char_tbl td .txt_msg {
  margin-left: 55px;
}

.service_page .last_notice {
  margin-top: 127px;
}

.service_page .last_notice strong {
  font-size: 18px;
}

.service_page .last_notice .dot_lst {
  margin-top: 6px;
}

.service_page .last_notice .dot_lst li {
  margin-top: 0;
  line-height: 35px;
}

.service_page .last_notice .dot_lst li:before {
  top: 16px;
}

.service_page .complete_msg_area {
  margin-top: 127px;
  text-align: center;
  font-size: 0;
}

.service_page .complete_msg_area .ico_done {
  display: inline-block;
}

.service_page .complete_msg_area strong {
  display: block;
  margin-top: 32px;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -1.3px;
}

.service_page .complete_msg_area small {
  display: block;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.3px;
}

.service_page .complete_msg_area small em {
  font-weight: 400;
  color: #222222;
}

.service_page .complete_msg_area .sv_info_bx {
  margin-top: 54px;
}

.service_page .complete_msg_area + .btn_grp {
  margin-top: 60px;
}

.service_page .result_area {
  margin-top: 55px;
}

.service_page .result_area .gray_bx {
  position: relative;
  padding: 42px 64px;
}

.service_page .result_area .gray_bx > span {
  font-size: 18px;
  font-weight: 500;
}

.service_page .result_area .gray_bx .res {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service_page .result_area .gray_bx .res .old_res {
  font-size: 20px;
  font-weight: 400;
  color: #868686;
}

.service_page .result_area .gray_bx .res .new_res {
  font-size: 20px;
  font-weight: 500;
  color: #404dab;
}

.service_page .result_area .gray_bx .res .ico_change_to {
  display: inline-block;
  margin: 0 60px;
}

.service_page .result_area .tbl_lst {
  margin-top: 30px;
}

.service_page .result_area + .btn_grp {
  margin-top: 61px;
}

.service_page .result_area + .btn_grp .btn {
  font-weight: 400;
}

.service_page .sv_info_bx {
  display: inline-block;
  width: 346px;
  height: 180px;
  padding: 40px 29px;
  margin-top: 20px;
  border: 1px solid #cccccc;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.service_page .sv_info_bx .info_txt {
  float: left;
  max-width: 186px;
}

.service_page .sv_info_bx .info_txt p {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.service_page .sv_info_bx .info_txt .price {
  display: block;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000;
}

.service_page .sv_info_bx .info_txt .price:before {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  content: '';
}

.service_page .sv_info_bx .img_container {
  float: right;
  font-size: 0;
}

.service_page.guild_create .registry_step_lst + .sp_common_area {
  margin-top: -7px;
}

.service_page.guild_entrust_lst .sp_common_area .btn_grp.right {
  margin-top: 0;
}

.service_page .gc_btn_grp {
  margin-top: 130px;
}

.service_page .area_tit + p {
  margin-top: 25px;
}

.service_page .safe_login_area {
  margin-top: 11px;
}

.service_page .safe_login_area p {
  font-size: 18px;
}

.service_page .safe_login_area .dot_lst {
  margin-top: 12px;
}

.service_page .safe_login_area .dot_lst li ~ li {
  margin-top: 9px;
}

.service_page .safe_login_area .dot_lst + p {
  margin-top: 14px;
}

.service_page .safe_login_area .step_lst {
  margin-top: 46px;
  padding: 46px;
  text-align: center;
}

.service_page .safe_login_area .step_lst .lst_itm {
  width: 637px;
  height: 269px;
  padding-bottom: 30px;
}

.service_page .safe_login_area .step_lst .lst_itm ~ .lst_itm {
  margin-left: 40px;
}

.service_page .safe_login_area .step_lst .step_ico {
  top: 42px;
}

.service_page .safe_login_area .step_lst .step_tit {
  margin-top: 54px;
  font-size: 22px;
}

.service_page .safe_login_area .step_lst .step_tit.txt_blue {
  color: #404dab;
}

.service_page .safe_login_area .step_lst .btn.btn_lg {
  width: 130px;
  height: 48px;
  margin-top: 28px;
}

.service_page .safe_login_area .info_bx {
  margin-top: 46px;
}

.service_page .safe_login_area .phone_verify {
  margin-top: 30px;
}

.service_page .safe_login_area .phone_verify tr, .service_page .safe_login_area .phone_verify th, .service_page .safe_login_area .phone_verify td {
  min-height: 74px;
  border: none;
}

.service_page .safe_login_area .phone_verify tr:only-child {
  height: 76px;
}

.service_page .safe_login_area .phone_verify tr:not(:only-child):first-child th, .service_page .safe_login_area .phone_verify tr:not(:only-child):first-child td {
  padding-top: 5px;
}

.service_page .safe_login_area .phone_verify tr:not(:only-child):last-child th, .service_page .safe_login_area .phone_verify tr:not(:only-child):last-child td {
  padding-bottom: 15px;
}

.service_page .safe_login_area .phone_verify th {
  font-size: 14px;
}

.service_page .safe_login_area .phone_verify .inp_txt {
  width: 47px;
}

.service_page .safe_login_area .phone_verify .btn {
  margin-left: 39px;
}

.service_page .safe_login_area .phone_verify .verify_phone_bx input {
  font-size: 14px;
}

.service_page .safe_login_area .phone_verify .dot_lst {
  margin-top: -5px;
  font-size: 14px;
}

.service_page .safe_login_area .phone_verify .dot_lst li {
  margin-top: 2px;
}

.service_page .safe_login_area .phone_verify .dot_lst li:first-child {
  margin-top: 0;
}

.service_page .verify_guide_bx {
  display: block;
  margin-left: 30px;
  text-align: left;
}

.service_page .verify_guide_bx .btn {
  width: 51px;
  height: 23px;
  padding: 0;
  margin-left: 0 !important;
  font-size: 13px;
  line-height: 23px;
  vertical-align: baseline;
  font-weight: 400;
}

.service_page.vip_services .dot_lst.note_txt {
  margin-top: 19px;
}

.service_page.vip_services .dot_lst.note_txt li {
  font-size: 14px;
}

.service_page.vip_services .dot_lst.note_txt li:before {
  top: 5px;
}

.service_page.vip_services .dot_lst.note_txt li ~ li {
  margin-top: 17px;
}

.service_page.vip_services .btn_grp.right {
  margin-top: 15px;
}

.service_page.vip_services .btn_grp.right .btn {
  width: auto;
  padding: 0 30px;
}

.service_page.vip_services .service_info {
  padding: 42px 66px 42px 70px;
  margin-top: 26px;
  white-space: nowrap;
}

.service_page.vip_services .service_info .class_rate, .service_page.vip_services .service_info .class_progress {
  position: relative;
  display: inline-block;
  width: 635px;
  min-height: 201px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.service_page.vip_services .service_info .class_rate .rate {
  display: block;
  margin-top: 22px;
  font-size: 24px;
}

.service_page.vip_services .service_info .class_rate .rate span {
  display: inline-block;
  margin-left: 10px;
}

.service_page.vip_services .service_info .class_rate .rate span:before {
  display: inline-block;
  width: 1px;
  height: 15px;
  margin-right: 10px;
  background: #c8c8cf;
  vertical-align: middle;
  content: '';
}

.service_page.vip_services .service_info .class_rate dl {
  margin-top: 10px;
  font-size: 14px;
}

.service_page.vip_services .service_info .class_rate dl dd, .service_page.vip_services .service_info .class_rate dl dt {
  display: inline;
}

.service_page.vip_services .service_info .class_rate dl div ~ div {
  margin-top: 2px;
}

.service_page.vip_services .service_info .class_rate .btn.btn_lg {
  width: 162px;
  margin-top: 18px;
}

.service_page.vip_services .service_info .class_rate .rate_ico {
  position: absolute;
  right: 92px;
  top: 26px;
}

.service_page.vip_services .service_info .class_progress {
  padding-left: 87px;
  margin-left: -4px;
}

.service_page.vip_services .service_info .class_progress:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 200px;
  background: #ffffff;
  content: '';
}

.service_page.vip_services .service_info .class_progress .class_lst {
  margin-top: 40px;
  font-size: 0;
}

.service_page.vip_services .service_info .class_progress .class_lst li {
  display: inline-block;
  width: 138px;
  text-align: center;
}

.service_page.vip_services .service_info .class_progress .class_lst li.on .rate_tit {
  font-weight: 400;
  color: #404dab;
}

.service_page.vip_services .service_info .class_progress .progress_bar {
  position: absolute;
  top: 105px;
  overflow: hidden;
  width: 552px;
  height: 24px;
  font-size: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: url("../img/service/progress_bar_bg.png") no-repeat;
}

.service_page.vip_services .service_info .class_progress .progress_bar li {
  position: relative;
  display: inline-block;
  max-width: 138px;
  height: 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.service_page.vip_services .service_info .class_progress .progress_bar li:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: inherit;
  content: '';
  background: #404dab;
}

.service_page.vip_services .service_info .class_progress .progress_bar li ~ li:before {
  -webkit-box-shadow: inset 1px 0px 0px 0px #5966be;
  -moz-box-shadow: inset 1px 0px 0px 0px #5966be;
  box-shadow: inset 1px 0px 0px 0px #5966be;
}

.service_page.vip_services .service_info .class_progress .rate_badge {
  display: block;
  margin: 0 auto;
}

.service_page.vip_services .service_info .class_progress .rate_tit {
  display: block;
  margin-top: 59px;
  font-size: 16px;
  font-weight: 300;
  color: #222222;
}

.service_page.vip_services .service_detail {
  margin-top: 80px;
}

.service_page.vip_services .service_detail [class^="ico_"] {
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.service_page.vip_services .service_detail .class_name {
  font-size: 16px;
  font-weight: 400;
}

.service_page.vip_services .service_detail .dot_lst {
  margin-top: 0;
}

.service_page.vip_services .service_detail .dot_lst li ~ li {
  margin-top: 6px;
}

.service_page.vip_services .service_detail td:nth-child(3) {
  padding: 28px 0;
}

.service_page.vip_services .service_detail .rate_area {
  padding-left: 102px;
  text-align: left;
}

.service_page .gray_bx.result {
  background: #f8f8f8;
}

.flow_lst {
  display: inline-block;
  font-size: 0;
}

.flow_lst li {
  display: inline-block;
  margin: 0 40px;
}

.flow_lst li .icon_container {
  position: relative;
  display: block;
  width: 92px;
  height: 92px;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #cccccc;
  font-size: 0;
}

.flow_lst li .icon_container span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.flow_lst li .flow_name {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 300;
  color: #868686;
  text-align: center;
  line-height: 18px;
}

.flow_lst li.active .icon_container {
  width: 113px;
  height: 113px;
  background: #384859;
}

.flow_lst li.active .flow_name {
  font-weight: bold;
  color: #022222;
}

.flow_lst.flow_v2 li {
  position: relative;
  margin: 0 42px;
}

.flow_lst.flow_v2 li:first-child:nth-last-child(6), .flow_lst.flow_v2 li:first-child:nth-last-child(6) ~ li {
  margin: 0 36px;
}

.flow_lst.flow_v2 li:first-child {
  margin-left: 0 !important;
}

.flow_lst.flow_v2 li:last-child {
  margin-right: 0 !important;
}

.flow_lst.flow_v2 li.active .flow_name {
  font-weight: 300;
}

.flow_lst.flow_v2 li.active .icon_container {
  width: 92px;
  height: 92px;
}

.flow_lst.flow_v2 li ~ li:before {
  position: absolute;
  top: 36px;
  left: -46px;
  content: '';
}

.tbl_fm {
  margin-top: 27px;
  font-size: 16px;
}

.tbl_fm .tbl_lst {
  border-top: none;
}

.tbl_fm th, .tbl_fm td {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 30px;
  text-align: left;
  vertical-align: middle;
}

.tbl_fm input[type="text"] {
  width: 100%;
  height: 36px;
  border: none;
  font-size: 14px;
  color: #222222;
  outline: none;
}

.tbl_fm input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 300;
  color: #a4a4a4;
  opacity: 1;
  /* Firefox */
}

.tbl_fm input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 300;
  color: #a4a4a4;
}

.tbl_fm input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 300;
  color: #a4a4a4;
}

.shop_page .lnb {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}

.shop_page .main_shop_tab li {
  margin-right: 34px;
}

.shop_page .main_shop_tab li a {
  letter-spacing: -0.8px;
}

.shop_page .menu_depth_3 {
  float: left;
  margin: 50px 0 30px;
}

.shop_page .menu_depth_3 .tab_lst {
  width: auto;
  padding: 0;
  border-top: none;
}

.shop_page .tbl_desc {
  float: right;
  margin-top: 54px;
}

.shop_page .tbl_lst {
  margin-top: 20px;
}

.shop_page .tbl_lst .itm_name_tit {
  padding-left: 137px;
  text-align: left;
}

.shop_page .tbl_lst .itm_name {
  overflow: hidden;
  display: block;
  width: 670px;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shop_page .tbl_lst .itm_name img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.shop_page .tbl_lst .itm_name.type2 {
  width: 390px;
}

.shop_page .tbl_lst a.lbl_outline {
  width: 80px;
  height: 36px;
  font-size: 15px;
  line-height: 36px;
  text-decoration: none;
}

.shop_page .lst_tit {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #101010;
}

.shop_page .dot_lst {
  margin-top: 12px;
}

.shop_page .dot_lst li ~ li {
  margin-top: 11px;
}

.shop_page .dot_lst + .tbl_lst {
  margin-top: 26px;
}

.shop_page .send_cp_area .send_cp_bx {
  margin-top: 18px;
  font-size: 0;
}

.shop_page .send_cp_area .send_cp_bx .txt {
  font-weight: 400;
  letter-spacing: -0.02em;
}

.shop_page .send_cp_area .send_cp_bx .def_inp {
  width: 126px;
  text-align: center;
}

.shop_page .send_cp_area .send_cp_bx .def_inp + .def_inp {
  margin-left: 9px;
}

.shop_page .send_cp_area .send_cp_bx .btn {
  width: 145px;
  margin-left: 29px;
}

.shop_page .send_cp_area .cp_stt_bx {
  margin-top: 18px;
  padding: 0 47px;
}

.shop_page .send_cp_area .cp_stt_bx .cp_type {
  letter-spacing: -0.02em;
}

.shop_page .send_cp_area .lst_tit {
  margin-top: 25px;
}

.shop_page .send_cp_area .dot_lst {
  margin-top: 13px;
}

.shop_page .send_cp_area .dot_lst li {
  padding-left: 12px;
  letter-spacing: -0.025em;
  margin-top: 9px;
  color: #5e5e5e;
}

.shop_page .send_cp_area .dot_lst li:before {
  background-color: #5e5e5e;
}

.shop_page .send_cp_area .dot_lst li:first-child {
  margin-top: 0;
}

.shop_page .area_tit {
  letter-spacing: -0.03em;
}

.shop_page .usage_guide_area {
  margin-top: 78px;
}

.shop_page .usage_guide_area .lst_tit {
  letter-spacing: -0.025em;
  margin-top: 25px;
}

.shop_page .usage_guide_area .dot_lst li {
  letter-spacing: -0.025em;
  color: #5e5e5e;
}

.shop_page .usage_guide_area .dot_lst li:before {
  background-color: #5e5e5e;
}

.shop_page .usage_guide_area .dot_lst + .lst_tit {
  margin-top: 20px;
}

.shop_page .registration_method_area {
  margin-top: 76px;
  font-size: 0;
}

.shop_page .registration_method_area .step_lst {
  padding: 41px 38px;
}

.shop_page .registration_method_area .lst_itm {
  width: 310px;
  min-height: 196px;
  margin-left: 31px;
  padding: 50px 0 0;
}

.shop_page .registration_method_area .lst_itm:first-child {
  margin-left: 0;
}

.shop_page .registration_method_area .lst_itm .step_ico {
  min-height: 71px;
  margin-top: 4px;
}

.shop_page .registration_method_area .lst_itm .step_desc {
  margin-top: 7px;
  font-size: 18px;
  letter-spacing: -0.02em;
}

.shop_page .registration_method_area .registration_case {
  padding: 2px 45px 0;
  text-align: center;
}

.shop_page .registration_method_area .registration_case dt {
  margin-top: 11px;
  padding-top: 14px;
  border-top: 1px solid #ebebeb;
  font-size: 18px;
  line-height: 20px;
}

.shop_page .registration_method_area .registration_case dt:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

.shop_page .registration_method_area .registration_case dd {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.shop_wrap {
  display: inline-block;
  width: 1100px;
  margin: 10px 0 0 36px;
  vertical-align: top;
}

.shop_wrap .search_area {
  margin: 50px 0 24px;
  padding: 30px 40px;
}

.shop_wrap .search_area .search {
  font-size: 0;
}

.shop_wrap .search_area .select {
  width: 140px;
  height: 50px;
  background-color: #fff;
}

.shop_wrap .search_area .select.lst_up .select_lst {
  bottom: 48px;
}

.shop_wrap .search_area .select .select_lst {
  overflow: auto;
  max-height: 200px;
}

.shop_wrap .search_area .tit {
  font-size: 18px;
  line-height: 50px;
}

.shop_wrap .search_area .search_box {
  margin-left: 15px;
}

.shop_wrap .search_area .search_box input {
  width: 625px;
  padding-left: 13px;
}

.shop_wrap .search_area .btn {
  width: 130px;
  margin-left: 14px;
}

.shop_lst {
  margin-top: 10px;
  font-size: 0;
}

.shop_lst li {
  display: inline-block;
  width: 260px;
  margin-left: 20px;
  border: 1px solid #ccc;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.shop_lst li:first-child {
  margin-left: 0;
}

.shop_itm {
  display: block;
  min-height: 298px;
  position: relative;
  padding: 46px 0;
  color: #000;
  text-align: center;
  box-sizing: border-box;
}

.shop_itm .thumb {
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border: 2px solid #f4cf88;
  box-sizing: border-box;
}

.shop_itm .itm_name {
  display: block;
  margin-top: 17px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.shop_itm .itm_price {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.shop_itm .itm_price:before {
  display: inline-block;
  margin-right: 3px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1103px -1112px;
  width: 17px;
  height: 17px;
  vertical-align: top;
  content: '';
}

.shop_itm .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 91px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.shop_itm .overlay .itm_name {
  margin-top: 0;
}

.shop_itm .overlay .itm_price {
  margin-top: 4px;
}

.shop_itm .overlay .itm_price:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -497px -1153px;
  width: 17px;
  height: 17px;
}

.shop_itm .overlay .btn_group {
  margin-top: 21px;
}

.shop_itm .overlay .btn {
  min-width: 60px;
  margin-left: 8px;
  background-color: #fff;
  font-size: 15px;
  line-height: 30px;
  color: #222;
}

.shop_itm .overlay .btn:first-child {
  margin-left: 0;
}

.shop_itm .overlay .btn.orange {
  background-color: #a68a5f;
  color: #fff;
}

.shop_area {
  position: relative;
}

.shop_area .btn_cash_charge {
  position: absolute;
  top: 23px;
  right: 0;
}

.product_lst_detail .product_num {
  display: inline-block;
  margin-top: 32px;
}

.product_lst_detail .select {
  float: right;
  width: 140px;
}

.product_lst {
  margin-top: 16px;
  border-top: 1px solid #a4a4a4;
  font-size: 0;
}

.product_lst li {
  display: inline-block;
  width: 346px;
  height: 180px;
  margin: 31px 0 0 31px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.product_lst li:nth-child(3n + 1) {
  margin-left: 0;
}

.product_lst.nodata {
  border-bottom: 1px solid #a4a4a4;
}

.product_lst.nodata li {
  position: relative;
  width: 100%;
  height: 460px;
  margin: 0;
  border: 0;
  text-align: center;
}

.product_lst.nodata li .ico {
  display: block;
  margin: 170px auto 0;
}

.product_lst.nodata li .txt_msg {
  display: block;
  margin: 13px auto 0;
  font-size: 24px;
  font-weight: normal;
  color: #868686;
  letter-spacing: -0.02em;
}

.product_itm {
  position: relative;
  padding: 39px 30px;
}

.product_itm .lbl {
  width: auto;
  min-width: 60px;
  height: 25px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 300;
  line-height: 25px;
  box-sizing: border-box;
}

.product_itm .lbl.darkblue {
  background-color: #72879e;
}

.product_itm .lbl.lightblue {
  background-color: #72949e;
}

.product_itm .lbl.green {
  background-color: #7e9e72;
}

.product_itm .lbl.blue {
  background-color: #727b9e;
}

.product_itm .lbl.pink {
  background-color: #9e7298;
}

.product_itm .lbl.darkorange {
  background-color: #9e8972;
}

.product_itm .lbl.darkviolet {
  background-color: #80729e;
}

.product_itm .lbl.darkgreen {
  background-color: #83b297;
}

.product_itm .lbl.darkred {
  background-color: #9e7272;
}

.product_itm .lbl.gray {
  background-color: #5e6c74;
}

.product_itm .lbl.darkyellow {
  background-color: #a79e73;
}

.product_itm .lbl.red {
  background-color: #bf6a6a;
}

.product_itm .product_tit {
  display: block;
  height: 42px;
  margin-top: 9px;
  padding-right: 110px;
  font-size: 14px;
  line-height: 21px;
}

.product_itm .product_thumb {
  display: block;
  position: absolute;
  top: 38px;
  right: 28px;
  width: 50px;
  height: 50px;
  padding: 25px;
  background: #f8f8f8;
}

.product_itm .product_thumb img {
  border: 1px solid #f4cf88;
}

.product_itm .product_price {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.product_itm .product_price:before {
  display: inline-block;
  margin: 2px 3px 0 0;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1103px -1112px;
  width: 17px;
  height: 17px;
  vertical-align: top;
  content: '';
}

.product_itm .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.product_itm .overlay .product_name {
  display: block;
  margin-top: 0;
  padding: 0 60px;
}

.product_itm .overlay .product_price {
  margin-top: 4px;
}

.product_itm .overlay .product_price:before {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -497px -1153px;
  width: 17px;
  height: 17px;
}

.product_itm .overlay .btn_group {
  margin-top: 21px;
}

.product_itm .overlay .btn {
  min-width: 60px;
  margin-left: 4px;
  background-color: #fff;
  color: #222;
}

.product_itm .overlay .btn:first-child {
  margin-left: 0;
}

.product_itm .overlay .btn.orange {
  background-color: #a68a5f;
  color: #fff;
}

.tbl_desc {
  text-align: right;
}

.tbl_desc .note {
  float: left;
  font-weight: 300;
  color: #404dab;
  letter-spacing: -0.007em;
  line-height: 46px;
}

.tbl_desc .tbl_lst_indi {
  display: inline-block;
  margin-top: 18px;
  vertical-align: top;
}

.tbl_desc .datepicker_area {
  display: inline-block;
  margin-left: 25px;
  vertical-align: top;
}

.tbl_desc .datepicker_area .tilde {
  margin: 0 15px;
  line-height: 46px;
}

.shop_guide_page .wrap {
  padding-top: 92px;
}

.shop_guide_page h2.sub_tit {
  font-weight: 400;
}

.shop_guide_page .sp_common_area {
  margin-top: 60px;
}

.shop_guide_page .sp_common_area h3 {
  font-size: 24px;
  font-weight: 400;
  color: #222;
  letter-spacing: -1.4px;
  line-height: 22px;
}

.shop_guide_page .sp_common_area h3 + p {
  margin-top: 14px;
  line-height: 25px;
}

.shop_guide_page .sp_common_area p {
  margin-top: 11px;
  line-height: 33px;
  word-wrap: break-word;
  font-weight: 300;
}

.shop_guide_page .sp_common_area p + .tbl_lst {
  margin-top: 20px;
}

.shop_guide_page .sp_common_area p + img {
  margin-top: 22px;
}

.shop_guide_page .sp_common_area .txt_bold {
  font-weight: 400;
}

.shop_guide_page .sp_common_area img + .tbl_lst {
  margin-top: 30px;
}

.shop_guide_page .sp_common_area .dot_lst {
  margin-top: 14px;
}

.shop_guide_page .sp_common_area .dot_lst li {
  line-height: 33px;
}

.shop_guide_page .sp_common_area .dot_lst li:before {
  top: 14px;
}

.shop_guide_page .sp_common_area .dot_lst li ~ li {
  margin-top: 9px;
}

.shop_guide_page .sp_common_area .tbl_lst {
  margin-top: 20px;
}

.shop_guide_page .sp_common_area .tbl_lst th {
  font-weight: 400;
}

.shop_guide_page .sp_common_area .tbl_lst.txt_left th,
.shop_guide_page .sp_common_area .tbl_lst.txt_left td {
  padding-left: 30px;
}

.shop_guide_page .sp_common_area ~ .sp_common_area {
  margin-top: 50px;
}

.cash_page .cash_status_area {
  padding-top: 50px;
  border-top: 2px solid #384859;
}

.cash_page .menu_depth_3 {
  margin-top: 40px;
}

.cash_page .tbl_desc {
  margin-top: 30px;
}

.cash_page .tbl_lst {
  margin-top: 30px;
}

.cash_page .tbl_lst + .btn_wrap {
  margin-top: 16px;
  text-align: right;
}

.cash_page .tbl_lst + .btn_wrap + .pagination {
  margin-top: 35px;
}

.cash_page2 .wrap {
  padding-top: 110px;
  padding-bottom: 90px;
}

.cash_page2 .registry_step_lst .ico_step1 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -485px -1078px;
  width: 58px;
  height: 58px;
}

.cash_page2 .registry_step_lst .ico_step2 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -733px -1078px;
  width: 58px;
  height: 58px;
}

.cash_page2 .registry_step_lst .ico_step3 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -547px -1078px;
  width: 58px;
  height: 58px;
}

.cash_page2 .registry_step_lst .ico_step4 {
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -981px -1078px;
  width: 58px;
  height: 58px;
}

.cash_page2 .registry_step_lst .txt {
  font-weight: 500;
  color: #222;
}

.cash_page2 .cash_status_area + .tbl_lst {
  margin-top: 53px;
}

.cash_page2 .tbl_lst th {
  padding-left: 30px;
  font-size: 14px;
}

.cash_page2 .tbl_lst td {
  height: 74px;
}

.cash_page2 .tbl_lst td em {
  font-weight: 400;
}

.cash_page2 .tbl_lst .txt_note {
  margin-left: 13px;
  font-size: 14px;
  color: #404dab;
  letter-spacing: -0.007em;
}

.cash_page2 .tbl_lst .bank {
  width: 210px;
}

.cash_page2 .tbl_lst .inp_acc_num {
  width: 216px;
  margin: 0 10px 0 25px;
}

.cash_page2 .tbl_lst .inp_acc {
  width: 145px;
  margin-left: 14px;
}

.cash_page2 .tbl_lst .bar {
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: #c8c8cf;
  vertical-align: middle;
}

.cash_page2 .tbl_lst .btn_verify {
  margin: 0 7px;
}

.cash_page2 .tbl_lst .btn_authenticate {
  margin-left: 47px;
}

.cash_page2 .tbl_lst .validate .message {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
}

.cash_page2 .btn_wrap {
  margin-top: 60px;
  font-size: 0;
  text-align: center;
}

.cash_page2 .btn_wrap .btn {
  margin-left: 20px;
  vertical-align: top;
}

.cash_page2 .btn_wrap .btn:first-child {
  margin-left: 0;
}

.cash_page2 .result_bx {
  width: 970px;
  margin: 0 auto;
  text-align: left;
}

.cash_page2 .result_bx .tit {
  text-align: center;
}

.cash_page2 .result_bx .tbl_lst {
  margin-top: 50px;
}

.cash_page2 .result_bx .tbl_lst th {
  padding-left: 0;
  font-size: 16px;
}

.cash_page2 .result_bx .tbl_lst td {
  height: 70px;
  padding-left: 30px;
}

.cash_page2 .result_bx .lst_tit {
  display: block;
  margin-top: 25px;
  font-size: 18px;
}

.cash_page2 .result_bx .dot_lst {
  margin-top: 10px;
}

.cash_page2 .result_bx .btn_wrap {
  margin-top: 36px;
}

.cash_page2 .result_bx .info_lst li {
  font-weight: 400;
}

.cash_status_area .cash_status {
  position: relative;
  padding: 43px 60px 42px;
  background-color: #f2f2f2;
  font-size: 0;
}

.cash_status_area .cash_status .tit {
  display: inline-block;
  min-width: 200px;
  font-size: 28px;
  color: #101010;
  letter-spacing: -0.05em;
  line-height: 46px;
  vertical-align: top;
}

.cash_status_area .cash_status .current_cash {
  display: inline-block;
  margin-left: 7px;
  font-size: 30px;
  font-weight: 300;
  color: #404dab;
  line-height: 46px;
  letter-spacing: -0.01em;
  vertical-align: top;
}

.cash_status_area .cash_status .current_cash .num {
  font-family: 'barlow', sans-serif;
  font-size: 31px;
  font-weight: 400;
}

.cash_status_area .cash_status .btn {
  vertical-align: top;
  width: 130px;
  height: 48px;
  margin-left: 16px;
  font-size: 16px;
}

.cash_status_area .cash_status .btn_refund_info {
  position: absolute;
  top: 43px;
  right: 60px;
}

.cash_status_area .cash_status .btn_refund_info:after {
  display: inline-block;
  margin: -2px 0 0 15px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1327px -1035px;
  width: 8px;
  height: 5px;
  vertical-align: middle;
  content: '';
}

.cash_status_area .cash_status .btn_refund_info.open:after {
  transform: rotate(180deg);
}

.cash_status_area .cash_status .btn_refund_info.open + .refund_info {
  display: block;
}

.cash_status_area .cash_status .refund_info {
  display: none;
  position: absolute;
  top: 109px;
  right: 0;
  width: 650px;
  padding: 25px 30px 25px 33px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  font-size: 14px;
}

.cash_status_area .cash_status .refund_info:before {
  position: absolute;
  top: -9px;
  right: 118px;
  background-size: 1335px 1279px;
  background-image: url("../img/sprites/normal.png");
  background-position: -1094px -643px;
  width: 12px;
  height: 9px;
  content: '';
}

.cash_status_area .cash_status .refund_info li {
  padding-left: 13px;
  letter-spacing: -0.013em;
}

.cash_status_area .cash_status .refund_info li:before {
  top: 9px;
}

.cash_status_area .cash_status .refund_info li ~ li {
  margin-top: 2px;
}

.cash_status_area .progress_bx {
  position: absolute;
  top: 51px;
  right: 60px;
  text-align: right;
}

.cash_status_area .progress_bx .progress_bar {
  display: block;
  width: 584px;
  height: 30px;
  border: 1px solid #dadada;
  border-radius: 15px;
  background-color: #fff;
}

.cash_status_area .progress_bx .bar {
  display: block;
  height: 28px;
  border: 1px solid #404dab;
  border-radius: 14px;
  background-color: #404dab;
}

.cash_status_area .progress_bx .progress_info {
  margin-top: 7px;
  font-size: 14px;
  line-height: 21px;
}

.cash_status_area .progress_bx .progress_info dt {
  display: inline-block;
  margin-left: 10px;
}

.cash_status_area .progress_bx .progress_info dt:first-child {
  margin-left: 0;
}

.cash_status_area .progress_bx .progress_info dd {
  display: inline-block;
  color: #404dab;
  letter-spacing: 0.01em;
}

.cash_status_area .progress_bx .progress_info .ico {
  display: inline-block;
  margin: 1px 1px 0 0;
  vertical-align: top;
}

.cash_status_area .cash_limit {
  display: block;
  margin-top: 20px;
  text-align: right;
}

.cash_status_area .cash_limit .num {
  color: #b83d3d;
}

.cash_status_area .cash_limit .btn_outline {
  width: 104px;
  height: 30px;
  margin-left: 4px;
  line-height: 28px;
  color: #868686;
  border: 1px solid #a4a4a4;
}

.charge_limit_area {
  display: table;
  width: 100%;
  margin-top: 70px;
  border: solid #a4a4a4;
  border-width: 1px 0;
}

.charge_limit_area .tit {
  display: table-cell;
  width: 200px;
  padding-left: 31px;
  font-size: 16px;
  vertical-align: middle;
  box-sizing: border-box;
}

.charge_limit_area .ct {
  display: table-cell;
  padding: 20px 0 25px;
}

.charge_limit_area .cash {
  margin-right: 13px;
  font-size: 16px;
  font-weight: 300;
  color: #b83d3d;
}

.charge_limit_area .cash .num {
  font-size: 18px;
  font-weight: 500;
}

.charge_limit_area .dot_lst {
  margin-top: 14px;
}

.charge_limit_area .dot_lst li {
  padding-left: 13px;
  font-size: 14px;
  color: #868686;
  line-height: 23px;
  letter-spacing: -0.01em;
}

.charge_limit_area .dot_lst li:before {
  background-color: #868686;
}

.charge_limit_area .dot_lst li ~ li {
  margin-top: 0;
}

.chart_area {
  margin-top: 58px;
}

.chart_area .area_tit {
  font-size: 24px;
  letter-spacing: -0.02em;
}

.chart_area .chart_bx {
  height: 353px;
  margin: 100px 0 60px;
  border-bottom: 1px solid #ccc;
  font-size: 0;
}

.chart_area .chart_bar {
  display: inline-block;
  position: relative;
  bottom: 0;
  width: 80px;
  height: 100%;
  margin-left: 186px;
  color: #222;
}

.chart_area .chart_bar:first-child {
  margin-left: 0;
}

.chart_area .chart_bar .value {
  position: absolute;
  top: -28px;
  width: 100%;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
}

.chart_area .chart_bar .bar {
  display: block;
  position: absolute;
  bottom: 0;
  width: 80px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #404dab;
}

.chart_area .chart_bar .time {
  position: absolute;
  bottom: -60px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.chart_area .chart_bar .point_txt {
  font-weight: bold;
  color: #404dab;
}

.service_page .wrap {
  padding-top: 90px;
}

.service_page .breadcrumb .select_lst {
  width: 155px;
}

.service_page .inner .emp_txt {
  margin-top: 22px;
  font-size: 18px;
  letter-spacing: -0.3px;
  color: #222;
}

.service_page .inner .emp_txt .btn {
  margin-left: 30px;
}

.service_page .inner > .clearfix .menu_depth_3, .service_page .inner > .attach_box .menu_depth_3, .service_page .inner > .sl_server .menu_depth_3, .service_page .inner > .sl_char .menu_depth_3 {
  float: left;
  margin-top: 0;
}

.service_page .inner > .clearfix .menu_depth_3 .tab_lst, .service_page .inner > .attach_box .menu_depth_3 .tab_lst, .service_page .inner > .sl_server .menu_depth_3 .tab_lst, .service_page .inner > .sl_char .menu_depth_3 .tab_lst {
  width: auto;
}

.service_page .inner > .clearfix .search_box, .service_page .inner > .attach_box .search_box, .service_page .inner > .sl_server .search_box, .service_page .inner > .sl_char .search_box {
  float: right;
}

.service_page .inner > .clearfix:first-child, .service_page .inner > .attach_box:first-child, .service_page .inner > .sl_server:first-child, .service_page .inner > .sl_char:first-child {
  margin-top: 13px;
}

.service_page h2.sub_tit {
  font-weight: 400;
}

.service_page .select .btn_open_select {
  color: #868686;
}

.service_page .select.open .btn_open_select {
  color: #222222;
}

.service_page .flow_guide_area {
  position: relative;
  text-align: center;
}

.service_page .flow_guide_area .flow_lst {
  margin-top: 39px;
}

.service_page .flow_guide_area .flow_lst li.active {
  margin-top: -16px;
}

.service_page .flow_guide_area .flow_lst.flow_v2 {
  margin-top: 25px;
}

.service_page .flow_guide_area .flow_lst.flow_v2 li.active {
  margin-top: 0;
}

.service_page .flow_guide_area .btn_flow {
  display: block;
  position: absolute;
  left: 50%;
  top: 71px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.service_page .flow_guide_area .btn_flow span {
  display: block;
}

.service_page .flow_guide_area .btn_flow.prev {
  margin-left: -106px;
}

.service_page .flow_guide_area .btn_flow.next {
  margin-left: 100px;
}

.service_page .sp_common_area {
  margin-top: 60px;
}

.service_page .sp_common_area h3 {
  font-size: 24px;
  font-weight: 400;
  color: #222222;
  letter-spacing: -1.4px;
  line-height: 22px;
}

.service_page .sp_common_area h3 .btn {
  margin-left: 30px;
  vertical-align: text-top;
}

.service_page .sp_common_area h3 em {
  display: inline-block;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #404dab;
  vertical-align: text-bottom;
  letter-spacing: 0;
}

.service_page .sp_common_area h3 small {
  display: inline-block;
  margin-left: 30px;
  font-size: 16px;
  font-weight: 300;
}

.service_page .sp_common_area h3 + .dot_lst {
  margin-top: 21px;
}

.service_page .sp_common_area p {
  margin-top: 11px;
  line-height: 33px;
  letter-spacing: -0.1px;
  word-wrap: break-word;
  font-weight: 300;
}

.service_page .sp_common_area p strong {
  font-weight: 400;
  color: #222222;
}

.service_page .sp_common_area p .emp_txt {
  margin-top: 0;
}

.service_page .sp_common_area p + .tbl_lst {
  margin-top: 20px;
}

.service_page .sp_common_area p span.btn {
  margin: 0 4px;
  line-height: 23px;
}

.service_page .sp_common_area p + img {
  margin-top: 22px;
}

.service_page .sp_common_area img + .tbl_lst {
  margin-top: 30px;
}

.service_page .sp_common_area .dot_lst {
  margin-top: 14px;
}

.service_page .sp_common_area .dot_lst li ~ li {
  margin-top: 9px;
}

.service_page .sp_common_area .tbl_lst {
  margin-top: 20px;
  border-top: 1px solid #a4a4a4;
}

.service_page .sp_common_area .tbl_lst + .gray_bx {
  margin-top: 30px;
}

.service_page .sp_common_area .tbl_lst.txt_left th,
.service_page .sp_common_area .tbl_lst.txt_left td {
  padding-left: 30px;
}

.service_page .sp_common_area .emp_txt {
  display: block;
  margin-top: 13px;
  font-weight: 500;
  font-size: 15px;
}

.service_page .sp_common_area ~ .sp_common_area {
  margin-top: 78px;
}

.service_page .sp_common_area + .tbl_lst {
  margin-top: 56px;
}

.service_page .sp_common_area + .tbl_lst ~ .tbl_lst {
  margin-top: 60px;
}

.service_page .sp_common_area + .tabs_lnk_lst {
  margin-top: 28px;
}

.service_page .sp_common_area .clearfix .btn_grp, .service_page .sp_common_area .attach_box .btn_grp, .service_page .sp_common_area .sl_server .btn_grp, .service_page .sp_common_area .sl_char .btn_grp {
  margin-top: 25px;
}

.service_page .sp_common_area .clearfix .btn_grp .btn, .service_page .sp_common_area .attach_box .btn_grp .btn, .service_page .sp_common_area .sl_server .btn_grp .btn, .service_page .sp_common_area .sl_char .btn_grp .btn {
  width: 100px;
}

.service_page .sp_common_area .clearfix + .tbl_lst, .service_page .sp_common_area .attach_box + .tbl_lst, .service_page .sp_common_area .sl_server + .tbl_lst, .service_page .sp_common_area .sl_char + .tbl_lst {
  margin-top: 14px;
}

.service_page .sp_common_area .txt_note {
  margin-top: 20px;
  letter-spacing: -1.2px;
}

.service_page .sp_common_area .txt_note .btn_sm {
  height: 26px;
  margin: 3px 7px 0;
  font-size: 12px;
  line-height: 26px;
  vertical-align: top;
}

.service_page .sp_common_area .term_txt_bx {
  margin-top: 21px;
  padding: 40px 0;
  border-top: 1px solid #a4a4a4;
  border-bottom: 1px solid #a4a4a4;
  font-weight: 300;
}

.service_page .sp_common_area .term_txt_bx .js_scrollbar {
  max-height: 350px;
}

.service_page .sp_common_area .term_txt_bx .js_scrollbar .sb-scrollbar-container {
  right: 0;
}

.service_page .sp_common_area .term_txt_bx .js_scrollbar .sb-content {
  width: calc(100% + 17px);
  padding: 0 30px;
}

.service_page .sp_common_area .term_txt_bx .term_tit {
  font-size: 20px;
  font-weight: bold;
}

.service_page .sp_common_area .term_txt_bx strong {
  font-weight: 500;
}

.service_page .sp_common_area .term_txt_bx ul {
  line-height: 25px;
}

.service_page .sp_common_area .term_txt_bx .disc_lst {
  list-style: inside disc;
}

.service_page .sp_common_area .agr_chk_bx {
  margin-top: 18px;
  padding: 35px 40px;
}

.service_page .sp_common_area .agr_chk_bx label {
  padding-left: 40px;
  font-size: 22px;
  font-weight: 500;
  color: #222222;
}

.service_page .sp_common_area .agr_chk_bx .chk_detail {
  display: block;
  margin-top: 6px;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 300;
}

.service_page .btn_grp {
  margin-top: 51px;
  text-align: center;
}

.service_page .btn_grp .btn {
  vertical-align: top;
}

.service_page .btn_grp.cs_btn_grp {
  margin-top: 120px;
}

.service_page .btn_grp.ct_btn_grp {
  margin-top: 130px;
}

.service_page .btn_grp.ge_btn_grp {
  margin-top: 60px;
}

.service_page .sub_sticky + .inner .sp_common_area:first-child {
  margin-top: 12px;
}

.service_page .sub_sticky + .inner .sp_common_area h3 {
  font-weight: 500;
}

.service_page .service_product_area {
  margin-top: 29px;
  padding-bottom: 50px;
  border-bottom: 1px solid #a4a4a4;
}

.service_page .ser_pro_lst {
  font-size: 0;
}

.service_page .ser_pro_lst li {
  display: inline-block;
  position: relative;
  width: 442px;
  height: 207px;
  padding: 0 38px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
}

.service_page .ser_pro_lst li .ser_pro_cont {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
}

.service_page .ser_pro_lst li:after {
  display: none;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: rgba(0, 0, 0, 0.8);
  content: '';
}

.service_page .ser_pro_lst li ~ li:not(:nth-child(3n+1)) {
  margin-left: 42px;
}

.service_page .ser_pro_lst li:nth-child(3) ~ li {
  margin-top: 42px;
}

.service_page .ser_pro_lst li:hover:after, .service_page .ser_pro_lst li:hover .btn_grp {
  display: block;
}

.service_page .ser_pro_lst .tag {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 66px;
  height: 30px;
  background: #404dab;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}

.service_page .ser_pro_lst .guideline {
  width: 250px;
  min-height: 100px;
  word-break: break-word;
}

.service_page .ser_pro_lst .guideline strong {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  color: #000;
}

.service_page .ser_pro_lst .guideline p {
  margin-top: 7px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: #222222;
}

.service_page .ser_pro_lst .guideline small {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 300;
  color: #868686;
}

.service_page .ser_pro_lst .price {
  display: block;
  margin-top: 1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
}

.service_page .ser_pro_lst .price:before {
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
  content: '';
}

.service_page .ser_pro_lst .ico {
  position: absolute;
  left: 305px;
  top: 50%;
  transform: translateY(-50%);
}

.service_page .ser_pro_lst .btn_grp {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 100%;
  margin-top: 0;
  transform: translate(-50%, -50%);
}

.service_page .ser_pro_lst .btn_grp .btn {
  width: 130px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
}

.service_page .ser_pro_lst .btn_grp .btn ~ .btn {
  margin-left: 10px;
}

.service_page .tbl_lst th.txt_left {
  padding-left: 30px;
}

.service_page .tbl_lst .inp_txt {
  display: inline-block;
  width: auto;
}

.service_page .tbl_lst .inp_txt.id_inp {
  width: 225px;
}

.service_page .tbl_lst .inp_txt.id_inp + .btn {
  width: 100px;
  margin-left: 10px;
}

.service_page .tbl_lst .btn {
  font-size: 14px;
  vertical-align: middle;
  line-height: 36px;
}

.service_page .tbl_lst .select ~ .select {
  margin-left: 12px;
}

.service_page .tbl_lst dl {
  font-size: 0;
}

.service_page .tbl_lst dl dt, .service_page .tbl_lst dl dd {
  display: inline-block;
  font-size: 16px;
}

.service_page .tbl_lst dl dd {
  margin-left: 20px;
}

.service_page .tbl_lst dl dd ~ dt {
  margin-left: 30px;
}

.service_page .tbl_lst dl dd ~ dt:before {
  display: inline-block;
  width: 1px;
  height: 17px;
  margin-right: 30px;
  margin-top: 5px;
  background: #c8c8cf;
  vertical-align: top;
  content: '';
}

.service_page .tbl_lst .txt_msg {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  color: #b83d3d;
}

.service_page .tbl_lst .nodata {
  height: 219px;
}

.service_page .tbl_lst .nodata .txt_msg {
  margin-top: 17px;
  font-size: 16px;
  font-weight: 400;
  color: #868686;
}

.service_page .tbl_lst .nodata .ico {
  margin-top: 2px;
}

.service_page .registry_step_lst {
  margin-top: 40px;
}

.service_page .registry_step_lst + .sp_common_area {
  margin-top: 80px;
}

.service_page .registry_step_lst + .sp_common_area .dot_lst {
  margin-top: 13px !important;
}

.service_page .registry_step_lst + .sp_common_area .dot_lst .txt_reg {
  color: #222222;
}

.service_page .registry_step_lst .txt {
  white-space: nowrap;
}

.service_page .step_lst {
  margin-top: 22px;
  padding: 42px 40px;
}

.service_page .step_lst .lst_itm {
  position: relative;
  height: 246px;
  padding-top: 86px;
}

.service_page .step_lst .lst_itm .step_ico {
  position: absolute;
  top: 46px;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
}

.service_page .step_lst .lst_itm .step_tit {
  font-size: 20px;
}

.service_page .step_lst .lst_itm .step_desc {
  margin-top: 11px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.7px;
}

.service_page .sl_server, .service_page .sl_char {
  padding: 30px 40px 30px 50px;
  margin-top: 17px;
  font-size: 0;
}

.service_page .sl_server label, .service_page .sl_char label {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
}

.service_page .sl_server .select, .service_page .sl_char .select {
  width: 210px;
  margin-left: 35px;
}

.service_page .sl_server .btn.btn_aside, .service_page .sl_char .btn.btn_aside {
  height: 50px;
  line-height: 50px;
}

.service_page .sl_server .txt_gray, .service_page .sl_char .txt_gray {
  display: block;
  float: right;
  margin-top: 14px;
  letter-spacing: -0.35px;
  font-size: 14px;
  font-weight: 300;
}

.service_page .sl_server .txt_gray .btn_sm, .service_page .sl_char .txt_gray .btn_sm {
  min-width: 45px;
  height: 24px;
  margin: 0 3px;
  font-size: 13px;
  line-height: 24px;
  vertical-align: middle;
}

.service_page .sl_server .btn.btn_aside {
  width: 107px;
}

.service_page .sl_char .btn.btn_aside {
  width: 126px;
  margin-left: 55px;
  border-radius: 2px;
}

.service_page .tabs_lnk_lst + .tbl_lst {
  margin-top: 29px;
}

.service_page .tabs_lnk_lst:first-child {
  margin-top: 7px;
  letter-spacing: -0.8px;
}

.service_page .tabs_lnk_lst + .sp_common_area {
  margin-top: 70px;
}

.service_page .impossible_itms {
  width: 360px;
}

.service_page .id_classification {
  width: 203px;
}

.service_page .lock_char_tbl td .btn {
  width: 100px;
  margin-left: 60px;
  font-size: 14px;
  line-height: 36px;
  vertical-align: middle;
}

.service_page .lock_char_tbl td.txt_bold {
  font-weight: 400;
}

.service_page .lock_char_tbl td .txt_msg {
  margin-left: 55px;
}

.service_page .last_notice {
  margin-top: 127px;
}

.service_page .last_notice strong {
  font-size: 18px;
}

.service_page .last_notice .dot_lst {
  margin-top: 6px;
}

.service_page .last_notice .dot_lst li {
  margin-top: 0;
  line-height: 35px;
}

.service_page .last_notice .dot_lst li:before {
  top: 16px;
}

.service_page .complete_msg_area {
  margin-top: 127px;
  text-align: center;
  font-size: 0;
}

.service_page .complete_msg_area .ico_done {
  display: inline-block;
}

.service_page .complete_msg_area strong {
  display: block;
  margin-top: 32px;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -1.3px;
}

.service_page .complete_msg_area small {
  display: block;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: -0.3px;
}

.service_page .complete_msg_area small em {
  font-weight: 400;
  color: #222222;
}

.service_page .complete_msg_area .sv_info_bx {
  margin-top: 54px;
}

.service_page .complete_msg_area + .btn_grp {
  margin-top: 60px;
}

.service_page .result_area {
  margin-top: 55px;
}

.service_page .result_area .gray_bx {
  position: relative;
  padding: 42px 64px;
}

.service_page .result_area .gray_bx > span {
  font-size: 18px;
  font-weight: 500;
}

.service_page .result_area .gray_bx .res {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service_page .result_area .gray_bx .res .old_res {
  font-size: 20px;
  font-weight: 400;
  color: #868686;
}

.service_page .result_area .gray_bx .res .new_res {
  font-size: 20px;
  font-weight: 500;
  color: #404dab;
}

.service_page .result_area .gray_bx .res .ico_change_to {
  display: inline-block;
  margin: 0 60px;
}

.service_page .result_area .tbl_lst {
  margin-top: 30px;
}

.service_page .result_area + .btn_grp {
  margin-top: 61px;
}

.service_page .result_area + .btn_grp .btn {
  font-weight: 400;
}

.service_page .sv_info_bx {
  display: inline-block;
  width: 346px;
  height: 180px;
  padding: 40px 29px;
  margin-top: 20px;
  border: 1px solid #cccccc;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.service_page .sv_info_bx .info_txt {
  float: left;
  max-width: 186px;
}

.service_page .sv_info_bx .info_txt p {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.service_page .sv_info_bx .info_txt .price {
  display: block;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000;
}

.service_page .sv_info_bx .info_txt .price:before {
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  content: '';
}

.service_page .sv_info_bx .img_container {
  float: right;
  font-size: 0;
}

.service_page.guild_create .registry_step_lst + .sp_common_area {
  margin-top: -7px;
}

.service_page.guild_entrust_lst .sp_common_area .btn_grp.right {
  margin-top: 0;
}

.service_page .gc_btn_grp {
  margin-top: 130px;
}

.service_page .area_tit + p {
  margin-top: 25px;
}

.service_page .safe_login_area {
  margin-top: 11px;
}

.service_page .safe_login_area p {
  font-size: 18px;
}

.service_page .safe_login_area .dot_lst {
  margin-top: 12px;
}

.service_page .safe_login_area .dot_lst li ~ li {
  margin-top: 9px;
}

.service_page .safe_login_area .dot_lst + p {
  margin-top: 14px;
}

.service_page .safe_login_area .step_lst {
  margin-top: 46px;
  padding: 46px;
  text-align: center;
}

.service_page .safe_login_area .step_lst .lst_itm {
  width: 637px;
  height: 269px;
  padding-bottom: 30px;
}

.service_page .safe_login_area .step_lst .lst_itm ~ .lst_itm {
  margin-left: 40px;
}

.service_page .safe_login_area .step_lst .step_ico {
  top: 42px;
}

.service_page .safe_login_area .step_lst .step_tit {
  margin-top: 54px;
  font-size: 22px;
}

.service_page .safe_login_area .step_lst .step_tit.txt_blue {
  color: #404dab;
}

.service_page .safe_login_area .step_lst .btn.btn_lg {
  width: 130px;
  height: 48px;
  margin-top: 28px;
}

.service_page .safe_login_area .info_bx {
  margin-top: 46px;
}

.service_page .safe_login_area .phone_verify {
  margin-top: 30px;
}

.service_page .safe_login_area .phone_verify tr, .service_page .safe_login_area .phone_verify th, .service_page .safe_login_area .phone_verify td {
  min-height: 74px;
  border: none;
}

.service_page .safe_login_area .phone_verify tr:only-child {
  height: 76px;
}

.service_page .safe_login_area .phone_verify tr:not(:only-child):first-child th, .service_page .safe_login_area .phone_verify tr:not(:only-child):first-child td {
  padding-top: 5px;
}

.service_page .safe_login_area .phone_verify tr:not(:only-child):last-child th, .service_page .safe_login_area .phone_verify tr:not(:only-child):last-child td {
  padding-bottom: 15px;
}

.service_page .safe_login_area .phone_verify th {
  font-size: 14px;
}

.service_page .safe_login_area .phone_verify .inp_txt {
  width: 47px;
}

.service_page .safe_login_area .phone_verify .btn {
  margin-left: 39px;
}

.service_page .safe_login_area .phone_verify .verify_phone_bx input {
  font-size: 14px;
}

.service_page .safe_login_area .phone_verify .dot_lst {
  margin-top: -5px;
  font-size: 14px;
}

.service_page .safe_login_area .phone_verify .dot_lst li {
  margin-top: 2px;
}

.service_page .safe_login_area .phone_verify .dot_lst li:first-child {
  margin-top: 0;
}

.service_page .verify_guide_bx {
  display: block;
  margin-left: 30px;
  text-align: left;
}

.service_page .verify_guide_bx .btn {
  width: 51px;
  height: 23px;
  padding: 0;
  margin-left: 0 !important;
  font-size: 13px;
  line-height: 23px;
  vertical-align: baseline;
  font-weight: 400;
}

.service_page.vip_services .dot_lst.note_txt {
  margin-top: 19px;
}

.service_page.vip_services .dot_lst.note_txt li {
  font-size: 14px;
}

.service_page.vip_services .dot_lst.note_txt li:before {
  top: 5px;
}

.service_page.vip_services .dot_lst.note_txt li ~ li {
  margin-top: 17px;
}

.service_page.vip_services .btn_grp.right {
  margin-top: 15px;
}

.service_page.vip_services .btn_grp.right .btn {
  width: auto;
  padding: 0 30px;
}

.service_page.vip_services .service_info {
  padding: 42px 66px 42px 70px;
  margin-top: 26px;
  white-space: nowrap;
}

.service_page.vip_services .service_info .class_rate, .service_page.vip_services .service_info .class_progress {
  position: relative;
  display: inline-block;
  width: 635px;
  min-height: 201px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.service_page.vip_services .service_info .class_rate .rate {
  display: block;
  margin-top: 22px;
  font-size: 24px;
}

.service_page.vip_services .service_info .class_rate .rate span {
  display: inline-block;
  margin-left: 10px;
}

.service_page.vip_services .service_info .class_rate .rate span:before {
  display: inline-block;
  width: 1px;
  height: 15px;
  margin-right: 10px;
  background: #c8c8cf;
  vertical-align: middle;
  content: '';
}

.service_page.vip_services .service_info .class_rate dl {
  margin-top: 10px;
  font-size: 14px;
}

.service_page.vip_services .service_info .class_rate dl dd, .service_page.vip_services .service_info .class_rate dl dt {
  display: inline;
}

.service_page.vip_services .service_info .class_rate dl div ~ div {
  margin-top: 2px;
}

.service_page.vip_services .service_info .class_rate .btn.btn_lg {
  width: 162px;
  margin-top: 18px;
}

.service_page.vip_services .service_info .class_rate .rate_ico {
  position: absolute;
  right: 92px;
  top: 26px;
}

.service_page.vip_services .service_info .class_progress {
  padding-left: 87px;
  margin-left: -4px;
}

.service_page.vip_services .service_info .class_progress:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 200px;
  background: #ffffff;
  content: '';
}

.service_page.vip_services .service_info .class_progress .class_lst {
  margin-top: 40px;
  font-size: 0;
}

.service_page.vip_services .service_info .class_progress .class_lst li {
  display: inline-block;
  width: 138px;
  text-align: center;
}

.service_page.vip_services .service_info .class_progress .class_lst li.on .rate_tit {
  font-weight: 400;
  color: #404dab;
}

.service_page.vip_services .service_info .class_progress .progress_bar {
  position: absolute;
  top: 105px;
  overflow: hidden;
  width: 552px;
  height: 24px;
  font-size: 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: url("../img/service/progress_bar_bg.png") no-repeat;
}

.service_page.vip_services .service_info .class_progress .progress_bar li {
  position: relative;
  display: inline-block;
  max-width: 138px;
  height: 24px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.service_page.vip_services .service_info .class_progress .progress_bar li:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: inherit;
  content: '';
  background: #404dab;
}

.service_page.vip_services .service_info .class_progress .progress_bar li ~ li:before {
  -webkit-box-shadow: inset 1px 0px 0px 0px #5966be;
  -moz-box-shadow: inset 1px 0px 0px 0px #5966be;
  box-shadow: inset 1px 0px 0px 0px #5966be;
}

.service_page.vip_services .service_info .class_progress .rate_badge {
  display: block;
  margin: 0 auto;
}

.service_page.vip_services .service_info .class_progress .rate_tit {
  display: block;
  margin-top: 59px;
  font-size: 16px;
  font-weight: 300;
  color: #222222;
}

.service_page.vip_services .service_detail {
  margin-top: 80px;
}

.service_page.vip_services .service_detail [class^="ico_"] {
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.service_page.vip_services .service_detail .class_name {
  font-size: 16px;
  font-weight: 400;
}

.service_page.vip_services .service_detail .dot_lst {
  margin-top: 0;
}

.service_page.vip_services .service_detail .dot_lst li ~ li {
  margin-top: 6px;
}

.service_page.vip_services .service_detail td:nth-child(3) {
  padding: 28px 0;
}

.service_page.vip_services .service_detail .rate_area {
  padding-left: 102px;
  text-align: left;
}

.service_page .gray_bx.result {
  background: #f8f8f8;
}

.flow_lst {
  display: inline-block;
  font-size: 0;
}

.flow_lst li {
  display: inline-block;
  margin: 0 40px;
}

.flow_lst li .icon_container {
  position: relative;
  display: block;
  width: 92px;
  height: 92px;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #cccccc;
  font-size: 0;
}

.flow_lst li .icon_container span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.flow_lst li .flow_name {
  display: block;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 300;
  color: #868686;
  text-align: center;
  line-height: 18px;
}

.flow_lst li.active .icon_container {
  width: 113px;
  height: 113px;
  background: #384859;
}

.flow_lst li.active .flow_name {
  font-weight: bold;
  color: #022222;
}

.flow_lst.flow_v2 li {
  position: relative;
  margin: 0 42px;
}

.flow_lst.flow_v2 li:first-child:nth-last-child(6), .flow_lst.flow_v2 li:first-child:nth-last-child(6) ~ li {
  margin: 0 36px;
}

.flow_lst.flow_v2 li:first-child {
  margin-left: 0 !important;
}

.flow_lst.flow_v2 li:last-child {
  margin-right: 0 !important;
}

.flow_lst.flow_v2 li.active .flow_name {
  font-weight: 300;
}

.flow_lst.flow_v2 li.active .icon_container {
  width: 92px;
  height: 92px;
}

.flow_lst.flow_v2 li ~ li:before {
  position: absolute;
  top: 36px;
  left: -46px;
  content: '';
}

.tbl_fm {
  margin-top: 27px;
  font-size: 16px;
}

.tbl_fm .tbl_lst {
  border-top: none;
}

.tbl_fm th, .tbl_fm td {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 30px;
  text-align: left;
  vertical-align: middle;
}

.tbl_fm input[type="text"] {
  width: 100%;
  height: 36px;
  border: none;
  font-size: 14px;
  color: #222222;
  outline: none;
}

.tbl_fm input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 300;
  color: #a4a4a4;
  opacity: 1;
  /* Firefox */
}

.tbl_fm input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 300;
  color: #a4a4a4;
}

.tbl_fm input[type="text"]::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 300;
  color: #a4a4a4;
}

.preload_wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  background: #ffffff;
}

.preload_wrap .preload_bx {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preload_wrap .preload_bx p {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #5b5b5b;
}

.preload_wrap .preload_bx .logo_loading {
  display: block;
  position: relative;
  width: 265px;
  height: 84px;
  margin: 0 auto;
  background: url("../img/loading_logo.png") no-repeat;
}

.preload_wrap .preload_bx .logo_loading .logo_loading_fill {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: url("../img/loading_logo_fill.png") no-repeat left bottom/265px 84px;
}

.intro_page {
  background: #000;
}

.intro_page .wrap {
  overflow: auto;
  padding: 0;
  height: 100%;
}

.intro_page .content > div {
  max-width: 2400px;
  margin: 0 auto;
}

.intro_page .content > div .inner {
  width: 100%;
  max-width: 1920px;
  padding: 0;
}

.intro_page .animate_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.intro_page .intro_top_area {
  position: relative;
  overflow: hidden;
  height: 1108px;
}

.intro_page .ch_img_bx {
  position: absolute;
  top: 0;
  left: 330px;
  font-size: 0;
}

.intro_page .ch_img_bx.fade_fx {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.intro_page .top_cont_bx {
  position: absolute;
  left: 50%;
  top: 409px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.intro_page .top_cont_bx .fade_fx {
  transform: none;
}

.intro_page .top_cont_bx .init_fx.fade_fx {
  transform: translateY(30px);
}

.intro_page .restart_txt {
  display: block;
  font-size: 0;
  transition-delay: 700ms !important;
}

.intro_page .year_txt {
  display: block;
  margin-top: -103px;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 18px;
  transition-delay: 750ms !important;
}

.intro_page .intro_txt {
  display: block;
  margin-top: 17px;
  font-size: 34px;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: -0.4px;
  transition-delay: 800ms !important;
}

.intro_page .btn_down {
  display: inline-block;
  margin-top: 136px;
  font-size: 0;
  transition-delay: 850ms !important;
}

.intro_page .btn_down.fade_fx {
  transition-property: opacity, transform;
}

.intro_page .area_tit {
  display: block;
  padding-left: 18px;
  font-size: 18px;
  font-weight: bold;
  line-height: 13px;
  color: #e80000;
  letter-spacing: 18px;
  text-indent: 18px;
  text-align: center;
}

.intro_page .sub_txt {
  display: block;
  margin-top: 31px;
  font-family: 'NanumMyeongjo', sans-serif;
  font-size: 32px;
  color: #292929;
}

.intro_page .desc_txt {
  display: block;
  margin-top: 19px;
  font-size: 16px;
  line-height: 26px;
}

.intro_page .story_area {
  overflow: hidden;
  height: 398px;
  padding-top: 112px;
  text-align: center;
  background: url("../img/intro/story_bg.jpg") no-repeat center/auto 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.intro_page .story_area .inner {
  position: relative;
}

.intro_page .story_area .sub_txt.init_fx.fade_fx, .intro_page .story_area .desc_txt.init_fx.fade_fx {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.intro_page .story_area .sub_txt.fade_fx, .intro_page .story_area .desc_txt.fade_fx {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.intro_page .story_area .sub_txt {
  transition-delay: 400ms !important;
}

.intro_page .story_area .desc_txt {
  transition-delay: 450ms !important;
  color: rgba(0, 0, 0, 0.7);
}

.intro_page .story_area .story_obj1, .intro_page .story_area .story_obj2 {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -moz-transition-timing-function: linear !important;
}

.intro_page .story_area .story_obj1 {
  margin-left: -757px;
  top: -65px;
}

.intro_page .story_area .story_obj1.init_fx.fade_fx {
  margin-left: -807px;
}

.intro_page .story_area .story_obj2 {
  margin-left: 622px;
  top: 73px;
}

.intro_page .story_area .story_obj2.init_fx.fade_fx {
  margin-left: 672px;
}

.intro_page .feature_area {
  height: 669px;
  padding-top: 141px;
  text-align: center;
  background: url("../img/intro/feature_bg.jpg") no-repeat center/auto 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.intro_page .feature_area .feature_lst {
  margin-top: 49px;
  font-size: 0;
}

.intro_page .feature_area .feature_lst li {
  display: inline-block;
  margin: 0 120px;
  text-align: center;
}

.intro_page .feature_area .feature_lst li > *.init_fx.fade_fx {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.intro_page .feature_area .feature_lst li > *.fade_fx {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.intro_page .feature_area .feature_lst li .ico_bx {
  transition-delay: 0.25s !important;
}

.intro_page .feature_area .feature_lst li .ft_name {
  transition-delay: 0.4s !important;
}

.intro_page .feature_area .feature_lst li .ft_desc {
  transition-delay: 0.55s !important;
}

.intro_page .feature_area .feature_lst .ico_bx {
  display: block;
  position: relative;
  height: 134px;
}

.intro_page .feature_area .feature_lst .ico {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.intro_page .feature_area .feature_lst .ft_name {
  display: inline-block;
  margin-top: 37px;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
}

.intro_page .feature_area .feature_lst .ft_desc {
  display: block;
  margin-top: 13px;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: #b3b3b2;
}

.intro_page .character_area {
  position: relative;
}

.intro_page .character_area .inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_page .character_area .area_tit {
  position: absolute;
  top: 143px;
  left: 50%;
  z-index: 999;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_page .character_area .ch_lst li {
  position: relative;
}

.intro_page .character_area .ch_lst li.human_ch .ch_img.init_fx.fade_fx {
  right: -209px;
}

.intro_page .character_area .ch_lst li.elf_ch .ch_img.ch_img1 {
  margin-left: -597px;
}

.intro_page .character_area .ch_lst li.elf_ch .ch_img.ch_img1.init_fx.fade_fx {
  margin-left: -627px;
}

.intro_page .character_area .ch_lst li.elf_ch .ch_img.ch_img2 {
  margin-left: 468px;
}

.intro_page .character_area .ch_lst li.elf_ch .ch_img.ch_img2.init_fx.fade_fx {
  margin-left: 498px;
}

.intro_page .character_area .ch_lst li.half_elf_ch .ch_img.init_fx.fade_fx {
  left: -274px;
}

.intro_page .character_area .ch_lst li.dhan_ch .fade_fx {
  -webkit-transition-duration: 1.5s;
  -moz-transition-duration: 1.5s;
  -ms-transition-duration: 1.5s;
  -o-transition-duration: 1.5s;
  transition-duration: 1.5s;
}

.intro_page .character_area .ch_lst li.dhan_ch .ch_img.ch_img1 {
  margin-left: -508px;
}

.intro_page .character_area .ch_lst li.dhan_ch .ch_img.ch_img1.init_fx.fade_fx {
  margin-left: -538px;
}

.intro_page .character_area .ch_lst li.dhan_ch .ch_img.ch_img2 {
  margin-left: 446px;
}

.intro_page .character_area .ch_lst li.dhan_ch .ch_img.ch_img2.init_fx.fade_fx {
  margin-left: 476px;
}

.intro_page .character_area .ch_lst li.dark_elf_ch .ch_img.ch_img1 {
  margin-left: -581px;
}

.intro_page .character_area .ch_lst li.dark_elf_ch .ch_img.ch_img1.init_fx.fade_fx {
  margin-left: -611px;
}

.intro_page .character_area .ch_lst li.dark_elf_ch .ch_img.ch_img2 {
  margin-left: 644px;
}

.intro_page .character_area .ch_lst li.dark_elf_ch .ch_img.ch_img2.init_fx.fade_fx {
  margin-left: 674px;
}

.intro_page .character_area .ch_lst li.dekan_ch .ch_txt_bx.txt_right + .ch_img {
  right: 21px;
}

.intro_page .character_area .ch_lst li.dekan_ch .ch_txt_bx.txt_right + .ch_img.init_fx.fade_fx {
  right: -11px;
}

.intro_page .character_area .ch_lst li.trinity_ch .ch_txt_bx.txt_right + .ch_img {
  right: -82px;
}

.intro_page .character_area .ch_lst li.trinity_ch .ch_txt_bx.txt_right + .ch_img.init_fx.fade_fx {
  right: -112px;
}

.intro_page .character_area .ch_lst li.giant_ch .ch_txt_bx.txt_left + .ch_img {
  left: -75px;
}

.intro_page .character_area .ch_lst li.giant_ch .ch_txt_bx.txt_left + .ch_img.init_fx.fade_fx {
  left: -105px;
}

.intro_page .character_area .ch_lst .ch_img, .intro_page .character_area .ch_lst .ch_txt_bx {
  position: absolute;
}

.intro_page .character_area .ch_lst .ch_img {
  bottom: 0;
  top: auto;
  left: auto;
  transition-duration: 0.5s;
}

.intro_page .character_area .ch_lst .ch_txt_bx {
  top: 373px;
  z-index: 99;
}

.intro_page .character_area .ch_lst .ch_txt_bx .init_fx.fade_fx {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.intro_page .character_area .ch_lst .ch_txt_bx .fade_fx {
  transition-duration: 2s;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.intro_page .character_area .ch_lst .ch_txt_bx.txt_right {
  right: 947px;
}

.intro_page .character_area .ch_lst .ch_txt_bx.txt_right + .ch_img {
  right: -179px;
}

.intro_page .character_area .ch_lst .ch_txt_bx.txt_left {
  left: 956px;
}

.intro_page .character_area .ch_lst .ch_txt_bx.txt_left + .ch_img {
  left: -244px;
}

.intro_page .character_area .ch_lst .ch_txt_bx.txt_center {
  right: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_page .character_area .ch_lst .ch_txt_bx.txt_center ~ .ch_img {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_page .character_area .ch_lst .bg_img_bx {
  position: relative;
  overflow: hidden;
  height: 1087px;
}

.intro_page .character_area .ch_lst .bg_img_bx img {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_page .character_area .ch_lst .type {
  display: block;
  margin-right: -18px;
  font-size: 18px;
  font-weight: 400;
  color: #cab18c;
  letter-spacing: 18px;
  transition-delay: 0.7s !important;
}

.intro_page .character_area .ch_lst .name {
  display: inline-block;
  position: relative;
  font-size: 0;
  transition-delay: 0.75s !important;
}

.intro_page .character_area .ch_lst .name:after {
  position: absolute;
  top: 100%;
  width: 28px;
  height: 2px;
  margin-top: 30px;
  background: #cab18c;
  content: '';
}

.intro_page .character_area .ch_lst .txt_right .name:after {
  right: 0;
}

.intro_page .character_area .ch_lst .txt_center .name:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.intro_page .character_area .ch_lst .txt_left .name:after {
  left: 0;
}

.intro_page .character_area .ch_lst .sub_txt {
  margin-top: 59px;
  font-size: 40px;
  color: #222222;
  letter-spacing: -0.7px;
  transition-delay: 0.8s !important;
}

.intro_page .character_area .ch_lst .desc {
  margin-top: 9px;
  font-weight: 300;
  color: #868686;
  line-height: 25px;
  transition-delay: 0.85s !important;
}

.intro_page .character_area .ch_lst .sld_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: none;
  z-index: 999;
}

.intro_page .character_area .ch_lst .sld_btn span {
  display: block;
}

.intro_page .character_area .ch_lst .sld_btn.prev_btn {
  margin-left: -683px;
}

.intro_page .character_area .ch_lst .sld_btn.next_btn {
  margin-left: 691px;
}

.intro_page .character_area .ch_lst .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 118px;
  z-index: 999;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0;
}

.intro_page .character_area .ch_lst .slick-dots li {
  display: inline-block;
}

.intro_page .character_area .ch_lst .slick-dots li button {
  display: block;
  font-size: 0;
  outline: none;
}

.intro_page .character_area .ch_lst .slick-dots li ~ li {
  margin-left: 5px;
}

.intro_page .content > div.monster_area {
  position: relative;
  overflow: hidden;
  height: 1538px;
  padding-top: 142px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.intro_page .content > div.monster_area .inner {
  max-width: 1410px;
  height: 100%;
}

.intro_page .content > div.monster_area .monster_info {
  position: absolute;
  top: 366px;
  left: 121px;
  text-align: right;
}

.intro_page .content > div.monster_area .monster_info > *.init_fx.fade_fx {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.intro_page .content > div.monster_area .monster_info > *.fade_fx {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.intro_page .content > div.monster_area .monster_info .name {
  display: inline-block;
  position: relative;
  font-size: 0;
  -webkit-transition-property: opacity, transform;
  -moz-transition-property: opacity, transform;
  -ms-transition-property: opacity, transform;
  -o-transition-property: opacity, transform;
  transition-property: opacity, transform;
}

.intro_page .content > div.monster_area .monster_info .name:after {
  position: absolute;
  top: 100%;
  right: 0;
  width: 28px;
  height: 2px;
  margin-top: 30px;
  background: #cab18c;
  content: '';
}

.intro_page .content > div.monster_area .monster_info .sub_txt {
  margin-top: 59px;
  font-size: 40px;
  color: #ffffff;
  letter-spacing: -0.7px;
  transition-delay: 50ms !important;
}

.intro_page .content > div.monster_area .monster_info .desc {
  margin-top: 12px;
  font-weight: 300;
  color: #a4a4a4;
  line-height: 25px;
  transition-delay: 100ms !important;
}

.intro_page .content > div.monster_area .monster_info .intro_btn {
  display: inline-block;
  margin-top: 55px;
  font-size: 18px;
  font-weight: 400;
  color: #222222;
  outline: none;
  transition-delay: 150ms !important;
  transition-property: opacity, transform;
}

.intro_page header {
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -ms-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}

.intro_page footer {
  position: absolute;
  bottom: 89px;
  left: 0;
  width: 100%;
  margin-bottom: -89px;
  background: transparent;
  border-top: none;
  color: rgba(255, 255, 255, 0.3);
  line-height: 22px;
}

.intro_page .fade_fx {
  -webkit-transition: all 1.1s cubic-bezier(0.31, 1.02, 0.93, 1.01);
  -moz-transition: all 1.1s cubic-bezier(0.31, 1.02, 0.93, 1.01);
  -ms-transition: all 1.1s cubic-bezier(0.31, 1.02, 0.93, 1.01);
  -o-transition: all 1.1s cubic-bezier(0.31, 1.02, 0.93, 1.01);
  transition: all 1.1s cubic-bezier(0.31, 1.02, 0.93, 1.01);
  opacity: 1;
}

.intro_page .init_fx {
  opacity: 0;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.intro_page .bg_fx .animate_bg {
  transition: height 7s ease-out;
}

.intro_page .zoomed .animate_bg {
  height: 115%;
}

.intro_page .intro_close_btn {
  position: fixed;
  top: 110px;
  right: 37px;
  z-index: 999;
  font-size: 0;
}

.intro_page .ico_top_btn, .intro_page .ico_down_btn {
  display: block;
  position: fixed;
  bottom: 110px;
  right: 47px;
  font-size: 0;
}
