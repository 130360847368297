.board_area,
.view_area {
    .name {
        font-size: 14px;
        color: $gray2;
        text-align: center;
    
        &:before {
            display: inline-block;
            margin-right: 5px;
            @include sp-normal('ico_person');
            vertical-align: middle;
            content: '';
        }
    }

    .comment {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: $gray2;
        text-align: center;
    
        &:before {
            display: inline-block;
            margin-right: 5px;
            @include sp-normal('ico_comment');
            vertical-align: middle;
            content: '';
        }
    }
    
    .view {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: $gray2;
        text-align: center;
    
        &:before {
            display: inline-block;
            margin-right: 5px;
            @include sp-normal('ico_eye');
            vertical-align: middle;
            content: '';
        }
    }
    
    .time {
        font-family: 'Roboto', 'notosans', sans-serif;
        font-size: 14px;
        color: $gray2;
        text-align: center;
    
        &:before {
            display: inline-block;
            margin-right: 5px;
            @include sp-normal('ico_clock');
            vertical-align: middle;
            content: '';
        }
    }

    .heart {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: $gray2;
        text-align: center;

        &:before {
            display: inline-block;
            margin-right: 5px;
            @include sp-normal('ico_heart');
            vertical-align: middle;
            content: '';
        }
    }
}

.board_area {
    .btn_wrap {
        margin-top: 16px;
        text-align: right;

        &+.pagination {
            margin-top: 35px;
        }
    }

    .search_box {
        margin-top: 40px;
        font-size: 0;
        text-align: center;
    }

    .area_tit {
        margin-bottom: 23px;
        line-height: 42px;
    }
}

.txt_new {
    display: inline-block;
    margin-left: 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #e80000;
    vertical-align: top;
}

.btn_grp.pop {
    font-size: 0;

    .btn {
        overflow: hidden;
        width: auto;
        height: 28px;
        padding: 0 17px;
        border: 1px solid $gray3;
        background-color: $white;
        font-size: 14px;
        font-weight: 400;
        color: $gray2;
        line-height: 30px;

        &+.btn {
            margin-left: 10px;
        }
    }

    &+div {
        margin-top: 32px;
    }
}