.support_page {
    .view_area {
        margin-top: 45px;
        .ct {
            padding-bottom: 38px;
        }
    }
}

.inquiry_area {
    .menu_depth_3 {
        margin-top: 39px;

        .tab_itm {
            a {
                min-width: 238px;
            }

            &+.tab_itm {
                margin-left: 16px;
            }
        }
    }

    .txt_note {
        margin-top: 26px;
        font-size: 14px;
        font-weight: 300;
        color: $gray2;
        letter-spacing: -0.02em;

        &+.tbl_lst {
            margin-top: 27px;
        }
    }
}

.search_area {
    margin: 80px 0;
    padding: 54px 68px 32px;
    background-color: #f8f8f8;

    .tit {
        display: inline-block;
        font-size: 28px;
        line-height: 57px;
        letter-spacing: -0.01em;
        vertical-align: top;
    }

    .search_box {
        display: inline-block;
        margin-left: 62px;
    }

    .search_inp input {
        width: 910px;
        font-size: 14px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #a4a4a4;
            font-weight: 300;
            letter-spacing: -0.02em;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #a4a4a4;
            font-weight: 300;
            letter-spacing: -0.02em;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #a4a4a4;
            font-weight: 300;
            letter-spacing: -0.02em;
        }
    }

    .filter_lst {
        margin-top: 7px;

        li {
            display: inline-block;
            position: relative;
            margin-left: 26px;

            &:before {
                position: absolute;
                top: 5px;
                left: -14px;
                width: 1px;
                height: 12px;
                background-color: #c5c5cc;
                content: '';
            }

            &:first-child {
                margin-left: 0;

                &:before {
                    content: none;
                }
            }

            a {
                font-size: 14px;
                font-weight: 300;
                color: $gray2;
            }
        }
    }

    .btn {
        width: 162px;
        height: 50px;
        margin-left: 27px;
        line-height: 50px;
        vertical-align: top;
    }
}

.board_area {
    .tabs_server_lst {
        float: none;

        &+.tbl_lst {
            margin-top: 28px;
        }

        &~.pagination {
            margin-top: 87px;
        }
    }
}

.report_lst {
    li {
        width: 100%;
        margin-top: 14px;

        &:first-child {
            margin-top: 0;
        }
    }

    .report_itm {
        display: table;
        width: 100%;
        height: 133px;
        background-color: #f2f2f2;

        &>div {
            display: table-cell;
            vertical-align: middle;
        }

        .ico_box {
            width: 160px;
            padding-left: 20px;
            text-align: center;
            box-sizing: border-box;

            .ico {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .tit_box {
            width: 260px;

            .tit {
                font-family: 'notosans', sans-serif;
                font-size: 28px;
                font-weight: 500;
                vertical-align: middle;
                letter-spacing: -0.05em;
            }
        }

        .content_box {
            width: 718px;
            padding-left: 13px;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: -0.025em;
            box-sizing: border-box;

            .desc {
                vertical-align: middle;
                color: $black2;
            }

            .note {
                color: #a4a4a4;
            }
        }

        .btn_box {
            text-align: center;

            .btn {
                width: 130px;
                height: 48px;
                line-height: 48px;
                font-size: 16px;
                letter-spacing: -0.02em;
            }
        }
    }
}

.report_area {
    .area_tit {
        line-height: 48px;
    }

    .report_lst {
        margin-top: 16px;
    }

    &~.report_area {
        margin-top: 41px;
    }
}

.card_itm {
    display: block;
    height: 213px;
    padding-top: 42px;
    background-color: #fff;
    font-size: 0;
    text-align: center;
    box-sizing: border-box;

    .ico {
        display: block;
        margin: 0 auto;
    }

    .info {
        display: inline-block;
        margin-top: 10px;
        vertical-align: top;

        .tit {
            display: block;
            font-size: 22px;
            font-weight: 400;
            letter-spacing: -0.02em;
            color: #101010;
        }
    
        .desc {
            display: block;
            margin-top: 4px;
            font-size: 14px;
            font-weight: 300;
            line-height: 17px;
            letter-spacing: -0.02em;
            color: #222;
        }
    }
}

.card_lst {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-top: 25px;
    padding: 45px 19px 35px;
    background-color: #f2f2f2;
    box-sizing: border-box;

    li {
        display: table-cell;
        padding: 0 19px;
        vertical-align: middle;
    }
}

.support_fm {
    margin-top: 25px;
    font-size: 16px;

    .tbl_lst {
        border-top: none;
    }

    th,td {
        height: auto;
        border-top:1px solid #e5e5e5;
        border-bottom:1px solid #e5e5e5;
        vertical-align: top;

        &>div {
            display: table-cell;
            min-height: 70px;
            vertical-align: middle;
        }
    }

    th{
        &> div {
            display: block;
            padding-left: 30px;
            line-height: 70px;
            text-align: left;
        }
    }

    td {
        padding-left: 30px;
        text-align: left;
        vertical-align: middle;

        &>div {
            width: 795px;
        }
    }

    input[type="text"] {
        width: 100%;
        height: 36px;
        border:none;
        font-size: 14px;
        color: #222222;
        outline: none;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #a4a4a4;
            font-weight: 300;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #a4a4a4;
            font-weight: 300;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #a4a4a4;
            font-weight: 300;
        }

        &.phone {
            position: relative;
            width: 35px;
            margin: 0;
            font-size: 18px;
            text-align: center;
            box-sizing: border-box;

            &.num2 {
                width: 45px;
            }
        }
    }

    .dash {
        font-size: 18px;
        font-weight: 300;
        padding: 0 12px;
    }

    .dot_lst {
        margin-top: 24px;

        .dash_lst {
            margin-left: -4px;
        }
    }
}

.datepicker_area {
    .tui-datepicker {
        top: 53px;
        left: -13px;
        z-index: 1;
        border: 1px solid #ebebeb;

        &:before {
            position: absolute;
            top: -7px;
            left: 50%;
            @include sp-normal('ico_arr_calendar');
            transform: translateX(-50%);
            content: '';
        }

        &.tui-rangepicker .tui-is-selectable.tui-is-selected {
            background-color: #404dab;
        }
    }

    .tui-datepicker-input {
        height: 46px;
        border: 1px solid #ebebeb;
        box-sizing: border-box;

        input {
            padding-left: 13px;
            font-size: 14px;
            color: $black2;
            letter-spacing: -0.02em;
        }

        &+.select {
            width: 100px;
            height: 46px;
            margin-left: 10px;
        }
    }

    .tui-datepicker-body {
        width: 320px;
    }

    .tui-calendar {
        width: 100%;

        tr {
            border-top: none;
        }

        th {
            background-color: $white;
            font-size: 12px;
            text-align: center;
            padding: 0;
        }

        td {
            text-align: center;
        }
    }

    .tui-calendar-header {
        border-bottom: 0;
    }

    .tui-calendar-header-inner {
        height: 60px;
    }

    .tui-calendar-header-info {
        display: none;
    }

    .tui-calendar-title {
        line-height: 28px;
    }

    .tui-calendar-btn {
        width: 66px;
        height: 60px;
    }

    .tui-calendar-body {
        width: 260px;
        margin: 0 auto;
    }

    .tui-calendar-body-inner {
        td {
            height: 38px;
            border-top: 0;
            color: $black2;

            &.tui-is-blocked {
                color: #a4a4a4;
            }
        }
    }

    .tui-calendar-body-header th {
        height: 37px;
        border-bottom: 1px solid #e5e5e5;
        color: $black2;
    }

    .select {
        button {
            padding-left: 15px;
        }
    }

    .ico_calendar {
        display: inline-block;
        position: absolute;
        top: 12px;
        right: 11px;
    }

    .txt {
        display: inline-block;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 300;
        line-height: 44px;
        color: $black2;
        vertical-align: top;
    }
}