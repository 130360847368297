.layer {
    display: none;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    padding: 50px 0;
    font-size: 0;
    text-align: center;
    background: rgba(0,0,0,0.6);
    &:before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: '';
    }
}

.popup {
    display: none;
    position: relative;
    z-index: 10001;
    width: 1410px;
    margin: 50px auto;
    padding: 26px 60px 60px;
    background-color: #fff;
    text-align: left;
    box-sizing: border-box;
    vertical-align: middle;

    .tbl_lst {
        em {
            font-weight: 400;
        }
    }

    &.guild_popup {
        width: 1092px;
        font-size: 16px;
        .popup_ct {
            margin-top: 27px;
            p {
                font-size: 16px;
                font-weight: 300;
                color: #222222;
            }
        }
    }

    &.calc_guide_pop {
        width: 932px;
        height: 420px;
        font-size: 16px;
        .popup_ct {
            margin-top: 25px;
            p {
                font-size: 16px;
                font-weight: 300;
                color: #222222;
            }
        }
        .sb-container {
            width: calc(100% + 30px);
        }
        .sb-content {
            max-height: 199px !important;
        }
    }

    &.invite_adv_pop, &.info_coupon_return {
        width: 932px;
        height: 420px;
        .sb-container {
            width: calc(100% + 30px);
        }
        .sb-content {
            max-height: 243px !important;
            .dot_lst {
                margin-top: 30px;
            }
        }
    }

    &.support_popup {
        width: 1092px;
        font-size: 16px;

        .popup_ct {
            margin-top: 25px;

            &>strong {
                display: block;
                margin: 21px 0 10px;
                color: $black2;

                &:first-child {
                    margin-top: 0;
                }
            }

            .select {
                width: 130px;

                &+.select {
                    margin-left: 6px;
                }
            }

            .btn_authenticate {
                width: 104px;
                margin-left: 22px;

                &.grayfill2 {
                    width: 92px;
                }
            }

            .certification_box {
                input {
                    width: 104px;
                }
            }
        }

        .dash_lst {
            margin-top: 12px;
    
            li {
                margin-top: 4px;
                padding-left: 10px;
                font-size: 14px;
                color: $gray2;
                line-height: 22px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &.sanctions_popup {
        width: 1092px;
        .popup_ct {
            p {
                margin-top: 28px;
                font-size: 16px;
                color: #222222;
                a {
                    position: relative;
                    color: #404dab;
                    &:before {
                        position: absolute;
                        left: 1px;
                        right: 1px;
                        bottom: 1px;
                        height: 1px;
                        background-color: #404dab;
                        content: '';
                    }
                }
            }
        }
        .tbl_lst {
            margin-top: 26px;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            font-size: 16px;
            th, td {
                height: 70px;
                padding-left: 30px;
                border-top: 1px solid #e5e5e5;
            }
            .txt_bold {
                font-weight: 400;
            }
        }
        .pop_btn_grp {
            .btn.btn_lg {
                width: 262px;
                margin: 0 10px;
            }
        }
    }

    &.product_popup {
        width: 1092px;

        .tbl_lst {
            border: solid #a4a4a4;
            border-width: 1px 0;

            th {
                padding-left: 30px;
            }

            td {
                height: 70px;
                padding-left: 30px;

                &>div {
                    padding: 17px 0 15px;
                }
            }

            .search_inp {
                padding: 0;

                input {
                    font-size: 16px;

                    &::-webkit-input-placeholder {
                        font-size: 14px;
                        color: #a4a4a4;
                    }
                
                    &:-moz-placeholder {
                        font-size: 14px;
                        color: #a4a4a4;
                    }
                
                    &:-ms-input-placeholder {
                        font-size: 14px;
                        color: #a4a4a4;
                    }
                
                    &::placeholder {
                        font-size: 14px;
                        color: #a4a4a4;
                    }
                }
            }

            .cash {
                margin-left: 14px;
                font-size: 14px;
                color: #b83d3d;
            }

            .txt_note {
                font-size: 14px;
                font-weight: 300;
                color: #404dab;
            }

            .btn_minus,
            .btn_plus {
                vertical-align: middle;
            }

            .num {
                display: inline-block;
                margin: 0 11px;
                font-weight: 400;
                line-height: 36px;
                vertical-align: middle;
            }

            .btn_charge {
                width: 60px;
                margin-left: 46px;
            }

            .btn_limit {
                margin-left: 54px;
            }

            .inp_pass {
                width: 160px;
            }
        }

        .product {
            &_bx {
                height: 130px;
                margin: 50px 0 40px;
                font-size: 0;
            }

            &_info {
                display: inline-block;
                position: relative;
                width: 450px;
                height: 100%;
                padding: 13px 0 0 134px;
                box-sizing: border-box;
                vertical-align: top;

                .btn_group {
                    margin-top: 27px;

                    button {
                        width: 34px;
                        height: 34px;
                        margin-left: 8px;
                        border-radius: 2px;
                        vertical-align: top;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }

                .ico {
                    display: inline-block;
                }

                .ico_heart_w {
                    margin-top: 4px;
                }

                .btn_heart {
                    background-color: #e02424;

                    &.is_disabled {
                        background-color: #a4a4a4;
                    }
                }

                .btn_gift {
                    background-color: #404dab;
                }
            }

            &_thumb {
                position: absolute;
                top: 13px;
                left: 0;
                width: 104px;
                height: 104px;
                border: 2px solid #f4cf88;
                box-sizing: border-box;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &_name {
                display: block;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
            }

            &_desc {
                display: block;
                margin-top: 4px;
                font-size: 14px;
                color: #022222;
            }

            &_detail {
                display: inline-block;
                width: 499px;
                height: 100%;
                margin-left: 22px;
                padding: 6px 10px;
                border: 1px solid #ccc;
                box-sizing: border-box;
                vertical-align: top;

                p {
                    margin-top: 0;
                    color: $black2;
                    line-height: 21px;
                }

                ul {
                    font-size: 14px;
                    font-weight: 300;
                    color: $black2;
                }

                .sb-content {
                    padding-top: 0;
                }

                .sb-scrollbar-container {
                    top: 10px;
                    right: 6px;
                    bottom: 10px;
                    border-radius: 2px;
                    background: #f8f8f8;
                }

                .sb-scrollbar {
                    border-radius: 2px;
                }
            }
        }

        .search_result {
            margin-top: 10px;
            font-size: 0;

            dt {
                display: inline-block;
                position: relative;
                margin-left: 30px;
                padding-left: 30px;
                font-size: 16px;
                font-weight: 400;
                color: $black2;

                &:before {
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 1px;
                    height: 14px;
                    background-color: #c8c8cf;
                    content: '';
                }

                &:first-child {
                    margin-left: 0;
                    padding-left: 0;

                    &:before {
                        content: none;
                    }
                }
            }

            dd {
                display: inline-block;
                margin-left: 17px;
                font-size: 16px;
                color: $gray2;
            }
        }

        .lst_tit {
            display: block;
            margin-top: 13px;
            font-size: 16px;
            font-weight: 400;
            color: $black2;
            line-height: 30px;
        }

        .dot_lst {
            margin-top: 2px;

            li {
                margin-top: 0;
                font-size: 14px;
                line-height: 22px;
                color: $black2;
            }
        }
    }

    &.char_lst_popup {
        width: 1092px;
        .dot_lst {
            margin-top: 30px;
            font-size: 16px;
        }
        .btn_grp {
            margin-top: 60px;
        }
    }

    &.char_transfer_popup {
        width: 1092px;
        .popup_ct {
            p {
                margin-top: 24px;
                font-size: 18px;
                font-weight: 300;
                line-height: 36px;
                color: #222222;
                strong {
                    font-weight: 500;
                }
            }
            .tbl_lst {
                margin-top: 19px;
                .txt_left {
                    padding-left: 30px;
                }
            }
            .dot_lst {
                margin-top: 24px;
                font-size: 16px;
            }
            .pop_btn_grp {
                margin-top: 55px;
            }
        }
    }

    &.purchase_history {
        width: 1092px;

        .note {
            margin: 25px 0 26px;

            strong {
                display: block;
                font-size: 20px;
                color: $black2;
            }

            p {
                margin-top: 3px;
                font-size: 16px;
                color: $black2;
            }
        }

        .tbl_lst {
            border: solid #e5e5e5;
            border-width: 1px 0;

            th,
            td {
                padding-left: 30px;
            }
        }

        .guide_lst {
            margin-top: 26px;

            dt {
                margin-top: 15px;
                font-size: 16px;
                color: $black2;

                &:first-child {
                    margin-top: 0;
                }
            }

            dd {
                font-size: 14px;
                font-weight: 300;
                color: #868686;
            }

            .ico_arr_txt {
                display: inline-block;
                margin: 6px 0 0;
                font-size: 0;
                vertical-align: top;
            }
        }
    }

    &.delegation_sv_popup {
        width: 1092px;
        .dot_lst {
            margin-top: 10px;
            font-size: 16px;
        }
        .tbl_lst {
            .txt_left {
                padding-left: 26px;
            }
            .btn {
                width: 100px;
                margin-left: 60px;
                font-size: 14px;
            }
        }
    }

    &.cash_popup {
        width: 1092px;

        .popup_ct {
            position: relative;
        }

        .txt_note {
            margin-top: 37px;
            font-size: 16px;
            color: $black2;
        }

        .btn_setting_limit {
            position: absolute;
            top: -7px;
            right: 0;
            width: 120px;
            font-size: 16px;
            font-weight: 400;
        }

        .tbl_lst {
            margin-top: 27px;

            th {
                padding-left: 30px;
            }

            td {
                height: 70px;
                padding-left: 30px;

                &>div {
                    padding: 17px 0 15px;
                }
            }

            .rdo.rdo_v1 label {
                border-radius: 2px;
            }

            .rdo_lst {
                margin: -5px -6px -4px;

                li {
                    margin: 5px 6px 4px;
                }
            }

            .cash {
                font-size: 18px;
                font-weight: 400;
                color: $black2;
                letter-spacing: -0.02em;

                .num {
                    font-size: 20px;
                    font-weight: 500;
                }

                &.txt_blue {
                    color: #404dab;
                }
            }

            .txt_after_charge {
                font-size: 18px;
                font-weight: 400;
                color: #404dab;
            }

            em {
                font-weight: 400;
            }
        }

        .txt_notice {
            margin-top: 16px;
            font-size: 16px;
            color: #404dab;
        }

        .cash_term {
            margin-top: 17px;

            label {
                font-size: 16px;
                color: $black2;
            }

            &+.btn_outline {
                width: 68px;
                height: 28px;
                margin-left: 10px;
                font-weight: 400;
                line-height: 28px;
                border: 1px solid #404dab;
                color: #404dab;
            }
        }

        .registry_step_lst {
            margin: 50px 0 45px;
        }

        .lst_tit {
            display: block;
            margin-top: 17px;
            font-size: 16px;
            color: $black2;
        }

        .dot_lst {
            margin-top: 4px;

            li {
                font-size: 14px;

                &:before {
                    top: 9px;
                }
                
                &~li {
                    margin-top: 2px;
                }
            }
        }

        .inp_pass {
            width: 190px;
        }

        .validate {
            .message {
                display: inline-block;
                margin-left: 16px;
                vertical-align: top;
                line-height: 46px;
            }
        }

        .btn_edit {
            display: inline-block;
            margin-top: 14px;
            font-size: 14px;
            color: #404dab;

            .ico_arr_txt_blue {
                display: inline-block;
                margin-top: 5px;
                font-size: 0;
                vertical-align: top;
            }
        }

        .result_bx {
            margin-top: 55px;
            padding-top: 0;

            .tit {
                margin-top: 17px;
                font-size: 15px;
                font-weight: 400;
                color: $gray2;
            }

            &+.tbl_lst {
                margin-top: 62px;

                &+.dot_lst {
                    margin-top: 15px;
                }
            }
        }
    }

    &.buy_vip_popup {
        width: 1092px;
        .tbl_lst {
            margin-top: 30px;
            .btn {
                width: 100px;
                margin-left: 60px;
                font-size: 14px;
            }
            .inp_txt {
                width: 150px;
                &+.txt_blue {
                    margin-left: 20px;
                }
            }
            td.txt_left {
                padding-left: 30px;
            }
            .txt_msg {
                margin-left: 15px;
            }
        }
        .dot_lst {
            margin-top: 14px;
            font-size: 16px;
            li~li {
                margin-top: 12px;
            }
        }
    }

    .pop_block {
        margin-top: 25px;
        .block_tit {
            display: block;
            font-size: 18px;
            color: #101010;
            font-weight: 500;
            &+.tbl_lst {
                margin-top: 17px;
            }
        }
        &~.pop_block {
            margin-top: 40px;
        }
    }

    .popup_tit_box {
        margin-top: 26px;
        padding-bottom: 24px;
        border-bottom: 2px solid #384859;
        text-align: center;
        .pop_tit {
            font-size: 26px;
            font-weight: 500;
            color: #384859;
        }

        &.has_tab_lnk {
            padding-bottom: 15px;
        }

        .tabs_lnk_lst {
            margin-top: 6px;
            text-align: left;
        }
    }

    .tab {
        display: table;
        table-layout: fixed;
        width: 100%;

        li {
            display: table-cell;

            &.on {
                a {
                    color: #384859;
                    border-bottom: 2px solid #384859;
                }
            }
        }

        a {
            display: block;
            font-size: 26px;
            font-weight: 500;
            color: $gray3;
            letter-spacing: -0.02em;
            line-height: 90px;
            text-align: center;
            border-bottom: 2px solid #e5e5e5;
            cursor: pointer;
        }
    }

    .popup_ct {
        margin-top: 2px;

        &.tab_ct {
            display: none;
        }

        .tit {
            font-size: 20px;
            font-weight: 500;
            color: $black;
        }

        .subtit {
            margin-top: 25px;
            font-size: 16px;
            font-weight: 300;
            color: $black2;
        }

        .tbl_wrap {
            margin-top: 14px;

            th,
            td {
                padding: 7px 0;
                border: 1px solid #cecece;
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
                letter-spacing: -0.02em;
                text-align: center;
            }

            thead th {
                color: #222;
                background-color: #f8f8f8;
            }

            td {
                color: #5e5e5e;
            }

            &+.tbl_wrap {
                margin-top: 33px;
            }
        }

        p {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            color: $gray2;
        }

        .agreement_lst {
            &>li {
                margin-top: 34px;
                padding-bottom: 35px;
                border-bottom: 1px solid #e2e7ee;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: 0;
                }

                em {
                    font-weight: normal;
                    color: #101010;
                }
            }

            .tit {
                font-size: 18px;

                .num {
                    font-family: 'Roboto', sans-serif;
                    font-size: 20px;
                    margin-right: 6px;
                }
            }

            .dot_lst {
                margin-top: 30px;
            }

            .dash_lst {
                margin-top: 19px;
            }
        }
    }

    .sb-container {
        width: 1325px;
    }

    .sb-scrollbar-container {
        right: 0;
    }

    .sb-content {
        max-height: 534px !important;
        padding-top: 22px;
        padding-bottom: 0;
    }

    .sb-content-inner {
        width: calc(100% - 30px);
        font-size: 16px;
    }

    .btn_close {
        display: inline-block;
        position: absolute;
        top: -60px;
        right: -10px;
        padding: 10px;
    }

    .pop_btn_grp {
        margin-top: 40px;
        text-align: center;
        .btn {
            margin: 0 8px;
        }
    }

    .dot_lst {
        li strong {
            font-weight: 400;
        }
    }
}