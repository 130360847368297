/* MAIN */
.main_page {
	.wrap, .header {
		min-width: 1890px;
	}
}

.main_visual {
	position: relative;
	overflow: hidden;
	height: 590px;

	&_lst {
		.slide {
			overflow: hidden;
			position: relative;
			a {
				display: block;
				height: 590px;
			}
		}
		.img_container {
			img {
				position: absolute;
				top: 0;
				left: 50%;
				z-index: 0;
				transform: translateX(-50%);
			}
		}
		.desc_container {
			position: absolute;
			top: 164px;
			left: 16.6%;
			color: #fff;

			.date {
				font-family: 'Roboto';
				font-size: 18px;
				letter-spacing: .5px;
			}
			.evt_tit {
				margin-top: 6px;
				margin-left: -7px;
				font-family: 'NanumMyeongjo';
				font-size: 72px;
				letter-spacing: -4.5px;
				line-height: 87px;
			}
			.evt_desc {
				margin-top: 4px;
				font-size: 22px;
				font-weight: 300;
				letter-spacing: -.37px;
			}
		}
	}

	&_indicater {
		overflow: hidden;
		position: relative;
		z-index: 10;
		max-width: 1410px;
		height: 70px;
		margin: -70px auto 0;
		&_lst {
			padding-left: 141px;
			padding-right: 71px;
			font-size: 0;
			.indicator {
				border-left: 1px solid #1c1e1e;
				background-color: rgba(0,0,0,.8);
				box-sizing: border-box;
				font-weight: 300;
				font-size: 16px;
				color: #fff;
				color: rgba(255,255,255,.4);
				line-height: 70px;
				text-align: center;
				&.slick-current {
					color: #fff;
				}
			}
		}
		.slick-arrow {
			position: absolute;
			top: 0;
			height: 70px;
			background-color: rgba(0,0,0,.8);
			color: #fff;
			span {
				display: inline-block;
				margin-top: 2px;
				font-size: 0;
				line-height: 0;
				vertical-align: top;
			}
		}
		.slick-prev {
			width: 70px;
			left: 0;
			span {
				@include sp-normal('main_banner_arr_prev');
			}
		}
		.slick-next {
			left: 70px;
			width: 71px;
			padding-left: 1px;
			span {
				@include sp-normal('main_banner_arr_next');
			}
			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 1px;
				height: 15px;
				margin-top: -7px;
				background-color: #3b3b3c;
				content: '';
			}
		}
		.btn_evt_link {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 70px;
			height: 70px;
			background-color: rgba(0,0,0,.8);
			border-left: 1px solid #1c1e1e;
			font-size: 0;
			line-height: 0;
			&:before, &:after {
				position: absolute;
				background-color: #b3b6bb;
				content: '';
			}
			&:before {
				top: 28px;
				left: 34px;
				width: 2px;
				height: 14px;
			}
			&:after {
				top: 34px;
				left: 28px;
				width: 14px;
				height: 2px;
			}
		}
	}
}

.main_boards {
	margin-top: 110px;
	.col {
		float: left;
		position: relative;
		width: 450px;
		box-sizing: border-box;
		&_news {
			padding: 12px 5px 0 0;
			.col_tit {
				font-weight: 500;
				font-size: 26px;
			}
			.news_lst {
				margin-top: 34px;
				li {
					margin-top: 14px;
					&:first-child {
						margin-top: 0;
					}
					a {
						display: block;
					}
					span {
						display: inline-block;
						line-height: 24px;
						vertical-align: top;
					}
					.icon {
						margin-top: 2px;
					}
					.tit {
						overflow: hidden;
						max-width: 70%;
						margin-left: 5px;
						font-size: 16px;
						color: #222;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.date {
						float: right;
						font-family: 'Roboto', sans-serif;
						font-size: 14px;
						color: #868686;
					}
					.new {
						font-weight: 500;
						font-size: 14px;
						color: $red;
					}
				}
			}
			.btn_more_news {
				position: absolute;
				top: 15px;
				right: 0;
				width: 34px;
				height: 34px;
				font-size: 0;
				line-height: 0;
				&:before, &:after {
					position: absolute;
					background-color: $black;
					content: '';
				}
				&:before {
					top: 5px;
					left: 16px;
					width: 2px;
					height: 24px;
				}
				&:after {
					top: 16px;
					left: 5px;
					width: 24px;
					height: 2px;
				}
			}
		}
		&_gmstory {
			overflow: hidden;
			height: 289px;
			.gmstory_banner {
				.slide {
					img {
						width: 100%;
						height: auto;
						vertical-align: top;
					}
				}
			}
			.slick-arrow {
				position: absolute;
				top: 0;
				z-index: 1;
				height: 45px;
				background-color: $white;
				color: #fff;
				span {
					display: inline-block;
					margin-top: 4px;
					font-size: 0;
					line-height: 0;
					vertical-align: top;
				}
			}
			.slick-prev {
				width: 45px;
				right: 46px;
				span {
					@include sp-normal('main_evt_arr_prev');
				}
			}
			.slick-next {
				width: 46px;
				right: 0;
				padding-left: 1px;
				span {
					@include sp-normal('main_evt_arr_next');
				}
				&:before {
					position: absolute;
					top: 50%;
					left: 0;
					width: 1px;
					height: 11px;
					margin-top: -6px;
					background-color: #dfdfdf;
					content: '';
				}
			}
		}
		&_download {
			.btn_game_download {
				display: block;
				height: 148px;
				background: url('../img/btn_download.jpg') no-repeat;
				font-size: 0;
				line-height: 0;
				&:hover {
					background-image: url('../img/btn_download_hover.jpg');
				}
			}
			.login_info {
				height: 65px;
				margin-top: 6px;
				padding: 18px 25px 17px;
				background-color: #384859;
				font-size: 15px;
				color: #fff;
				line-height: 28px;
				box-sizing: border-box;
				.name {
					display: block;
					overflow: hidden;
					float: left;
					max-width: 220px;
					font-weight: normal;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.cash_info {
					overflow: hidden;
					text-align: right;
					.cash {
						display: inline-block;
						margin-right: 10px;
						font-family: 'Roboto', sans-serif;
						font-weight: 500;
						&:before {
							display: inline-block;
							margin: -3px 5px 0 0;
							@include sp-normal('ico_cash_gray');
							vertical-align: middle;
							content: '';
						}
					}
					.btn_cash {
						width: 70px;
						font-size: 14px;
						color: #fff;
						border: 1px solid #6e7985;
						border-radius: 2px;
						line-height: 28px;
						letter-spacing: -0.5px;
					}
				}
			}
			.btn_login {
				display: block;
				width: 100%;
				margin-top: 6px;
				background-color: #384859;
				font-size: 15px;
				color: #fff;
				line-height: 65px;

				.ico_daum {
					display: inline-block;
					overflow: hidden;
					margin-right: 5px;
					@include sp-normal('ico_daum');
					vertical-align: middle;
					line-height: 999px;
					content: "";
				}
				.ico_ongate {
					display: inline-block;
					overflow: hidden;
					margin-right: 5px;
					@include sp-normal('ico_ongate');
					vertical-align: middle;
					line-height: 999px;
					content: "";
				}

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}
			.server_info {
				position: relative;
				margin-top: 5px;
			}
			.btn_server_status {
				display: block;
				width: 100%;
				border: 1px solid #ccc;
				background-color: #fff;
				font-size: 15px;
				color: #373636;
				line-height: 63px;
			}
		}
	}
	.col + .col {
		margin-left: 30px;
	}
	// channeling 대응
	.channel_download {

		.login_info {
			position: relative;
			height: 75px;
			margin-top: 5px;
			padding: 11px 33px 11px 25px;

			.myinfo_lst {
				position: absolute;
				bottom: 16px;
				font-size: 0;
				line-height: initial;

				li {
					display: inline-block;
					
					&:first-child:before {
						display: none;
					}

					&:before{
						display: inline-block;
						width: 1px;
						height: 11px;
						margin: 0 7px;
						background: #606d7a;
						vertical-align: middle;
						content: "";
					}
				}

				li a {
					display: inline-block;
					font-size: 13px;
					color: #d1d4d6;	
					vertical-align: middle;
				}
			}
			.cash_info {
				line-height: initial;
				.cash {
					line-height: initial;
					vertical-align: middle;
				}
				.dsc_txt {
					font-family: 'notosans';
					font-weight: 300;
				}
				.btn_cash{
					display: inline-block;
					width: auto;
					border: 0;
					font-size: 12px;
					font-weight: 300;
					text-decoration: underline;
					line-height: initial;
					vertical-align: middle;
					letter-spacing: 0;
				}

				&+.cash_info {
					margin-top: 7px;
				}
			}
		}
		.btn_game_download {
			height: 129px;
			background: url('../img/btn_download2.jpg') no-repeat;
			&:hover {
				background-image: url('../img/btn_download2_hover.jpg');
			}
		}
		.btn_server_status {
			height: 75px;
		}
	}
}

.layer_server_status {
	display: none;
	position: absolute;
	top: 50px;
	left: 70px;
	z-index: 100;
	width: 310px;
	border: 1px solid #384859;
	background-color: #fff;
	box-sizing: border-box;
	.layer_title {
		padding: 0 18px;
		background-color: #384859;
		font-weight: normal;
		font-size: 16px;
		color: #fff;
		line-height: 46px;
	}
	.server_lst {
		padding: 13px 19px 15px;
		li {
			overflow: hidden;
			border-top: 1px solid #dfdfdf;
			padding: 12px 15px 10px 10px;
			&:first-child {
				border-top: 0;
			}
			.server_name {
				float: left;
				font-size: 15px;
				line-height: 24px;
			}
			.server_status {
				float: right;
			}
		}
	}
	.btn_close {
		position: absolute;
		top: 0;
		right: 0;
		width: 46px;
		height: 46px;
		font-size: 0;
		line-height: 0;
		&:after {
			display: inline-block;
			@include sp-normal('main_server_layer_close');
			content: '';
		}
	}
}

.main_shop {
	overflow: hidden;
	margin-top: 100px;
	&_tab {
		li {
			display: inline-block;
			position: relative;
			margin-right: 50px;
			padding-top: 3px;
			vertical-align: top;
			a {
				font-weight: 500;
				font-size: 20px;
				color: #a5a4a4;
				line-height: 60px;
				letter-spacing: -0.3px;
			}
			&.active {
				&:after {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 3px;
					background-color: $black;
					content: '';
				}
				a {
					color: $black;
				}
			}
		}

	}
	&_lst {
		overflow: hidden;
		height: 350px;
		margin: 10px -10px 0;
		font-size: 0;
		letter-spacing: -5px;
		li {
			display: inline-block;
			width: 266px;
			margin: 0 10px;
			box-sizing: border-box;
			vertical-align: top;
			outline: 0;
		}
		.item_box {
			position: relative;
			height: 350px;
			border: 1px solid #ccc;
			padding: 49px 35px 0;
			text-align: center;
			letter-spacing: 0;
			box-sizing: border-box;
		}
		.thumb {
			display: block;
			position: relative;
			width: 120px;
			height: 120px;
			margin: 0 auto;
			padding: 2px;
			background: linear-gradient(148deg, rgba(244,207,136,1) 30%, rgba(152,106,75,1) 50%, rgba(244,207,136,1) 70%);
			box-sizing: border-box;
			&:before {
				position: absolute;
				top: 2px;
				left: 2px;
				background-color: $black;
				width: 116px;
				height: 116px;
				content: '';
			}
			img {
				position: relative;
				width: 100%;
				height: 100%;
				vertical-align: top;
			}
		}
		.item_tit {
			display: block;
			overflow: hidden;
			margin-top: 17px;
			font-size: 14px;
			height: 40px;
		}
		.item_price {
			display: block;
			margin-top: 11px;
			font-family: 'Roboto', sans-serif;
			font-weight: 500;
			font-size: 14px;
			&:before {
				display: inline-block;
				margin: -2px 4px 0 0;
				@include sp-normal('ico_cash_black_small');
				vertical-align: middle;
				content: '';
			}
		}
		.btn_buy {
			position: absolute;
			left: 50%;
			bottom: 39px;
			width: 99px;
			margin-left: -50px;
			border-radius: 2px;
			background-color: #a68a5f;
			font-weight: 300;
			font-size: 13px;
			color: $white;
			line-height: 34px;
		}
		.shop_label {
			position: absolute;
			top: -1px;
			left: -1px;
		}
		.slick-arrow {
			position: absolute;
			top: 50%;
			z-index: 1;
			width: 35px;
			height: 34px;
			margin-top: -17px;
			background-color: #a4a4a4;
			box-sizing: border-box;
			opacity: 0;
			span {
				display: block;
				font-size: 0;
				line-height: 0;
				vertical-align: top;
			}
		}
		.slick-prev {
			left: 10px;
			span {
				margin-left: 12px;
				@include sp-normal('main_shop_arr_prev');
			}
		}
		.slick-next {
			right: 10px;
			span {
				margin-left: 14px;
				@include sp-normal('main_shop_arr_next');
			}
		}
	}
	.btn_cash_charge {
        position: absolute;
        top: 23px;
        right: 20px;
    }
}

.main_vip_banner {
	overflow: hidden;
	position: relative;
	margin-top: 110px;
	height: 251px;
	.slide {
		overflow: hidden;
		position: relative;
		height: 251px;
		img {
			position: absolute;
			top: 0;
			left: 50%;
			z-index: 0;
			transform: translateX(-50%);
		}
	}
	.slick-arrow {
		opacity: 0;
		position: absolute;
		top: 50%;
		z-index: 1;
		width: 50px;
		height: 80px;
		margin-top: -40px;
		span {
			display: inline-block;
			font-size: 0;
			line-height: 0;
			vertical-align: top;
		}
	}
	.slick-prev {
		left: 50%;
		margin-left: -720px;
		span {
			@include sp-normal('main_vip_arr_prev');
		}
	}
	.slick-next {
		right: 50%;
		margin-right: -720px;
		span {
			@include sp-normal('main_vip_arr_next');
		}
	}
}

.main_guide {
	padding: 120px 0;
	background: url('../img/main_guide_bg.jpg') no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	.inner {
		overflow: hidden;
		width: 1890px;
		padding: 0;	
	}
	&_lst {
		overflow: hidden;
		margin: 0 -15px;
		font-size: 0;
		letter-spacing: -4px;
		text-align: center;
		white-space: nowrap;
	}
	.guide {
		overflow: hidden;
		display: inline-block;
		position: relative;
		width: 330px;
		height: 622px;
		padding: 0 15px;
		font-size: normal;
		letter-spacing: 0;
		vertical-align: top;
		white-space: normal;
		outline: 0;
		&_thumb {
			overflow: hidden;
			position: absolute;
			width: 330px;
			height: 622px;
			img {
				position: absolute;
				top: 50%;
				left: 0;
				bottom: 0;
				width: auto;
				height: 522px;
				vertical-align: top;
				transform: translateY(-50%);
			}
			img.default {
				opacity: 1;
			}
			img.active {
				opacity: 0;
			}
		}
		&_title {
			position: absolute;
			top: 50%;
			left: 0;
			width: 330px;
			z-index: 1;
			color: #fff;
			transform: translateY(-50%);
			span {
				display: block;
				padding-left: 11px;
				font-family: 'Roboto', sans-serif;
				font-weight: 500;
				font-size: 14px;
				letter-spacing: 11px;
				text-transform: uppercase;
			}
			h3 {
				margin-top: 14px;
				font-family: 'NanumMyeongjo','나눔명조', sans-serif;
				font-weight: normal;
				font-size: 30px;
			}
		}
		&_link {
			opacity: 0;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			width: 60px;
			height: 60px;
			background-color: $black;					
			&:before,&:after {
				position: absolute;
				background-color: $white;
				content: '';
			}
			&:before {
				top: 18px;
				left: 29px;
				width: 2px;
				height: 24px;
			}
			&:after {
				top: 29px;
				left: 18px;
				width: 24px;
				height: 2px;
			}
		}
		&_details {
			position: absolute;
			top: 174px;
			left: 466px;
			width: 365px;
			opacity: 0;
			z-index: 1;
			text-align: left;
			.cate {
				display: block;
				font-family: 'Roboto', sans-serif;
				font-weight: 500;
				font-size: 16px;
				color: $red;
				letter-spacing: 16px;
				text-transform: uppercase;
			}
			.tit {
				margin-top: 19px;
				font-family: 'NanumMyeongjo','나눔명조', sans-serif;
				font-weight: normal;
				font-size: 70px;
				text-indent: -4px;
				color: #fff;
			}
			.desc {
				margin-top: 17px;
				font-weight: 300;
				font-size: 16px;
				line-height: 28px;
				color: #fff;
				opacity: .7;
				word-wrap: break-word;
				word-break: keep-all;
			}
		}
	}
}
