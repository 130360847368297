.shop_page {
    .lnb {
        display: inline-block;
        margin-top: 10px;
        vertical-align: top;
    }

    .main_shop_tab {
        li {
            margin-right: 34px;

            a {
                letter-spacing: -0.8px;
            }
        }
    }

    .menu_depth_3 {
        float: left;
        margin: 50px 0 30px;

        .tab_lst {
            width: auto;
            padding: 0;
            border-top: none;
        }
    }

    .tbl_desc {
        float: right;
        margin-top: 54px;
    }

    .tbl_lst {
        margin-top: 20px;

        .itm_name_tit {
            padding-left: 137px;
            text-align: left;
        }

        .itm_name {
            overflow: hidden;
            display: block;
            width: 670px;
            padding-left: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;

            img {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }

            &.type2 {
                width: 390px;
            }
        }

        a.lbl_outline {
            width: 80px;
            height: 36px;
            font-size: 15px;
            line-height: 36px;
            text-decoration: none;
        }
    }

    .lst_tit {
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #101010;
    }

    .dot_lst {
        margin-top: 12px;

        li ~ li {
            margin-top: 11px;
        }

        &+.tbl_lst {
            margin-top: 26px;
        }
    }

    .send_cp_area {
        .send_cp_bx {
            margin-top: 18px;
            font-size: 0;

            .txt {
                font-weight: 400;
                letter-spacing: -0.02em;
            }

            .def_inp {
                width: 126px;
                text-align: center;
                
                &+.def_inp {
                    margin-left: 9px;
                }
            }

            .btn {
                width: 145px;
                margin-left: 29px;
            }
        }

        .cp_stt_bx {
            margin-top: 18px;
            padding: 0 47px;

            .cp_type {
                letter-spacing: -0.02em;
            }
        }

        .lst_tit {
            margin-top: 25px;
        }

        .dot_lst {
            margin-top: 13px;

            li {
                padding-left: 12px;
                letter-spacing: -0.025em;
                margin-top: 9px;
                color: #5e5e5e;

                &:before {
                    background-color: #5e5e5e;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .area_tit {
        letter-spacing: -0.03em;
    }

    .usage_guide_area {
        margin-top: 78px;

        .lst_tit {
            letter-spacing: -0.025em;
            margin-top: 25px;
        }

        .dot_lst {
            li {
                letter-spacing: -0.025em;
                color: #5e5e5e;

                &:before {
                    background-color: #5e5e5e;
                }
            }

            &+.lst_tit {
                margin-top: 20px;
            }
        }
    }

    .registration_method_area {
        margin-top: 76px;
        font-size: 0;

        .step_lst {
            padding: 41px 38px;
        }

        .lst_itm {
            width: 310px;
            min-height: 196px;
            margin-left: 31px;
            padding: 50px 0 0;

            &:first-child {
                margin-left: 0;
            }

            .step_ico {
                min-height: 71px;
                margin-top: 4px;
            }

            .step_desc {
                margin-top: 7px;
                font-size: 18px;
                letter-spacing: -0.02em;
            }
        }

        .registration_case {
            padding: 2px 45px 0;
            text-align: center;

            dt {
                margin-top: 11px;
                padding-top: 14px;
                border-top: 1px solid #ebebeb;
                font-size: 18px;
                line-height: 20px;

                &:first-child {
                    margin-top: 0;
                    padding-top: 0;
                    border-top: 0;
                }
            }

            dd {
                margin-top: 2px;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                letter-spacing: -0.02em;
            }
        }
    }
}

.shop_wrap {
    display: inline-block;
    width: 1100px;
    margin: 10px 0 0 36px;
    vertical-align: top;

    .search_area {
        margin: 50px 0 24px;
        padding: 30px 40px;

        .search {
            font-size: 0;
        }

        .select {
            width: 140px;
            height: 50px;
            background-color: $white;

            &.lst_up {
                .select_lst {
                    bottom: 48px;
                }
            }

            .select_lst {
                overflow: auto;
                max-height: 200px;
            }
        }

        .tit {
            font-size: 18px;
            line-height: 50px;
        }

        .search_box {
            margin-left: 15px;

            input {
                width: 625px;
                padding-left: 13px;
            }
        }

        .btn {
            width: 130px;
            margin-left: 14px;
        }
    }
}

.shop_lst {
    margin-top: 10px;
    font-size: 0;

    li {
        display: inline-block;
        width: 260px;
        margin-left: 20px;
        border: 1px solid #ccc;
        background-color: #f2f2f2;
        box-sizing: border-box;

        &:first-child {
            margin-left: 0;
        }
    }
}

.shop_itm {
    display: block;
    min-height: 298px;
    position: relative;
    padding: 46px 0;
    color: $black;
    text-align: center;
    box-sizing: border-box;

    .thumb {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto;
        border: 2px solid #f4cf88;
        box-sizing: border-box;
    }

    .itm_name {
        display: block;
        margin-top: 17px;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .itm_price {
        display: block;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;

        &:before {
            display: inline-block;
            margin-right: 3px;
            @include sp-normal('ico_cash_black_small');
            vertical-align: top;
            content: '';
        }
    }

    .overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding-top: 91px;
        background-color: rgba(0,0,0,0.8);
        color: $white;

        .itm_name {
            margin-top: 0;
        }

        .itm_price {
            margin-top: 4px;

            &:before {
                @include sp-normal('ico_cash_gray_small');
            }
        }

        .btn_group {
            margin-top: 21px;
        }

        .btn {
            min-width: 60px;
            margin-left: 8px;
            background-color: #fff;
            font-size: 15px;
            line-height: 30px;
            color: $black2;

            &:first-child {
                margin-left: 0;
            }

            &.orange {
                background-color: #a68a5f;
                color: $white;
            }
        }
    }
}

.shop_area {
    position: relative;

    .btn_cash_charge {
        position: absolute;
        top: 23px;
        right: 0;
    }
}

.product_lst_detail {
    .product_num {
        display: inline-block;
        margin-top: 32px;
    }

    .select {
        float: right;
        width: 140px;
    }
}

.product_lst {
    margin-top: 16px;
    border-top: 1px solid #a4a4a4;
    font-size: 0;

    li {
        display: inline-block;
        width: 346px;
        height: 180px;
        margin: 31px 0 0 31px;
        border: 1px solid #ccc;
        box-sizing: border-box;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }

    &.nodata {
        border-bottom: 1px solid #a4a4a4;

        li {
            position: relative;
            width: 100%;
            height: 460px;
            margin: 0;
            border: 0;
            text-align: center;

            .ico {
                display: block;
                margin: 170px auto 0;
            }

            .txt_msg {
                display: block;
                margin: 13px auto 0;
                font-size: 24px;
                font-weight: normal;
                color: $gray2;
                letter-spacing: -0.02em;
            }
        }
    }
}

.product_itm {
    position: relative;
    padding: 39px 30px;

    .lbl {
        width: auto;
        min-width: 60px;
        height: 25px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 300;
        line-height: 25px;
        box-sizing: border-box;

        &.darkblue {
            background-color: #72879e;
        }

        &.lightblue {
            background-color: #72949e;
        }

        &.green {
            background-color: #7e9e72;
        }

        &.blue {
            background-color: #727b9e;
        }

        &.pink {
            background-color: #9e7298;
        }

        &.darkorange {
            background-color: #9e8972;
        }

        &.darkviolet {
            background-color: #80729e;
        }

        &.darkgreen {
            background-color: #83b297;
        }

        &.darkred {
            background-color: #9e7272;
        }

        &.gray {
            background-color: #5e6c74;
        }

        &.darkyellow {
            background-color: #a79e73;
        }

        &.red {
            background-color: #bf6a6a;
        }
    }

    .product_tit {
        display: block;
        height: 42px;
        margin-top: 9px;
        padding-right: 110px;
        font-size: 14px;
        line-height: 21px;
    }

    .product_thumb {
        display: block;
        position: absolute;
        top: 38px;
        right: 28px;
        width: 50px;
        height: 50px;
        padding: 25px;
        background: #f8f8f8;

        img {
            border: 1px solid #f4cf88;
        }
    }

    .product_price {
        display: block;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;

        &:before {
            display: inline-block;
            margin: 2px 3px 0 0;
            @include sp-normal('ico_cash_black_small');
            vertical-align: top;
            content: '';
        }
    }

    .overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding-top: 40px;
        background-color: rgba(0,0,0,0.8);
        font-size: 14px;
        color: $white;
        text-align: center;

        .product_name {
            display: block;
            margin-top: 0;
            padding: 0 60px;
        }

        .product_price {
            margin-top: 4px;

            &:before {
                @include sp-normal('ico_cash_gray_small');
            }
        }

        .btn_group {
            margin-top: 21px;
        }

        .btn {
            min-width: 60px;
            margin-left: 4px;
            background-color: #fff;
            color: $black2;

            &:first-child {
                margin-left: 0;
            }

            &.orange {
                background-color: #a68a5f;
                color: $white;
            }
        }
    }
}

.tbl_desc {
    text-align: right;

    .note {
        float: left;
        font-weight: 300;
        color: #404dab;
        letter-spacing: -0.007em;
        line-height: 46px;
    }

    .tbl_lst_indi {
        display: inline-block;
        margin-top: 18px;
        vertical-align: top;
    }

    .datepicker_area {
        display: inline-block;
        margin-left: 25px;
        vertical-align: top;

        .tilde {
            margin: 0 15px;
            line-height: 46px;
        }
    }
}

.shop_guide_page {
    .wrap {
        padding-top: 92px;
    }

    h2.sub_tit {
        font-weight: 400;
    }

    .sp_common_area {
        margin-top: 60px;

        h3 {
            font-size: 24px;
            font-weight: 400;
            color: $black2;
            letter-spacing: -1.4px;
            line-height: 22px;
            
            &+p {
                margin-top: 14px;
                line-height: 25px;
            }
        }

        p {
            margin-top: 11px;
            line-height: 33px;
            word-wrap:break-word;
            font-weight: 300;

            &+.tbl_lst {
                margin-top: 20px;
            }

            &+img {
                margin-top: 22px;
            }
        }

        .txt_bold {
            font-weight: 400;
        }

        img + .tbl_lst {
            margin-top: 30px;
        }

        .dot_lst {
            margin-top: 14px;

            li {
                line-height: 33px;

                &:before {
                    top: 14px;
                }

                &~li {
                    margin-top: 9px;
                }
            }
        }

        .tbl_lst {
            margin-top: 20px;
            
            th {
                font-weight: 400;
            }

            &.txt_left {
                th,
                td {
                    padding-left: 30px;
                }
            }
        }

        &~.sp_common_area {
            margin-top: 50px;
        }
    }
}