.etc_page {
    .wrap {
        padding-top: 111px;
        padding-bottom: 90px;
    }

    .content>.inner {
        padding-bottom: 100px;
    }

    .txt_note {
        display: block;
        font-weight: 300;
        color: $black2;
        letter-spacing: -0.025em;
        line-height: 27px;

        &+p.txt_note {
            margin-top: 7px;
        }
    }

    strong.txt_note {
        margin-top: 27px;
        font-weight: 500;
    }

    .card_lst {
        margin-top: 33px;
        padding: 46px 23px;

        li {
            padding: 0 23px;
        }
    }

    .card_itm {
        height: 233px;
        padding-top: 42px;

        .ico_monitor_l {
            margin-top: 7px;
        }

        .desc {
            margin-top: 8px;
            font-size: 16px;
        }
    }

    .lst_tit {
        display: block;
        margin-top: 24px;
        font-size: 18px;
    }

    .dot_lst {
        margin-top: 7px;
        
        li {
            padding-left: 13px;
            line-height: 35px;
            letter-spacing: -0.025em;

            &:before {
                top: 16px;
                left: 1px;
                background-color: $gray2;
            }

            &~li {
                margin-top: 0;
            }
        }

        &.gray {
            li {
                color: $gray2;

                &:before {
                    background-color: $gray2;
                }
            }
        }
    }

    .btn_wrap {
        margin-top: 30px;
        font-size: 0;
        text-align: center;

        .btn {
            vertical-align: top;
        }
    }

    .result_bx {
        .txt {
            margin-top: 4px;
        }

        .btn_wrap {
            margin-top: 36px;

            .btn ~ .btn {
                margin-left: 20px;
            }
        }
    }

    .tab_lst {
        padding: 100px 0 75px;
    }

    .support_bx {
        margin-top: 25px;
        padding: 32px 0 29px 47px;
        border: solid #a4a4a4;
        border-width: 1px 0;

        p {
            color: #101010;
            letter-spacing: -0.025em;

            &+.lst_tit {
                margin-top: 32px;
            }
        }
    }

    .result_bx {
        .tbl_lst {
            width: 970px;
            margin: 52px auto 0;
            border-bottom: 1px solid #e5e5e5;

            th,
            td {
                height: 70px;
            }

            th {
                background-color: #f8f8f8;
                font-weight: 500;
            }

            td.txt_left {
                padding-left: 31px;
            }
        }

        .guide_download {
            width: 970px;
            margin: 51px auto 0;
            padding: 35px 197px 41px;
            background-color: #f8f8f8;
            box-sizing: border-box;

            .txt_guide {
                font-weight: 300;
                color: $black2;
                line-height: 25px;
                letter-spacing: -0.02em;
            }

            .download_info {
                margin-top: 27px;
                padding-top: 30px;
                border-top: 1px solid #cecece;
                font-size: 0;
            }

            .browser {
                &_ico {
                    display: inline-block;
                    vertical-align: middle;
                }

                &_name {
                    margin-left: 10px;
                    font-size: 24px;
                    font-weight: 500;
                    vertical-align: middle;
                }
            }

            .btn_download {
                width: 195px;
                margin-left: 20px;
                vertical-align: middle;

                .ico_download_s {
                    display: inline-block;
                    margin-left: 8px;
                }
            }

            &+.btn_wrap {
                margin-top: 40px;
            }
        }
    }
}

.rest_area {
    padding-top: 94px;
}

.view_area {
    .view_ct {
        border-top: 2px solid #384859;
        border-bottom: 1px solid #a4a4a4;

        .js_scrollbar {
            min-height: 599px;
            max-height: 599px;
            margin: 38px 0 50px;
            padding-left: 30px;
        }

        .sb-scrollbar-container {
            right: 0;
        }

        .sb-content {
            padding-top: 0;
            padding-bottom: 0;
        }

        .tit {
            font-size: 26px;
            font-weight: 400;
            line-height: 1;
        }

        .chapter_tit {
            display: block;
            margin-top: 20px;
            font-size: 22px;
        }

        .article_tit {
            display: block;
            margin-top: 24px;
        }

        .article_itm {
            p {
                margin-top: 2px;
                line-height: 25px;
            }

            ol {
                margin-top: 2px;
            }

            li {
                line-height: 25px;
            }
        }

        .article_menu_lst {
            li {
                line-height: 1;
                margin-top: 8px;

                &:first-child {
                    margin-top: 0;
                }
            }

            a {
                font-weight: 500;
                color: $black2;
            }
        }

        &+.btn_wrap {
            margin-top: 40px;
            text-align: right;

            .btn {
                background-color: #3f546b;
            }
        }

        table {
            margin: 10px 0;
            table-layout: fixed;
            border-collapse: collapse;

            th, td {
                height: 40px;
                padding: 0 10px;
                border: 1px solid #a4a4a4;
                text-align: center;
                vertical-align: middle;
            }

            td {
                font-weight: 300;
            }
        }
    }
}

.authentication_area {
    margin-top: 48px;

    .tit {
        font-size: 24px;
        letter-spacing: -0.04em;
    }

    .email_bx {
        margin-top: 24px;
        padding: 27px 0 27px 31px;
        border: solid #a4a4a4;
        border-width: 1px 0;

        .txt {
            font-size: 14px;
            color: $black2;
            letter-spacing: -0.025em;
        }

        .inp_mail {
            margin-left: 75px;
            width: 196px;
        }

        .at_sign {
            font-family: 'barlow', sans-serif;
            font-size: 20px;
            font-weight: 300;
            color: #101010;
        }

        .domain {
            margin-left: 32px;
        }
    }

    &+.card_lst {
        margin-top: 30px;
    }
}