body, input, select, textarea, button {
    font-family: 'Roboto', 'notosans', 'Apple SD Gothic Neo', Dotum, '돋움', sans-serif;
}

.blind,
caption span,
legend {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    font-size: 0;
    text-indent: -9999px;
    white-space: nowrap;
}

i {
    display: inline-block;
}

.skip {
    height: 0;
}

.skip a {
    display: block;
    position: absolute;
    left: 0;
    top: -100px;
    width: 100%;
    height: 25px;
    text-align: center;
}

.skip a:focus,
#skip a:active {
    position: absolute;
    top: 0;
    z-index: 100;
    padding: 10px 0;
    background: #000;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    text-decoration: none
}

.wrap {
    position: relative;
    min-width: 1730px;
    min-height: 100%;
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 343px;
    box-sizing: border-box;
}

.inner {
    position: relative;
    width: 1410px;
    margin: 0 auto;
    padding: 0 20px;
    &:after {
        display: block;
        clear: both;
        content: '';
    }
}

// header
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-width: 1730px;
    height: 90px;
    background: #000;
    z-index: 9999;

    &.transparent {
        background: transparent;
        .gnb {
            border-bottom: 1px solid rgba(255,255,255,0.1);
        }
    }

    &.fixed_left_header {
        position: fixed!important;
        width: 300px;
        min-width: 300px;
        height: 100%;
        padding: 42px 20px 42px 0;
        background: #384859;
        border-bottom: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .sb-content {
            width: 122%;
            padding: 0 42px 0 0;
        }
        .sub_tit {
            display: block;
            margin-left: 29px;
            font-size: 28px;
            font-weight: 500;
            color: #ffffff;
        }
    }
    &.white {
        height: 110px;
        border-bottom: 1px solid #ebebeb;
        background: #f8f8f8;
        .inner {
            height: 100%;
        }
        .logo {
            margin: 27px 27px 0 20px;
        }
        .header_tit {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 36px;
            font-weight: 500;
            color: #222222;
            letter-spacing: -0.02em;
            transform: translate(-50%,-50%);
        }
    }
    &.open {
        width: auto;
        height: auto;
        background: none;
    }
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 18px 27px 0 62px;
    vertical-align: top;

    a {
        display: block;
        overflow: hidden;
        width: 177px;
        height: 56px;
        @include sp-normal('logo');
        line-height: 999px;
    }
    &.gray a {
        @extend .logo_gray;
    }
}

.gnb {
    position: relative;
    min-width: 1440px;
    height: 100%;
    border-bottom: 1px solid #171a1d;
    box-sizing: border-box;
    z-index: 1;

    span {
        position: relative;
    }

    .ico_new {
        overflow: hidden;
        position: absolute;
        width: 5px;
        height: 5px;
        background: #e80000;
        line-height: 999px;
        transform: rotate(45deg);
    }
}

.gnb_lst {
    display: table;
    position: relative;
    min-width: 860px;
    margin-left: 265px;
    font-size: 0;
    vertical-align: top;
}

.gnb_lst .lst_itm {
    display: table-cell;
    position: relative;
    height: 90px;
    text-align: center;
    vertical-align: top;

    a {
        position: relative;
        display: block;
        min-width: 117px;
        height: 100%;
        font-size: 20px;
        color: #fff;
        line-height: 20px;

    }
    & > a {
        span {
            display: inline-block;
            vertical-align: middle;
        }
        &:before {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            content: "";
        }
        .ico_new {
            top: -6px;
            right: -10px;
        }
    }

    .ico_guide {
        overflow: hidden;
        position: absolute;
        top: 50%;
        right: -19px;
        width: 11px;
        height: 11px;
        margin-top:-6px;
        @include sp-normal('ico_guide');
        line-height: 999px;
        content: "";
    }
}

.lst_itm .sub_lst {
    display: none;
    position: absolute;
    top: 94px;
    width: 100%;
    margin-top: 11px;

    a {
        position: relative;
        display: block;
        height: 20px;
        padding: 5px 0;
        font-weight: 300;
        font-size: 14px;
        color: #8e8e8e;
        letter-spacing: -.3px;
        & > span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
        }
        &:hover {
            color: #fff;
            font-weight: 400;
            & > span {
                line-height: 22px;
                text-decoration: underline;
            }
        }
    }
    .ico_new {
        top: -2px;
        right: -8px;
    }
}

header .gnb_util {
    position: absolute;
    top: 0;
    right: 0;
    margin: 26px 51px 0 0;
    font-size: 0;

    .btn_login {
        display: inline-block;
        padding: 10px 29px 10px 32px;
        .ico_login {
            overflow: hidden;
            display: block;
            @include sp-normal('ico_login');
            line-height: 999px;
        }

        &.btn_name {
            padding: 5px 28px;
            font-size: 18px;
            color: #fff;
        }
    }

    .btn_login_ongate {
        display: inline-block;
        padding: 5px 0;
        margin-right: 30px;
        font-size: 18px;
        color: #fff;
        .ico_login_ongate {
            overflow: hidden;
            display: inline-block;
            margin: -3px 10px 0 0;
            @include sp-normal('ico_login_ongate');
            line-height: 999px;
            vertical-align: middle;
        }
    }

    .btn_login_gamemania {
        display: inline-block;
        padding: 5px 0;
        margin-right: 30px;
        font-size: 18px;
        color: #fff;
    }

    .btn_outline {
        display: inline-block;
        height: 32px;
        margin: 2px 25px 0 0;
        padding: 5px 14px 0;
        border: 1px solid #a4a4a4;
        border-radius: 2px;
        font-size: 15px;
        color: #fff;
        vertical-align: top;
        box-sizing: border-box;
        letter-spacing: -.5px;

        .ico_login {
            margin-top: 4px;
            @include sp-normal('ico_login2');
        }
    }

    .member {
        display: inline-block;
        position: relative;
        vertical-align: top;
    }

    .member_info {
        display: none;
        position: absolute;
        top: 52px;
        left: -68px;
        width: 300px;
        height: auto;
        padding-bottom: 20px;
        background: #fff;

        &:before {
            position: absolute;
            top: -13px;
            left: 130px;
            @include sp-normal('tooltip_arrow');
            content: ""
        }

        .info_header {
            display: block;
            padding: 11px 20px 13px;
            background: #efefef;

            .name {
                font-size: 16px;
                letter-spacing: -.4px;
            }
        }

        .info_cnt {
            padding: 12px 20px 0;
        }

        .cash_area {
            position: relative;

            &+.cash_area {
                margin-top: 12px;
            }
        }

        .cash {
            font-size: 16px;
            color: #000;
            line-height: 30px;

            &:before {
                display: inline-block;
                @include sp-normal('ico_cash');
                margin: -4px 4px 0 0;
                vertical-align: middle;
                content: "";
            }

            .point_txt {
                font-weight: 500;
            }
        }

        .btn_cash {
            overflow: hidden;
            float: right;
            height: 30px;
            padding: 0 10px;
            border-radius: 3px;
            background: #384859;
            font-size: 14px;
            color: #fff;
            line-height: 28px;

            &:after {
                display: block;
                clear: both;
                content: "";
            }
        }

        .info_lst {
            margin-top: 12px;
            padding-top: 20px;
            border-top: 1px solid #dfdfdf; 
            text-align: center;

            li {
                display: inline-block;
                position: relative;
                margin:0 0 0 21px;
                padding:0 0 0 21px;
                // 게임매니아 util 대응
                background-image: none;
                float: initial;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    width: 1px;
                    height: 11px;
                    background: #dfdfdf;
                    content: ""
                }

                &:first-child {
                    margin: 0;
                    padding: 0;

                    &:before {
                        display: none
                    }
                }
            }

            li a {
                font-size: 15px;
                color: #000;
                letter-spacing: -.4px;
            }

        }
        .info_lst_type2 {
            text-align: center;
            li {
                margin-left: 9px;
                padding-left: 10px;

                a {
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }

    .pcroom {
        display: inline-block;
        margin-top: 8px;
        font-size: 14px;
        color: #cab18c;
        vertical-align: top;

        .case {
            padding-left: 5px;
            color: #fff;
            opacity: 0.4;

            &.on {
                opacity: 1;
            }
        }
    }

    .util_lst {
        display: inline-block;
        margin: 8px 0 0 23px;
        vertical-align: top;

        li {
            display: inline-block;
            position: relative;
            height: auto;
            vertical-align: top;
            padding: 0 0 0 11px;
            margin: 0 0 0 12px;
            background: none;
            line-height: unset;

            &:first-child {
                padding: 0;
                margin: 0;
            }

            a {
                display: block;
                font-size: 14px;
                color: #cab18c;
            }

            &:before {
                position: absolute;
                top: 5px;
                left: 0;
                width: 1px;
                height: 10px;
                background: #2c2c2c;
                content: "";
            }

            &:first-child:before {
                display: none;
            }
        }
    }
}

.depth_bg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 336px;
    background: #000;
}

.lnb {
    width: 270px;
    border: solid $black2;
    border-width: 1px 0;

    &_itm {
        border-top: 1px solid #e5e5e5;

        &:first-child {
            border-top: 0;
        }

        &.has_sub {
            a:after {
                position: absolute;
                top: 32px;
                right: 25px;
                @include sp-normal('ico_arr_lnb');
                content: '';
            }

            &.on {
                a:after {
                    transform: rotate(180deg);
                }

                .sub_lst {
                    display: block;
                }
            }
        }

        &.on {
            &>a {
                color: #222;
            }
        }

        &>a {
            display: block;
            position: relative;
            padding-left: 25px;
            font-size: 18px;
            color: #a4a4a4;
            line-height: 72px;
            letter-spacing: -0.02em;
        }

        .sub_lst {
            display: none;
            padding: 13px 0 9px 44px;
            border-top: 1px solid #e5e5e5;

            .sub_itm {
                padding: 6px 0 8px;

                &.on {
                    a {
                        color: $black;
                    }
                }

                a {
                    font-size: 16px;
                    color: #a4a4a4;
                    line-height: 20px;
                }
            }
        }
    }
}

iframe {
    display: block;
}

// content
.content {
    &>.inner:last-child {
        padding-bottom: 130px;
    }
}

//footer

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #13151a;
}

.center_footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 89px;
    border-top: 1px solid #e5e5e5;
    background: #ffffff;
    text-align: center;
    line-height: 89px;
    .copyright {
        font-size: 13px;
    }
}

.policy_area {
    padding: 37px 0 35px;
    text-align: center;
}

.policy_lst {
    position: relative;
    font-size: 0;

    .lst_itm {
        display: inline-block;
        position: relative;

        a {
            padding: 4px 14px;
            font-size: 15px;
            font-weight: 300;
            color: #dadadb;
            letter-spacing: -.2px;
        }

        &:before {
            position: absolute;
            top: 6px;
            left: 0;
            width: 1px;
            height: 11px;
            background: #424448;
            content: "";
        }

        &:first-child:before{
            display: none;
        }
    }

    .dsc_tooltip {
        display: none;
        position: absolute;
        bottom: 40px;
        left: -318px;
        z-index: 100;
        width: 746px;
        height: 130px;
        padding: 25px 0;
        background: #26272c;
        border-radius: 3px;
        box-sizing: border-box;

        &:after {
            position: absolute;
            bottom: -13px;
            left: 50%;
            margin-left: -10px;
            @include sp-normal('tooltip_arrow2');
            content: "";
        }
    }
}

.company_info {
    padding: 31px 0 92px;
    border-top: 1px solid #24272d;
    text-align: center;
    line-height: 22px;
    font-size: 13px;

    .info_lst {
        width: 640px;
        margin: 0 auto;
        font-size: 13px;
        .lst_itm {
            display: inline-block;
            margin-right: 10px;
            font-size: 13px;
            color: #707172;
            letter-spacing: -.2px;

            a {
                display: inline-block;
                font-size: 13px;
                color: #5a5c5f;
                letter-spacing: -.2px;
            }
        }
    }
    .copyright, .copy_lst {
        margin-top: 2px;
        font-family: 'Roboto';
        font-weight: 500;
        color: #707172;
    }
    .copy_lst {
        font-size: 0;
        li {
            display: inline-block;
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 13px;
            color: #707172 ;
            vertical-align: middle;

            &:before {
                display: inline-block;
                width: 1px;
                height: 12px;
                margin: 0 12px;
                background: #4b4c55;
                vertical-align: middle;
                content: "";
            }

            &:first-child:before {
                display: none;
            }
        }
    }
    .line_end {
        margin-right: 0;
    }
    .dsc_txt {
        font-size: 13px;
        color: #5a5c5f;
        letter-spacing: -.2px;
    }
}

.channel {
    .info_lst {
        width: 1048px;
    }
}

.logo_playwith {
    display: inline-block;
    margin-top: 16px;
    opacity: 0.8;
    a {
        display: block;
        overflow: hidden;
        @include sp-normal('logo_playwith');
        line-height: 999px;
    }
}

.logo_kakaogames {
    display: inline-block;
    margin-top: 16px;
    margin-left: 20px;
    vertical-align: top;
    a {
        display: block;
        overflow: hidden;
        @include sp-normal('logo_kakaogames');
        line-height: 999px;
    }
}

.logo_ongate {
    display: inline-block;
    margin-top: 20px;
    margin-left: 20px;
    vertical-align: top;
    a {
        display: block;
        overflow: hidden;
        @include sp-normal('logo_ongate');
        line-height: 999px;
    }
}

.logo_gamemania {
    display: inline-block;
    margin-top: 16px;
    margin-left: 20px;
    vertical-align: top;
    a {
        display: block;
        overflow: hidden;
        @include sp-normal('logo_gamemania');
        line-height: 999px;
    }
}

.quick_menu {
    display: none;
    position: absolute;
    right: 50px;
    z-index: 100;
    width: 90px;
    &.floating {
        position: fixed;
        top: 200px;
    }
    ul {
        overflow: hidden;
        padding-bottom: 25px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        border: 1px solid #e5e5e5;
        border-bottom: 0;
        background-color: #f2f2f2;
    }
    li {
        margin-top: 29px;
        a {
            display: block;
            font-weight: 300;
            font-size: 14px;
            color: #3f546b;
            text-align: center;
            &:before {
                display: block;
                margin: 0 auto 5px;
                content: '';
            }
        }
        &.cash a:before {
            @include sp-normal('quick_ico_cash');
        }
        &.coupon a:before {
            @include sp-normal('quick_ico_coupon');
        }
        &.pcbang a:before {
            @include sp-normal('quick_ico_pcbang');
        }
        &.report a:before {
            @include sp-normal('quick_ico_report');
        }
        &.cs a:before {
            @include sp-normal('quick_ico_cs');
        }
        &.invite {
            a {
                color: #404dab;
                &:before {
                    @include sp-normal('quick_ico_invite');
                }
            }
        }
    }
    .btn_top {
        display: block;
        width: 100%;
        background-color: #8b8c92;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        line-height: 49px;
        outline: none;
        span {
            display: inline-block;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #fff;
            vertical-align: middle;
        }
        &:after {
            display: inline-block;
            position: relative;
            margin-left: 3px;
            @include sp-normal('quick_arr_top');
            vertical-align: middle;
            content: '';
        }
    }
}


// borad icon
.icon {
    display: inline-block;
    width: 22px;
    height: 21px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    &:before {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #fff;
        line-height: 21px;
    }
    &.notice {
        background-color: $red;
        &:before {
            content: 'N';
        }
    }
    &.update {
        background-color: $blue;
        &:before {
            content: 'U';
        }
    }
}

.sub_visual {
    position: relative;
    overflow: hidden;
    height: 412px;
    &_lst {
        .slide {
            overflow: hidden;
            position: relative;
            height: 412px;
            a {
                display: block;
                height: 590px;
            }
        }
        .img_container {
            img {
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
                height: auto;
                z-index: 0;
                transform: translateX(-50%);
            }
        }
        .desc_container {
            position: absolute;
            top: 122px;
            left: 50%;
            margin-left: -633px;
            color: #fff;

            .date {
                font-family: 'Roboto';
                font-size: 16px;
            }
            .evt_tit {
                margin-top: 16px;
                margin-left: -5px;
                font-family: 'NanumMyeongjo';
                font-size: 60px;
                letter-spacing: -4.5px;
            }
            .evt_desc {
                margin-top: 4px;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -.37px;
            }
        }
        .slick-arrow {
            position: absolute;
            top: 50%;
            z-index: 1;
            width: 50px;
            height: 80px;
            margin-top: -40px;
            span {
                display: inline-block;
                color: #fff;
                font-size: 0;
                line-height: 0;
                vertical-align: top;
            }
        }
        .slick-prev {
            left: 50%;
            margin-left: -720px;
            span {
                @include sp-normal('sub_visual_arr_prev');
            }
        }
        .slick-next {
            right: 50%;
            margin-right: -720px;
            span {
                @include sp-normal('sub_visual_arr_next');
            }
        }
        .slick-dots {
            position: absolute;
            z-index: 1;
            left: 50%;
            margin-left: -633px;
            bottom: 82px;
            li {
                display: inline-block;
                margin-right: 4px;
                button {
                    width: 30px;
                    height: 2px;
                    background-color: #74777e;
                    font-size: 0;
                    line-height: 0;
                }
                &.slick-active {
                    button {
                        background-color: $white;
                    }
                }
            }
        }
    }
}

.select {
    display: inline-block;
    position: relative;
    width: 190px;
    height: 50px;
    border: solid 1px $gray4;
    color: $black2;
    font-size: 14px;
    vertical-align: top;
    box-sizing: border-box;
    z-index: 1;

    .btn_open_select {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 50px 0 20px;
        background: #ffffff;
        font-size: 14px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        outline: none;

        &:after {
            position: absolute;
            top: 50%;
            right: 20px;
            @include sp-normal('ico_arr_down2');
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            content: '';
        }
    }

    &.open {
        border: 1px solid $black2;
        border-bottom: transparent;

        .select_lst {
            display: block;
            top: 100%;
            z-index: 9999;
            right: -1px;
        }

        .btn_open_select:after {
            -webkit-transform: translateY(-50%) rotate(180deg);
            -moz-transform: translateY(-50%) rotate(180deg);
            -ms-transform: translateY(-50%) rotate(180deg);
            -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
        }
    }

    &.lst_up {
        border-bottom: 1px solid $black2;

        .select_lst {
            top: auto;
            bottom: 100%;
            border-top: 1px solid $black2;
            border-bottom: transparent;
        }
    }

    &.domain {
        width: 170px;
        height: 46px;
    }
}

.select_lst {
    overflow-y: auto;
    display: none;
    position: absolute;
    width: 100%;
    max-height: 200px;
    border: 1px solid $black2;
    border-top: transparent;
    background-color: $white;

    .select_itm {
        width: 100%;
        border-top: 1px solid $gray4;

        &:first-child {
            border-top: 0;
        }
    }

    .btn_option {
        width: 100%;
        padding-left: 22px;
        font-weight: 300;
        font-size: 14px;
        color: $black2;
        line-height: 49px;
        text-align: left;

        &:hover {
            background-color: #f2f2f2;
        }
    }
}

.breadcrumb {
    font-size: 0;

    .itm {
        display: inline-block;
        position: relative;
        vertical-align: middle;

        &:after {
            position: absolute;
            top: 6px;
            right: -15px;
            @include sp-normal('ico_arr_right');
            content: '';
        }

        &.dropdown {
            position: relative;

            a {
                position: relative;
                padding-right: 20px;

                &:before {
                    position: absolute;
                    top: 4px;
                    right: 0;
                    @include sp-normal('ico_arr_down');
                    content: '';
                }
            }

            &.open {
                a:before {
                    transform: rotate(180deg);
                }

                .select_lst {
                    display: block;
                }
            }
        }

        &+.itm {
            padding-left: 24px;
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }

    a {
        font-size: 14px;
        color: $gray2;
    }

    .select_lst {
        top: 28px;
        right: 0;
        z-index: 1;
        width: 140px;
        max-height: initial;
        border: 1px solid #ebebeb;
    }
}

.tab_lst {
    overflow: hidden;
    display: table;
    width: 100%;
    table-layout: fixed;

    .tab_itm {
        display: table-cell;
        border: 1px solid $gray4;
        background-color: $white;

        &:last-child {
            border-right: 1px solid $gray4;
        }

        &.on,
        &:hover {
            background-color: #384859;
            border: 1px solid #384859;

            a {
                color: #fff;
            }
        }
    }

    a {
        display: block;
        font-size: 20px;
        color: $gray3;
        text-align: center;
        line-height: 61px;
    }

    .ico_guide {
        display: inline-block;
        margin-top: -4px;
        vertical-align: middle;
        @include sp-normal('ico_guide_gray');
    }
}

.menu_depth_3 {
    margin-top: 30px;
    .tab_lst {
        .tab_itm {
            display: inline-block;
            a {
                display: block;
                min-width:130px;
                padding: 0 33px;
                font-size: 18px;
                line-height: 48px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            &~.tab_itm {
                margin-left: 12px;
            }
        }
    }
}

.sub_sticky {
    position: absolute;
    width: 100%;
    padding: 77px 0 32px;
    min-width: 1730px;
    background-color: $white;
    z-index: 10;

    .tit {
        font-size: 40px;
        font-weight: normal;
        color: $black2;
        letter-spacing: -0.02em;
    }

    .breadcrumb {
        position: absolute;
        top: 32px;
        right: 20px;
    }

    .tab_lst {
        margin-top: 41px;
        .tab_itm:not(:last-child) {
            border-right: none;
        }
        &.has_border {
            padding-top: 50px;
            border-top: 2px solid #384859;
        }
    }

    .filter_time {
        position: absolute;
        top: 33px;
        right: 20px;

        .btn_time {
            font-size: 14px;
            color: $gray2;
            line-height: 18px;

            &:after {
                display: inline-block;
                margin: 4px 0 0 10px;
                @include sp-normal('ico_arr_down');
                vertical-align: top;
                content: '';
            }
        }

        .select_lst {
            top: 26px;
            right: 0;
            width: 140px;
            border: 1px solid #ebebeb;
        }

        &.open {
            .btn_time:after {
                transform: rotate(180deg);
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
}

.pagination {
    margin-top: 50px;
    font-size: 0;
    text-align: center;

    .btn_pagination,
    .lst_itm {
        display: inline-block;
        vertical-align: middle;

        a {
            display: block;
            width: 40px;
            height: 40px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            color: $gray3;
            line-height: 40px;
            text-align: center;
        }
    }

    .lst_itm {
        a {
            &:hover {
                color: #202020;
            }

            &.on {
                color: #202020;
                font-weight: 500;
            }
        }
    }

    .btn_pagination {
        &.prev,
        &.last {
            margin-left: -1px;
        }
    }

    &_lst {
        display: inline-block;
        margin: 0 19px;

        .lst_itm {
            margin: 0 1px;
        }
    }
}

.tbl_img_lst {
    position: relative;
    overflow: hidden;
    margin: 0 -15px;
    padding-bottom: 33px;
    font-size: 0;

    &:before {
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        left: 15px;
        border-top: solid 2px #384859;
        content: '';
    }

    li {
        display: inline-block;
        width: 33.333%;
        padding: 42px 15px 18px;
        box-sizing: border-box;

        &.end {
            a {
                cursor: default;
            }

            .overlay {
                display: block;
            }
        }

        &.nodata {
            position: relative;
            width: 100%;
            height: 444px;
            text-align: center;

            &:after {
                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;
                border-bottom: 1px solid #a4a4a4;
                content: '';
            }

            .ico {
                display: block;
                margin: 110px auto 0;
            }
            .txt_msg {
                display: block;
                margin: 13px auto 0;
                font-size: 24px;
                font-weight: normal;
                color: $gray2;
                letter-spacing: -0.02em;
            }
        }
    }

    a {
        display: block;
        position: relative;
        width: 390px;
        padding: 278px 29px 24px;
        border: solid 1px #ccc;

        .time:before {
            content: none;
        }
    }

    .lbl {
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 5;
        min-width: 67px;
        height: 35px;
        font-size: 16px;
        line-height: 35px;
    }

    .img {
        overflow: hidden;
        position: absolute;
        top: -1px;
        right: -1px;
        left: -1px;
        height: 255px;
        background: url(../img/news/img_event_default.jpg) no-repeat;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            transition: 0.3s;
        }

        .overlay {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 4;
            background-color: rgba(0,0,0,0.7);
            color: $white;
            text-align: center;

            strong {
                display: inline-block;
                width: 94px;
                height: 32px;
                margin-top: 98px;
                border: 1px solid $gray3;
                border-radius: 3px;
                font-size: 16px;
                font-weight: normal;
                line-height: 32px;
                letter-spacing: -0.025em;
            }

            p {
                margin-top: 7px;
                font-size: 14px;
                letter-spacing: -0.02em;
            }
        }
    }

    .tit {
        display: block;
        box-sizing: border-box;

        .txt {
            overflow: hidden;
            display: inline-block;
            max-width: 360px;
            font-size: 16px;
            font-weight: 400;
            color: $black2;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .txt_new {
            margin-left: 10px;
            line-height: 20px;
        }
    }

    .time {
        display: inline-block;
        margin-top: 7px;
    }

    .view {
        position: absolute;
        bottom: 24px;
        right: 28px;
    }
}

.search_inp {
    display: inline-block;
    position: relative;
    border: 1px solid $gray4;
    color: $black2;
    font-size: 14px;
    box-sizing: border-box;

    input {
        width: 465px;
        height: 48px;
        padding: 0 54px 0 19px;
        border: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        letter-spacing: 0.04em;
        outline: none;

        &::-webkit-input-placeholder {
            color: #ccc;
        }

        &::-moz-placeholder {
            color: #ccc;
        }

        &:-ms-input-placeholder {
            color: #ccc;
        }

        &:-moz-placeholder {
            color: #ccc;
        }
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background-color: #f2f2f2;
        font-size: 0;
        outline: none;
    }
}

.clearfix:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: '';
}

.notice_txt {
    font-size: 14px;
    line-height: 15px;
    font-weight: 300;
    color: #222222;
}

.txt_blue {
    color: #404dab !important;
}

.txt_gray {
    color: #868686 !important;
}

.txt_gray2 {
    color: #5e5e5e !important;
}

.txt_gray3 {
    color: $gray3 !important;
}

.txt_left {
    text-align: left;
}

.txt_center {
    text-align: center;
}

.txt_right {
    text-align: right;
}

.txt_reg {
    font-weight: 400;
}

.txt_med {
    font-weight: 500;
}

.search_box {
    .select {
        height: 56px;

        &+.search_inp {
            margin-left: -1px;
        }
    }

    &.size_l {
        .search_inp {
            input {
                width: 537px;
                height: 54px;
            }

            button {
                width: 55px;
                height: 54px;
            }
        }
    }
}

.view_area {
    position: relative;
    width: 990px;

    &.faq {
        .ct {
            border-bottom: 0;

            &~.ct {
                border-bottom: 1px solid #a4a4a4;
            }
        }

        .info .time {
            right: 20px;
            letter-spacing: 0.01em;
        }

        .btn_wrap {
            margin-top: 39px;
            text-align: right;
        }
    }

    .title {
        display: block;
        position: relative;
        width: 100%;
        padding: 0 150px 0 29px;
        border-top: solid 2px #384859;
        border-bottom: 1px solid #e5e5e5;
        background-color: #f8f8f8;
        font-size: 0;
        box-sizing: border-box;

        strong {
            display: inline-block;
            position: relative;
            font-size: 0;
            color: $black2;
            font-weight: normal;
            line-height: 80px;

            .lbl_outline {
                margin: 0 10px 0 2px;
                vertical-align: middle;
            }

            .txt {
                display: inline-block;
                overflow: hidden;
                max-width: 681px;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
                font-size: 20px;
            }

            .txt_q,
            .txt_a {
                display: inline-block;
                font-family: 'notosans', sans-serif;
                font-size: 24px;
                font-weight: 500;
                color: #404dab;
                vertical-align: top;
            }

            .txt_q {
                margin: 0 14px 0 1px;
                line-height: 74px;
            }

            .txt_a {
                margin: 0 14px 0 3px;
                line-height: 77px;
            }

            .ico_attach {
                display: inline-block;
                margin-left: 12px;
                vertical-align: middle;
            }
        }
    }

    .info {
        position: relative;
        height: 59px;
        border-bottom: 1px solid #e5e5e5;

        .view,
        .time,
        .comment,
        .name {
            display: inline-block;
            position: absolute;
            top: 0;
            line-height: 59px;
        }

        .name {
            left: 30px;
        }

        .comment {
            right: 240px;
            width: 150px;
        }

        .view {
            right: 150px;
            width: 90px;
        }

        .time {
            right: 0;
            width: 150px;
        }
    }

    .ct {
        padding: 33px 0 72px;
        border-bottom: 1px solid #a4a4a4;
        font-size: 16px;
        font-weight: 300;
        color: $black2;
        word-break: break-word;

        p {
            margin: 25px 0 0 31px;
            line-height: 25px;

            &:first-child {
                margin-top: 0;
            }
        }

        &>span {
            display: block;
            margin: 5px 0 0 29px;
            font-size: 14px;
            font-weight: 300;
            color: $gray2;

            &+table {
                margin-top: 32px;
            }
        }

        table {
            margin: 8px 0 0 31px;
            font-size: 14px;
            font-weight: 300;
        }

        thead {
            th {
                height: 37px;
                color: #222;
                background-color: #f8f8f8;
                border: 1px solid #e5e5e5;
            }
        }

        tbody {
            td {
                height: 50px;
                border: 1px solid #e5e5e5;
                line-height: 18px;
                text-align: center;
            }
        }
    }

    .share_box {
        position: relative;

        .share {
            padding: 39px 0 30px;
            font-size: 0;

            li {
                display: inline-block;
                margin-left: 8px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    display: block;
                }
            }
        }

        .btn_wrap {
            position: absolute;
            top: 39px;
            right: 0;
            font-size: 0;

            .btn {
                margin-left: 8px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .tbl_lst {
        margin-top: 32px;
        border: solid #e5e5e5;
        border-width: 1px 0;

        td {
            height: 62px;

            &.txt_left {
                padding-left: 30px;
            }

            .lbl_outline + .tit {
                max-width: 400px;
            }

            .tit {
                max-width: 490px;
                font-size: 14px;
                letter-spacing: -0.02em;
            }
        }
    }
}

.write_area {
    position: relative;
    width: 990px;

    &.full_width {
        width: 100%;

        input {
            &::-webkit-input-placeholder {
                font-size: 14px;
            }

            &::-moz-placeholder {
                font-size: 14px;
            }

            &:-ms-input-placeholder {
                font-size: 14px;
            }

            &:-moz-placeholder {
                font-size: 14px;
            }
        }
    }

    .area_tit {
        font-family: 'notosans', sans-serif;
        line-height: 48px;
    }

    .txt_note {
        position: absolute;
        top: 19px;
        right: 0;
        font-size: 14px;
        font-weight: 300;
        color: $gray2;
        letter-spacing: -0.02em;
    }

    .file_upload_box {
        .guide_txt {
            display: inline-block;
            margin-left: 10px;
            font-size: 14px;
            font-weight: 300;
            color: $gray3;
        }

        label {
            padding: 0 13px;
            cursor: pointer;
        }
    }

    .file_lst {
        display: inline-block;
        width: calc(100% - 105px);
        vertical-align: top;
        line-height: 30px;
        font-size: 0;

        li {
            display: inline-block;
            position: relative;
            margin-left: 10px;

            .ico {
                display: inline-block;
                margin-top: 5px;
                vertical-align: top;
            }

            .filename {
                display: inline-block;
                margin-left: 5px;
                font-size: 14px;
                font-weight: 300;
                color: $gray2;
                vertical-align: top;
            }

            .btn_remove_file {
                display: inline-block;
                margin: 6px 0 0 10px;
                @include sp-normal('ico_del');
                vertical-align: top;
            }
        }
    }

    .btn_wrap {
        margin-top: 59px;
        font-size: 0;
        text-align: center;

        .btn {
            margin-left: 20px;
            vertical-align: top;

            &:first-child {
                margin-left: 0;
            }

            &_outline {
                border: 1px solid $gray3;
                color: $gray3;
            }
        }
    }
}

.inp_txt {
    width: 100%;
    padding: 11px 0;
    border: 0;
    font-size: 16px;
    color: $black2;
    line-height: 24px;
    outline: none;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
        font-size: 14px;
        font-weight: 300;
        color: #a4a4a4;
    }

    &:-moz-placeholder {
        font-size: 14px;
        color: $gray3;
        font-weight: 300;
    }

    &:-ms-input-placeholder {
        font-size: 14px;
        font-weight: 300;
        color: #a4a4a4;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: 300;
        color: #a4a4a4;
    }

    &.password {
        width: 500px;
    }

    &.phone {
        position: relative;
        width: 35px;
        margin: 0;
        font-size: 18px;
        text-align: center;
        box-sizing: border-box;

        &.num2 {
            width: 45px;
        }
    }

    &.inp_mail {
        width: 201px;
    }

    &.inp_domain {
        width: 150px;
        margin: 0 5px;

        &:read-only {
            pointer-events: none;
        }
    }
}

.aside {
    position: absolute;
    top: 0;
    right: -420px;
    width: 300px;
    border: 1px solid #e5e5e5;

    .tit {
        display: block;
        padding-left: 20px;
        border-bottom: 1px solid #e5e5e5;
        background-color: #f8f8f8;
        line-height: 80px;
        font-size: 20px;
        font-weight: normal;
        color: $black2;
    }

    .recent_posts_lst {
        padding: 0 20px;

        li {
            border-top: solid 1px #e5e5e5;

            &:first-child {
                border-top: none;
            }
        }

        a {
            display: block;
            padding: 20px 0 17px;
            font-size: 14px;
            color: $black2;
            letter-spacing: -0.02em;
        }

        strong {
            display: block;
            margin-top: 6px;
            font-weight: 300;
            line-height: 21px;
            word-break: break-word;
        }

        .time {
            display: block;
            margin-top: 2px;
            color: $gray3;
            text-align: left;
            letter-spacing: 0;

            &:before {
                content: none;
            }
        }
    }

    .term_policy_lst {
        padding: 0 20px;

        li {
            border-top: solid 1px #e5e5e5;

            &:first-child {
                border-top: none;
            }

            &.on a {
                color: $blue;
            }
        }

        a {
            display: block;
            padding: 17px 0;
            font-size: 14px;
            color: $black2;
            letter-spacing: -0.02em;
        }
    }
}

.btn {
    display: inline-block;
    width: 93px;
    height: 36px;
    border: transparent;
    background-color: #384859;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    color: $white;
    line-height: 36px;
    letter-spacing: -0.02em;
    text-align: center;

    &.btn_round {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    &.btn_aside {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.disabled {
        cursor: not-allowed;
    }
    &.grayfill {
        background:$gray3;
    }
    &.goldfill {
        background: #a68a5f;
    }
    &.silverfill {
        background: #a5a5b6;
    }
    &.grayfill2 {
        background:$gray2;
    }
    &.grayfill3 {
        background-color: #737f8b;
    }
    &.whitefill {
        background-color: #ffffff;
        color: #222222;
    }

    &.btn_outline {
        background-color: transparent;
        border: 1px solid #3f546b;
        color: #3f546b;
        &.grayout {
            border: 1px solid #a4a4a4;
            color: #a4a4a4;
        }
        &.blueout {
            border: 1px solid $blue;
            color: $blue;
        }
        &.whiteout {
            border: 1px solid #ffffff;
            color: #ffffff;
        }
    }

    &.full_width {
        width: 100%;
    }

    &.btn_sm {
        width: auto;
        height: 30px;
        padding: 0 10px;
        line-height: 32px;
        font-weight: 400;
        box-sizing: border-box;
        &.btn_round {
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
        }
    }

    &.btn_xs {
        width: auto;
        height: 22px;
        padding: 0 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
    }

    &.btn_lg {
        width: 242px;
        height: 52px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 50px;
        font-weight: 500;
        box-sizing: border-box;
    }
}

.right_shortcut {
    float: right;
    height: 16px;
    margin-top: 10px;
    li {
        display: inline-block;
        height: 16px;
        vertical-align: top;
        &~li {
            margin-left: 14px;
            &:before {
                display: inline-block;
                width: 1px;
                height: 13px;
                margin-right: 18px;
                margin-top: 2px;
                background: #c8c8cf;
                vertical-align: top;
                content: '';
            }
        }
        a:hover,&.selected {
            color: #404dab;
        }
        a {
            display: inline-block;
            height: 16px;
            font-size: 16px;
            font-weight: 300;
            line-height: 16px;
            color: #222222;
            vertical-align: top;
            &:before {
                display: inline-block;
                margin-right: 8px;
                @extend .ico_bars;
                vertical-align: baseline;
                content: '';
            }
            &:hover:before {
                @extend .ico_bars_hover;
            }
        }
    }
}

.right {
    float: right;
}
.left {
    float: left;
}

.chkbox {
    display: inline-block;
    padding: 0;
    font-size: 0;

    input {
        position: absolute;
        opacity: 0;

        &:checked+label:before {
            @extend .ico_chkbox_checked;
        }
    }

    label {
        display: inline-block;
        position: relative;
        padding-left: 27px;
        font-size: 14px;
        font-weight: 300;
        color: #5e5e5e;
        line-height: 20px;
        cursor: pointer;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            @extend .ico_chkbox;
            content: "";
        }
    }

    &.no_txt {
        label {
            padding-left: 0;
            &:before {
                position: relative;
                display: block;
            }
        }
    }
}

.rdo {
    display: inline-block;
    position: relative;
    overflow: hidden;
    line-height: 34px;
    vertical-align: top;
    input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    &.rdo_v1 {
        label {
            display: inline-block;
            height: 36px;
            min-width: 92px;
            padding: 0 15px;
            border:1px solid #ebebeb;
            font-size: 14px;
            font-weight: 300;
            color: #a4a4a4;
            text-align: center;
            line-height: 34px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            vertical-align: middle;
            cursor: pointer;
        }

        input:checked + label {
            border: none;
            background: #737f8b;
            font-weight: 500;
            color: #ffffff;
            line-height: 36px;
        }
    }
    &.rdo_v2 {
        label:before {
            display: inline-block;
            margin-right: 8px;
            @extend .rdo_def;
            vertical-align: middle;
            content: '';
        }
        input:checked + label:before {
            @extend .rdo_selected;
        }
    }
}

.rdo_lst {
    display: inline-block;
    li {
        display: inline-block;
        &~li {
            margin-left: 8px;
        }
    }
    &.rdo_lst_v2 {
        li:not(:last-child) {
            margin-right: 46px;
        }
        li~li {
            margin-left: 0;
        }
    }
}

.comment_box {
    margin-top: 32px;

    .txtarea {
        width: 100%;
        height: 110px;
        font-size: 0;

        textarea {
            width: 100%;
            height: 100%;
            padding: 16px 28px;
            border: 1px solid rgba($color: #c5d0dd, $alpha: 0.5);
            font-size: 14px;
            font-weight: 300;
            box-sizing: border-box;
            resize: none;

            &::-webkit-input-placeholder {
                color: #a5a5a5;
            }

            &::-moz-placeholder {
                color: #a5a5a5;
            }

            &:-ms-input-placeholder {
                color: #a5a5a5;
            }

            &:-moz-placeholder {
                color: #a5a5a5;
            }
        }

        &.not_login {
            textarea {
                display: none;
            }

            .notice_login {
                display: block;
            }
        }
    }

    .notice_login {
        display: none;
        width: 100%;
        height: 100%;
        padding: 18px 0;
        border: 1px solid rgba($color: #ccc, $alpha: 0.5);
        background-color: #f8f8f8;
        font-size: 16px;
        font-weight: 300;
        color: $gray3;
        text-align: center;
        letter-spacing: -0.01em;
        box-sizing: border-box;

        .btn {
            margin-top: 7px;
            border-radius: 3px;
        }
    }

    .btn_wrap {
        text-align: right;
        margin-top: 10px;

        .char_num {
            margin-right: 6px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: $gray3;
        }
    }

    .action_bx {
        position: absolute;
        top: 3px;
        right: 16px;
        font-size: 0;

        button {
            position: relative;
            padding: 0 14px;
            font-size: 14px;
            font-weight: 300;
            color: $black2;
            line-height: 20px;

            &:hover {
                color: #404dab;
            }

            &+button:before {
                position: absolute;
                top: 4px;
                left: 0;
                width: 1px;
                height: 12px;
                background-color: #c8c8cf;
                content: '';
            }
        }
    }

    .lst {
        margin-top: 40px;
        border-bottom: 1px solid #e5e5e5;

        &>li {
            padding: 23px 0 26px;
            border-top: 1px solid rgba($color: #c5d0dd, $alpha: 0.5);
        }

        .itm {
            position: relative;
            padding-left: 30px;
        }

        .username {
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.02em;
        }

        .comment_time {
            margin-left: 10px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #a5a5a5;
        }

        .txt {
            margin: 9px 30px 0 0;
            font-weight: 300;
            color: $gray2;
            letter-spacing: 0.01em;
            word-wrap: break-word;
        }

        .reply {
            padding: 0 0 30px;
            border-top: 0;
        }

        .reply .itm{
            margin-top: 0;
            padding: 13px 0 14px 53px;
            background-color: #f8f8f8;

            &:before {
                display: block;
                position: absolute;
                top: 18px;
                left: 30px;
                @include sp-normal('ico_right_angle');
                content: '';
            }

            .action_bx {
                top: 17px;
            }
        }

        .reply_write {
            position: relative;
            margin: 23px 0 0;
            padding-left: 53px;

            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 30px;
                @include sp-normal('ico_right_angle');
                content: '';
            }

            .txtarea {
                height: 86px;
            }
        }

        .notice_login {
            padding: 8px 0;
        }
    }

    .btn_more {
        display: block;
        width: 450px;
        height: 65px;
        margin: 28px 0 0 278px;
        border: 1px solid #ccc;
        border-radius: 2px;
        font-size: 16px;
        color: $gray;

        &:after {
            display: inline-block;
            margin-left: 6px;
            vertical-align: middle;
            @include sp-normal('ico_arr_down');
            content: '';
        }
    }

    &+.tbl_lst {
        margin-top: 80px;
    }
}

.dot_lst {
    &>li {
        position: relative;
        padding-left: 11px;
        font-weight: 300;

        &:before {
            position: absolute;
            top: 11px;
            left: 0;
            width: 3px;
            height: 3px;
            background: #222222;
            content: '';
        }

        &.txt_blue:before {
            background: #404dab;
        }
        &.txt_gray:before {
            background: #868686;
        }
        &.txt_gray2:before {
            background: #5e5e5e;
        }

        & ~ li {
            margin-top: 13px;
        }

        em {
            font-weight: 400;
        }
    }
    &.inline_lst {
        li {
            display: inline-block;
            margin-top: 0;
            vertical-align: middle;
        }
    }
}

.dash_lst {
    &>li {
        position: relative;
        padding-left: 16px;
        font-size: 16px;
        font-weight: 300;
        color: #5e5e5e;
        line-height: 25px;
        margin-top: 9px;

        &:first-child {
            margin-top: 0;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '-';
        }
    }
}

.content_head {
    margin-top: 27px;
    padding-bottom: 19px;
    border-bottom: 2px solid #384859;
}

.tabs_lnk_lst {
    li {
        display: inline-block;
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
        &~li {
            &:before {
                display: inline-block;
                width: 1px;
                height: 14px;
                background: #c8c8cf;
                margin-right: 16px;
                vertical-align: middle;
                content: '';
            }
        }
        &.active {
            a {
                color: #404dab;
            }
        }
        a {
            display: inline-block;
            font-size: 16px;
            line-height: 16px;
            color: #222222;
            vertical-align: middle;
        }
        [class^="ico_"] {
            display: inline-block;
            margin-left: 2px;
        }
    }
}

.area_tit {
    font-size: 30px;
    font-weight: 400;
    line-height: 27px;
}

.area_desc {
    display: inline-block;
    margin: 5px 0 0 8px;
    font-size: 14px;
    color: #a4a4a4;
    line-height: 14px;
}

.intro_txt {
    display: block;
    font-size: 35px;
    color: #222222;
    font-family: 'notosans', 'roboto', sans-serif;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
}

.sub_intro_txt {
    display: block;
    margin-top: 15px;
    font-size: 25px;
    font-weight: 400;
    line-height: 23px;
    color: #222222;
    text-align: center;
}

.gray_bx {
    background: #f2f2f2;
}

.step_lst {
    margin-top: 29px;
    li {
        display: inline-block;
        position: relative;
        padding: 30px;
        background: #ffffff;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .step_lbl {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 93px;
            height: 40px;
            padding: 0 14px;
            background: #404dab;
            font-size: 14px;
            font-weight: 500;
            color: #f5f5f5;
            text-align: center;
            line-height: 40px;
            text-transform: uppercase;
            box-sizing: border-box;
            &.green {
                background: #509216;
            }
        }
        .step_ico {
            margin-top: 2px;
            span {
                display: block;
                margin: auto;
            }
        }
        .step_tit {
            display: block;
            margin-top: 30px;
            font-size: 22px;
            color: #101010;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            &+.step_desc {
                margin-top: 7px;
            }
        }
        .step_desc {
            margin-top: 21px;
            font-size: 16px;
            color: #222222;
            text-align: center;
        }
    }
    &.step3 {
        padding: 40px;
        li {
            width: 420px;
            height: 186px;
            &~li {
                margin-left: 31px;
            }
        }
    }
    &.step4 {
        padding: 50px;
        li {
            width: 630px;
            height: 250px;
            padding: 40px 45px 35px;
            &:nth-child(even) {
                margin-left: 46px;
            }
            &:nth-child(2) ~li {
                margin-top: 50px;
            }
            .step_desc {
                font-weight: 300;
                strong {
                    font-weight: 400;
                }
            }
        }
    }
}

.note_txt {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
}

.hr_blue {
    display: block;
    margin: 53px 0;
    border-bottom:none;
    border-left:none;
    border-right:none;
    border-top:2px solid #384859;
}

.btn_grp {
    .btn ~ .btn {
        margin-left: 6px;
    }
}

.def_inp {
    display: inline-block;
    height: 50px;
    padding: 18px 20px;
    border: 1px solid #ebebeb;
    border-right: none;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.top_line_gray {
    border-top: 1px solid #a4a4a4;
}

.btn_go_top {
    position: fixed;
    bottom: 239px;
    left: 1440px;
    @extend .top_btn;
}
.result_bx {
    padding-top: 150px;
    text-align: center;

    .ico {
        display: block;
        margin: 0 auto;
    }

    .tit {
        display: block;
        margin-top: 30px;
        font-size: 30px;
        font-weight: 500;
        color: #101010;
        letter-spacing: -0.04em;
    }

    .txt {
        display: block;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 300;
        color: $gray2;
        line-height: 21px;
        letter-spacing: -0.02em;
    }

    .btn {
        width: 262px;
        border-radius: 3px;
    }

    .info_lst {
        li {
            display: inline-block;
            position: relative;
            margin-left: 28px;

            &:before {
                position: absolute;
                top: 6px;
                left: -16px;
                width: 1px;
                height: 13px;
                background-color: #c8c8cf;
                content: '';
            }

            &:first-child {
                margin-left: 0;

                &:before {
                    content: none;
                }
            }
        }
    }
}

.verify_phone_bx {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    &:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 17px;
        background: #c8c8cf;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        content: '';
    }
    .txt {
        display: inline-block;
        width: 82px;
        margin-left: 31px;
        border: none;
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        outline: none;
        &::-webkit-input-placeholder {
            font-size: 14px;
            color: #a4a4a4;
        }

        &:-ms-input-placeholder {
            font-size: 14px;
            color: #a4a4a4;
        }

        &::placeholder {
            font-size: 14px;
            color: #a4a4a4;
        }
    }
    .time_count {
        display: inline-block;
        margin-left: 26px;
        font-size: 16px;
        font-weight: 400;
        color: #404dab;
    }
    .btn {
        margin-left: 10px!important;
    }
}