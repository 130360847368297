.tabs_server_lst {
    float: left;
    li {
        display: inline-block;
        width: 120px;
        height: 50px;
        border:1px solid #ebebeb;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        a {
            display: block;
            font-size: 18px;
            color: #a4a4a4;
            line-height: 48px;
            text-align: center;
        }
        & ~ li {
            margin-left: 8px;
        }
        &.on {
            background: #384859;
            border-color:#384859;
            & a {
                color: #ffffff;
            }
        }
    }
}

.top_rank_lst {
    padding: 36px;
    background: #f4f5f7;
    font-size: 0;

    .lst_itm {
        display: inline-block;
        width: 426px;
        height: 186px;
        margin-left: 30px;
        background: #ffffff;

        &:first-child {
            margin-left: 0;
        }

        &:after {
            display: block;
            clear: both;
            content: "";
        }
    }

    .top_rank_ico {
        display: block;
        width: 98px;
        height: 38px;
        margin: 27px auto 0;
        line-height: 999px;

        &.top_rank_1 {
            @extend .top_rank_1
        }

        &.top_rank_2 {
            @extend .top_rank_2
        }

        &.top_rank_3 {
            @extend .top_rank_3
        }
    }

    .info_container {
        margin: 16px auto 0;
        text-align: center;
    }

    .name {
        display: block;
        font-size: 24px;
        font-weight: bold;
        color: #101010;
        line-height: 23px;

        img {
            margin-top: -3px
        }
    }

    .desc_lst {
        font-size: 0;

        li {
            display: inline-block;
            margin-left: 10px;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 300;
            color: #868686;
            line-height: 15px;
            vertical-align: bottom;
            letter-spacing: -0.2px;

            &:first-child {
                margin: 0
            }

            &:first-child:before {
                display: none;
            }

            &:before {
                display: inline-block;
                width: 1px;
                height: 14px;
                margin-right: 10px;
                background: #c8c8cf;
                vertical-align: bottom;
                content: '';
            }

            span {
                display: inline-block;
                font-weight: 500;
            }
        }
    }
    &.guild_rank {
        .info_container {
            margin-top: 13px;
        }
        .name {
            img {
                vertical-align: middle;
            }
            a {
                color: #222222;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .desc_lst {
            li {
                vertical-align: baseline;
            }
        }
        .lbl_outline.s_lbl {
            width: 53px;
            height: 22px;
            line-height: 22px;
        }
    }
}

.rank_page {
    .sub_sticky {
        padding-bottom: 30px;
    }
    .search_box {
        float: right;
        .search_inp {
            input {
                width: 469px;
                height: 48px;
            }
        }
    }
    .tbl_lst_indi {
        float: left;
    }
    .notice_txt {
        float: right;
        margin-top: 30px;
    }
    .search_box {
        .select {
            width: 130px;
            height: 50px;
            margin-right: -4px;
        }
    }
    .notice_txt_grp {
        float: left;
        margin-top: 13px;
        .notice_txt {
            float: none;
            margin-top: 0;
            line-height: 20px;
        }
    }
    .btn_grp {
        float: right;
        margin-top: 22px;
        .btn:not(:last-child) {
            margin-right: 5px;
        }
    }
    .notice_area {
        margin-top: 0;
        .notice_txt_grp {
            margin-top: 0;
        }
        .btn_grp{
            margin-top: 7px;
        }
    }
    .guild_info_area {
        .area_tit {
            float: left;
        }
    }
    .guild_info_tbl {
        margin-top: 30px;
        border-top: none;
        th, td {
            height: 54px;
            border: 1px solid #ebebeb;
            font-size: 16px;
            color: #222222;
        }
        th {
            font-weight: 400;
            border-bottom:none;
        }
        td {
            font-weight: 300;
            a{
                font-size: 16px;
                color: #222222;
                &:hover {
                    text-decoration: underline;

                }
            }
        }
        .guild_main_info {
            border: none;
            background: url("../img/guild_info_bg.jpg") no-repeat;
            .guild_name {
                font-size: 26px;
                font-weight: 400;
                color: #ffffff;
                img {
                    margin-top: -5px;
                    vertical-align: middle;
                }
            }
            dl {
                dt,dd {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 13px;
                    color: #ffffff;
                }
                dt {
                    margin-right: 7px;
                    color: rgba(255,255,255,.4);
                    &:after {
                        display: inline-block;
                        width: 1px;
                        height: 10px;
                        margin-left: 11px;
                        background: #afb3b8;
                        opacity: .2;
                        content: '';
                    }
                }
            }
        }
    }
    .guild_fm {
        margin-top: 27px;
        font-size: 16px;
        .tbl_lst {
            border-top: none;
        }
        th,td {
            height: auto;
            border-top:1px solid #e5e5e5;
            border-bottom:1px solid #e5e5e5;
            vertical-align: top;
            &>div {
                display: table-cell;
                min-height: 71px;
            }
        }
        th{
            &> div {
                display: block;
                line-height: 71px;
            }
        }
        td {
            padding-left: 30px;
            text-align: left;
            vertical-align: middle;
        }
        input[type="text"] {
            width: 100%;
            height: 36px;
            border:none;
            font-size: 14px;
            color: #222222;
            outline: none;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #a4a4a4;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #a4a4a4;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #a4a4a4;
            }
        }
        .rdo_lst_v2 {
            li {
                margin-top: 15px;
            }

            label img {
                vertical-align: middle;
            }
        }
    }
    .guild_popup {
        .dot_lst {
            margin-top: 11px;
        }
    }
    .calc_guide_pop {
        .dot_lst+p {
            margin-top: 31px;
        }
    }
    .file_upload_box {
        margin-top: 32px;
        margin-bottom: 26px;
        .guide_txt {
            display: inline-block;
            margin-left: 5px;
            font-size: 14px;
            font-weight: 300;
            color: #222222;
        }
        label {
            cursor: pointer;
        }
        .file_name {
            display: inline-block;
            margin-left: 6px;
            font-size: 14px;
            font-weight: 300;
            color: #868686;
            line-height: 30px;
        }
        .btn_del_file {
            margin-left:  9px;
            vertical-align: middle;
            .ico_del {
                display: block;
            }
        }
    }

    .rank_board_area {
        margin-top: 80px;
        &:first-child {
            margin-top: 40px;
        }
        .area_tit {
            &+.top_filter {
                margin-top: 40px;
            }
        }
        .clearfix > .area_tit {
            float: left;
            margin-top: 10px;
        }
        .tbl_lst {
            margin-top: 30px;
        }
    }

    .top3_area, .guild_info_area {
        margin-top: 40px;
    }
}