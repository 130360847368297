.preload_wrap {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999;
    background: #ffffff;
    .preload_bx {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        p {
            margin-top: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #5b5b5b;
        }
        .logo_loading {
            display: block;
            position: relative;
            width: 265px;
            height: 84px;
            margin: 0 auto;
            background: url("../img/loading_logo.png") no-repeat;
            .logo_loading_fill {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                background: url("../img/loading_logo_fill.png") no-repeat left bottom/ 265px 84px;
            }
        }
    }
}
.intro_page {
    background: #000;
    .wrap {
        overflow: auto;
        padding: 0;
        height: 100%;
    }
    .content > div {
        max-width: 2400px;
        margin:0 auto;
        .inner {
            width: 100%;
            max-width: 1920px;
            padding: 0;
        }
    }
    .animate_bg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .intro_top_area {
        position: relative;
        overflow: hidden;
        height: 1108px;
    }
    .ch_img_bx {
        position: absolute;
        top: 0;
        left: 330px;
        font-size: 0;
        &.fade_fx {
            transition-duration: 0.5s;
            transition-timing-function: ease-in;
        }
    }
    .top_cont_bx {
        position: absolute;
        left: 50%;
        top: 409px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        text-align: center;
        .fade_fx {
            transform: none;
        }
        .init_fx.fade_fx {
            transform: translateY(30px);
        }
    }
    .restart_txt {
        display: block;
        font-size: 0;
        transition-delay: 700ms!important;
    }
    .year_txt {
        display: block;
        margin-top: -103px;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 18px;
        transition-delay: 750ms!important;
    }
    .intro_txt {
        display: block;
        margin-top: 17px;
        font-size: 34px;
        font-weight: 300;
        color: #ffffff;
        letter-spacing: -0.4px;
        transition-delay: 800ms!important;
    }
    .btn_down {
        display: inline-block;
        margin-top: 136px;
        font-size: 0;
        transition-delay: 850ms!important;
        &.fade_fx {
            transition-property: opacity, transform;
        }
        &:hover {
            @extend .btn_down_hover;
        }
    }
    .area_tit {
        display: block;
        padding-left: 18px;
        font-size: 18px;
        font-weight: bold;
        line-height: 13px;
        color: #e80000;
        letter-spacing: 18px;
        text-indent: 18px;
        text-align: center;
    }
    .sub_txt {
        display: block;
        margin-top: 31px;
        font-family: 'NanumMyeongjo', sans-serif;
        font-size: 32px;
        color: #292929;
    }
    .desc_txt {
        display: block;
        margin-top: 19px;
        font-size: 16px;
        line-height: 26px;
    }
    .story_area {
        overflow: hidden;
        height: 398px;
        padding-top: 112px;
        text-align: center;
        background: url('../img/intro/story_bg.jpg') no-repeat center/auto 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .inner {
            position: relative;
        }
        .sub_txt,.desc_txt {
            &.init_fx.fade_fx {
                -webkit-transform: translateY(30px);
                -moz-transform: translateY(30px);
                -ms-transform: translateY(30px);
                -o-transform: translateY(30px);
                transform: translateY(30px);
            }
            &.fade_fx {
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
                transform: none;
            }
        }
        .sub_txt {
            transition-delay: 400ms!important;
        }
        .desc_txt {
            transition-delay: 450ms!important;
            color: rgba(0,0,0,.7);
        }
        .story_obj1,.story_obj2 {
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            -moz-transition-timing-function: linear!important;
        }
        .story_obj1 {
            margin-left: -757px;
            top: -65px;
            &.init_fx.fade_fx {
                margin-left: -807px;
            }
        }
        .story_obj2 {
            margin-left: 622px;
            top: 73px;
            &.init_fx.fade_fx {
                margin-left: 672px;
            }
        }
    }
    .feature_area {
        height: 669px;
        padding-top: 141px;
        text-align: center;
        background: url('../img/intro/feature_bg.jpg') no-repeat center/auto 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .feature_lst {
            margin-top: 49px;
            font-size: 0;
            li {
                display: inline-block;
                margin: 0 120px;
                text-align: center;
                &>* {
                    &.init_fx.fade_fx {
                        -webkit-transform: translateY(30px);
                        -moz-transform: translateY(30px);
                        -ms-transform: translateY(30px);
                        -o-transform: translateY(30px);
                        transform: translateY(30px);
                    }
                    &.fade_fx {
                        -webkit-transform: none;
                        -moz-transform: none;
                        -ms-transform: none;
                        -o-transform: none;
                        transform: none;
                    }
                }
                .ico_bx {
                     transition-delay: 0.25s!important;
                 }
                .ft_name {
                    transition-delay: 0.4s!important;
                }
                .ft_desc {
                    transition-delay: 0.55s!important;
                }
            }
            .ico_bx {
                display: block;
                position: relative;
                height: 134px;
            }
            .ico {
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            .ft_name {
                display:inline-block;
                margin-top: 37px;
                font-size: 32px;
                font-weight: 400;
                color: #ffffff;
            }
            .ft_desc {
                display:block;
                margin-top: 13px;
                font-size: 16px;
                font-weight: 300;
                line-height: 26px;
                color: #b3b3b2;
            }
        }
    }
    .character_area {
        position: relative;
        .inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .area_tit {
            position: absolute;
            top: 143px;
            left: 50%;
            z-index: 999;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        .ch_lst {
            li {
                position: relative;
                &.human_ch {
                    .ch_img {
                        &.init_fx.fade_fx {
                            right: -209px;
                        }
                    }
                }
                &.elf_ch {
                    .ch_img {
                        &.ch_img1 {
                            margin-left: -597px;
                            &.init_fx.fade_fx {
                                margin-left: -627px;
                            }
                        }
                        &.ch_img2 {
                            margin-left: 468px;
                            &.init_fx.fade_fx {
                                margin-left: 498px;
                            }
                        }
                    }
                }
                &.half_elf_ch {
                    .ch_img {
                        &.init_fx.fade_fx {
                            left: -274px;
                        }
                    }
                }
                &.dhan_ch {
                    .fade_fx {
                        -webkit-transition-duration: 1.5s;
                        -moz-transition-duration: 1.5s;
                        -ms-transition-duration: 1.5s;
                        -o-transition-duration: 1.5s;
                        transition-duration: 1.5s;
                    }
                    .ch_img {
                        &.ch_img1 {
                            margin-left: -508px;
                            &.init_fx.fade_fx {
                                margin-left: -538px;
                            }
                        }
                        &.ch_img2 {
                            margin-left: 446px;
                            &.init_fx.fade_fx {
                                margin-left: 476px;
                            }
                        }
                    }
                }
                &.dark_elf_ch {
                    .ch_img {
                        &.ch_img1 {
                            margin-left: -581px;
                            &.init_fx.fade_fx {
                                margin-left: -611px;
                            }
                        }
                        &.ch_img2 {
                            margin-left: 644px;
                            &.init_fx.fade_fx {
                                margin-left: 674px;
                            }
                        }
                    }
                }
                &.dekan_ch {
                    .ch_txt_bx.txt_right+.ch_img {
                        right: 21px;
                        &.init_fx.fade_fx {
                            right: -11px;
                        }
                    }
                }
                &.trinity_ch {
                    .ch_txt_bx.txt_right+.ch_img {
                        right: -82px;
                        &.init_fx.fade_fx {
                            right: -112px;
                        }
                    }
                }
                &.giant_ch {
                    .ch_txt_bx.txt_left+.ch_img {
                        left: -75px;
                        &.init_fx.fade_fx {
                            left: -105px;
                        }
                    }
                }
            }
            .ch_img,.ch_txt_bx {
                position: absolute;
            }
            .ch_img {
                bottom: 0;
                top: auto;
                left: auto;
                transition-duration: 0.5s;
            }
            .ch_txt_bx {
                top: 373px;
                z-index: 99;
                .init_fx.fade_fx {
                    -webkit-transform: translateY(30px);
                    -moz-transform: translateY(30px);
                    -ms-transform: translateY(30px);
                    -o-transform: translateY(30px);
                    transform: translateY(30px);
                }
                .fade_fx {
                    transition-duration: 2s;
                    -webkit-transform: none;
                    -moz-transform: none;
                    -ms-transform: none;
                    -o-transform: none;
                    transform: none;
                }
                &.txt_right {
                    right: 947px;
                    &+.ch_img {
                        right: -179px;
                    }
                }
                &.txt_left {
                    left: 956px;
                    &+.ch_img {
                        left: -244px;
                    }
                }
                &.txt_center {
                    right: auto;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    &~.ch_img {
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                }
            }
            .bg_img_bx {
                position: relative;
                overflow: hidden;
                height: 1087px;
                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                }
            }
            .type {
                display: block;
                margin-right: -18px;
                font-size: 18px;
                font-weight: 400;
                color: #cab18c;
                letter-spacing: 18px;
                transition-delay: 0.7s!important;
            }
            .name {
                display: inline-block;
                position: relative;
                font-size: 0;
                transition-delay: 0.75s!important;
                &:after {
                    position: absolute;
                    top: 100%;
                    width: 28px;
                    height: 2px;
                    margin-top: 30px;
                    background: #cab18c;
                    content: '';
                }
            }
            .txt_right .name:after {
                right: 0;
            }
            .txt_center .name:after {
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            .txt_left .name:after {
                left: 0;
            }
            .sub_txt {
                margin-top: 59px;
                font-size: 40px;
                color: #222222;
                letter-spacing: -0.7px;
                transition-delay: 0.8s!important;
            }
            .desc {
                margin-top: 9px;
                font-weight: 300;
                color: #868686;
                line-height: 25px;
                transition-delay: 0.85s!important;
            }
            .sld_btn {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 9999;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                outline: none;
                z-index: 999;
                span {
                    display: block;
                }
                &.prev_btn {
                    margin-left: -683px;
                }
                &.next_btn {
                    margin-left: 691px;
                }
            }
            .slick-dots {
                position: absolute;
                left: 50%;
                bottom: 118px;
                z-index: 999;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                font-size: 0;
                li {
                    display: inline-block;
                    button {
                        display: block;
                        @extend .ch_lst_indi;
                        font-size:0;
                        outline: none;
                    }
                    &.slick-active button, button:hover {
                        @extend .ch_lst_indi_hover;
                    }
                    &~li {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    & .content >div.monster_area {
        position: relative;
        overflow: hidden;
        height: 1538px;
        padding-top: 142px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .inner {
            max-width: 1410px;
            height: 100%;
        }
        .monster_info {
            position: absolute;
            top: 366px;
            left: 121px;
            text-align: right;
            &>* {
                &.init_fx.fade_fx {
                    -webkit-transform: translateY(30px);
                    -moz-transform: translateY(30px);
                    -ms-transform: translateY(30px);
                    -o-transform: translateY(30px);
                    transform: translateY(30px);
                }
                &.fade_fx {
                    -webkit-transform: none;
                    -moz-transform: none;
                    -ms-transform: none;
                    -o-transform: none;
                    transform: none;
                }
            }
            .name {
                display: inline-block;
                position: relative;
                font-size: 0;
                -webkit-transition-property: opacity, transform;
                -moz-transition-property: opacity, transform;
                -ms-transition-property: opacity, transform;
                -o-transition-property: opacity, transform;
                transition-property: opacity, transform;
                &:after {
                    position: absolute;
                    top: 100%;
                    right: 0;
                    width: 28px;
                    height: 2px;
                    margin-top: 30px;
                    background: #cab18c;
                    content: '';
                }
            }
            .sub_txt {
                margin-top: 59px;
                font-size: 40px;
                color: #ffffff;
                letter-spacing: -0.7px;
                transition-delay: 50ms!important;
            }
            .desc {
                margin-top: 12px;
                font-weight: 300;
                color: #a4a4a4;
                line-height: 25px;
                transition-delay: 100ms!important;
            }
            .intro_btn {
                display: inline-block;
                margin-top: 55px;
                @extend .intro_def_btn;
                font-size: 18px;
                font-weight: 400;
                color: #222222;
                outline: none;
                transition-delay: 150ms!important;
                transition-property: opacity, transform;
                &:hover {
                    @extend .intro_def_btn_hover;
                }
            }
        }
    }
    header {
        -webkit-transition: background 0.4s;
        -moz-transition: background 0.4s;
        -ms-transition: background 0.4s;
        -o-transition: background 0.4s;
        transition: background 0.4s;
    }
    footer {
        position: absolute;
        bottom: 89px;
        left: 0;
        width: 100%;
        margin-bottom: -89px;
        background: transparent;
        border-top: none;
        color: rgba(255,255,255,.3);
        line-height: 22px;
    }

    .fade_fx {
        -webkit-transition: all 1.1s cubic-bezier(.31,1.02,.93,1.01);
        -moz-transition: all 1.1s cubic-bezier(.31,1.02,.93,1.01);
        -ms-transition: all 1.1s cubic-bezier(.31,1.02,.93,1.01);
        -o-transition: all 1.1s cubic-bezier(.31,1.02,.93,1.01);
        transition: all 1.1s cubic-bezier(.31,1.02,.93,1.01);
        opacity: 1;
    }
    .init_fx {
        opacity: 0;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
    }
    .bg_fx .animate_bg{
        transition: height 7s ease-out;
    }
    .zoomed .animate_bg{
        height: 115%;
    }
    .intro_close_btn {
        position: fixed;
        top: 110px;
        right: 37px;
        z-index: 999;
        font-size: 0;
    }
    .ico_top_btn,.ico_down_btn {
        display: block;
        position: fixed;
        bottom: 110px;
        right: 47px;
        font-size: 0;
    }
}