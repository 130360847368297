.tbl_lst_indi {
    margin-top: 30px;
    height: 13px;
    li {
        display: inline-block;
        height: 100%;
        a {
            display: inline-block;
            font-size: 15px;
            line-height: 13px;
            color: #868686;
            vertical-align: top;
        }
        & ~ li {
            margin-left: 16px;
            &:before {
                display: inline-block;
                width: 1px;
                height: 13px;
                margin-right: 20px;
                background: #c8c8cf;
                vertical-align: top;
                content: '';
            }
        }
        &.active {
            a {
                color: #222222;
            }
        }
    }
}

.tbl_lst {
    width: 100%;
    border-top: 2px solid #384859;
    border-bottom: 1px solid #a4a4a4;
    font-size: 16px;
    text-align: center;

    &.bot_line_gray {
        border-bottom: 1px solid #e5e5e5;
    }

    &.top_line_gray {
        border-top: 1px solid #e5e5e5;
    }
    &.top_line_gray2 {
        border-top: 1px solid #a4a4a4;
    }

    &.high_lh {
        th,td {
            height: 70px;
        }
    }

    &.type1 {
        th,td {
            padding-top: 8px;
            padding-bottom: 7px;
        }
    }

    th {
        font-weight: 400;
    }

    thead th {
        height: 60px;
        border-bottom: 1px solid #dfdfdf;
        font-weight: 400;
        background-color: #f8f8f8;

        &.txt_left {
            padding-left: 40px;
        }
    }

    tbody tr {
        border-top: 1px solid #dfdfdf;

        &:first-child {
            border-top: 0;
        }

        &.top_rank_row {
            td {
                font-weight: 400;
                .r_change {
                    top: 10px;
                }
            }
        }
    }

    .nodata {
        height: 480px;

        .ico {
            display: block;
            margin: 8px auto 0;
        }

        .txt_msg {
            display: block;
            margin: 12px auto 0;
            font-size: 24px;
            font-weight: normal;
            color: $gray2;
            letter-spacing: -0.02em;
            text-align: center;
        }
    }

    td {
        height: 60px;
        font-weight: 300;
        color: $black2;

        &.txt_left {
            padding-left: 40px;
        }

        &.guild_name_cell {
            padding-left: 145px;

            a {
                font-size: 16px;
                color: #222222;
            }
        }

        &.guild_name_cell2 {
            padding-left: 88px;

            img {
                margin-top: 4px;
            }
        }

        a {
            display: inline-block;
            font-size: 0;
            line-height: 20px;
            vertical-align: top;

            &:hover {
                text-decoration: underline;

                .tit {
                    text-decoration: underline;
                }
            }

            &.btn {
                text-decoration: none;
            }
        }

        .lbl_outline {
            &+.tit {
                max-width: 740px;
                margin-left: 10px;
            }
        }

        .tit {
            overflow: hidden;
            display: inline-block;
            max-width: 870px;
            font-size: 16px;
            color: $black2;
            line-height: 28px;
            letter-spacing: -0.005em;
            vertical-align: top;
            text-overflow: ellipsis;
            white-space: nowrap;

            &+.ico_attach{
                margin-left: 12px;
            }
        }

        .number {
            display: inline-block;
            margin-left: 12px;
            font-size: 14px;
            font-weight: 300;
            color: $gray2;
            line-height: 25px;
            vertical-align: top;

            &+.txt_new {
                margin-left: 4px;
            }
        }

        .ico_attach {
            display: inline-block;
            margin-left: 4px;
        }

        .txt_new {
            line-height: 26px;
        }

        em {
            font-weight: bold;
        }

        .ico_change {
            display: inline-block;
            font-size: 11px;
            margin: 0 4px;
            line-height: 19px;
        }

        .txt_result {
            font-size: 14px;
        }
    }

    .cell_v1 {
        background-color: #f8f8f8;

        td {
            font-weight: 400;
        }
    }

    .cell_v2 {
        background-color: #f3f3f4;
    }

    .cell_v3 {
        background-color: #f2f2f2;
    }

    .r_num {
        display: inline-block;
        position: relative;
        width: 26px;
        vertical-align: middle;
        .r_change {
            position: absolute;
            top: 7px;
            left: 100%;
            height: 9px;
            margin-left: 10px;
            font-size: 13px;
            line-height: 9px;
            color: #868686;
            white-space: nowrap;
            &:before {
                display: inline-block;
                width: 7px;
                height: 1px;
                margin-right: 7px;
                background-color: #8b8c92;
                vertical-align: middle;
                content: '';
            }
            &.r_up:before, &.r_down:before {
                display: inline-block;
                background-color: transparent;
            }
            &.r_up:before {
                margin-top: -3px;
                @extend .up;
            }
            &.r_down:before {
                margin-top: -2px;
                @extend .down;
            }
        }
        [class^="lbl_top_"] {
            display: block;
        }
    }

    .lbl_top_1 {
        display: inline-block;
        font-size: 0;
        vertical-align: middle;
        @extend .s_rank_1;
    }

    .lbl_top_2 {
        display: inline-block;
        font-size: 0;
        vertical-align: middle;
        @extend .s_rank_2;
    }

    .lbl_top_3 {
        display: inline-block;
        font-size: 0;
        vertical-align: middle;
        @extend .s_rank_3;
    }

    .sort_btn {
        font-size: 16px;
        outline: none;
        &:after {
            display: inline-block;
            margin-left: 8px;
            margin-top: 9px;
            @extend .down;
            vertical-align: top;
            content: '';
        }
        &.no_btn:after {
            margin-top: 7px;
        }
        &.sort_up:after {
            transform: rotateZ(180deg);
        }
    }

    &.question {
        a {
            display: block;
            position: relative;
            line-height: 72px;
    
            &:after {
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 50px;
                @include sp-normal('ico_arr_faq');
                transform: translateY(-50%);
                content: '';
            }

            &:hover {
                .tit {
                    text-decoration: none;
                }
            }
        }
    
        .txt_q {
            display: inline-block;
            width: 117px;
            font-family: 'notosans', sans-serif;
            font-size: 24px;
            font-weight: bold;
            color: #404dab;
            line-height: 69px;
            text-align: center;
            vertical-align: top;
        }

        .lbl_outline {
            vertical-align: middle;
        }
        
        .tit {
            padding-left: 0;
            line-height: 72px;
        }
    }
    &.download_faq tr > * {
        &:first-child {
            padding-left: 50px;
            text-align: left;
            box-sizing: border-box;
        }

        &:nth-child(2) {
            padding-right: 50px;
            text-align: left;
            box-sizing: border-box;
        }
    }

    &.novel {
        .txt_left {
            padding-left: 20px;
        }

        .tit {
            max-width: 240px;
            font-weight: 400;
            color: $black2;
        }

        .number {
            margin-left: 8px;
        }
    
        .txt_novel {
            display: inline-block;
            max-width: 776px;
            padding: 17px 0 18px;
            font-size: 14px;
            font-weight: 300;
            color: $black2;
            line-height: 20px;
            vertical-align: top;

            &:hover {
                text-decoration: underline;
            }
        }
    
        &+.pagination {
            margin-top: 100px;
        }
    }

    .dash {
        font-size: 18px;
        font-weight: 300;
        padding: 0 12px;
    }

    .at_sign {
        font-size: 16px;
        font-weight: 300;

        &+.domain {
            margin: -6px 0 0 21px;
        }
    }

    .sms {
        margin-left: 44px;
    }

    .certification_box {
        display: inline-block;
        margin-left: 27px;

        &:before {
            display: inline-block;
            width: 1px;
            height: 16px;
            background-color: #c8c8cf;
            vertical-align: middle;
            content: '';
        }

        input {
            width: 104px;
            margin-left: 25px;
        }

        .countdown {
            font-weight: 400;
            color: #404dab;
        }

        .btn {
            margin-left: 12px;
        }
    }

    .message {
        overflow: hidden;
        display: none;
        font-size: 14px;
        font-weight: 300;
        color: #b83d3d;
        line-height: 24px;
        vertical-align: top;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .validate {
        .message {
            display: block;
        }
    }
}

.write_area {
    .area_tit {
        margin-bottom: 17px;
    }

    .tbl_lst {
        border-bottom: 0;

        .editor {
            border-top: 0;
        }

        th,
        td {
            height: 74px;
            text-align: left;
        }

        th {
            padding-left: 29px;
            font-size: 14px;
            font-weight: 400;
        }

        .rdo_lst {
            font-size: 14px;
            font-weight: 400;

            li {
                margin-left: 24px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .rdo_v2 {
            input:checked + label {
                padding-top: 1px;
                font-weight: 400;
                color: #384859;
            }

            label {
                display: inline-block;
                font-weight: 300;
                color: $gray2;

                &:before {
                    margin: -2px 5px 0 0;
                }
            }
        }
        .sms {
            margin-left: 44px;
        }

        .file_upload_box {
            margin: 10px 0;
        }
    }
}