.my_page {
    &.completed_layout {
        .wrap {
            padding-top: 111px;
        }
        .content > .inner {
            padding-bottom: 189px;
        }
    }
    .user_info_area {
        padding-top: 49px;
        border-top:2px solid #384859;
    }
    .user_tbl_wrap {
        overflow: hidden;
        border:1px solid #a4a4a4;
    }
    .user_info {
        width: 1410px;
        margin: -1px;
        border-top: none;
        .greeting {
            height: 212px;
            padding: 0 60px;
            border:1px solid #a4a4a4;
            border-right: 1px solid #ebebeb;
            font-size: 26px;
            font-weight: 300;
            line-height: 24px;
            color: #222222;
            text-align: left;
            strong {
                display: block;
                margin-top: 13px;
                font-size: 32px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: -1.7px;
            }
        }
        th,td {
            border: 1px solid #ebebeb;
            text-align: center;
            &:last-child {
                border-right:1px solid #a4a4a4;
            }
        }
        th {
            height: 62px;
            border-top:1px solid #a4a4a4;
            border-left: none;
        }
        td {
            border-left: none;
            padding: 27px 0 33px;
            border-bottom: 1px solid #a4a4a4;
            strong {
                display: block;
                font-weight: 500;
                font-size: 28px;
                line-height: 32px;
                [class^="ico_"] {
                    display: inline-block;
                    margin-right: 8px;
                    margin-top: 7px;
                    vertical-align: top;
                }
            }
            a {
                display: inline-block;
                margin-top: 25px;
                font-size: 14px;
                color: #868686;
                .ico_quest {
                    display: inline-block;
                    margin-right: 4px;
                    vertical-align: text-bottom;
                    &+span {
                        position: relative;
                        &:before {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: #868686;
                            content: '';
                        }
                    }
                }
                &.btn {
                    width: 130px;
                    height: 42px;
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 42px;
                    color: #ffffff;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .user_info_area + .tab_lst {
        margin-top: 80px;
    }
    .write_area, .acc_sanctions_area {
        margin-top: 39px;
        .clearfix {
            .area_tit {
                float: left;
                margin-bottom: 0;
                line-height: 48px;
            }
            .note_txt {
                float: right;
                .btn {
                    width: 67px;
                    height: 28px;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 28px;
                }
            }
        }
        .tbl_lst {
            margin-top: 19px;
        }
    }
    .write_area {
        .clearfix .note_txt {
            margin-top: 18px;
        }
        .tbl_lst {
            border-top: 1px solid #a4a4a4;
            border-bottom: 1px solid #a4a4a4;
            tr:first-child {
                th,td {
                    border-top: none;
                }
            }
            th {
                height: auto;
                padding: 26px 0 0 31px;
                border-top: 1px solid #e5e5e5;
                font-size: 15px;
                vertical-align: top;
            }
            td {
                height: 46px;
                padding: 14px 0;
                span+.btn {
                    margin-left: 35px;
                }
                input+.btn {
                    margin-left: 20px;
                }
            }
            .dot_lst {
                li:before {
                    top: 9px;
                }
                li~li {
                    margin-top: 3px;
                }
            }
            .valign_top {
                th {
                    padding-top: 25px;
                    vertical-align: top;
                }
                td {
                    padding-top: 14px;
                    padding-bottom: 16px;
                }
            }
            .chkbox.email {
                margin-top: 15px;
            }
            &.tbl_private {
                tr:last-child {
                    td {
                        font-size: 0;
                    }
                    .btn {
                        margin-left: 50px;
                    }
                }
                .chkbox.sms {
                    margin-left: 30px;
                }
            }
        }
        .cf_ct {
            margin-top: 20px;
            padding-top: 24px;
            border-top: 1px solid #a4a4a4;
            letter-spacing: -0.5px;
            .cf_txt {
                font-size: 20px;
                font-weight: 500;
                color: #101010;
                .username {
                    color: #404dab;
                }
            }
            .caution_txt {
                margin-top: 30px;
                font-size: 18px;
                font-weight: 500;
                color: #101010;
            }
            .dot_lst {
                margin-top: 11px;
                margin-bottom: -3px;
                li~li {
                    margin-top: 11px;
                }
            }
        }
    }
    .result_bx {
        .txt {
            margin-top: 0;
        }
    }
    .precaution_done {
        width: 971px;
        margin: 52px auto 0;
        th {
            font-size: 18px;
            font-weight: 500;
        }
        td {
            padding: 23px 36px 25px;
            text-align: left;
        }
        .dot_lst {
            li~li {
                margin-top: 11px;
            }
        }
        &+.btn_wrap {
            margin-top: 40px;
        }
    }
    .acc_sanctions_area {
        .clearfix .note_txt {
            margin-top: 26px;
        }
    }
    .tbl_lst {
        .nodata {
            height: 480px;
            .ico{
                display: block;
                margin: auto;
            }
            .txt_msg {
                display: block;
                margin-top: 10px;
                font-size: 24px;
                font-weight: 400;
                color: #868686;
            }
        }
    }
    .tabs_lnk_lst {
        margin-top: 40px;
        font-size: 0;
        li {
            margin-right: 14px;
        }
        li~li {
            &:before {
                margin-right: 13px;
            }
        }
        li.on a,a:hover {
            color: #404dab;
        }
        &+.editor_area {
            h5:first-child {
                margin-top: 76px;
                &+.tbl_lst {
                    margin-top: 20px;
                }
            }
        }
    }
}