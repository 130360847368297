/* SVG Icons */

// i {
//     display: inline-block;
// }

// $folder: "svg";
// @each $icon in svg-list($folder) {
//     $url: inline-svg($icon, #fff);
//     .#{$icon} {
//         background-image: $url;
//         background-position: center;
//         background-size: 100% auto;
//         background-repeat: no-repeat;
//     }
// }