// Server Status labels
.server_status {
    display: inline-block;
    width: 44px;
    background-color: $blue;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    vertical-align: top;
    &.inspect {
        background-color: $gray;
    }
    &.crowded {
        background-color: $red;
    }
    &.uncrowded {
        background-color: $green;
    }
    &.recommand {
        background-color: $yellow;
    }
}

// Shop Labels
.shop_label {
    display: inline-block;
    width: 66px;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    &.best {
        background-color: $yellow;
    }
    &.new {
        background-color: $blue;
    }
    &.md {
        background-color: $green;
    }
}

.lbl {
    display: inline-block;
    min-width: 70px;
    padding: 0 5px;
    font-size: 13px;
    color: $white;
    line-height: 24px;
    text-align: center;
    vertical-align: top;
    box-sizing: border-box;

    &.red {
        background-color: $red;
    }

    &.yellow {
        background-color: $yellow;
    }

    &.green {
        background-color: $green;
    }

    &.blue {
        background-color: $blue;
    }

    &.purple {
        background-color: $purple;
    }
}

.lbl_outline {
    display: inline-block;
    min-width: 72px;
    height: 26px;
    padding: 0 5px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    box-sizing: border-box;
    &.red {
        border: 1px solid $red;
        color: $red;
    }
    &.yellow {
        border: 1px solid $yellow;
        color: $yellow;
    }
    &.green {
        border: 1px solid $green;
        color: $green;
    }
    &.blue {
        border: 1px solid $blue;
        color: $blue;
    }
    &.purple {
        border: 1px solid $purple;
        color: $purple;
    }
    &.black {
        border: 1px solid $black2;
        color: $black2;
    }
    &.s_lbl {
        width: 55px;
        height: 24px;
        line-height: 24px;
    }
    &.txt_s {
        font-size: 12px;
    }
}